import React, { Component } from 'react'
import HeaderComponent from '../components/Header/Header'
import Sidebar from '../components/Sidebar/Sidebar'
import { connect } from 'react-redux'
import '../components/_globals/layouts.scss';
import { setIsMobile } from '../actions/actions'
import { setUserData } from '../actions/userActions'
import DesktopHeader from '../components/DesktopHeader/DesktopHeader';
import DesktopSidebar from '../components/DesktopSidebar/DesktopSidebar';
import Loader from '../components/Loader/Loader';
import { Layout } from 'antd';
import { initiateSocket, socket } from '../utils/Socket'
// import axios from 'axios';
// import cf from '../config/APIConfig'
import axiosBW from '../utils/Http';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
const { Header, Content, Sider } = Layout;


class MainLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    if (window.innerWidth < 768) {
      this.props.setIsMobile(true)
    } else {
      this.props.setIsMobile(false)
    }
    window.addEventListener('resize', () => {
      this.resize()
    })
    await this.syncUserData()
    if ((!socket || socket.disconnected) && this.props.email) {
      initiateSocket(this.props.email, this.props.companyId, this.props.firstName, this.props.lastName)
    }
  }
  async syncUserData() {
    try {
      console.log('USER INFO')
      const res = await axiosBW.get('/auth/user-info')
      this.setState({
        messageForm: {
          title: '',
          description: '',
          icon: ''
        }
      })
      const user = res.data.payload.user
      const token = res.data.payload.token
      if(res.data.message.length === 0) {
        this.props.setUserData(user, token)
        if (user.haveToCompleteData) {
          this.props.history.push('/ultimo_paso')
        } else if (user.firstRedirect) {
          this.props.history.push(user.firstRedirect)
        }
      }
    } catch (err) {
      console.log(err.response)
      if (err.response) {
        
      }
      localStorage.clear()
      this.props.history.push('/ingresar')
    }
  }
  
  resize() {
    if (window.innerWidth < 768) {
      this.props.setIsMobile(true)
    } else {
      this.props.setIsMobile(false)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {
      this.resize()
    })
  }

  render() {
    if(!this.props.isMobile) {
      return (
        <Layout className="desktop_layout">
          <Header className="desktop_layout__header" style={{ position: 'fixed', zIndex: 100, width: '100%', maxWidth: '1580px', padding: '0px'}}>
            <DesktopHeader type='full' />
            {this.props.adminMessage ? (<div className="admin-message" dangerouslySetInnerHTML={{ __html: this.props.adminMessage}}></div>): ''}
          </Header>
          <Layout>
            <Sider className="desktop_layout__side">
              <DesktopSidebar history={this.props.history} />
            </Sider>
            <Layout>
              <Content className="desktop_layout__content">
              { this.props.token ? this.props.children : '' }
              </Content>
            </Layout>
          </Layout>
          <Loader />
        </Layout>
      )
    } else {
      return (
        <>
          <div className="main_layout__wrapper">
            <div className="main_layout__header">
              <HeaderComponent />
              {this.props.adminMessage ? (<div className="admin-message_mobile" dangerouslySetInnerHTML={{ __html: this.props.adminMessage}}></div>): ''}
            </div>
            <div className="main_layout__sidebar">
              <Sidebar history={this.props.history} />
            </div>
            <main className="main_layout__content">
              { this.props.token ? this.props.children : '' }
            </main>
          </div>
          <Loader />
        </>
      )
    }
    
  }
}

const mapStateToProps = (state) => ({
  isMobile: state.navigation.isMobile,
  email: state.user.email,
  companyId: state.user.companyId,
  firstName: state.user.firstName,
  lastName: state.user.lastName,
  adminMessage: state.user.adminMessage,
  token: state.user.token
})

const mapDispatchToProps = (dispatch) => ({
  setIsMobile: (bool) => dispatch(setIsMobile(bool)),
  setUserData: (user, token) => dispatch(setUserData(user, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)