import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import { inputChange } from '../../../utils/Helpers'

import './ChangePassword.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import validations from '../Register_old/Register.validations'
// import Axios from 'axios';
// import cf from '../../../config/APIConfig'
import axiosBW from '../../../utils/Http'
import { setGlobalLoader } from '../../../actions/actions'
import { Helmet } from 'react-helmet';


class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formPassword: '',
      formPasswordError: {
        title: '',
        description: '',
        icon: '',
      },
      formPasswordRepeated: '',
      resetPasswordMessage: {
        icon: '',
        description: ``,
      },
      currStep: '1',
      currentToken: ''
    }

    // form refs
  }
  componentDidMount() {
    this.setState({
      currentToken: this.props.match.params.token
    })
    this.props.setGlobalLoader(false)

  }
  
  goToIndex(e) {
    e.preventDefault()
    this.props.history.push('/')
  }

  goToReintent(e){
    e.preventDefault()
    this.setState({
      currStep: '1'        
    })
  }

  doChangePassword(e) {
    e.preventDefault()
    let value = 'wrong'
    if(validations.matchPasswords(this.state.formPassword, this.state.formPasswordRepeated).error !== 0) {
      value = 'wrong'
      this.setState({
        formPasswordError: {
          title: 'Error en Contraseñas',
          description: 'Las contraseñas no coinciden',
          icon: <FontAwesomeIcon icon="exclamation-triangle" />
        }
      })
      return 
    } else {
      value = 'ok'
      this.setState({
        formPasswordError: {
          title: '',
          description: '',
          icon: ''
        }
      })
    }
    if(validations.lengthPassword(this.state.formPassword).error !== 0 && validations.lengthPassword(this.state.formPasswordRepeated).error !== 0) {
      value = 'wrong'
      this.setState({
        formPasswordError: {
          title: 'Error en Contraseñas',
          description: 'El largo mínimo de la contraseña debe ser de 6 caracteres',
          icon: <FontAwesomeIcon icon="exclamation-triangle" />
        }
      })
      return 
    } else {
      value = 'ok'
      this.setState({
        formPasswordError: {
          title: '',
          description: '',
          icon: ''
        }
      })
    }

    if(value !== 'ok') {
      return true
    } else {
      axiosBW.get(`/auth/reset-password-validate/${this.state.currentToken}`)
      .then(response => {
        console.log('response', response.data)
        axiosBW.post('/auth/reset-password', {
            token: this.state.currentToken,
            newPassword: this.state.formPassword
        })
        .then(res => {
          console.log('RESPUESTA reset-password', res.data)
          if(res.data.payload) {
            this.setState({
              currStep: '2',
              resetPasswordMessage: {
                icon: <FontAwesomeIcon className="guest__check_icon--inside" icon={["far", "check-circle"]} />,
                description: `Se ha cambiado la contraseña correctamente. Intente iniciar sesión con la nueva contraseña ingresada.`,
              } 
              
            })
          }
          
        })
        .catch(err => {
          if(err.response.data.message) {
            let msg = err.response.data.message
            switch(msg){
              case 'No user':
                msg = 'El correo ingresado no posee una cuenta o aún no ha sido habilitada'
              break;
              case 'New password cant be equal to actual password':
                msg = 'Contraseña no puede ser igual a la actual'
              break;
              case 'Token Expired':
                msg = 'Token Expirado'
              break;
              case 'Invalid Token':
                msg = 'Token no válido'
              break;
              case 'Token Used':
                msg = 'Token ya fue utilizado'
              break;
              case 'New password cant be equal to old password':
                msg = 'Contraseña ya fue utilizada anteriormente'
              break;
              default:
                msg = 'Intente nuevamente'
            }
            this.setState({
              currStep: '2',
              resetPasswordMessage: {
                title: 'Error',
                icon: <FontAwesomeIcon className="guest__check_icon--inside" icon="exclamation-triangle" />,
                description: `Hubo un error al intentar restablecer la contraseña. ${msg}`,
              } 
            })
          } else {
            this.setState({
              currStep: '2',
              resetPasswordMessage: {
                title: 'Error',
                icon: <FontAwesomeIcon className="guest__check_icon--inside" icon="exclamation-triangle" />,
                description: `Hubo un error al intentar restablecer la contraseña. Intente nuevamente`,
              } 
            })
          }
          
        })
      })
      .catch(error => {
        console.log('error', error)
        if(error.response.data.message && error.response.data.message === 'Invalid Token') {
          this.setState({
            resetPasswordMessage: {
              title: 'Token Inválido',
              description: <p>El token Enviado es inválido o ha sido adulterado, por favor contactar a soporte técnico (support@andes-cs.com) o solicitar nuevamente el cambio de contraseña.</p>,
              icon: <FontAwesomeIcon icon="exclamation-triangle" />
            },
            currStep: 2
          })
        } else {
          this.setState({
            resetPasswordMessage: {
              title: 'Error',
              description: 'Se ha producido un error al generar el cambio de contraseña. Por favor intente nuevamente restablecer su contraseña y en caso que el problema continúe, contactar a soporte técnico (support@andes-cs.com).',
              icon: <FontAwesomeIcon icon="exclamation-triangle" />
            },
            currStep: 2
          })
        }
        
      })
      
      
    }
  }

  ChangePasswordComponent() {
    return (
      <div className="guest__wrapper">
        <section className="forgot">
          <header className="guest__title">
            <h1> Cambio de Contraseña </h1>
          </header>
          <form className="guest__form" noValidate>
            <div className="guest__form_element">
              <label className="guest__form_label"> Ingrese Su Nueva Contraseña </label>
              <input 
                className="guest__form_input" 
                onChange={(e) => inputChange(this, 'formPassword', e.target.value)} 
                type="password" 
                value={this.state.formPassword} 
              />
            </div>
            <div className="guest__form_element">
              <label className="guest__form_label"> Repita Su Nueva Contraseña </label>
              <input 
                className="guest__form_input" 
                onChange={(e) => inputChange(this, 'formPasswordRepeated', e.target.value)} 
                type="password" 
                value={this.state.formPasswordRepeated} 
              />
              {this.state.formPasswordError.title.length > 0 ? (
                <>
                  <div className={`guest__message_form`}>
                    <h2>{this.state.formPasswordError.icon} {this.state.formPasswordError.title}</h2>
                    <p>{this.state.formPasswordError.description}</p>
                  </div>
                </>
              ): ''}
            </div>
            <div className="guest__form_element">
              <button 
                className="guest__button button_padding"
                onClick={(e) => this.doChangePassword(e)}
                ref={this.refLoginButton}
              >
                <span>Restablecer mi Contraseña</span>
              </button>
            </div>
          </form>
        
        </section>
        <Helmet>
          <title>Cambiar Contraseña - Blizwork</title>
        </Helmet>
      </div>
    )
  }

  SuccessMessageComponent() {
    return (
      
      <section className="register h_white_block">
          <header className="guest__title">
            <h1>Cambio de Contraseña</h1>
          </header>
          <div className="guest__subtitle--nobg">
            <p>{this.state.resetPasswordMessage.description}</p>
          </div>
          <div className="guest__check_icon">
            {this.state.resetPasswordMessage.icon}
          </div>
          <div className="guest__form_element button_padding">
          {this.state.resetPasswordMessage.title === 'Error' ? (
              <button 
                className="guest__button"
                onClick={(e) => this.goToReintent(e)}
                id="intentar"
              >
                <span>Volver a Intentarlo</span>
                </button>
          ) : ''}
                <br></br>
                <button 
                className="guest__button"
                onClick={(e) => this.goToIndex(e)}
                id="register"
                ref={this.refLoginButton}
              >
                <span>Volver al Inicio</span>
              </button>
            </div>
            <Helmet>
              <title>Cambio Exitoso - Blizwork</title>
            </Helmet>
        </section>
    )
  }
  
  render() {
      console.log('currStep', this.state.currStep)
      if(this.props.isMobile) {
        if(this.state.currStep === '1') {
          return this.ChangePasswordComponent()
        } else {
          return this.SuccessMessageComponent()
        }
      } else {
          return (
            <>
            <div className="guest__header--desktop">
              <img src="/img/logo-mini.png" alt="blizwork logo" />
            </div>
            <div className="guest__wrapper--desktop">
              <div className="guest__side_image">
  
              </div>
              <div className="guest__content h_center_flex">
                {this.state.currStep === '1' ? (
                  this.ChangePasswordComponent()
                ) : (
                  this.SuccessMessageComponent()
                )}
              </div>
            </div>
            
            </>
          )
      }
  }
}

const mapStateToProps = (state) => ({
  isMobile: state.navigation.isMobile
})
const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)