// import axios from 'axios'
// import cf from '../config/APIConfig'
import axiosBW from '../utils/Http';


export const GET_WORKFLOW_INFO_BEGIN   = 'GET_WORKFLOW_INFO_BEGIN';
export const GET_WORKFLOW_INFO_SUCCESS = 'GET_WORKFLOW_INFO_SUCCESS';
export const GET_WORKFLOW_INFO_FAILURE = 'GET_WORKFLOW_INFO_FAILURE';
export const CHANGE_ACTIVE_PROPERTIES = 'CHANGE_ACTIVE_PROPERTIES';
export const GET_USERS_BY_ROLE_BEGIN   = 'GET_USERS_BY_ROLE_BEGIN';
export const GET_USERS_BY_ROLE_SUCCESS = 'GET_USERS_BY_ROLE_SUCCESS';
export const GET_USERS_BY_ROLE_FAILURE = 'GET_USERS_BY_ROLE_FAILURE';
export const GET_VERSION_LIST_BEGIN   = 'GET_VERSION_LIST_BEGIN';
export const GET_VERSION_LIST_SUCCESS = 'GET_VERSION_LIST_SUCCESS';
export const GET_VERSION_LIST_FAILURE = 'GET_VERSION_LIST_FAILURE';
export const GET_WORKFLOW_VERSION_BEGIN   = 'GET_WORKFLOW_VERSION_BEGIN';
export const GET_WORKFLOW_VERSION_SUCCESS = 'GET_WORKFLOW_VERSION_SUCCESS';
export const GET_WORKFLOW_VERSION_FAILURE = 'GET_WORKFLOW_VERSION_FAILURE';
export const CREATE_DRAFT_BEGIN   = 'CREATE_DRAFT_BEGIN';
export const CREATE_DRAFT_SUCCESS = 'CREATE_DRAFT_SUCCESS';
export const CREATE_DRAFT_FAILURE = 'CREATE_DRAFT_FAILURE';

export const getWorkflowInfoBegin = () => ({
  type: GET_WORKFLOW_INFO_BEGIN
});

export const getWorkflowInfoSuccess = workflow => ({
  type: GET_WORKFLOW_INFO_SUCCESS,
  payload: { workflow }
});

export const getWorkflowInfoFailure = error => ({
  type: GET_WORKFLOW_INFO_FAILURE,
  payload: { error }
});
export const changeActiveProperties = (activePropertie, aux) => ({
  type: CHANGE_ACTIVE_PROPERTIES,
  payload: { activePropertie, aux }
});

export const getWorkflowInfo = (processSlug, companyId) => {
  return dispatch => {
    dispatch(getWorkflowInfoBegin());
    return axiosBW.get(`/process/by-slug/${companyId}/${processSlug}/`)
    .then(resp =>{
      console.log(resp.data);
      dispatch(getWorkflowInfoSuccess(resp.data.payload));
    })
    .catch(err =>{
      dispatch(getWorkflowInfoFailure(err.response.data));
    })
  }
}

export const getUsersByRoleBegin = () => ({
  type: GET_USERS_BY_ROLE_BEGIN
});

export const getUsersByRoleSuccess = users => ({
  type: GET_USERS_BY_ROLE_SUCCESS,
  payload: { users }
});

export const getUsersByRoleFailure = error => ({
  type: GET_USERS_BY_ROLE_FAILURE,
  payload: { error }
});

export const getUsersByRole = (companyId, role, processSlug) => {
  return dispatch => {
    dispatch(getUsersByRoleBegin());
    return axiosBW.get(`/process/users-by-role/${companyId}`,{
      params: { role, processSlug }
    })
    .then(resp =>{
      console.log(resp.data);
      dispatch(getUsersByRoleSuccess(resp.data.payload));
    })
    .catch(err =>{
      dispatch(getUsersByRoleFailure(err.response.data));
    })
  }
}
export const getVersionListBegin = () => ({
  type: GET_VERSION_LIST_BEGIN
});

export const getVersionListSuccess = versionList => ({
  type: GET_VERSION_LIST_SUCCESS,
  payload: { versionList }
});

export const getVersionListFailure = error => ({
  type: GET_VERSION_LIST_FAILURE,
  payload: { error }
});

export const getVersionList = (companyId, processSlug) => {
  return dispatch => {
    dispatch(getVersionListBegin());
    return axiosBW.get(`/process/version-list/${companyId}/${processSlug}/`)
    .then(resp =>{
      console.log(resp.data);
      dispatch(getVersionListSuccess(resp.data.payload));
    })
    .catch(err =>{
      dispatch(getVersionListFailure(err.response.data));
    })
  }
}
export const getWorkflowVersionBegin = () => ({
  type: GET_WORKFLOW_VERSION_BEGIN
});

export const getWorkflowVersionSuccess = workflow => ({
  type: GET_WORKFLOW_VERSION_SUCCESS,
  payload: { workflow }
});

export const getWorkflowVersionFailure = error => ({
  type: GET_WORKFLOW_VERSION_FAILURE,
  payload: { error }
});

export const getWorkflowVersion = (processSlug, version) => {
  return dispatch => {
    dispatch(getWorkflowVersionBegin());
    return axiosBW.get(`/process/by-version/${processSlug}/${version}`)
    .then(resp =>{
      console.log(resp.data);
      dispatch(getWorkflowVersionSuccess(resp.data.payload));
    })
    .catch(err =>{
      dispatch(getWorkflowVersionFailure(err.response.data));
    })
  }
}
export const createDraftBegin = () => ({
  type: CREATE_DRAFT_BEGIN
});

export const createDraftSuccess = workflow => ({
  type: CREATE_DRAFT_SUCCESS,
  payload: { workflow }
});

export const createDraftFailure = error => ({
  type: CREATE_DRAFT_FAILURE,
  payload: { error }
});

export const createDraft = (processSlug, version) => {
  return async dispatch => {
    dispatch(createDraftBegin());
    try {
      const resp = await axiosBW.post('/process/create-draft-from-version/',
        {
          processSlug,
          version
        });
      console.log(resp.data);
      dispatch(createDraftSuccess(resp.data.payload));
    } catch (err) {
      dispatch(createDraftFailure(err.response.data));
    }
  }
}
export const SET_WORKFLOW = 'SET_WORKFLOW';
export const setWorkflow = wf => ({
  type: SET_WORKFLOW,
  payload: { wf }
});
export const CHANGE_WORKFLOW_PROPERTIES = 'CHANGE_WORKFLOW_PROPERTIES';
export const changeWorkflowProperties = propertie => ({
  type: CHANGE_WORKFLOW_PROPERTIES,
  payload: propertie
});

export const CHANGE_DRAFT_MODE = 'CHANGE_DRAFT_MODE';
export const changeDraftMode = mode => ({
  type: CHANGE_DRAFT_MODE,
  payload: mode
});
export const SET_USERS_CONNECTED = 'SET_USERS_CONNECTED';
export const setUsersConnected = users => ({
  type: SET_USERS_CONNECTED,
  payload: users
});
export const SET_USERS_EDITING = 'SET_USERS_EDITING';
export const setUsersEditing = users => ({
  type: SET_USERS_EDITING,
  payload: users
});

export const DISCARD_DRAFT_BEGIN   = 'DISCARD_DRAFT_BEGIN';
export const DISCARD_DRAFT_SUCCESS = 'DISCARD_DRAFT_SUCCESS';
export const DISCARD_DRAFT_FAILURE = 'DISCARD_DRAFT_FAILURE';

export const discardDraftBegin = () => ({
  type: DISCARD_DRAFT_BEGIN
});

export const discardDraftSuccess = workflow => ({
  type: DISCARD_DRAFT_SUCCESS,
  payload: { workflow }
});

export const discardDraftFailure = error => ({
  type: DISCARD_DRAFT_FAILURE,
  payload: { error }
});

export const discardDraft = (processSlug) => {
  return async dispatch => {
    dispatch(discardDraftBegin());
    try {
      const resp = await axiosBW.post('/process/discard-draft/',
        {
          processSlug
        });
      console.log(resp.data);
      dispatch(discardDraftSuccess(resp.data.payload));
    } catch (err) {
      dispatch(discardDraftFailure(err.response.data));
    }
  }
}

export const CHANGE_WORKFLOW_ACTION = 'CHANGE_WORKFLOW_ACTION';
export const changeWorkflowAction = action => ({
  type: CHANGE_WORKFLOW_ACTION,
  payload: action
});
export const CHANGE_WORKFLOW_ROLE = 'CHANGE_WORKFLOW_ROLE';
export const changeWorkflowRole = data => ({
  type: CHANGE_WORKFLOW_ROLE,
  payload: data
});
export const ADD_WORKFLOW_ROLE = 'ADD_WORKFLOW_ROLE';
export const addWorkflowRole = data => ({
  type: ADD_WORKFLOW_ROLE,
  payload: data
});
export const DELETE_WORKFLOW_ROLE = 'DELETE_WORKFLOW_ROLE';
export const deleteWorkflowRole = data => ({
  type: DELETE_WORKFLOW_ROLE,
  payload: data
});
export const MOVE_UP_WORKFLOW_ROLE = 'MOVE_UP_WORKFLOW_ROLE';
export const moveUpWorkflowRole = data => ({
  type: MOVE_UP_WORKFLOW_ROLE,
  payload: data
});
export const MOVE_DOWN_WORKFLOW_ROLE = 'MOVE_DOWN_WORKFLOW_ROLE';
export const moveDownWorkflowRole = data => ({
  type: MOVE_DOWN_WORKFLOW_ROLE,
  payload: data
});
export const SET_DRAGGING_STATUS = 'SET_DRAGGING_STATUS';
export const setDraggingStatus = status => ({
  type: SET_DRAGGING_STATUS,
  payload: status
});
export const CHANGE_FORM_POSITION = 'CHANGE_FORM_POSITION';
export const changeFormPosition = data => ({
  type: CHANGE_FORM_POSITION,
  payload: data
});
export const RESET_WF_DATA = 'RESET_WF_DATA';
export const resetWfData = () => ({
  type: RESET_WF_DATA
});
export const SET_NEW_ACTION_SLUG = 'SET_NEW_ACTION_SLUG';
export const setNewActionSlug = (data) => ({
  type: SET_NEW_ACTION_SLUG,
  payload: data
});
export const CREATE_NEW_ACTION_BEGIN = 'CREATE_NEW_ACTION_BEGIN';
export const createNewActionBegin = (data) => ({
  type: CREATE_NEW_ACTION_BEGIN,
  payload: data
});
export const CREATE_NEW_ACTION_END = 'CREATE_NEW_ACTION_END';
export const createNewActionEnd = (data) => ({
  type: CREATE_NEW_ACTION_END,
  payload: data
});
export const CREATE_NEW_ACTION_CANCEL = 'CREATE_NEW_ACTION_CANCEL';
export const createNewActionCancel = () => ({
  type: CREATE_NEW_ACTION_CANCEL
});
export const CREATE_NEW_ACTION_SOCKET = 'CREATE_NEW_ACTION_SOCKET';
export const createNewActionSocket = (data) => ({
  type: CREATE_NEW_ACTION_SOCKET,
  payload: data
});
export const EDITING_ACTION_STATUS = 'EDITING_ACTION_STATUS';
export const editingActionStatus = (data) => ({
  type: EDITING_ACTION_STATUS,
  payload: data
});
export const EDITING_ACTION_BEGIN = 'EDITING_ACTION_BEGIN';
export const editingActionBegin = (data) => ({
  type: EDITING_ACTION_BEGIN,
  payload: data
});
export const EDITING_ACTION_CANCEL = 'EDITING_ACTION_CANCEL';
export const editingActionCancel = () => ({
  type: EDITING_ACTION_CANCEL
});
export const EDITING_ACTION_SET_START_POSITION = 'EDITING_ACTION_SET_START_POSITION';
export const editingActionSetStartPosition = (data) => ({
  type: EDITING_ACTION_SET_START_POSITION,
  payload: data
});
export const EDITING_ACTION_END = 'EDITING_ACTION_END';
export const editingActionEnd = (data) => ({
  type: EDITING_ACTION_END,
  payload: data
});
export const EDITING_ACTION_SOCKET = 'EDITING_ACTION_SOCKET';
export const editingActionSocket = (data) => ({
  type: EDITING_ACTION_SOCKET,
  payload: data
});
export const DELETE_ACTION = 'DELETE_ACTION';
export const deleteAction = (data) => ({
  type: DELETE_ACTION,
  payload: data
});
export const GET_USERS_WITHOUT_ROLE_BEGIN   = 'GET_USERS_WITHOUT_ROLE_BEGIN';
export const getUsersWithoutRoleBegin = () => ({
  type: GET_USERS_WITHOUT_ROLE_BEGIN
});

export const GET_USERS_WITHOUT_ROLE_SUCCESS = 'GET_USERS_WITHOUT_ROLE_SUCCESS';
export const getUsersWithoutRoleSuccess = users => ({
  type: GET_USERS_WITHOUT_ROLE_SUCCESS,
  payload: { users }
});

export const GET_USERS_WITHOUT_ROLE_FAILURE = 'GET_USERS_WITHOUT_ROLE_FAILURE';
export const getUsersWithoutRoleFailure = error => ({
  type: GET_USERS_WITHOUT_ROLE_FAILURE,
  payload: { error }
});

export const getUsersWithoutRole = (companyId, role, processSlug) => {
  return dispatch => {
    dispatch(getUsersWithoutRoleBegin());
    return axiosBW.get(`/process/users-without-role/${companyId}`,{
      params: { role, processSlug }
    })
    .then(resp =>{
      console.log(resp.data);
      dispatch(getUsersWithoutRoleSuccess(resp.data.payload));
    })
    .catch(err =>{
      dispatch(getUsersWithoutRoleFailure(err.response.data));
    })
  }
}
export const CREATE_FORM = 'CREATE_FORM';
export const createForm = (data) => ({
  type: CREATE_FORM,
  payload: data
});
export const DELETE_FORM = 'DELETE_FORM';
export const deleteForm = (data) => ({
  type: DELETE_FORM,
  payload: data
});
export const PUBLISH_DRAFT_BEGIN   = 'PUBLISH_DRAFT_BEGIN';
export const PUBLISH_DRAFT_SUCCESS = 'PUBLISH_DRAFT_SUCCESS';
export const PUBLISH_DRAFT_FAILURE = 'PUBLISH_DRAFT_FAILURE';

export const publishDraftBegin = () => ({
  type: PUBLISH_DRAFT_BEGIN
});

export const publishDraftSuccess = workflow => ({
  type: PUBLISH_DRAFT_SUCCESS,
  payload: { workflow }
});

export const publishDraftFailure = error => ({
  type: PUBLISH_DRAFT_FAILURE,
  payload: { error }
});

export const publishDraft = (processSlug) => {
  return async dispatch => {
    dispatch(publishDraftBegin());
    try {
      const resp = await axiosBW.post('/process/publish-draft/',
        {
          processSlug
        });
      console.log(resp.data);
      dispatch(publishDraftSuccess(resp.data.payload));
    } catch (err) {
      dispatch(publishDraftFailure(err.response.data));
    }
  }
}

export const CHANGE_VERSION_TYPE = 'CHANGE_VERSION_TYPE';
export const changeVersionType = type => ({
  type: CHANGE_VERSION_TYPE,
  payload: type
});