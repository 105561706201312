/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import axiosBW from '../../../../../utils/Http';
import { Row, Modal, Input, Collapse } from 'antd';
import AnalyticBox from '../AnalyticBox/AnalyticBox'
import { PlusCircleOutlined, EditOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { setGlobalLoader } from '../../../../../actions/actions'
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  PointerSensor
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy
} from "@dnd-kit/sortable";
import './AnalyticsList.scss'

const { Panel } = Collapse;
const AnalyticsList = ({ processSlug, processTitle, history }) => {
  const dispatch = useDispatch();
  const [analyticsList, setAnalyticsList] = useState([]);
  const [showModal, setModal] = useState(false)
  const [showModalEdit, setModalEdit] = useState(false)
  const [renameSectionId, setRenameSectionId] = useState(null)
  const [newSectionName, setNewSectionName] = useState('')
  const [loading, setLoading] = useState(false);
  const companyId = useSelector(state => state.user.companyId)
  // const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const sensors = useSensors(useSensor(PointerSensor, {
    activationConstraint: {
      distance: 3,
      // delay: 250,
      // tolerance: 5,
    },
  }));


  useEffect(() => {
    console.log('va el hooks-log');
    (async () => {
      setLoading(true)
      await getAnalyticsList()
      setLoading(false)
    })();
    
  }, []);
  const getAnalyticsList = async() => {
    try {
      const resAnalytics = await axiosBW.get(`analytics/list/${companyId}/${processSlug}/`)
      if(resAnalytics && resAnalytics.data) {

        if(resAnalytics.data.status === 0) {
          setAnalyticsList(resAnalytics.data.payload)
        }
      }
    } catch(ex) {
      console.log(ex);
    }
  }
  const createNewSection = async () => {
    try {
      // setLoading(true)
      const resAnalytics = await axiosBW.post(`analytics/create-section/`, {
        companyId,
        processSlug,
        title: newSectionName
      })
      if(resAnalytics && resAnalytics.data) {

        if(resAnalytics.data.status === 0) {
          setAnalyticsList(resAnalytics.data.payload)
        }
      }
      setModal(false)
      // setLoading(false)
      setNewSectionName('')
    } catch (error) {
      console.log(error);
      setLoading(false)
      setModal(false)
      setNewSectionName('')
    }
  }
  const renameSection = async () => {
    try {
      // setLoading(true)
      const resAnalytics = await axiosBW.post(`analytics/rename-section/`, {
        companyId,
        processSlug,
        sectionId: renameSectionId,
        title: newSectionName
      })
      if(resAnalytics && resAnalytics.data) {

        if(resAnalytics.data.status === 0) {
          setAnalyticsList(resAnalytics.data.payload)
        }
      }
      setModalEdit(false)
      // setLoading(false)
      setNewSectionName('')
      setRenameSectionId(null)
    } catch (error) {
      console.log(error);
      // setLoading(false)
      setModalEdit(false)
      setNewSectionName('')
      setRenameSectionId(null)
    }
  }
  const switchActive = async (companyId, processSlug, sectionId, analyticId, active) =>{
    // const loadInfo = this.loadInfo
    // const setState = this.setState
    try {
      // props.setGlobalLoader(true)
      const resAnalytics = await axiosBW.post(`analytics/switch-active/`, {
        companyId,
        processSlug,
        sectionId,
        analyticId,
        active
      })
      if(resAnalytics && resAnalytics.data) {

        if(resAnalytics.data.status === 0) {
          setAnalyticsList(resAnalytics.data.payload)
          toast.success('Analítica modificada correctamente')
        }
      }
      // props.setGlobalLoader(false)
      
    } catch (error) {
      // props.setGlobalLoader(false)
      toast.error('Error al activar/desactivar la anlítica')
      return
    }
  }
  const removeAnalytic = async (companyId, processSlug, sectionId, analyticId) =>{
    // const loadInfo = this.loadInfo
    // const setState = this.setState
    try {
      // props.setGlobalLoader(true)
      const resAnalytics = await axiosBW.post(`analytics/remove-analytic/`, {
        companyId,
        processSlug,
        sectionId,
        analyticId
      })
      if(resAnalytics && resAnalytics.data) {

        if(resAnalytics.data.status === 0) {
          setAnalyticsList(resAnalytics.data.payload)
          toast.success('Analítica eliminada correctamente')
        }
      }
      // props.setGlobalLoader(false)
      
    } catch (error) {
      // props.setGlobalLoader(false)
      toast.error('Error al eliminar la anlítica')
      return
    }
  }

  if (loading) {
      return 'Loading...'
  }
  const reorderAnalytics = async (sectionId, analyticId, newPositionId) =>{
    // const loadInfo = this.loadInfo
    // const setState = this.setState
    try {
      // props.setGlobalLoader(true)
      const resAnalytics = await axiosBW.post(`analytics/reorder-analytics/`, {
        companyId,
        processSlug,
        sectionId,
        analyticId,
        newPositionId
      })
      if(resAnalytics && resAnalytics.data) {

        if(resAnalytics.data.status === 0) {
          setAnalyticsList(resAnalytics.data.payload)
          toast.success('Analítica ordenada correctamente')
        }
      }
      // props.setGlobalLoader(false)
      
    } catch (error) {
      // props.setGlobalLoader(false)
      toast.error('Error al ordenar la anlítica')
      return
    }
  }
  const handleDragEnd = async(sectionId, event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const analytics = analyticsList.find(l => l._id === sectionId).analytics
      const sectionIndex = analyticsList.findIndex(l => l._id === sectionId)
      const oldIndex = analytics.findIndex((i) => i._id === active.id);
      const newIndex = analytics.findIndex((i) => i._id === over.id);
      const orderedList = arrayMove(analytics, oldIndex, newIndex);
      const newList = [...analyticsList]
      newList[sectionIndex].analytics = orderedList
      setAnalyticsList(newList)

      dispatch(setGlobalLoader(true))
      await reorderAnalytics(sectionId, active.id, over.id)
      dispatch(setGlobalLoader(false))
    }
  }
 
  return (
    <div className="analytics-list-wrapper">
      <Row className="analytics-list-title">
        Secciones
        <button className="new-section-button" onClick={ () => {
          setModal(true)
        }}
        >
          Nueva sección
        </button>
      </Row>
      <Collapse>
        {analyticsList.map((elem, i) => {
          console.log(elem)
          console.log(elem.analytics.map(a => a._id.toString()))
          // const analyticIds = elem.analytics.map((a, i) => JSON.stringify(a))
          const analyticIds = elem.analytics.map((a, i) => a._id)
          return (
            <Panel header={(<div>{elem.title} <EditOutlined style={{fontSize: '15px', paddingLeft: '11px', cursor: 'pointer'}} onClick={(e) => {
              e.stopPropagation()
              console.log('ASDASDASD')   
              setNewSectionName(elem.title)
              setRenameSectionId(elem._id)
              setModalEdit(true)  
            }} /></div>)} key={i}>
              <DndContext
                autoScroll={false}
                sensors={sensors}
                onDragEnd={(e) => handleDragEnd(elem._id, e)}
              >
                <SortableContext items={analyticIds} strategy={rectSortingStrategy}>
                    <div className="analytics-list-panel">
                      {elem.analytics.map((analytic, j) => {
                        console.log(analytic)
                        return (
                          <AnalyticBox 
                            analytic={analytic} 
                            companyId={companyId}
                            processSlug={processSlug}
                            processTitle={processTitle}
                            analyticId={analytic._id}
                            sectionId={elem._id}
                            switchActive={switchActive}
                            removeAnalytic={removeAnalytic}
                            analyticIndex={j}
                            index={j}
                            key={analytic._id}
                            id={analytic._id}
                          />
                        )
                        
                      })}
                      <div className="analytic-box" style={{cursor: 'pointer'}} onClick={() => { history.push({ 
                        pathname: '/analytic-designer/nueva', 
                        state: { 
                          companyId,
                          processSlug,
                          processTitle,
                          sectionId: elem._id,
                          analytic: {}
                        }
                      })}}>
                        <div className="analytic-box-content">
                          <p className="analytic-title-text"><b>Nueva analítica</b></p>
                          <div className="analytic-logo add-analytic-icon">
                            <PlusCircleOutlined />
                            
                          </div>
                        </div>
                      </div>
                    </div>
                </SortableContext>
              </DndContext>
            </Panel>
          )
        })}

      </Collapse>
      <Modal
        width={360}
        closable={false}
        centered={true}
        visible={showModal}
        title={(<div style={{alignContent: 'center', textAlign: 'center'}}>Nueva sección</div>)}
        onOk={createNewSection}
        onCancel={() => { setModal(false); setNewSectionName('') }}
        cancelText="Cancelar"
        okText="Crear"
      >
        <div className="modal-form">
          <div className="label">
            Nombre
          </div>
          <Input
            type="text"
            name="newSectionName"
            value={newSectionName}
            onChange={(e) => setNewSectionName(e.target.value)}
          />
        </div>
      </Modal>
      <Modal
        width={360}
        closable={false}
        centered={true}
        visible={showModalEdit}
        title={(<div style={{alignContent: 'center', textAlign: 'center'}}>Editar sección</div>)}
        onOk={renameSection}
        onCancel={() => { setModalEdit(false); setNewSectionName('') }}
        cancelText="Cancelar"
        okText="Crear"
      >
        <div className="modal-form">
          <div className="label">
            Nombre
          </div>
          <Input
            type="text"
            name="newSectionName"
            value={newSectionName}
            onChange={(e) => setNewSectionName(e.target.value)}
          />
        </div>
      </Modal>
    </div>
  )
}


export default AnalyticsList