import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FileTextOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import './MyAccount.scss'
import { countries } from './countries'
// import axios from 'axios';
// import cf from '../../../config/APIConfig'
import axiosBW from '../../../utils/Http';
import { setGlobalLoader } from '../../../actions/actions'

class FormBilling extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formBilling: {
        formRut: {
          error: 0,
          errorMessage: '',
          value: '',
          validations: []
        },
        formRazonSocial: {
          error: 0,
          errorMessage: '',
          value: '',
        },
        formMarca: {
          error: 0,
          errorMessage: '',
          value: '',
        },
        formGiro: {
          error: 0,
          errorMessage: '',
          value: '',
          validations: []
        },
        formTelefono: {
          error: 0,
          errorMessage: '',
          value: '',
        },
        formCorreoElectronico: {
          error: 0,
          errorMessage: '',
          value: '',
        },
        formDireccion: {
          error: 0,
          errorMessage: '',
          value: '',
        },
        formComuna: {
          error: 0,
          errorMessage: '',
          value: '',
          validations: []
        },
        formRegion: {
          error: 0,
          errorMessage: '',
          value: '',
          validations: []
        },
        formPais: {
          error: 0,
          errorMessage: '',
          value: '',
        },
        formCiudad: {
          error: 0,
          errorMessage: '',
          value: '',
        },
        formNombres: {
          error: 0,
          errorMessage: '',
          value: '',
        },
        
      },
      companyId: '',
      formMessage: ''
    }
  }
  componentDidMount() {
    console.log('object', this.props.currentData)
    if(this.props.currentData && this.props.currentData.legalName) {
      const response = this.props.currentData
      this.setState({
        formBilling: {
          ...this.state.formBilling,
          formRazonSocial: {
            ...this.state.formBilling.formRazonSocial,
            value: response.legalName
          }
        }
      })
    } else {
      axiosBW.get(`/companies/account-info/${this.props.companyId}`)
      .then(res => {
        const response = res.data.payload
        this.setState({
          companyId: response._id || '',
          formBilling: {
            ...this.state.formBilling,
            formRazonSocial: {
              ...this.state.formBilling.formRazonSocial,
              value: response.legalName || ''
            },
            formMarca: {
              ...this.state.formBilling.formMarca,
              value: response.fantasyName || ''
            },
            formDireccion: {
              ...this.state.formBilling.formDireccion,
              value: response.addressLine || ''
            },
            formCorreoElectronico: {
              ...this.state.formBilling.formCorreoElectronico,
              value: response.contactEmail || ''
            },
            formPais: {
              ...this.state.formBilling.formPais,
              value: response.country || ''
            },
            formRut: {
              ...this.state.formBilling.formRut,
              value: response.taxId || ''
            },
            formComuna: {
              ...this.state.formBilling.formComuna,
              value: response.commune || ''
            },
            formCiudad: {
              ...this.state.formBilling.formCiudad,
              value: response.city || ''
            },
            formRegion: {
              ...this.state.formBilling.formRegion,
              value: response.state || ''
            },
            formGiro: {
              ...this.state.formBilling.formGiro,
              value: response.businessActivity || ''
            },
            formTelefono: {
              ...this.state.formBilling.formTelefono,
              value: response.phoneNumber || ''
            },
            formNombres: {
              ...this.state.formBilling.formNombres,
              value: response.billingContactUser || ''
            }
            
          }
        })
      })
      .catch(err => {
        console.log('error', err)
      })
    }
  }
  changeForm(key, e) {
    this.setState({
      formBilling: {...this.state.formBilling, [key]: {...this.state.formBilling[key], value: e.target.value}}
    })
  }
  changeSelect(key, value) {
    this.setState({
      [key]: {...this.state.formBilling[key], value: value}
    })
  }
  lostFocusForm(key) {
    let cont = '0'
    // simple validations
    if(this.state.formBilling[key].value.length <= 0) {
      cont = '1'
      this.setState({
        formBilling: {...this.state.formBilling, [key]: {...this.state.formBilling[key], error: 1, errorMessage: 'Este campo no puede quedar vacío'} }
      })
    } else {
      this.setState({
        formBilling: {...this.state.formBilling, [key]: {...this.state.formBilling[key], error: 0, errorMessage: ''} }
      })
    }

    if(this.state.formBilling[key].validations && cont === '0') {
      this.state.formBilling[key].validations.forEach(validation => {
        console.log('validation: ', validation.name)
        if(cont === '0') {
          if(validation.validate(key, this.state.formBilling[key]) === '1') {
            cont = '1'
          } else {
            this.setState({
              [key]: {...this.state.formBilling[key], error: 0, errorMessage: ''}
            })
          }
        }
      })
    }
    
  }
  updateBilling() {
    let validated = true
    for (const form in this.state.formBilling ){
      if (this.state.formBilling[form].error !== 0) {
        console.log(form);
        validated = false
      }
    }
    if (validated) {
      axiosBW.put(`companies/billing-data/${this.props.companyId}`, {
        legalName: this.state.formBilling.formRazonSocial.value,
        fantasyName: this.state.formBilling.formMarca.value,
        businessActivity: this.state.formBilling.formGiro.value,
        taxId: this.state.formBilling.formRut.value,
        addressLine: this.state.formBilling.formDireccion.value,
        state: this.state.formBilling.formRegion.value,
        city: this.state.formBilling.formCiudad.value,
        commune: this.state.formBilling.formComuna.value,
        country: this.state.formBilling.formPais.value,
        phoneNumber: this.state.formBilling.formTelefono.value,
        billingContactUser: this.state.formBilling.formNombres.value,
        contactEmail: this.state.formBilling.formCorreoElectronico.value,
      })
      .then(res => {
        console.log('res update billing', res.data)
        this.setState({
          formMessage: 'Se han guardado los cambios correctamente'
        })

      })
      .catch(err => {
        console.log('err', err.response.data)
        this.setState({
          formMessage: err.response.data.message
        })
      })
    } else {
      this.setState({
        formMessage: 'Por favor corrija los campos correspondientes'
      })
    }
    
  }

  render() {
    return (
      <section className="myacc__billing_info">
        <div className="billing_info__element">
          <div className="flow_form__label">
            Identificador de empresa
          </div>
          <div className="flow_form__field">
            <input 
            className="flow_form__default flow_form__input_text billing_info_id"
            type="text" 
            value={this.state.companyId}
            disabled={true}
            />
          </div>
        </div>
              <div className="myacc__subtitle">
                <FileTextOutlined />
                <h2>
                  Dirección de Facturación
                </h2>
              </div>
              <form className="billing_info__form">
                <div className="billing_info__element">
                  <div className="flow_form__label">
                    Razón Social o Nombre Legal de la Empresa
                  </div>
                  <div className="flow_form__field">
                    <input 
                    className="flow_form__default flow_form__input_text"
                    placeholder="Industrias STARK EIRL"
                    type="text" 
                    onBlur={e => this.lostFocusForm('formRazonSocial', this.state.formBilling.formRazonSocial, e)}
                    onChange={e => {this.changeForm('formRazonSocial', e)}} 
                    value={this.state.formBilling.formRazonSocial.value}
                    />
                    {
                      this.state.formBilling.formRazonSocial.errorMessage.length > 0 ? (
                        <div className={`guest__form_error`}>
                          {this.state.formBilling.formRazonSocial.errorMessage}
                        </div>
                      ) : ''
                    }
                  </div>
                </div>
                <div className="billing_info__element">
                  <div className="flow_form__label">
                    Nombre corto/Marca
                  </div>
                  <div className="flow_form__field">
                    <input 
                    className="flow_form__default flow_form__input_text"
                    placeholder="Industrias STARK EIRL"
                    type="text" 
                    onBlur={e => this.lostFocusForm('formMarca', this.state.formBilling.formMarca, e)}
                    onChange={e => {this.changeForm('formMarca', e)}} 
                    value={this.state.formBilling.formMarca.value}
                    />
                    {
                      this.state.formBilling.formMarca.errorMessage.length > 0 ? (
                        <div className={`guest__form_error`}>
                          {this.state.formBilling.formMarca.errorMessage}
                        </div>
                      ) : ''
                    }
                  </div>
                </div>
                <div className="billing_info__element">
                  <div className="flow_form__label">
                    RUT o Identificación de Impuestos de la empresa
                  </div>
                  <div className="flow_form__field">
                    <input 
                    className={`flow_form__default flow_form__input_text ${this.state.formBilling.formRut.error ? 'input_alert' : ''}`}
                    placeholder="12312312-3"
                    type="text" 
                    onBlur={e => this.lostFocusForm('formRut', this.state.formBilling.formRut, e)}
                    onChange={e => {this.changeForm('formRut', e)}} 
                    value={this.state.formBilling.formRut.value}
                    />
                    {
                      this.state.formBilling.formRut.errorMessage.length > 0 ? (
                        <div className={`guest__form_error`}>
                          {this.state.formBilling.formRut.errorMessage}
                        </div>
                      ) : ''
                    }
                  </div>
                </div>
                <div className="billing_info__element">
                  <div className="flow_form__label">
                    Giro
                  </div>
                  <div className="flow_form__field">
                    <input 
                    className={`flow_form__default flow_form__input_text ${this.state.formBilling.formGiro.error ? 'input_alert' : ''}`}
                    placeholder="Comercializadora x"
                    type="text" 
                    onBlur={e => this.lostFocusForm('formGiro', this.state.formBilling.formGiro, e)}
                    onChange={e => {this.changeForm('formGiro', e)}} 
                    value={this.state.formBilling.formGiro.value}
                    />
                    {
                      this.state.formBilling.formGiro.errorMessage.length > 0 ? (
                        <div className={`guest__form_error`}>
                          {this.state.formBilling.formGiro.errorMessage}
                        </div>
                      ) : ''
                    }
                  </div>
                </div>
                <div className="billing_info__element">
                  <div className="flow_form__label">
                    Dirección
                  </div>
                  <div className="flow_form__field">
                    <input 
                    className="flow_form__default flow_form__input_text"
                    placeholder="Las acacias 1314, Ñuñoa"
                    type="text" 
                    onBlur={e => this.lostFocusForm('formDireccion', this.state.formBilling.formDireccion, e)}
                    onChange={e => {this.changeForm('formDireccion', e)}} 
                    value={this.state.formBilling.formDireccion.value}
                    />
                    {
                      this.state.formBilling.formDireccion.errorMessage.length > 0 ? (
                        <div className={`guest__form_error`}>
                          {this.state.formBilling.formDireccion.errorMessage}
                        </div>
                      ) : ''
                    }
                  </div>
                </div>
                <div className="billing_info__element">
                  <div className="flow_form__label">
                    País
                  </div>
                  <div className="flow_form__field">
                    <div className="flow_form__field">
                      <input 
                      className="flow_form__default flow_form__input_text"
                      placeholder="Las acacias 1314, Ñuñoa"
                      type="text" 
                      onBlur={e => this.lostFocusForm('formPais', this.state.formBilling.formPais, e)}
                      onChange={e => {this.changeForm('formPais', e)}} 
                      value={this.state.formBilling.formPais.value}
                      />
                      {
                        this.state.formBilling.formPais.errorMessage.length > 0 ? (
                          <div className={`guest__form_error`}>
                            {this.state.formBilling.formPais.errorMessage}
                          </div>
                        ) : ''
                      }
                    </div>
                    {/* <Select 
                      className="flow_form__default flow_form__input_text" 
                      placeholder="Australia"
                      onBlur={e => this.lostFocusForm('formPais', this.state.formBilling.formPais, e)}
                      onChange={value => {this.changeSelect('formPais', value)}} 
                      value={this.state.formBilling.formPais.value}
                    >
                      {countries.map((e, i) => {
                        return (
                          <Select.Option key={`country-${i}`} value={e.key}>
                            {e.value}
                          </Select.Option>
                        )
                      })}
                      
                    </Select>
                    {
                      this.state.formBilling.formPais.errorMessage.length > 0 ? (
                        <div className={`guest__form_error`}>
                          {this.state.formBilling.formPais.errorMessage}
                        </div>
                      ) : ''
                    } */}
                  </div>
                </div>
                <div className="billing_info__element">
                  <div className="flow_form__label">
                    Región
                  </div>
                  <div className="flow_form__field">
                    <input 
                    className="flow_form__default flow_form__input_text"
                    placeholder="Metropolitana"
                    type="text" 
                    onBlur={e => this.lostFocusForm('formRegion', this.state.formBilling.formRegion, e)}
                    onChange={e => {this.changeForm('formRegion', e)}} 
                    value={this.state.formBilling.formRegion.value}
                    />
                    {
                      this.state.formBilling.formRegion.errorMessage.length > 0 ? (
                        <div className={`guest__form_error`}>
                          {this.state.formBilling.formRegion.errorMessage}
                        </div>
                      ) : ''
                    }
                  </div>
                </div>
                <div className="billing_info__element">
                  <div className="flow_form__label">
                    Ciudad
                  </div>
                  <div className="flow_form__field">
                    <input 
                    className="flow_form__default flow_form__input_text"
                    placeholder="Santiago"
                    type="text" 
                    onBlur={e => this.lostFocusForm('formCiudad', this.state.formBilling.formCiudad, e)}
                    onChange={e => {this.changeForm('formCiudad', e)}} 
                    value={this.state.formBilling.formCiudad.value}
                    />
                    {
                      this.state.formBilling.formCiudad.errorMessage.length > 0 ? (
                        <div className={`guest__form_error`}>
                          {this.state.formBilling.formCiudad.errorMessage}
                        </div>
                      ) : ''
                    }
                  </div>
                </div>
                <div className="billing_info__element">
                  <div className="flow_form__label">
                    Comuna
                  </div>
                  <div className="flow_form__field">
                    <input 
                    className="flow_form__default flow_form__input_text"
                    placeholder="San Joaquín"
                    type="text" 
                    onBlur={e => this.lostFocusForm('formComuna', this.state.formBilling.formComuna, e)}
                    onChange={e => {this.changeForm('formComuna', e)}} 
                    value={this.state.formBilling.formComuna.value}
                    />
                    {
                      this.state.formBilling.formComuna.errorMessage.length > 0 ? (
                        <div className={`guest__form_error`}>
                          {this.state.formBilling.formComuna.errorMessage}
                        </div>
                      ) : ''
                    }
                  </div>
                </div>
                <div className="billing_info__element">
                  <div className="flow_form__label">
                    Nombre contacto
                  </div>
                  <div className="flow_form__field">
                    <input 
                    className={`flow_form__default flow_form__input_text ${this.state.formBilling.formNombres.error === 1 ? 'input_alert' : ''}`}
                    placeholder="Juan Antonio"
                    type="text" 
                    onBlur={e => this.lostFocusForm('formNombres')}
                    onChange={e => {this.changeForm('formNombres', e)}} 
                    value={this.state.formBilling.formNombres.value} 
                    />
                    {
                      this.state.formBilling.formNombres.errorMessage.length > 0 ? (
                        <div className={`guest__form_error`}>
                          {this.state.formBilling.formNombres.errorMessage}
                        </div>
                      ) : ''
                    }
                    
                  </div>
                </div>
                <div className="billing_info__element">
                  <div className="flow_form__label">
                    Teléfono contacto
                  </div>
                  <div className="flow_form__field">
                    <input 
                    className="flow_form__default flow_form__input_text"
                    placeholder="+56933885566"
                    type="text" 
                    onBlur={e => this.lostFocusForm('formTelefono', this.state.formBilling.formTelefono, e)}
                    onChange={e => {this.changeForm('formTelefono', e)}} 
                    value={this.state.formBilling.formTelefono.value}
                    />
                    {
                      this.state.formBilling.formTelefono.errorMessage.length > 0 ? (
                        <div className={`guest__form_error`}>
                          {this.state.formBilling.formTelefono.errorMessage}
                        </div>
                      ) : ''
                    }
                  </div>
                </div>
                <div className="billing_info__element">
                  <div className="flow_form__label">
                    Correo Electrónico contacto
                  </div>
                  <div className="flow_form__field">
                    <input 
                    className="flow_form__default flow_form__input_text"
                    placeholder="mi@correo.com"
                    type="text" 
                    onBlur={e => this.lostFocusForm('formCorreoElectronico', this.state.formBilling.formCorreoElectronico, e)}
                    onChange={e => {this.changeForm('formCorreoElectronico', e)}} 
                    value={this.state.formBilling.formCorreoElectronico.value}
                    />
                    {
                      this.state.formBilling.formCorreoElectronico.errorMessage.length > 0 ? (
                        <div className={`guest__form_error`}>
                          {this.state.formBilling.formCorreoElectronico.errorMessage}
                        </div>
                      ) : ''
                    }
                  </div>
                </div>
                {
                  !this.props.hideButton ? (
                    <>
                      <div className="flow_form__field">
                        <button onClick={e => this.updateBilling()} className="billing_info__btn">
                          Modificar Datos
                        </button>
                      </div>
                      <div className="flow_form__message">
                        {
                          this.state.formMessage.length > 0 ? (
                            <div className="flow_form__messagex">
                              {this.state.formMessage}
                            </div>
                          ) : null
                        }
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flow_form__advisor">
                        Los datos de formulario serán actualizados al proceder a confirmar el método de pago
                      </div>
                    </>
                  )
                }
              </form>
            </section>
    );
  }
}
const mstp = state => ({
  companyId: state.user.companyId
})

export default connect(mstp)(FormBilling)