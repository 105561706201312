import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './ProcessList.scss'
import { Modal, Input } from 'antd'
import axiosBW from '../../../utils/Http'
import { setGlobalLoader } from '../../../actions/actions'
import ProcessBox from './components/ProcessBox/ProcessBox'
import { Helmet } from 'react-helmet'
import { PlusCircleOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy
} from "@dnd-kit/sortable";

const ProcessList = (props) => {
  const dispatch = useDispatch();
  const companyId = useSelector(state => state.user.companyId)
  const userPermissions = useSelector(state => state.user.permissions)
  const [processList, setProcessList] = useState([])
  const [processIdList, setProcessIdList] = useState([])
  const [newProcessName, setNewProcessName] = useState('')
  const [newProcessSlug, setNewProcessSlug] = useState('')
  const [visible, setVisible] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [showPopover, setShowPopover] = useState(true)
  const [canReorderProcesses, setCanReorderProcesses] = useState(false)
  const sensors = useSensors(useSensor(PointerSensor, {
    activationConstraint: {
      distance: 3
    },
  }));

  
  const loadInfo = async() => {
    let resProcessByCompany
    try {
      resProcessByCompany = await axiosBW.get(`/process/by-company/${companyId}`)
    } catch (ex) {
      console.log('error at process-by-company', ex)
    }
    if (!resProcessByCompany || !resProcessByCompany.data) {
      Modal.error({
        title: 'Error',
        content: 'Error al obtener los procesos, intente más tarde'
      })
      return
    }
    // console.log('res', resProcessByCompany.data)
    setProcessList(resProcessByCompany.data.payload)
    setProcessIdList(resProcessByCompany.data.payload.map(p => p._id))
  }

  useEffect(() => {
    (async () => {
      console.log(userPermissions)
      if (!userPermissions.some( p => p === 'admin' || p === 'process-list')) {
        toast.error('No posees los permisos para acceder a esta funcionalidad')
        props.history.push('/')
      }
      dispatch(setGlobalLoader(true))
      
      await loadInfo()
      if (userPermissions.some( p => p === 'admin' || p === 'process-reorder')) {
        setCanReorderProcesses(true)
      }
      dispatch(setGlobalLoader(false)) 
    })()
    
  }, [])

  const switchActive = async (processSlug, active) => {
    try {
      dispatch(setGlobalLoader(true))
      await axiosBW.post(`/process/switch-active/${companyId}/${processSlug}`,
      {
        active
      })
      const resProcessByCompany = await axiosBW.get(`/process/by-company/${companyId}`)
      setProcessList(resProcessByCompany.data.payload)
      setProcessIdList(resProcessByCompany.data.payload.map(p => p._id))

      dispatch(setGlobalLoader(false))
      
    } catch (error) {
      dispatch(setGlobalLoader(false))
      Modal.error({
        title: 'Error',
        content: 'Error al activar/desactivar el proceso'
      })
      return
    }
  }

  const changeState = async(processSlug, state) => {
    try {
      dispatch(setGlobalLoader(true))
      await axiosBW.post(`/process/change-state/${companyId}/${processSlug}`,
      {
        state
      })
      const resProcessByCompany = await axiosBW.get(`/process/by-company/${companyId}`)
      setProcessList(resProcessByCompany.data.payload)
      setProcessIdList(resProcessByCompany.data.payload.map(p => p._id))
      dispatch(setGlobalLoader(false))
      
    } catch (error) {
      dispatch(setGlobalLoader(false))
      Modal.error({
        title: 'Error',
        content: 'Error al cambiar el estado del proceso'
      })
      return
    }
  }

  const showModal = () => {
    setVisible(true)
  }

  const showModalEditFn = (processSlug) => {
    setShowModalEdit(true)
    setNewProcessSlug(processSlug)
  }

  const cancelModal = () => {
    setVisible(false)
    setShowModalEdit(false)
    setNewProcessSlug('')
    setNewProcessName('')
  }

  const createNewProcess = async () => {
    if (newProcessName.trim() === '' || newProcessSlug.trim() === '') {
      cancelModal()
      Modal.error({
        title: 'Error',
        content: 'Debe completar todos los campos'
      })
      return
    }
    try {
      const name = newProcessName
      const slug = newProcessSlug
      cancelModal()
      dispatch(setGlobalLoader(true))   
      await axiosBW.post(`/process/create-new-process/`,{
        companyId: companyId,
        name,
        slug
      })
      await loadInfo()
      dispatch(setGlobalLoader(false)) 
    } catch (error) {
      console.log(error.response.data);
      let errMsg = 'Ha ocurrido un problema al crear el flujo de trabajo'
      if (error.response.data.message === 'Process slug already exists') {
        errMsg = 'El slug ya se encuentra ocupado'
      }
      dispatch(setGlobalLoader(false)) 
      cancelModal()
      Modal.error({
        title: 'Error',
        content: errMsg
      })
      return;
    }
  }

  const renameProcess = async () => {
    if (newProcessName.trim() === '') {
      cancelModal()
      toast.error('Debe completar todos los campos')
      return;
    }
    try {
      const processName = newProcessName
      const processSlug = newProcessSlug
      cancelModal()
      dispatch(setGlobalLoader(true))   
      const resp = await axiosBW.post(`/process/rename-process/`,{
        companyId: companyId,
        processName,
        processSlug
      })
      setProcessList(resp.data.payload)
      setProcessIdList(resp.data.payload.map(p => p._id))

      dispatch(setGlobalLoader(false)) 
      toast.success('Flujo de trabajo renombrado correctamente')
      
    } catch (error) {
      console.log(error.response.data);
      let errMsg = 'Ha ocurrido un problema al renombrar el flujo de trabajo'
      if (error.response.data.message === 'Process slug already exists') {
        errMsg = 'El slug ya se encuentra ocupado'
      }
      dispatch(setGlobalLoader(false)) 
      cancelModal()
      toast.error(errMsg)
      return;
    }
    

  }

  const handleDragEnd = async( event) => {
    document.body.style.setProperty('cursor', '');
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = processList.findIndex((i) => i._id === active.id);
      const newIndex = processList.findIndex((i) => i._id === over.id);
      const orderedList = arrayMove(processList, oldIndex, newIndex);
      setProcessList(orderedList)
      setProcessIdList(orderedList.map(p => p._id))
      setShowPopover(true)

      dispatch(setGlobalLoader(true))
      await reorderProcesses(active.id, over.id)
      dispatch(setGlobalLoader(false))
    }
  }

  const reorderProcesses = async ( processId, newPositionId) =>{
    try {
      const resProcess = await axiosBW.post(`process/reorder-processes/`, {
        companyId,
        processId,
        newPositionId
      })
      if(resProcess && resProcess.data) {

        if(resProcess.data.status === 0) {
          setProcessList(resProcess.data.payload)
          setProcessIdList(resProcess.data.payload.map(p => p._id))
          toast.success('Procesos ordenados correctamente')
        }
      }
    } catch (error) {
      toast.error('Error al ordenar el proceso')
      return
    }
  }

  const ProcessListContainer = () => (
    <div className="process_list_container">
      {processList.map((process, i) => {
        return (
          <ProcessBox 
            process={process} 
            switchActive={switchActive} 
            changeState={changeState} 
            showModalEdit={showModalEditFn} 
            key={process._id}
            id={process._id}
            showPopover={showPopover}
            loadInfo={loadInfo}
            />
        )
      })}
      <div className="process-box-container" >
        <Modal
          width={360}
          closable={false}
          centered={true}
          visible={visible}
          title={(<div style={{alignContent: 'center', textAlign: 'center'}}>Nuevo flujo de trabajo</div>)}
          onOk={createNewProcess}
          onCancel={cancelModal}
          cancelText="Cancelar"
          okText="Crear"
        >
          <div className="modal-form">
            <div className="label">
              Nombre
            </div>
            <Input
              type="text"
              name="newProcessName"
              value={newProcessName}
              onChange={e => setNewProcessName(e.target.value)}
            />
            <div className="label">
              ID
            </div>
            <Input
              type="text"
              name="newProcessSlug"
              value={newProcessSlug}
              onChange={e => setNewProcessSlug(e.target.value)}
            />
          </div>
        </Modal>
        <div className="process-box" style={{cursor: 'pointer'}} onClick={() => {showModal()}}>
          <div className="process-box-content">
            <p className="process-title-text"><b>Nuevo flujo de trabajo</b></p>
            <div className="process-logo add-workflow-icon">
              <PlusCircleOutlined />              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  
  return (
    <>
      {canReorderProcesses ? (
        <DndContext
          autoScroll={false}
          sensors={sensors}
          onDragEnd={(e) => handleDragEnd(e)}
          onDragStart={() => {
            setShowPopover(false)
            document.body.style.setProperty('cursor', 'grabbing');
          }}
        >
          <SortableContext items={processIdList} strategy={rectSortingStrategy}>
            {ProcessListContainer()}
          </SortableContext>
        </DndContext>
      ): (
        ProcessListContainer()
      )}
        
      
      <Helmet>
        <title> Lista de Procesos - Blizwork </title>
      </Helmet>
      <Modal
        width={360}
        closable={false}
        centered={true}
        visible={showModalEdit}
        title={(<div style={{alignContent: 'center', textAlign: 'center'}}>Editar nombre de proceso</div>)}
        onOk={renameProcess}
        onCancel={cancelModal}
        cancelText="Cancelar"
        okText="Crear"
      >
        <div className="modal-form">
          <div className="label">
            Nombre
          </div>
          <Input
            type="text"
            name="newProcessName"
            value={newProcessName}
            onChange={e => setNewProcessName(e.target.value)}
          />
        </div>
      </Modal>
      
    </>
  )
}
export default ProcessList