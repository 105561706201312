import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setIsMobile } from '../actions/actions'
import Loader from '../components/Loader/Loader';

class LayoutDesign extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount () {
    if (window.innerWidth < 768) {
      this.props.setIsMobile(true)
    } else {
      this.props.setIsMobile(false)
    }
    window.addEventListener('resize', () => {
      this.resize()
    })
  }

  resize () {
    if (window.innerWidth < 768) {
      this.props.setIsMobile(true)
    } else {
      this.props.setIsMobile(false)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', () => {
      this.resize()
    })
  }


  render () {

    return (
      <>
        <div className="l_guest__wrapper">
          <div className="l_guest__content">
            {this.props.children}
          </div>
        </div>
        <Loader />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isMobile: state.navigation.isMobile
})

const mapDispatchToProps = (dispatch) => ({
  setIsMobile: (bool) => dispatch(setIsMobile(bool))
})

export default connect(mapStateToProps, mapDispatchToProps)(LayoutDesign)
