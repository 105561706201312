import React, { Component, createRef } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import './WorkflowDesigner.scss'
import { Row, Col, Avatar, Select, Modal, Input, Tooltip, Badge } from 'antd';
import Xarrow from "react-xarrows";
import { ScrollSync } from 'react-scroll-sync';
import { setGlobalLoader } from '../../../actions/actions';
import { toast } from 'react-toastify';
import { 
  getWorkflowInfo,
  changeActiveProperties,
  getVersionList,
  getWorkflowVersion,
  createDraft,
  setWorkflow,
  changeWorkflowProperties,
  changeDraftMode,
  setUsersConnected,
  setUsersEditing,
  discardDraft,
  changeWorkflowAction,
  changeWorkflowRole,
  addWorkflowRole,
  deleteWorkflowRole,
  moveDownWorkflowRole,
  moveUpWorkflowRole,
  changeFormPosition,
  resetWfData,
  createNewActionSocket,
  editingActionSocket,
  deleteAction,
  createForm,
  deleteForm,
  publishDraft,
  changeVersionType,
  getUsersByRole,
  getUsersWithoutRole
} from '../../../actions/WorkflowDesignerActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlusCircleOutlined, ExclamationCircleOutlined, UploadOutlined, CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';
import  RoleSwimlane from './components/RoleSwimlane/RoleSwimlane';
import  WorkflowProperties from './components/WorkflowProperties/WorkflowProperties';
import  RoleProperties from './components/RoleProperties/RoleProperties';
import  ActionProperties from './components/ActionProperties/ActionProperties';
import  TestDraft from './components/TestDraft/TestDraft';
import { socket } from '../../../utils/Socket'

import { DndProvider, useDrag } from "react-dnd";
import  HTML5Backend from 'react-dnd-html5-backend'
const ObjectID = require('bson').ObjectID;

class WorkflowDesigner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      propertiesSelected: 'workflow',
      lines: [],
      formRefs: {},
      cols: 0,
      mode:'read',
      usersConnected: [],
      editing: {
        zone: 'none',
        aux: null
      },
      usersEditing: [],
      myUser: null,
      propertiesEd: null,
      visible: false,
      newFormTitle: '',
      newFormSlug: '',
      testDraftModalVisible: false,
      canDraftManagement: false
    }
    this.selectWorkflowProperties = this.selectWorkflowProperties.bind(this);
    this.formRefs = {}
    this.testRef = createRef()
  }
  unselectLines = (lines) => {
    for (let i = 0; i < lines.length; i++) {
      lines[i].selected = false
    }
  }
  getLinePosition(position){
    switch (position) {
      case 'topOne':
        return {position: 'top', offset:{ rightness: -30}}
      case 'topTwo':
        return 'top'
      case 'topThree':
        return {position: 'top', offset:{ rightness: 30}}
      case 'bottomOne':
        return {position: 'bottom', offset:{ rightness: -30}}
      case 'bottomTwo':
        return 'bottom'
      case 'bottomThree':
        return {position: 'bottom', offset:{ rightness: 30}}
      case 'leftOne':
        return {position: 'left', offset:{ bottomness: -15}}
      case 'leftTwo':
        return 'left'
      case 'leftThree':
        return {position: 'left', offset:{ bottomness: 15}}
      case 'rightOne':
        return {position: 'right', offset:{ bottomness: -15}}
      case 'rightTwo':
        return 'right'
      case 'rightThree':
        return {position: 'right', offset:{ bottomness: 15}}
      default:
        return position
    }
  }
  
  createLines = () => {
    const { workflow } = this.props
    const lines = []
    for (let i = 0; i < workflow.forms.length; i++) {
      const form = workflow.forms[i];
      for (let j = 0; j < form.actions.length; j++) {
        const action = form.actions[j];
        const startLine = this.getLinePosition(action.startLine)
        const endLine = this.getLinePosition(action.endLine)
        const labelStyle = {
          fontSize: '11px',
          fontWeight: 'bold',
          marginBottom: action.startLine && action.startLine.indexOf('bottom') !== -1 ? '-16px' : undefined,
          marginTop: action.startLine && action.startLine.indexOf('bottom') !== -1 ? '15px' : undefined
        }
        const line = {
          from: form.slug,
          to: action.formSlug,
          defaultColor: 'silver',
          strokeWidth: 4,
          headSize:5,
          curveness: 1.09,
          // path: 'smooth',
          path: 'grid',
          startAnchor: startLine || 'auto',
          endAnchor: endLine || 'auto',
          selected:false,
          label: {start: action.extensions && action.extensions.length > 0 ? <Badge dot><div style={labelStyle}>{action.label}</div></Badge> : <div style={labelStyle}>{action.label}</div>},
          action: {...action, startSlug: form.slug}
        }
        lines.push(line)
      }
    }
    this.setState({
      lines
    })
  }
  createRefs = async (workflow) => {
    this.formRefs = undefined
    this.formRefs = {}
    // for (let i = 0; i < workflow.forms.length; i++) {
    //   const element = workflow.forms[i];
    //   this.formRefs[element.slug] = createRef()
    // }
    this.props.workflow.forms.map(form => (this.formRefs[form.slug] = createRef()))
  }

  setMaxColumns = () => {
    const { workflow } = this.props 
    if (workflow && workflow.forms && workflow.roles) {
      //FIRST WE SEARCH POSITION
      for (let i = 0; i < workflow.forms.length; i++) {
        const form = workflow.forms[i];
        if (form.position && form.position + 1 > this.state.cols) {
          const pos = form.position + 1
          this.setState({
            cols: pos
          })
        }
      }
      //THEN FOR FORMS LENGTH
      for (let i = 0; i < workflow.roles.length; i++) {
        const assignedForms = workflow.forms.filter(f => f.roleAssignment === workflow.roles[i])
        if (assignedForms.length > this.state.cols) {
          this.setState({
            cols: assignedForms.length
          })
        }
        
      }
    }
  }
  async componentDidMount() {
    console.log('DID MOOOOUNT')
    this.props.dispatch(resetWfData())
    const canDraftManagement = this.props.permissions.some(p => p === `draft-management|${this.props.match.params.processSlug}` || p === 'admin')
    this.setState({
      canDraftManagement
    })
    if(this.props.match.params.draft && this.props.match.params.draft === 'draft') {
      if (!canDraftManagement) {
        toast.error('No tiene permisos para realizar esta acción')
        this.props.history.push(`/`)
      }
      await this.changeVersion('draft')
    } else {
      await this.loadWorkFlowInfo()
      this.props.dispatch(setGlobalLoader(false))
    }
    document.addEventListener("scroll", (e) => {
      if (this.props.workflow.forms) {
        // this.setMaxColumns()
        this.createRefs(this.props.workflow)
        this.createLines(this.props.workflow)
        this.updateSelected()
      }
    }, true);
  
  }
  loadWorkFlowInfo = async () => {
    await this.props.dispatch(getVersionList(this.props.companyId, this.props.match.params.processSlug))
    await this.props.dispatch(getWorkflowInfo(this.props.match.params.processSlug, this.props.companyId))
    this.props.dispatch(changeVersionType('published'))
    if (this.props.workflow.forms) {
      this.setMaxColumns()
      this.createRefs(this.props.workflow)
      this.createLines(this.props.workflow)
    }
  }
  startSockets = () => {
    socket.on('server:wf-properties', this.onWorkflowProperties)
    socket.on('server:wf-action', this.onWorkflowAction)
    socket.on('server:wf-role', this.onWorkflowRole)
    socket.on('server:users-connected', this.onUsersConnected)
    socket.on('server:wf-get-users-editing', this.onGetUsersEditing)
    socket.on('server:wf-users-editing', this.onUsersEditing)
    socket.on('server:wf-get-editing-zone', this.onGetEditingZone)
    socket.on('server:wf-editing-zone', this.onEditingZone)
    socket.on('server:wf-clean-editing-zone', this.onCleanEditingZone)
    socket.on('server:wf-discard-draft', this.onDraftDiscard)
    socket.on('server:wf-publish-draft', this.onDraftPublish)
    socket.on('server:wf-delete-role', this.onDeleteRole)
    socket.on('server:wf-move-down-role', this.onMoveDownRole)
    socket.on('server:wf-move-up-role', this.onMoveUpRole)
    socket.on('server:wf-add-role', this.onAddRole)
    socket.on('server:wf-form-position', this.onFormPosition)
    socket.on('server:wf-new-action', this.onNewAction)
    socket.on('server:wf-edit-action-position', this.onEditActionPosition)
    socket.on('server:wf-delete-action', this.onDeleteAction)
    socket.on('server:wf-add-form', this.onAddForm)
    socket.on('server:wf-delete-form', this.onDeleteForm)
  }
  disconnectSockets = () => {
    console.log('disconect sockets');
    socket.off('server:wf-properties', this.onWorkflowProperties)
    socket.off('server:wf-action', this.onWorkflowAction)
    socket.off('server:wf-role', this.onWorkflowRole)
    socket.off('server:users-connected', this.onUsersConnected)
    socket.off('server:wf-get-users-editing', this.onGetUsersEditing)
    socket.off('server:wf-users-editing', this.onUsersEditing)
    socket.off('server:wf-get-editing-zone', this.onGetEditingZone)
    socket.off('server:wf-editing-zone', this.onEditingZone)
    socket.off('server:wf-clean-editing-zone', this.onCleanEditingZone)
    socket.off('server:wf-discard-draft', this.onDraftDiscard)
    socket.off('server:wf-publish-draft', this.onDraftPublish)
    socket.off('server:wf-delete-role', this.onDeleteRole)
    socket.off('server:wf-move-down-role', this.onMoveDownRole)
    socket.off('server:wf-move-up-role', this.onMoveUpRole)
    socket.off('server:wf-add-role', this.onAddRole)
    socket.off('server:wf-form-position', this.onFormPosition)
    socket.off('server:wf-new-action', this.onNewAction)
    socket.off('server:wf-edit-action-position', this.onEditActionPosition)
    socket.off('server:wf-delete-action', this.onDeleteAction)
    socket.off('server:wf-add-form', this.onAddForm)
    socket.off('server:wf-delete-form', this.onDeleteForm)
  }
  emitDeleteRole = (roleName) => {
    socket.emit('client:wf-delete-role', {companyId: this.props.companyId, processSlug: this.props.match.params.processSlug, token: this.props.token, roleName })
  }
  emitMoveDownRole = (roleName) => {
    socket.emit('client:wf-move-down-role', {companyId: this.props.companyId, processSlug: this.props.match.params.processSlug, token: this.props.token, roleName })
  }
  emitMoveUpRole = (roleName) => {
    socket.emit('client:wf-move-up-role', {companyId: this.props.companyId, processSlug: this.props.match.params.processSlug, token: this.props.token, roleName })
  }
  cleanConnected = () => {
    this.props.dispatch(setUsersEditing([]))
    this.props.dispatch(setUsersConnected([]))
  }
  onDraftDiscard = async () => {
    socket.emit('client:leave-wf-room', {companyId: this.props.companyId, processSlug: this.props.match.params.processSlug, token: this.props.token})
    this.disconnectSockets()
    this.cleanConnected()
    this.props.dispatch(setGlobalLoader(true))
    await this.props.dispatch(changeDraftMode(false))
    await this.loadWorkFlowInfo()
    this.props.dispatch(setGlobalLoader(false))
  }
  onDraftPublish = async () => {
    this.leaveRoom()
    this.props.dispatch(setGlobalLoader(true))
    await this.props.dispatch(changeDraftMode(false))
    await this.loadWorkFlowInfo()
    this.props.dispatch(setGlobalLoader(false))
  }
  onDeleteRole = async (data) => {
    this.props.dispatch(deleteWorkflowRole(data))
  }
  onMoveDownRole = async (data) => {
    this.props.dispatch(moveDownWorkflowRole(data))
  }
  onMoveUpRole = async (data) => {
    this.props.dispatch(moveUpWorkflowRole(data))
  }
  onAddRole = async (data) => {
    this.props.dispatch(addWorkflowRole(data))
  }
  onFormPosition = async (data) => {
    this.props.dispatch(changeFormPosition(data))
    this.setMaxColumns()
  }
  onNewAction = async (data) => {
    this.props.dispatch(createNewActionSocket(data))
    this.createLines()
    this.updateSelected()
  }
  onEditActionPosition = async (data) => {
    console.log('ON EDIT ACTIOOOOON');
    this.props.dispatch(editingActionSocket(data))
    this.createLines()
    // this.updateSelected()
  }
  onDeleteAction = async (data) => {
    console.log('ON DELETE ACTIOOOOON');
    this.props.dispatch(deleteAction(data))
    this.createLines()
    // this.updateSelected()
  }
  onAddForm = async (data) => {
    console.log('ON ADD FOOORM');
    this.props.dispatch(createForm(data))
    this.createRefs()
    this.createLines()
    this.updateSelected()
    // this.createLines()
    // this.updateSelected()
  }
  onDeleteForm = async (data) => {
    console.log('ON ADD FOOORM');
    this.props.dispatch(deleteForm(data))
    this.createRefs()
    this.createLines()
    this.updateSelected()
    // this.createLines()
    // this.updateSelected()
  }

  async changeVersion(version) {
    // this.setState({
    //   mode: version === 'draft' ? version : 'read'
    // })
    await this.props.dispatch(changeDraftMode(version === 'draft' || false))
    this.props.dispatch(setGlobalLoader(true))
    await this.props.dispatch(getWorkflowVersion(this.props.match.params.processSlug, version))
    await this.props.dispatch(getVersionList(this.props.companyId, this.props.match.params.processSlug))
    
    // await this.props.dispatch(getVersionList(this.props.companyId, this.props.match.params.processSlug))
    if (version === 'draft') {
      this.props.dispatch(changeVersionType('draft'))
      if (this.props.location.pathname.indexOf('/draft') === -1) {
        this.props.history.push(`${this.props.location.pathname}/draft`)
      }
      this.startSockets()
      socket.emit('client:join-wf-room', {companyId: this.props.companyId, processSlug: this.props.match.params.processSlug, token: this.props.token})
      await new Promise(resolve => setTimeout(resolve, 2000));
      this.setState({
        myUser: this.props.usersConnected.find(u => u.email === this.props.email)
      })
      this.selectWorkflowProperties()
    } else {
      const versionChoosed = this.props.versionList.find(v => v.version === version)
      this.props.dispatch(changeVersionType(versionChoosed.type === 'actual' ? 'published' : 'historic'))
      this.props.history.push(this.props.location.pathname.replace('/draft', ''))
      socket.emit('client:leave-wf-room', {companyId: this.props.companyId, processSlug: this.props.match.params.processSlug, token: this.props.token})
      this.disconnectSockets()
      this.cleanConnected()
    }
    if (this.props.workflow.forms) {
      this.setMaxColumns()
      await this.createRefs(this.props.workflow)
      this.createLines(this.props.workflow)
    }
    
    this.props.dispatch(setGlobalLoader(false))
  }
  
  componentDidUpdate(){
    window.onbeforeunload = (e) => {
      console.log('BEFORE UNLOAD')
      this.leaveRoom()
    }
  }
  async createDraft(version){
    this.props.dispatch(setGlobalLoader(true))
    await this.props.dispatch(createDraft(this.props.match.params.processSlug, version))
    if (this.props.draftError) {
      Modal.error({
        title: 'Error',
        content: 'El proceso ya posee un borrador'
      })
      this.props.dispatch(setGlobalLoader(false))
      return
    }
    this.changeVersion('draft')
    // await this.props.dispatch(getVersionList(this.props.companyId, this.props.match.params.processSlug))
    // if (this.props.workflow.forms) {
    //   this.setMaxColumns(this.props.workflow.roles, this.props.workflow.forms)
    //   this.createRefs(this.props.workflow)
    //   this.createLines(this.props.workflow)
    // }
    // // this.setState({
    // //   mode: 'draft'
    // // })
    // await this.props.dispatch(changeDraftMode(true))
    
    // this.props.dispatch(setGlobalLoader(false))
  }
  onWorkflowProperties = (data) => {
    this.props.dispatch(changeWorkflowProperties(data))
    this.updateSelected()
  }
  onWorkflowAction = async (data) => {
    this.props.dispatch(changeWorkflowAction(data))
    await this.createRefs(this.props.workflow)
    this.createLines(this.props.workflow)
    this.updateSelected()
  }
  onWorkflowRole = (data) => {
    this.props.dispatch(changeWorkflowRole(data))
    this.props.dispatch(getUsersByRole(this.props.companyId, data.newRole, this.props.match.params.processSlug))
    this.props.dispatch(getUsersWithoutRole(this.props.companyId, data.newRole, this.props.match.params.processSlug))
    this.updateSelected()
    // this.createRefs(this.props.workflow)
    // this.createLines(this.props.workflow)
  }
  onUsersConnected = (data) => {
    this.props.dispatch(setUsersConnected(data))
  }
  onUsersEditing = (data) => {
    this.props.dispatch(setUsersEditing(data))
  }
  onGetUsersEditing = () => {
    socket.emit('client:wf-users-editing', {
      companyId: this.props.companyId,
      processSlug: this.props.match.params.processSlug,
      token: this.props.token,
      editing: this.props.usersEditing
    })
  }
  onGetEditingZone = () => {
    if (this.state.editing.zone !== 'none') {
      socket.emit('client:wf-editing-zone', {
        companyId: this.props.companyId,
        processSlug: this.props.match.params.processSlug,
        token: this.props.token,
        editing: this.state.editing
      })
    }
  }
  onCleanEditingZone = async (data) => {
    this.cleanLines(this.props.usersConnected.find(u => u.email === data.user).color)
    const usersEditingList = this.props.usersEditing
    const index = usersEditingList.findIndex(u => u.user === data.user)
    if (index !== -1) {
      usersEditingList.splice(index,1)
    } 
    await this.props.dispatch(setUsersEditing(usersEditingList))
    this.updateSelected()
  }
  onEditingZone = async (data) => {
    const userConnected = this.props.usersConnected.find(u => u.email === data.user)
    if (!userConnected) {
      console.log('NO USER CONNECTED');
      return;
    }
    this.cleanLines(userConnected.color)
    const usersEditingList = this.props.usersEditing
    const index = usersEditingList.findIndex(u => u.user === data.user)
    if (index !== -1) {
      usersEditingList[index] = data
    } else {
      usersEditingList.push(data)
    }
    await this.props.dispatch(setUsersEditing(usersEditingList))
    this.updateSelected()
  }
  setSelected = (editing) => {
    const color = this.props.usersConnected.find(u => u.email === editing.user).color
    if (editing.zone === 'wf-action') {
      const lines = this.state.lines
      // const lines = this.state.lines.map(l => l.color === color ? {...l, color: l.defaultColor} : l)
      const index = lines.findIndex(l => l.from === editing.aux.from && l.to === editing.aux.to)
      if (lines[index].color) {
        lines[index].color = lines[index].color !== lines[index].defaultColor ? lines[index].color : color
      } else {  
        lines[index].color = color 
      }
      this.setState({lines})
    } else if (editing.zone === 'wf-properties') {
      this.setState({propertiesEd: editing.user === this.props.email ? null : color})
    }
  }
  updateSelected = () => {
    if (this.props.usersConnected.length !== 0) {
      for (let i = 0; i < this.props.usersEditing.length; i++) {
        const editing = this.props.usersEditing[i];
        const color = this.props.usersConnected.find(u => u.email === editing.user).color
        if (editing.zone === 'wf-action') {
          const lines = this.state.lines
          // const lines = this.state.lines.map(l => l.color === color ? {...l, color: l.defaultColor} : l)
          const index = lines.findIndex(l => l.from === editing.aux.from && l.to === editing.aux.to)
          if (index === -1) {
            return;
          }
          if (lines[index].color) {
            lines[index].color = lines[index].color !== lines[index].defaultColor ? lines[index].color : color
          } else {  
            lines[index].color = color 
          }
          this.setState({lines})
        } else if (editing.zone === 'wf-properties') {
          this.setState({propertiesEd: editing.user === this.props.email ? null : color})
        }
      }
    }
    
  }
  cleanLines = (color) => {
    this.setState({lines : this.state.lines.map(l => l.color === color ? {...l, color: l.defaultColor} : l)})
  }
  access = () => {
    return this.state.propertiesEd
  }
  // CHANGE WF ZONES
  changeWorkflowProperties = (data) => {
    this.props.dispatch(changeWorkflowProperties(data))
  }
  changeWorkflowAction = async (action) => {
    this.props.dispatch(changeWorkflowAction(action))
    await this.createRefs(this.props.workflow)
    this.createLines(this.props.workflow)
    this.updateSelected()
  }
  changeWorkflowRole = (data) => {
    this.props.dispatch(changeWorkflowRole(data))
    this.updateSelected()
  }
  // SEND ACTIONS TO SOCKETS
  sendWorkflowProperties = (data) => {
    socket.emit('client:wf-properties', {
      companyId: this.props.companyId,
      processSlug: this.props.match.params.processSlug, 
      token: this.props.token,
      properties: data})
  }
  sendWorkflowAction = (action) => {
    socket.emit('client:wf-action', {
      companyId: this.props.companyId,
      processSlug: this.props.match.params.processSlug, 
      token: this.props.token,
      action: action})
  }
  sendWorkflowRole = (data) => {
    console.log('sendWorkflowRole');
    socket.emit('client:wf-role', {
      companyId: this.props.companyId,
      processSlug: this.props.match.params.processSlug, 
      token: this.props.token,
      roleData: data})
  }
  selectWorkflowProperties = () => {
    console.log('selectWorkflowProperties');
    this.selectBlank()
    if (this.props.draftMode) {
      const editingUserFound = this.props.usersEditing.find(u => u.zone === 'wf-properties')
      if (!editingUserFound) {
        socket.emit('client:wf-editing-zone', {
          companyId: this.props.companyId,
          processSlug: this.props.match.params.processSlug,
          token: this.props.token,
          editing: {
            zone: 'wf-properties',
            aux: null
          }
        })
      } else {
        socket.emit('client:wf-clean-editing-zone', {
          companyId: this.props.companyId,
          processSlug: this.props.match.params.processSlug,
          token: this.props.token
        })
      }
    }
    this.props.dispatch(changeActiveProperties('workflow', null))
  }
  selectBlank = () => {
    this.props.dispatch(changeActiveProperties('blank', null))
  }

  selectAction = async (action) => {
    await this.sleepTransition()
    if (this.props.draftMode) {
      const editingUserFound = this.props.usersEditing.find(u => u.zone === 'wf-action' && u.aux.from === action.startSlug && u.aux.to === action.formSlug)
      if (!editingUserFound) {
        socket.emit('client:wf-editing-zone', {
          companyId: this.props.companyId,
          processSlug: this.props.match.params.processSlug,
          token: this.props.token,
          editing: {
            zone: 'wf-action',
            aux: {
              from: action.startSlug,
              to: action.formSlug
            }
          }
        })
      } else {
        socket.emit('client:wf-clean-editing-zone', {
          companyId: this.props.companyId,
          processSlug: this.props.match.params.processSlug,
          token: this.props.token
        })
      }
    }
    

    this.props.dispatch(changeActiveProperties('action', action))
  }
  sleepTransition = async () => {
    this.selectBlank()
    this.props.dispatch(setGlobalLoader(true))
    await new Promise(r => setTimeout(r, 500));
    this.props.dispatch(setGlobalLoader(false))
  }
  selectRole = async (role) => {
    await this.sleepTransition()
    if (this.props.draftMode) {
      const editingUserFound = this.props.usersEditing.find(u => u.zone === 'wf-role' && u.aux.role === role)
      if (!editingUserFound) {
        socket.emit('client:wf-editing-zone', {
          companyId: this.props.companyId,
          processSlug: this.props.match.params.processSlug,
          token: this.props.token,
          editing: {
            zone: 'wf-role',
            aux: {
              role
            }
          }
        })
      } else {
        socket.emit('client:wf-clean-editing-zone', {
          companyId: this.props.companyId,
          processSlug: this.props.match.params.processSlug,
          token: this.props.token
        })
      }
    }
    this.props.dispatch(changeActiveProperties('role', role))
  }
  discardDraft = async () => {
    console.log('discardDraft');
    this.props.dispatch(setGlobalLoader(true))
    await this.props.dispatch(discardDraft(this.props.match.params.processSlug))
    if (this.props.discardDraftError) {
      Modal.error({
        title: 'Error',
        content: 'El proceso ya posee un borrador'
      })
      this.props.dispatch(setGlobalLoader(false))
      return
    } else {
      socket.emit('client:wf-discard-draft', {companyId: this.props.companyId, processSlug: this.props.match.params.processSlug, token: this.props.token})
      this.leaveRoom()
      await this.props.dispatch(changeDraftMode(false))
      await this.loadWorkFlowInfo()
      this.props.dispatch(setGlobalLoader(false))
      
    }
  }
  publishDraft = async () => {
    console.log('discardDraft');
    this.props.dispatch(setGlobalLoader(true))
    await this.props.dispatch(publishDraft(this.props.match.params.processSlug))
    if (this.props.publishDraftError) {
      Modal.error({
        title: 'Error',
        content: 'Ha ocurrido un error al publicar'
      })
      this.props.dispatch(setGlobalLoader(false))
      return
    } else {
      socket.emit('client:wf-publish-draft', {companyId: this.props.companyId, processSlug: this.props.match.params.processSlug, token: this.props.token})
      this.leaveRoom()
      await this.props.dispatch(changeDraftMode(false))
      await this.loadWorkFlowInfo()
      this.props.dispatch(setGlobalLoader(false))
      
    }
  }
  addWorkflowRole = async () => {
    await this.sleepTransition()
    const roleName = `rol-${this.props.workflow.roles.length + 1}`
    this.props.dispatch(addWorkflowRole(roleName))
    socket.emit('client:wf-add-role', {companyId: this.props.companyId, processSlug: this.props.match.params.processSlug, token: this.props.token, roleName})
  }
  addNewForm = () => {
    if (this.state.newFormTitle.trim() === '' || this.state.newFormSlug.trim() === '') {
      this.cancelModal()
      Modal.error({
        title: 'Error',
        content: 'Debe completar todos los campos'
      })
      return;
    }
    const name = this.state.newFormTitle
    const slug = this.state.newFormSlug
    if (this.props.workflow.forms.find(f => f.slug === slug )) {
      this.cancelModal()
      Modal.error({
        title: 'Error',
        content: 'el ID ya se encuentra ocupado'
      })
      return;
    }
    this.cancelModal()
    const data = {
      _id: new ObjectID(),
      title: name,
      slug
    }
    this.props.dispatch(createForm(data))
    socket.emit('client:wf-add-form', {companyId: this.props.companyId, processSlug: this.props.match.params.processSlug, token: this.props.token, form: data})
    this.createRefs()
    this.createLines()
    this.updateSelected()
  }
  thisFormIsEndOfAction = (formSlug) => {
    let status = false
    for (let i = 0; i < this.props.workflow.forms.length; i++) {
      const form = this.props.workflow.forms[i];
      if (form.actions.find(a => a.formSlug === formSlug)) {
        status = true
      }
    }
    return status
  }
  inputOnChange = async (e) => {
    this.setState({
       [e.target.name]: e.target.value
    })
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  cancelModal = () => {
    this.setState({
      visible: false,
      newFormTitle: '',
      newFormSlug: ''
    });
  }
  testDraftShowModal = () => {
    this.setState({
      testDraftModalVisible: true,
    });
  };
  testDraftCancelModal = () => {
    this.setState({
      testDraftModalVisible: false
    });
  }
  testDraftModal = () => {
    return (
      <Modal
        className="draft-modal"
        width={'80vw'}
        closable={true}
        centered={true}
        visible={this.state.testDraftModalVisible}
        title={(<div style={{alignContent: 'center', textAlign: 'center'}}>Casos de prueba</div>)}
        onCancel={this.testDraftCancelModal}
        footer={null}
      >
        <TestDraft history={this.props.history} disconnectSockets={this.disconnectSockets}/>
      </Modal>
    )
  }
  leaveRoom = () => {
    socket.emit('client:leave-wf-room', {companyId: this.props.companyId, processSlug: this.props.match.params.processSlug, token: this.props.token})
    this.disconnectSockets()
    this.cleanConnected()
  }
   

  render() {
    const { workflow, loading, error, history, activePropertie, auxPropertie, draftMode, usersConnected } = this.props
    const versionList = !this.state.canDraftManagement ? this.props.versionList.filter(v => v.type !== 'draft') : this.props.versionList
    if (error) {
    return <div>Error! {error.message}</div>;
    }

    if (loading) {
    return <div>Loading...</div>;
    }
    return (
      <div className="workflow__wrapper">
       <header>
        <div className="workflow__tit">
          
          <div className="workflow__tit_name">
            <div className="workflow__back_arrow" onClick={
              () => {
                this.leaveRoom()
                history.push('/procesos')
              }
            }
            >
              <FontAwesomeIcon icon="arrow-left" />
            </div>
            <p onClick={()=> {this.selectWorkflowProperties()}}>
              <img src="/assets/form-builder/ico-title-editor.png" />
              Editor de flujo: {workflow.title} ({workflow.slug})
              {/* {props.formData[0] ? `${props.formData[0].processName}` : 'Editor de formulario'}: <b>{props.formData[0] ? `${props.formData[0].title} (${props.formData[0].slug}) ${props.formModified ? '*': ''}` : ''}</b> */}
            </p>
            {draftMode ? (
              <>
                <p onClick={()=> {this.addWorkflowRole()}}>
                  <PlusCircleOutlined style={{paddingRight: '4px'}} />
                  Nuevo Rol
                </p>
                <p onClick={()=> {this.showModal()}}>
                  <PlusCircleOutlined style={{paddingRight: '4px'}} />
                  Nuevo Formulario
                </p>
                <Modal
                  width={360}
                  closable={false}
                  centered={true}
                  visible={this.state.visible}
                  title={(<div style={{alignContent: 'center', textAlign: 'center'}}>Nuevo Formulario</div>)}
                  onOk={this.addNewForm}
                  onCancel={this.cancelModal}
                  cancelText="Cancelar"
                  okText="Crear"
                >
                  <div className="modal-form">
                    <div className="label">
                      Nombre
                    </div>
                    <Input
                      type="text"
                      name="newFormTitle"
                      value={this.state.newFormTitle}
                      onChange={this.inputOnChange}
                    />
                    <div className="label">
                      ID
                    </div>
                    <Input
                      type="text"
                      name="newFormSlug"
                      value={this.state.newFormSlug}
                      onChange={this.inputOnChange}
                    />
                  </div>
                </Modal>
              </>
            ): ''}
          </div>
          {/* <div className="workflow__tit_role">
            <p>Rol: <b>{props.formMetadata.filter(f => f.name === 'roleAssignment')[0].value ? props.formMetadata.filter(f => f.name === 'roleAssignment')[0].value : ''}</b></p>
          </div> */}
          <div className="version-actions">
            {
              draftMode ? (
                <div className="draft-avatar">
                  {usersConnected.map((elem, index) => {
                    return (
                      <Avatar
                        key={index}
                        style={{
                          backgroundColor: elem.color,
                          marginLeft: '2px'
                        }}
                      >
                        {`${elem.firstName.charAt(0).toUpperCase()}${elem.lastName.charAt(0).toUpperCase()}`}
                      </Avatar>
                    )
                  })}
                </div>
              ) : ''
            }
            
            <div className="version-select">
              <Select 
                onChange={value => this.changeVersion(value)}
                // value={obj.defaultValue}
                defaultValue={draftMode ? 'draft' : workflow.version}
              >
                {
                  versionList ? (
                    versionList.map((elem, index) => {
                      return (
                        <Select.Option key={`optSel-${index}`} value={elem.type === 'draft' ? elem.type : elem.version}>
                          Versión {elem.version} {`${elem.type === 'actual' ? '(Publicada)' : elem.type === 'draft' ? '(Borrador)' : '' }`}
                        </Select.Option>
                      )
                    })
                  ) : ''
                }
              </Select>
            </div>
            { this.state.canDraftManagement?
              !draftMode ? 
              (
                <div className="new-draft-button" >
                  <button 
                  onClick={ () => {
                      this.createDraft(workflow.version)
                      // Modal.info({
                      //   title: 'Esta funcionalidad aun no se encuentra disponible',
                      //   onOk() {},
                      // });
                    }}
                  >
                    Crear nueva versión
                  </button>
                </div>
              ):
              (
                <div className="publish-draft-button">
                  <button 
                  onClick={ () => {
                    this.testDraftShowModal()
                    }}
                  >
                    <Tooltip title="Probar borrador">
                      <CaretRightOutlined />
                    </Tooltip>
                  </button>
                  {this.testDraftModal()}
                  <button 
                  onClick={ () => {
                    Modal.confirm({
                      title: '¿Desea descartar el borrador actual?',
                      icon: <ExclamationCircleOutlined />,
                      onOk: () => {
                        this.discardDraft()
                      },
                      onCancel() {
                        console.log('Descartar cancelado');
                      },
                    });
                      
                      // props.setGlobalLoader(true)
                      // props.updateForm(this.props.companyId, props.processSlug, props.formSlug, props.currentForm, props.formMetadata, props.rawFormData)
                    }}
                  >
                    <Tooltip title="Descartar borrador">
                     <DeleteOutlined />
                    </Tooltip>
                  </button>
                  <button 
                  onClick={ () => {
                    Modal.confirm({
                      title: '¿Desea publicar el borrador actual?',
                      icon: <ExclamationCircleOutlined />,
                      onOk: () => {
                        this.publishDraft()
                      },
                      onCancel() {
                        console.log('publish cancelado');
                      },
                    });
                      // this.createDraft(workflow.version)
                      // props.setGlobalLoader(true)
                      // props.updateForm(this.props.companyId, props.processSlug, props.formSlug, props.currentForm, props.formMetadata, props.rawFormData)
                    }}
                  >
                    <Tooltip title="Publicar borrador">
                      <UploadOutlined />
                    </Tooltip>
                  </button>
                  
                </div>
              ) : ''
            }
           
          </div>
          {/* <div style={{color: 'white',paddingTop: '13px'}}>
            DF (Beta) 
            <Link to={`/form-list/${workflow._id}/${workflow.slug}`}>
              <Switch checked={true} />
            </Link>
          </div> */}
        </div>
        
        
      </header>
      <Row style={{flexWrap: 'nowrap'}}>
        {/* <Col flex="0 1 1160px" style={ {backgroundColor: 'black', height: '500px'} }> */}
        <Col flex="auto">
          <DndProvider backend={HTML5Backend}>
            <ScrollSync>
              <div className="role-swimlane-container" >
                {workflow.roles ? workflow.roles.map((role, i) =>  {
                  const forms = workflow.forms.filter(f => f.roleAssignment === role)
                  const finalForms = new Array(this.state.cols + 1).fill(null)
                  for (let j = 0; j < finalForms.length; j++) {
                    finalForms[j] = forms.find(f => f.position !== undefined && f.position !== null && f.position === j)
                    if ((finalForms[j] === null || finalForms[j] === undefined) && forms[j] && (forms[j].position === null || forms[j].position === undefined) ) {
                      finalForms[j] = forms[j]
                    }
                    // if (!finalForms[i] && forms[i] && !forms[i].position ) {
                    //   finalForms[i] = forms[i]
                    // }
                  }
                  return (
                    
                      <RoleSwimlane 
                      key={i}
                      roleName={role}
                      forms={finalForms}
                      processSlug={workflow.slug}
                      maxCols={this.state.cols}
                      ref={this.formRefs}
                      selectRole={this.selectRole}
                      emitDeleteRole={this.emitDeleteRole}
                      emitMoveUpRole={this.emitMoveUpRole}
                      emitMoveDownRole={this.emitMoveDownRole}
                      setMaxColumns={this.setMaxColumns}
                      createLines={this.createLines}
                      updateSelected={this.updateSelected}
                      thisFormIsEndOfAction={this.thisFormIsEndOfAction}
                      lines={this.state.lines}
                      unselectLines={this.unselectLines}
                      selectAction={this.selectAction}
                      ></RoleSwimlane>
                    
                  )
                }): ''}
                {this.formRefs && this.state.lines.length ? 
                  this.state.lines.map((line, i) => {
                    if (!this.formRefs[line.from]) {
                      return ''
                    }
                    if (line.from === line.to) {
                      return ''
                    }
                    if (!line.from || !line.to) {
                      return ''
                    }
                    return(
                      <Xarrow
                        key={i}
                        start={this.formRefs[line.from]}
                        end={this.formRefs[line.to]}
                        color={line.color ? line.color : line.defaultColor}
                        passProps = {{
                          onClick: (e) => {
                            e.stopPropagation(); //so only the click event on the box will fire on not on the conainer itself
                            this.unselectLines(this.state.lines)
                            line.selected = true
                            this.selectAction(line.action)
                          },
                          cursor: "pointer",
                        }}
                        {...line}
                      />
                      )
                  }
                  )
                : ''}
              </div>
            </ScrollSync>
          </DndProvider>
        </Col>
        <Col flex="0 1 332px" >
          <div className="side-properties-container">
            {
              activePropertie === 'workflow'
                // ? (<WorkflowProperties workflow={workflow} readMode={ this.state.mode === 'draft' ? false : true } otherEditing={this.state.mode === 'draft' ? this.state.usersEditing.find(u => u.zone === 'wf-properties') ? true : false : false} changeWorkflowProperties={this.changeWorkflowProperties}/>)
                ? (<WorkflowProperties workflow={workflow}  readMode={ this.state.mode === 'draft' ? false : true } changeWorkflowProperties={this.changeWorkflowProperties} sendWorkflowProperties={this.sendWorkflowProperties} loadWorkFlowInfo={this.loadWorkFlowInfo}/>)
                : activePropertie === 'role'
                  ? (<RoleProperties workflow={workflow} role={auxPropertie} changeWorkflowRole={this.changeWorkflowRole} sendWorkflowRole={this.sendWorkflowRole} />)
                  : activePropertie === 'action'
                    ? (<ActionProperties action={auxPropertie} changeWorkflowAction={this.changeWorkflowAction} sendWorkflowAction={this.sendWorkflowAction} createLines={this.createLines} processSlug={workflow.slug} />)
                    : activePropertie === 'blank'
                      ? ''
                      : ''
            } 
            
          </div>
        </Col>
      </Row>
      <footer>
        <section className="workflow__actions">
          <div style={{display: 'flex', justifyContent: 'center', flex: 4}}>
            <div key={`btn-return`} className="">
              <a className="workflow_back_down_button" 
              onClick={
                () => {
                  this.leaveRoom()
                  history.push('/procesos')
                }
              }
              ><FontAwesomeIcon icon="arrow-left" />Volver</a>
            </div>
            {/* <button onClick={ () => {
              // props.setGlobalLoader(true)
              // props.updateForm(this.props.companyId, props.processSlug, props.formSlug, props.currentForm, props.formMetadata, props.rawFormData)
            }}
            >
              Guardar
            </button> */}
          </div>
          
        </section>
      </footer>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  const { workflow, form, loading, error, activePropertie, auxPropertie, versionList, versionListLoading, versionListError, draftError, draftMode, usersConnected, usersEditing, discardDraftError, publishDraftError } = state.workflowDesigner
  const { token, companyId, email, permissions } = state.user
  return {
    workflow,
    form,
    loading,
    error,
    activePropertie,
    auxPropertie,
    versionList,
    versionListLoading,
    versionListError,
    draftError,
    draftMode,
    usersConnected,
    usersEditing,
    discardDraftError,
    publishDraftError,
    token,
    companyId,
    email,
    permissions
  }
}

export default connect(mapStateToProps, null, null, {forwardRef : true})(WorkflowDesigner)
// export default connect(mapStateToProps)(WorkflowDesigner)