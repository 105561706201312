import React, { Component } from 'react'

import { connect } from 'react-redux'
import '../components/_globals/layouts.scss';
import { setIsMobile } from '../actions/actions'
import { setUserData } from '../actions/userActions'

import Loader from '../components/Loader/Loader';
import { Layout } from 'antd';
import { initiateSocket, socket } from '../utils/Socket'
import axiosBW from '../utils/Http';
const { Content } = Layout;


class FullScreenLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount () {
    if (window.innerWidth < 768) {
      this.props.setIsMobile(true)
    } else {
      this.props.setIsMobile(false)
    }
    window.addEventListener('resize', () => {
      this.resize()
    })
    await this.syncUserData()
    if ((!socket || socket.disconnected) && this.props.email) {
      initiateSocket(this.props.email, this.props.companyId, this.props.firstName, this.props.lastName)
    }
  }

  async syncUserData () {
    try {
      const res = await axiosBW.get('/auth/user-info')
      this.setState({
        messageForm: {
          title: '',
          description: '',
          icon: ''
        }
      })
      const user = res.data.payload.user
      const token = res.data.payload.token
      if (res.data.message.length === 0) {
        this.props.setUserData(user, token)
        if (user.haveToCompleteData) {
          this.props.history.push('/ultimo_paso')
        } else if (user.firstRedirect) {
          this.props.history.push(user.firstRedirect)
        }
      }
    } catch (err) {
      console.log(err.response)
      if (err.response) {

      }
      localStorage.clear()
      this.props.history.push('/ingresar')
    }
  }

  resize () {
    if (window.innerWidth < 768) {
      this.props.setIsMobile(true)
    } else {
      this.props.setIsMobile(false)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', () => {
      this.resize()
    })
  }

  render () {
    return (
      <Layout className="fullscreen_layout">
        <Content>
          {this.props.adminMessage ? (<div className="admin-message" dangerouslySetInnerHTML={{ __html: this.props.adminMessage }}></div>) : ''}
          {this.props.token ? this.props.children : ''}
        </Content>
        <Loader />
      </Layout>
    )

  }
}

const mapStateToProps = (state) => ({
  isMobile: state.navigation.isMobile,
  email: state.user.email,
  companyId: state.user.companyId,
  firstName: state.user.firstName,
  lastName: state.user.lastName,
  token: state.user.token,
  adminMessage: state.user.adminMessage,
})

const mapDispatchToProps = (dispatch) => ({
  setIsMobile: (bool) => dispatch(setIsMobile(bool)),
  setUserData: (user, token) => dispatch(setUserData(user, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(FullScreenLayout)