import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Modal, Row, Col, Input, Button, Form, Image, Radio } from 'antd';
import './CountryTaxId.scss'
// import axios from 'axios'
// import cf from '../../../config/APIConfig'
import axiosBW from '../../../utils/Http';
import { setGlobalLoader } from '../../../actions/actions'

const CountryTaxId = (props) => {
  const dispatch = useDispatch();
  console.log({props})
  const isMobile = useSelector(state => state.navigation.isMobile)
  const legalName = useSelector(state => state.user.legalName)
  const companyId = useSelector(state => state.user.companyId)
  const [visible, setVisible] = useState(false)
  const [countryChoosed, setCountryChoosed] = useState('')
  const [companyType, setCompanyType] = useState(1)
  const [step, setStep] = useState(1)
  const [stepMsg, setStepMsg] = useState('')
  const [formCountry] = Form.useForm();
  const [form] = Form.useForm();
  const countries = [
    {value: 'AR', label: 'Argentina'},
    {value: 'CL', label: 'Chile'},
    {value: 'PE', label: 'Perú'},
    {value: 'UR', label: 'Uruguay'},
    {value: 'CO', label: 'Colombia'},
    {value: 'MX', label: 'México'},
    {value: 'EC', label: 'Ecuador'},
    {value: 'PA', label: 'Panamá'},
    {value: 'PY', label: 'Paraguay'},
    {value: 'CR', label: 'Costa Rica'},
    {value: 'DO', label: 'República Dominicana'},
  ]
  

  const onFinishCountry = async (values) => {
    console.log('Received values of form: ', values);
    setCountryChoosed(countries.find(c => c.value === values.countryChoose).label)
    formCountry.setFieldsValue({
      country: countries.find(c => c.value === values.countryChoose).label
    })
    setStep(2)
  };
  const onFinish = async (values) => {
    console.log('Received values of form: ', values);

    dispatch(setGlobalLoader(true))
    try {
      const resp = await axiosBW.post(`/auth/last-step/`,
        {
          country: values.country,
          taxId: values.taxId,
          companyId: companyId
        }
      );
      dispatch(setGlobalLoader(false))
      Modal.info({
        className: "invitation_modal_success",
        icon: null,
        width: 586,
        footer: null,
        okText: 'OK',
        title: (<div style={{alignContent: 'center', textAlign: 'center', fontSize: '30px'}}>¡Bienvenid@ a Blizwork!</div>),
        content: (<div style={{alignContent: 'center', textAlign: 'center', fontSize: '17px'}}>
            <div>
              Serás redirigido en un momento
            </div>
          </div>)
      })
      setTimeout(() => {
        props.history.push('/')
      }, 2000); 
    } catch (err) {
      localStorage.clear()
      if (err.response && err.response.data && err.response.data.message) {
        const respMsg = err.response.data.message
        Modal.error({
          title: 'Error',
          content: respMsg
        })
      } else {
        Modal.error({
          title: 'Error',
          content: 'Ha ocurrido un error, contacte con el administrador'
        })
        setTimeout(() => {
          props.history.push('/')
        }, 2000); 
      }
    }
  };


  // Reemplazo de componentDidMount
  useEffect( () => {
    console.log('va el UseEffect');
    dispatch(setGlobalLoader(false))
  }, []);
  // const dispatch = useDispatch();
  // //useSelector reemplaza el mapStateToProps
  // const users = useSelector(state => state.workflowDesigner.usersByRole);
  // const companyId = useSelector(state => state.workflowDesigner.workflow.companyId);
  
  // if (!users) {
  //   return 'No USERS'
  // }
  return (
    <div className="invitation_back">
      <div className="invitation_wrapper">
        <Row>
          <Col className="image-wrapper" flex="0 1 auto">
            {
              !isMobile ? (
                <Image
                  preview={false}
                  src='\img\atenea-a-un-click.png'
                />
              ) : ''
            }
            
          </Col>
          <Col flex="auto">
            {
              step === 1 ? (
                <div className="invitation_form_wrapper">
                  <div className="invitation_form_title">
                    ¿Completemos tu perfil?
                  </div>
                  <div className="invitation_form_subtitle">
                    <p>Antes de continuar, necesitamos algunos datos básicos de tu organización. Como eres el primer usuario de ésta, <b>tú serás el administrador</b>.</p>
                    <br/>
                    <p>Por favor, ingresa los siguientes datos de tu Empresa u Organización:</p>
                     
                  </div>
                    <Form
                    form={formCountry}
                    size="large"
                    name="register"
                    onFinish={onFinishCountry}
                    preserve={false}
                    initialValues={{
                      legalName
                    }}
                    scrollToFirstError
                  >
                    <Form.Item 
                      name="country" 
                      label=""
                      style={{opacity: '0', position:'absolute'}}
                    >
                      <Input style={{opacity: '0', position:'absolute'}}/>
                    </Form.Item>
                    <Form.Item 
                      name="legalName" 
                      label=""
                      style={{opacity: '0', position:'absolute'}}
                    >
                      <Input style={{opacity: '0', position:'absolute'}}/>
                    </Form.Item>

                    <Form.Item 
                      name="countryChoose" 
                      label=""
                      rules={[
                        {
                          required: true,
                          message: 'País requerido',
                        },
                      ]} 
                    >
                      
                      <Radio.Group >
                        <Radio.Button className="country-button" value="AR">
                        <Image
                          // width={75}
                          // height={50}
                          style={{
                            width: '75px',
                            height: '50px',
                            objectFit: 'fill'
                          }}
                          preview={false}
                          src='\img\countries/AR.png'
                        />
                          <p>Argentina</p>
                        </Radio.Button>
                        <Radio.Button className="country-button" className="country-button" value="CL">
                        <Image
                          // width={75}
                          // height={50}
                          style={{
                            width: '75px',
                            height: '50px',
                            objectFit: 'fill'
                          }}
                          preview={false}
                          src='\img\countries/CL.png'
                        />
                        <p>
                          Chile
                        </p>
                          </Radio.Button>
                        <Radio.Button className="country-button" value="PE">
                        <Image
                          // width={75}
                          // height={50}
                          style={{
                            width: '75px',
                            height: '50px',
                            objectFit: 'fill'
                          }}
                          preview={false}
                          src='\img\countries/PE.png'
                        />
                          <p>Perú</p>
                        </Radio.Button>
                        <Radio.Button className="country-button" value="UR">
                        <Image
                          // width={75}
                          // height={50}
                          style={{
                            width: '75px',
                            height: '50px',
                            objectFit: 'fill'
                          }}
                          preview={false}
                          src='\img\countries/UR.png'
                        />
                          <p>Uruguay</p>
                        </Radio.Button>
                        <Radio.Button className="country-button" value="CO">
                        <Image
                          // width={75}
                          // height={50}
                          style={{
                            width: '75px',
                            height: '50px',
                            objectFit: 'fill'
                          }}
                          preview={false}
                          src='\img\countries/CO.png'
                        />
                          <p>Colombia</p>
                        </Radio.Button>
                        <Radio.Button className="country-button" value="MX">
                        <Image
                          // width={75}
                          // height={50}
                          style={{
                            width: '75px',
                            height: '50px',
                            objectFit: 'fill'
                          }}
                          preview={false}
                          src='\img\countries/MX.png'
                        />
                          <p>Mexico</p>
                        </Radio.Button>
                        <Radio.Button className="country-button" value="EC">
                        <Image
                          // width={75}
                          // height={50}
                          style={{
                            width: '75px',
                            height: '50px',
                            objectFit: 'fill'
                          }}
                          preview={false}
                          src='\img\countries/EC.png'
                        />
                          <p>Ecuador</p>
                        </Radio.Button>
                        <Radio.Button className="country-button" value="PA">
                        <Image
                          // width={75}
                          // height={50}
                          style={{
                            width: '75px',
                            height: '50px',
                            objectFit: 'fill'
                          }}
                          preview={false}
                          src='\img\countries/PA.png'
                        />
                          <p>Panamá</p>
                        </Radio.Button>
                        <Radio.Button className="country-button" value="PY">
                        <Image
                          // width={75}
                          // height={50}
                          style={{
                            width: '75px',
                            height: '50px',
                            objectFit: 'fill'
                          }}
                          preview={false}
                          src='\img\countries/PY.png'
                        />
                          <p>Paraguay</p>
                        </Radio.Button>
                        <Radio.Button className="country-button" value="CR">
                        <Image
                          // width={75}
                          // height={50}
                          style={{
                            width: '75px',
                            height: '50px',
                            objectFit: 'fill'
                          }}
                          preview={false}
                          src='\img\countries/CR.png'
                        />
                          <p>Costa Rica</p>
                        </Radio.Button>
                        <Radio.Button className="country-button" value="DO">
                        <Image
                          // width={75}
                          // height={50}
                          style={{
                            width: '75px',
                            height: '50px',
                            objectFit: 'fill'
                          }}
                          preview={false}
                          src='\img\countries/DO.png'
                        />
                          <p>República Dominicana</p>
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                    <Button type="primary" htmlType="submit"
                    >
                      Siguiente
                    </Button>
                  </Form>
                </div>
              ):(
                <div className="invitation_form_wrapper">
                  <div className="invitation_form_title">
                    ¿Completemos tu perfil?
                  </div>
                  <div className="invitation_form_subtitle">
                    <p>Antes de continuar, necesitamos algunos datos básicos de tu organización. Como eres el primer usuario de ésta, <b>tú serás el administrador</b>.</p>
                    <br/>
                    <p>Por favor, ingresa los siguientes datos de tu Empresa u Organización:</p>
                     
                  </div>
                    <Form
                      form={form}
                      size="large"
                      name="register"
                      onFinish={onFinish}
                      // preserve={false}
                      scrollToFirstError
                    >
                      <label>País de Residencia:</label>
                      <Form.Item
                        className="invitation_form_field"
                        name="country"
                        style={{ width: '100% ' }}
                      >
                        <Input  disabled={true} placeholder="País" />
                      </Form.Item>
                      <label>Razón social o nombre legal de la empresa:</label>
                      <Form.Item
                        className="invitation_form_field"
                        name="legalName"
                        style={{ width: '100% ' }}
                      >
                        <Input  disabled={true} placeholder="Empresa" />
                      </Form.Item>
                      <label>RUT/RUC de la organización:</label>
                      <Form.Item
                        className="invitation_form_field"
                        name="taxId"
                        style={{ width: '100% ' }}
                        rules={[
                          {
                            required: true,
                            message: 'RUT/RUC requerido',
                          },
                        ]} 
                      >
                        <Input  placeholder="Rut" />
                      </Form.Item>
                    <Button type="primary" htmlType="submit"
                    >
                      ¡Listo!
                    </Button>
                  </Form>
                </div>
              )
            }
            
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default CountryTaxId