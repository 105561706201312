/* eslint-disable no-script-url */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Select, Checkbox, Modal, Button, Tooltip, Radio, InputNumber } from 'antd'
import ReactSelect from 'react-select'
import { DeleteOutlined, PlusCircleOutlined, EditOutlined, QuestionCircleFilled, LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  setPropertiesVisible,
  changeFormMetadata,
  formChangeValue,
  setFormModified,
} from '../../../../../actions/actions';
import axiosBW from '../../../../../utils/Http';
import AddHookBox from '../../../../../components/AddHookBox/AddHookBox'
import MyHooksModal from '../../../../../components/MyHooksModal/MyHooksModal'

const SideProperties = props => {
  const dispatch = useDispatch();
  const currentFieldSelected = useSelector(state => state.formBuilder.currentFieldSelected)
  const tableWidgets = useSelector(state => state.formBuilder.tableWidgets)
  const initialFormData = useSelector(state => state.formBuilder.initialFormData)
  const initialFormMetaData = useSelector(state => state.formBuilder.initialFormMetaData)
  const formMetadata = useSelector(state => state.formBuilder.formMetadata)
  const visiblePropertiesList = useSelector(state => state.formBuilder.visiblePropertiesList)
  const currentForm = useSelector(state => state.formBuilder.currentForm)
  const companyId = useSelector(state => state.user.companyId)
  const [visible, setVisible] = useState(false);
  const [myHooksModalVisible, setMyHooksModalVisible] = useState(false);
  const [myHooksModalKey, setMyHooksModalKey] = useState(null);
  /*eslint-disable-next-line no-unused-vars*/
  const [masterTableVisible, setMasterTableVisible] = useState(false);
  const [tableFieldPropertiesModalVisible, setTableFieldPropertiesModalVisible] = useState(false);
  const [actualSelect, setActualSelect] = useState(null);
  /*eslint-disable-next-line no-unused-vars*/
  const [actualField, setActualField] = useState(null);
  const [actualIndex, setActualIndex] = useState(null);
  const [masterCollections, setMasterCollections] = useState([]);
  const [masterColumns, setMasterColumns] = useState([]);
  const [hooksActionSelected, setHooksActionSelected] = useState(0)
  const openMyHooksModal = () => {
    setMyHooksModalKey(Date.now())
    setMyHooksModalVisible(true)
  }
  const closeMyHooksModal = () => {
    setMyHooksModalVisible(false)
  }
  const formChangeValueD = (elem_id, value, identifier) => {dispatch(formChangeValue(elem_id, value, identifier))}
  const setPropertiesVisibleD = element => dispatch(setPropertiesVisible(element))
  const onCancel = () => {
    setVisible(false)
    setTableFieldPropertiesModalVisible(false)
    setActualSelect(null)
    setActualField(null)
    setActualIndex(null)
  }
  
  const addHook = (actionIndex, actions) => {
    const newActions = [...actions]
    newActions[actionIndex].hooks.push('')
    dispatch(changeFormMetadata('hooks', newActions))
  }

  const deleteHook = (actionIndex, actions, hookIndex) => {
    const newActions = [...actions]
    newActions[actionIndex].hooks.splice(hookIndex, 1)
    dispatch(changeFormMetadata('hooks', newActions))
  }

  const addExtension = (hook) => {
    const actions= props.formMetadata.find(f=> f.type === 'hooks').value
    const newActions = [...actions]
    const h = { ...hook }
    if (h.bodyParams) {
      for (let i = 0; i < h.bodyParams.length; i++) {
        const param = h.bodyParams[i];
        if (param.type === 'select') {
          const def = param.options.find(o => o.default)
          param.value = def ? def.value : param.value
        }
      }
    }
    const newExtension = {
      hookId: h._id,
      urlParams: h.urlParams,
      bodyParams: h.bodyParams,
      firstAdd: true
    }
    if (newActions[hooksActionSelected].extensions) {
      newActions[hooksActionSelected].extensions.push(newExtension)
    } else {
      newActions[hooksActionSelected].extensions = [newExtension]
    }
    props.changeFormMetadata('hooks', newActions)
    setMyHooksModalVisible(false)
  }

  const deleteExtension = (hookIndex) => {
    const actions= props.formMetadata.find(f=> f.type === 'hooks').value
    const newActions = [...actions]
    newActions[hooksActionSelected].extensions.splice(hookIndex, 1)
    props.changeFormMetadata('hooks', newActions)
  }

  const editExtension = (urlParams, bodyParams, hookIndex) => {
    const actions= props.formMetadata.find(f=> f.type === 'hooks').value
    const newActions = [...actions]
    newActions[hooksActionSelected].extensions[hookIndex].urlParams = urlParams
    newActions[hooksActionSelected].extensions[hookIndex].bodyParams = bodyParams
    props.changeFormMetadata('hooks', newActions)
  }

  const changeHook = (actionIndex, actions, hookIndex, value) => {
    const newActions = [...actions]
    newActions[actionIndex].hooks[hookIndex] = value
    dispatch(changeFormMetadata('hooks', newActions))
  }

  const changeActionMetadata = (actionIndex, actions, field, value) => {
    const newActions = [...actions]
    newActions[actionIndex][field] = value
    dispatch(changeFormMetadata('hooks', newActions))
  }

  // Master Data Methods BEGIN //

  const getMasterCollections = () => {
    return axiosBW.get(`master-data/master-collections-by-company/${companyId}/`)
  }

  useEffect( () => {
    if (visiblePropertiesList.length > 0 && visiblePropertiesList.find(v => v.display_type === 'selector-master-collection')) {
      const masterData = visiblePropertiesList.find(v => v.display_type === 'selector-master-collection')
      setMasterCollections([])
      setMasterColumns([])
      getMasterCollections()
      .then(resp => {
        setMasterCollections(resp.data.payload) 
        if (masterData.value.collection !== '') {
          getMasterFields(masterData.value.collection)
          .then(fieldResp => {
            setMasterColumns(fieldResp.data.payload.map(m => ({value: m, label: m})))
          })
          .catch(err => {
            console.log(err);
            setMasterColumns([])
          })
        }
        setPropertiesVisibleD(currentForm.filter(el => el.elem_id === masterData.elem_id)[0])
      })
      .catch(err => {
        console.log(err);
        setMasterCollections([])
      })
    }
  }, [currentFieldSelected])

  const getMasterFields = (collection) => {
    return axiosBW.get(`master-data/field-names/${collection}/`)
  }

  const onMasterAllowNewValues = (selectControl, element)  => {
    // console.log(`allowNewValues: ${JSON.stringify(selectControl)}, ${JSON.stringify(element)}`)
    const newElementValue = {...element.value}
    newElementValue.allowNewValues = selectControl.checked
    formChangeValueD(element.elem_id, newElementValue, element.identifier)
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === element.elem_id)[0])
  }

  const onSelectMasterCollection = (value, element) => {
    const newElementValue = {...element.value}
    newElementValue.collection = value
    formChangeValueD(element.elem_id, newElementValue, element.identifier)
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === element.elem_id)[0])
    getMasterFields(value)
    .then(fieldResp => {
      setMasterColumns(fieldResp.data.payload.map(m => ({value: m, label: m})))
    })
    .catch(err => {
      console.log(err);
      setMasterColumns([])
    })
  }

  const onChangeMasterFilter = (value, element) => {
    const newElementValue = {...element.value}
    newElementValue.filter = value
    formChangeValueD(element.elem_id, newElementValue, element.identifier)
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === element.elem_id)[0])
  }

  const onSelectMasterValue = (value, element) => {
    const newElementValue = {...element.value}
    newElementValue.value = value
    formChangeValueD(element.elem_id, newElementValue, element.identifier)
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === element.elem_id)[0])
  }

  const onSelectMasterLabel = (value, element) => {
    const newElementValue = {...element.value}
    newElementValue.label = value
    formChangeValueD(element.elem_id, newElementValue, element.identifier)
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === element.elem_id)[0])
  }

  const updateMasterFieldSlug = (index, e, element)  => {
    const newElementValue = {...element.value}
    newElementValue.fields[index].slug = e.target.value
    formChangeValueD(element.elem_id, newElementValue, element.identifier)
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === element.elem_id)[0])
  }

  const updateMasterFieldColumn = (index, e, element)  => {
    const newElementValue = {...element.value}
    newElementValue.fields[index].column = e
    formChangeValueD(element.elem_id, newElementValue, element.identifier)
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === element.elem_id)[0])
  }

  const addMasterField = (element)  => {
    const newElementValue = {...element.value}
    newElementValue.fields.push({slug: '', column: ''})
    formChangeValueD(element.elem_id, newElementValue, element.identifier)
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === element.elem_id)[0])
  }

  const deleteMasterField = (index, element)  => {
    const newElementValue = {...element.value}
    newElementValue.fields.splice(index, 1)
    formChangeValueD(element.elem_id, newElementValue, element.identifier)
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === element.elem_id)[0])
  }
  // Master-Data Methods END //

  // Master-Data Table Methods BEGIN //
  const onSelectMasterCollectionTable = (value)  => {
    let copyColumns = [...actualSelect.value]
    copyColumns[actualIndex].masterDataConfig.collection = value
    formChangeValueD(
      actualSelect.elem_id, 
      copyColumns, 
      'columns'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === actualSelect.elem_id)[0])
    actualSelect.value = copyColumns
    setActualSelect(actualSelect)
    getMasterFields(value)
    .then(fieldResp => {
      setMasterColumns(fieldResp.data.payload.map(m => ({value: m, label: m})))
    })
    .catch(err => {
      console.log(err);
      setMasterColumns([])
    })
  }

  const onSelectMasterValueTable = (value)  => {
    let copyColumns = [...actualSelect.value]
    copyColumns[actualIndex].masterDataConfig.value = value
    formChangeValueD(
      actualSelect.elem_id, 
      copyColumns, 
      'columns'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === actualSelect.elem_id)[0])
    actualSelect.value = copyColumns
    setActualSelect(actualSelect)
  }

  const onChangeMasterFilterTable = (value)  => {
    let copyColumns = [...actualSelect.value]
    copyColumns[actualIndex].masterDataConfig.filter = value
    formChangeValueD(
      actualSelect.elem_id, 
      copyColumns, 
      'columns'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === actualSelect.elem_id)[0])
    actualSelect.value = copyColumns
    setActualSelect(actualSelect)
  }

  const onSelectMasterLabelTable = (value)  => {
    let copyColumns = [...actualSelect.value]
    copyColumns[actualIndex].masterDataConfig.label = value
    formChangeValueD(
      actualSelect.elem_id, 
      copyColumns, 
      'columns'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === actualSelect.elem_id)[0])
    actualSelect.value = copyColumns
    setActualSelect(actualSelect)
  }

  const addMasterFieldTable = ()  => {
    let copyColumns = [...actualSelect.value]
    copyColumns[actualIndex].masterDataConfig.fields.push({slug: '', column: ''})
    formChangeValueD(
      actualSelect.elem_id, 
      copyColumns, 
      'columns'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === actualSelect.elem_id)[0])
    actualSelect.value = copyColumns
    setActualSelect(actualSelect)
  }

  const deleteMasterFieldTable = (index)  => {
    let copyColumns = [...actualSelect.value]
    copyColumns[actualIndex].masterDataConfig.fields.splice(index, 1)
    formChangeValueD(
      actualSelect.elem_id, 
      copyColumns, 
      'columns'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === actualSelect.elem_id)[0])
    actualSelect.value = copyColumns
    setActualSelect(actualSelect)
  }

  const updateMasterFieldSlugTable = (index, e)  => {
    let copyColumns = [...actualSelect.value]
    copyColumns[actualIndex].masterDataConfig.fields[index].slug = e.target.value
    formChangeValueD(
      actualSelect.elem_id, 
      copyColumns, 
      'columns'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === actualSelect.elem_id)[0])
    actualSelect.value = copyColumns
    setActualSelect(actualSelect)
  }

  const updateMasterFieldColumnTable = (index, e)  => {
    let copyColumns = [...actualSelect.value]
    copyColumns[actualIndex].masterDataConfig.fields[index].column = e
    formChangeValueD(
      actualSelect.elem_id, 
      copyColumns, 
      'columns'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === actualSelect.elem_id)[0])
    actualSelect.value = copyColumns
    setActualSelect(actualSelect)
  }

  const tableMasterDataModal = () => {
    return (
      masterTableVisible ?(
        <Modal
            className="master_data_modal"
            visible={masterTableVisible}
            title= {(
              <div>
              <div className="modal_title">
                Tabla: <b>{visiblePropertiesList.find(p => p.identifier === 'name').value} ({visiblePropertiesList.find(p => p.identifier === 'slug').value})</b>
              </div>  
              <div className="modal_title">
                Columna: <b>{actualSelect.value[actualIndex].slug}</b>
              </div>  

              </div>
            )}
            onCancel={onCancel}
            footer={(
              <Button key="back" onClick={onCancel}>
                Cerrar
              </Button>
            )}
          >
            {
              masterCollections.length > 0 ?
              (
                <div  className="side_properties__input--element side_properties__master_data_wrapper_modal">
                  <div className="side_properties__input--label">
                    Tabla de datos maestros
                  </div>
                  <ReactSelect 
                    className="side_properties__input--select"
                    styles={{
                      menuList: base => ({
                        ...base,
                        maxHeight: '150px'
                      })
                    }}
                    defaultValue={actualSelect.value[actualIndex].masterDataConfig.collection ? masterCollections.find(c => c.value = actualSelect.value[actualIndex].masterDataConfig.collection) : ''}
                    // value={masterCollections.find(c => c.value = actualSelect.masterDataConfig.collection)}
                    onChange={obj => {console.log(obj); onSelectMasterCollectionTable(obj.value)}}
                    options={masterCollections}
                    placeholder='Buscar...'
                  />
                  <>
                    <div className="side_properties__input--label">
                      Columna a guardar
                    </div>
                    {masterColumns.length > 0 ? 
                    (<ReactSelect 
                      className="side_properties__input--select"
                      styles={{
                        menuList: base => ({
                          ...base,
                          maxHeight: '150px'
                        })
                      }}
                      defaultValue={actualSelect.value[actualIndex].masterDataConfig.value ? masterColumns[masterColumns.findIndex(m => m.value === actualSelect.value[actualIndex].masterDataConfig.value )] : ''}
                      onChange={obj => onSelectMasterValueTable(obj.value)}
                      options={masterColumns}
                      placeholder='Buscar...'
                    />): 'Seleccione una tabla'}
                    <div className="side_properties__input--label">
                      Columna a mostrar
                    </div>
                    {masterColumns.length > 0 ? 
                    (<ReactSelect 
                      className="side_properties__input--select"
                      styles={{
                        menuList: base => ({
                          ...base,
                          maxHeight: '150px'
                        })
                      }}
                      defaultValue={actualSelect.value[actualIndex].masterDataConfig.label ? masterColumns[masterColumns.findIndex(m => m.value === actualSelect.value[actualIndex].masterDataConfig.label )] : ''}
                      onChange={obj => onSelectMasterLabelTable(obj.value)}
                      options={masterColumns}
                      placeholder='Buscar...'
                    />): 'Seleccione una tabla'}
                    <div className="side_properties__input--label">
                      Campos de destino
                      <a className="side_properties__add_option_btn" onClick={() => addMasterFieldTable(actualSelect)} href='javascript:void(0);'><PlusCircleOutlined /></a>
                    </div>
                    <div className="side_properties__columns_wrapper">
                      {actualSelect.value[actualIndex].masterDataConfig ? actualSelect.value[actualIndex].masterDataConfig.fields.map((e, i) => {
                        return (
                          <div key={i} className="side_properties__input_table--element">
                            <input placeholder="Objetivo" type="text" name="key" value={e.slug} onChange={(ev) => updateMasterFieldSlugTable(i, ev)}/>
                            <Select 
                              className="side_properties__input--table_select" 
                              placeholder="Seleccione una opción"
                              onChange={(ev) => updateMasterFieldColumnTable(i,ev)}
                              value={e.column}
                            >
                              {masterColumns.map((e, i) => {
                                return (
                                  <Select.Option key={`opt-${i}`} value={e.value}>
                                    {e.label}
                                  </Select.Option>
                                )
                              })}
                              
                            </Select>
                            <a className="side_properties__input--delete" onClick={() => deleteMasterFieldTable(i)} href='javascript:void(0);'><DeleteOutlined /></a>

                          </div>
                        )
                      }) : 'WOOOT'}
                    </div>
                  </>
                  
                  
                </div>
              ) : <LoadingOutlined />

            }
          </Modal>
      ) : ''
    )
  }
  // Master-Data Table Methods END //

  const tableFieldPropertiesModal = () => {
    return (
      tableFieldPropertiesModalVisible ?(
        <Modal
            className="table_field_modal"
            visible={tableFieldPropertiesModalVisible}
            title= {(
              <div>
              <div className="modal_title">
                Tabla: <b>{visiblePropertiesList.find(p => p.identifier === 'name').value} ({visiblePropertiesList.find(p => p.identifier === 'slug').value})</b>
              </div>  
              <div className="modal_title">
                Columna: <b>{actualSelect.value[actualIndex].slug}</b> 
              </div>  

              </div>
            )}
            onCancel={() => setTableFieldPropertiesModalVisible(false)}
            footer={(
              <Button key="back" onClick={() => setTableFieldPropertiesModalVisible(false)}>
                Cerrar
              </Button>
            )}
          >
            <div  className="side_properties__input--element side_properties__master_data_wrapper_modal">
              <div className="side_properties__input--label">
                Encabezado de columna
              </div>
              <div className="side_properties__input--element">
                <input placeholder="Objetivo" type="text" name="key" value={actualSelect.value[actualIndex].name} onChange={(ev) => updateColumn(actualIndex,ev.target.value, actualSelect, 'name') }/>
              </div>
              {['text', 'textarea', 'select', 'rut'].indexOf(actualSelect.value[actualIndex].type) > -1 ? (
                <>
                  <div className="side_properties__input--label">
                    Fórmula
                  </div>
                  <div className="side_properties__input--element">
                    <input type="text" name="key" value={actualSelect.value[actualIndex].expression} onChange={(ev) => updateColumn(actualIndex,ev.target.value, actualSelect, 'expression') }/>
                  </div>
                </>
              ) : ''}
              <div className="side_properties__input">
                <Checkbox
                  className="side_properties__input--table_checkbox"
                  checked={actualSelect.value[actualIndex].readOnly}
                  onChange={ev => updateColumn(actualIndex,ev.target.checked, actualSelect, 'readOnly')}
                ></Checkbox>
                <label className="side_properties__input--label"> Solo Despliegue </label>
              </div>
              <div className="side_properties__input--label">
                Ancho (%)
              </div>
              <div className="side_properties__input--element">
                <input placeholder="%" type="text" name="key" value={actualSelect.value[actualIndex].width} onChange={(ev) => updateColumn(actualIndex,ev.target.value, actualSelect, 'width') }/>
              </div>
              {['text', 'textarea', 'rut'].indexOf(actualSelect.value[actualIndex].type) > -1 ? (
                <>
                  <div className="side_properties__input--label">
                    Alineación
                  </div>
                  <div className="side_properties__input">
                    <Radio.Group
                      className="side_properties__input_select--element"
                      value={actualSelect.value[actualIndex].align ? actualSelect.value[actualIndex].align : 'left'}
                      onChange={ev => updateColumn(actualIndex, ev.target.value, actualSelect, 'align')}
                    >
                      <Radio className="side_properties__input_select--element" value={'left'}>Izquierda</Radio>
                      <Radio className="side_properties__input_select--element" value={'middle'}>Centro</Radio>
                      <Radio className="side_properties__input_select--element" value={'right'}>Derecha</Radio>
                    </Radio.Group>
                  </div>
                </>
              ) : ''}
              {['text'].indexOf(actualSelect.value[actualIndex].type) > -1 ? (
                <>
                  <Checkbox
                    className="side_properties__input--table_checkbox"
                    checked={actualSelect.value[actualIndex].numberFormat}
                    onChange={ev => updateColumn(actualIndex, ev.target.checked, actualSelect, 'numberFormat')}
                  ></Checkbox>
                  <label className="side_properties__input--label"> Usar formato numérico </label>
                  &nbsp;
                  <label className="side_properties__input--label"> Decimales </label>
                  <InputNumber min={0} max={4} defaultValue={actualSelect.value[actualIndex].decimalPlaces} onChange={value => updateColumn(actualIndex, value, actualSelect, 'decimalPlaces')} />
                </>
              ) : ''}
              {actualSelect.value[actualIndex].type === 'master-data' ? (
                <>
                  <div className="side_properties__input--label">
                    Tabla de datos maestros
                  </div>
                  <ReactSelect 
                    className="side_properties__input--select"
                    styles={{
                      menuList: base => ({
                        ...base,
                        maxHeight: '150px'
                      })
                    }}
                    defaultValue={actualSelect.value[actualIndex].masterDataConfig.collection ? masterCollections.find(c => c.value = actualSelect.value[actualIndex].masterDataConfig.collection) : ''}
                    // value={masterCollections.find(c => c.value = actualSelect.masterDataConfig.collection)}
                    onChange={obj => {console.log(obj); onSelectMasterCollectionTable(obj.value)}}
                    options={masterCollections}
                    placeholder='Buscar...'
                  />
                  <>
                    <div className="side_properties__input--label">
                      Filtro
                    </div>
                    <div className="side_properties__input--element">
                      <input placeholder="Objetivo" type="text" name="key" value={actualSelect.value[actualIndex].masterDataConfig.filter || ''} onChange={(ev) => onChangeMasterFilterTable(ev.target.value) }/>
                      {/* <a className="side_properties__input--delete"onClick={() => deleteMasterFieldTable(i)} ><DeleteOutlined /></a> */}
                    </div>
                    <div className="side_properties__input--label">
                      Columna a guardar
                    </div>
                    {masterColumns.length > 0 ? 
                    (<ReactSelect 
                      className="side_properties__input--select"
                      styles={{
                        menuList: base => ({
                          ...base,
                          maxHeight: '150px'
                        })
                      }}
                      defaultValue={actualSelect.value[actualIndex].masterDataConfig.value ? masterColumns[masterColumns.findIndex(m => m.value === actualSelect.value[actualIndex].masterDataConfig.value )] : ''}
                      onChange={obj => onSelectMasterValueTable(obj.value)}
                      options={masterColumns}
                      placeholder='Buscar...'
                    />): 'Seleccione una tabla'}
                    <div className="side_properties__input--label">
                      Columna a mostrar
                    </div>
                    {masterColumns.length > 0 ? 
                    (<ReactSelect 
                      className="side_properties__input--select"
                      styles={{
                        menuList: base => ({
                          ...base,
                          maxHeight: '150px'
                        })
                      }}
                      defaultValue={actualSelect.value[actualIndex].masterDataConfig.label ? masterColumns[masterColumns.findIndex(m => m.value === actualSelect.value[actualIndex].masterDataConfig.label )] : ''}
                      onChange={obj => onSelectMasterLabelTable(obj.value)}
                      options={masterColumns}
                      placeholder='Buscar...'
                    />): 'Seleccione una tabla'}
                    <div className="side_properties__input--label">
                      Campos de destino
                      <a className="side_properties__add_option_btn" onClick={() => addMasterFieldTable(actualSelect)} href='javascript:void(0);'><PlusCircleOutlined /></a>
                    </div>
                    <div className="side_properties__columns_wrapper">
                      {actualSelect.value[actualIndex].masterDataConfig ? actualSelect.value[actualIndex].masterDataConfig.fields.map((e, i) => {
                        return (
                          <div key={i} className="side_properties__input_table--element">
                            <input placeholder="Objetivo" type="text" name="key" value={e.slug} onChange={(ev) => updateMasterFieldSlugTable(i, ev)}/>
                            <Select 
                              className="side_properties__input--table_select" 
                              placeholder="Seleccione una opción"
                              onChange={(ev) => updateMasterFieldColumnTable(i,ev)}
                              value={e.column}
                            >
                              {masterColumns.map((e, i) => {
                                return (
                                  <Select.Option key={`opt-${i}`} value={e.value}>
                                    {e.label}
                                  </Select.Option>
                                )
                              })}
                              
                            </Select>
                            <a className="side_properties__input--delete" onClick={() => deleteMasterFieldTable(i)} href='javascript:void(0);'><DeleteOutlined /></a>

                          </div>
                        )
                      }) : 'WOOOT'}
                    </div>
                  </>
                </>
              ): actualSelect.value[actualIndex].type === 'select' ? (
                <div className="side_properties__input">
                  <div className="side_properties__input--label">
                    Opciones
                    <a className="side_properties__add_option_btn" onClick={() => addTableOption(actualSelect, actualIndex)} href='javascript:void(0);'><PlusCircleOutlined /></a>
                  </div>
                  <div className="side_properties__columns_wrapper_modal">
                    {actualSelect.value[actualIndex].options ? actualSelect.value[actualIndex].options.map((element, i) => {
                      return (
                        
                        <div key={i} className="side_properties__input_select--element">
                          <input placeholder="Valor" type="text" name="key" value={element.key} onChange={event => updateTableOption(actualIndex,i,event,element,actualSelect)}/>
                          <input placeholder="Etiqueta" type="text" name="value"  value={element.value} onChange={event => updateTableOption(actualIndex,i,event,element,actualSelect)}/>
                          <a className="side_properties__input--delete" onClick={() => deleteTableOption(actualSelect, actualIndex, i)} href='javascript:void(0);'><DeleteOutlined /></a>
                        </div>
                      )
                    }) : ''}
                  </div>
                </div>
              ): ''}
            </div>
          </Modal>
      ) : ''
    )
  }

  const openTableFieldPropertiesModal = (e, i) => {
    if (e.value[i].type === 'master-data') {
      if (!e.value[i].masterDataConfig) {
        e.value[i].masterDataConfig = {
          collection: '',
          value: '',
          label: '',
          filter: '',
          fields :[]
        }
      }
      setMasterCollections([])
      setMasterColumns([])
      getMasterCollections()
      .then(resp => {
        setMasterCollections(resp.data.payload) 
        if (e.value[i].masterDataConfig.collection !== '') {
          getMasterFields(e.value[i].masterDataConfig.collection)
          .then(fieldResp => {
            setMasterColumns(fieldResp.data.payload.map(m => ({value: m, label: m})))
          })
          .catch(err => {
            console.log(err);
            setMasterColumns([])
          })
        }
        setPropertiesVisibleD(currentForm.filter(el => el.elem_id === e.elem_id)[0])
      })
      .catch(err => {
        console.log(err);
        setMasterCollections([])
      })
    }
    setActualSelect(e)
    setActualIndex(i)
    setTableFieldPropertiesModalVisible(true)
  }
  
  const updateOption = (index, e, elem)  => {
    let copyOptions = [...elem.value]
    copyOptions[index][e.target.name] = e.target.value
    formChangeValueD(
      elem.elem_id, 
      copyOptions, 
      'options'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === elem.elem_id)[0])
  }
  
  const deleteOption = (index, elem)  => {
    let copyOptions = [...elem.value]
    copyOptions.splice(index, 1)
    formChangeValueD(
      elem.elem_id, 
      copyOptions, 
      'options'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === elem.elem_id)[0])
  }

  const addOption = (elem)  => {
    let copyOptions = [...elem.value, {key: '', value:''}]
    formChangeValueD(
      elem.elem_id, 
      copyOptions, 
      'options'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === elem.elem_id)[0])
  }

  const addColumn = (elem)  => {
    let copyColumns = [...elem.value,
      {
        name: `Columna ${elem.value.length + 1}`,
        type: 'text',
        mandatory: false,
        readOnly: false,
        options: [],
        fileUrl: '',
        width: 0,
        slug: `columna-${elem.value.length + 1}`
      }]
    formChangeValueD(
      elem.elem_id, 
      copyColumns, 
      'columns'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === elem.elem_id)[0])
  }
  
  const updateColumn = (index, value, elem, property)  => {
    console.log(index, value, elem, property)
    let copyColumns = [...elem.value]
    copyColumns[index][property] = value
    formChangeValueD(
      elem.elem_id, 
      copyColumns, 
      'columns'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === elem.elem_id)[0])
  }
  
  const updateColumnType = (index, e, elem)  => {
    let copyColumns = [...elem.value]
    copyColumns[index].type = e
    copyColumns[index].options = []
    if (e === 'select') {
      copyColumns[index].options = [
          {
            key:'',
            value: ''
          },
          {
            key:'',
            value: ''
          }
      ]
    }
    formChangeValueD(
      elem.elem_id, 
      copyColumns, 
      'columns'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === elem.elem_id)[0])
  } 
  
  const deleteColumn = (index, elem)  => {
    let copyColumns = [...elem.value]
    copyColumns.splice(index, 1)
    formChangeValueD(
      elem.elem_id, 
      copyColumns, 
      'columns'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === elem.elem_id)[0])
  }
  
  const updateTableOption = (columnIndex,optionIndex, event, elem, columns)  => {
    let copyColumns = [...columns.value]
    copyColumns[columnIndex].options[optionIndex][event.target.name] = event.target.value
    formChangeValueD(
      columns.elem_id, 
      copyColumns, 
      'columns'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === columns.elem_id)[0])
    columns.value = copyColumns
    setActualSelect(columns)
  }

  const addTableOption = (columns, columnIndex)  => {
    let copyColumns = [...columns.value]
    copyColumns[columnIndex].options.push({key: '', value:''})

    formChangeValueD(
      columns.elem_id, 
      copyColumns, 
      'columns'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === columns.elem_id)[0])
    columns.value = copyColumns
    setActualSelect(columns)
  }

  const deleteTableOption = (columns, columnIndex, optionIndex)  => {
    let copyColumns = [...columns.value]
    copyColumns[columnIndex].options.splice(optionIndex, 1)
    formChangeValueD(
      columns.elem_id, 
      copyColumns, 
      'columns'
    )
    setPropertiesVisibleD(currentForm.filter(el => el.elem_id === columns.elem_id)[0])
    columns.value = copyColumns
    setActualSelect(columns)
  }

  const tableSelectModal = () => {
    return (
      visible ?(
        <Modal
            className="table_modal"
            visible={visible}
            title= {(
              <div>
              <div className="modal_title">
                Tabla: <b>{visiblePropertiesList.find(p => p.identifier === 'name').value} ({visiblePropertiesList.find(p => p.identifier === 'slug').value})</b>
              </div>  
              <div className="modal_title">
                Columna: <b>{actualSelect.value[actualIndex].slug}</b>
              </div>  

              </div>
            )}
            // onOk={setVisible(false)}
            onCancel={onCancel}
            footer={(
              <Button key="back" onClick={onCancel}>
                Cerrar
              </Button>
            )}
          >
            <div className="side_properties__input">
              <div className="side_properties__input--label">
                Opciones
                <a className="side_properties__add_option_btn" onClick={() => addTableOption(actualSelect, actualIndex)} href='javascript:void(0);'><PlusCircleOutlined /></a>
              </div>
              <div className="side_properties__columns_wrapper_modal">
                {actualSelect.value[actualIndex].options ? actualSelect.value[actualIndex].options.map((element, i) => {
                  return (
                    
                    <div key={i} className="side_properties__input_select--element">
                      <input placeholder="Valor" type="text" name="key" value={element.key} onChange={event => updateTableOption(actualIndex,i,event,element,actualSelect)}/>
                      <input placeholder="Etiqueta" type="text" name="value"  value={element.value} onChange={event => updateTableOption(actualIndex,i,event,element,actualSelect)}/>
                      <a className="side_properties__input--delete" onClick={() => deleteTableOption(actualSelect, actualIndex, i)} href='javascript:void(0);'><DeleteOutlined /></a>
                    </div>
                  )
                }) : ''}
              </div>
            </div>
          </Modal>
      ) : ''
    )
  }

  const printInput = (inputData) => {
    switch(inputData.type) {
      case 'text': {
        return (
          <div className="side_properties__input side--text">
            <div className="side_properties__input--label">
              {inputData.label}
            </div>
            <div className="side_properties__input--element">
              <input
                className={inputData.isMarked ? 'wooop' : 'nopneees'} 
                type="text" 
                onChange={(e) => dispatch(changeFormMetadata(inputData.name, e.target.value))} 
                placeholder={inputData.placeholder}
                value={inputData.value || ''} 
              />
            </div>
          </div>
        )
      }
      case 'rut': {
        return (
          <div className="side_properties__input side--rut">
            <div className="side_properties__input--label">
              {inputData.label}
            </div>
            <div className="side_properties__input--element">
              <input 
                type="text" 
                onChange={(e) => dispatch(changeFormMetadata(inputData.name, e.target.value))} 
                placeholder={inputData.placeholder}
                value={inputData.value} 
              />
            </div>
          </div>
        )
      }
      case 'textarea': {
        return (
          <div className="side_properties__input side--text">
            {inputData.tooltip && inputData.tooltip !== ''? (
              <Tooltip placement="topLeft" title={inputData.tooltip}>
                <div className="side_properties__input--label">
                  {inputData.label}
                </div>
              </Tooltip>
            ): (
              <div className="side_properties__input--label">
                {inputData.label}
              </div>
            )}
            
            <div className="side_properties__input--element">
              <textarea onChange={(e) => dispatch(changeFormMetadata(inputData.name, e.target.value))} 
                placeholder={inputData.placeholder}
                value={inputData.value}
              >
              </textarea>
            </div>
          </div>
        )
      }
      case 'select': {
        return (
          <div className="side_properties__input side--text">
            <div className="side_properties__input--label">
              {inputData.label}
            </div>
            <div className="side_properties__input--element">
              <Select 
                className="side_properties__input--select" 
                placeholder="Seleccione una opción"
                onChange={value => {dispatch(changeFormMetadata(inputData.name, value))}} 
                value={inputData.value}
              >
                {inputData.options.map((e, i) => {
                  return (
                    <Select.Option key={`opt-${i}`} value={e.key}>
                      {e.value}
                    </Select.Option>
                  )
                })}
                
              </Select>
            </div>
            
          </div>
        )
      }
      case 'validated': {
        return (
          <div className="side_properties__input side--rut">
            <div className="side_properties__input--label">
              {inputData.label}
            </div>
            <div className="side_properties__input--element">
              <input 
                type="text" 
                onChange={(e) => dispatch(changeFormMetadata(inputData.name, e.target.value))} 
                placeholder={inputData.placeholder}
                value={inputData.value} 
              />
            </div>
          </div>
        )
      }
      case 'column_selector': {
        return (
          <div className="side_properties__input side--rut">
            <div className="side_properties__input--label">
              {inputData.label}
            </div>
            <div className="side_properties__input--element">
              <input 
                type="text" 
                onChange={(e) => dispatch(changeFormMetadata(inputData.name, e.target.value))} 
                placeholder={inputData.placeholder}
                value={inputData.value} 
              />
            </div>
          </div>
        )
      }
      case 'hooks': {
        return (
          <div className="side_properties__input side--text">
            <div className="side_properties__input--label">
              {inputData.label}
            </div>
            {inputData.value && inputData.value.length > 0 ?
              (
                <>
                  <div className="side_properties__input--label">
                    Selecciona la acción
                  </div>
                  <div className="side_properties__input--element">
                    <Select 
                      className="side_properties__input--select" 
                      placeholder="Seleccione una opción"
                      onChange={value => setHooksActionSelected(value)} 
                      value={hooksActionSelected}
                    >
                      {inputData.value.map((e, i) => {
                        return (
                          <Select.Option key={`opt-${i}`} value={i}>
                            {e.label}
                          </Select.Option>
                        )
                      })}
                      
                    </Select>
                  </div>
                  <div className="side_properties__input side--text">
                    <div className="side_properties__input--label">
                      Color de fondo <Tooltip title="El botón se desplegará con este color de fondo (Estilo CSS. Ejemplos: #FF0000, red)."><InfoCircleOutlined /></Tooltip> 
                    </div>
                    <div className="side_properties__input--element">
                      <input
                        // className={inputData.isMarked ? 'wooop' : 'nopneees'} 
                        type="text" 
                        onChange={(e) => changeActionMetadata(hooksActionSelected, inputData.value, 'backgroundColor', e.target.value)} 
                        value={inputData.value[hooksActionSelected].backgroundColor} 
                      />
                    </div>
                  </div>
                  <div className="side_properties__input side--text">
                    <div className="side_properties__input--label">
                      Color de texto <Tooltip title="El botón se desplegará con este color de texto (Estilo CSS. Ejemplos: #FF0000, red)."><InfoCircleOutlined /></Tooltip> 
                    </div>
                    <div className="side_properties__input--element">
                      <input
                        // className={inputData.isMarked ? 'wooop' : 'nopneees'} 
                        type="text" 
                        onChange={(e) => changeActionMetadata(hooksActionSelected, inputData.value, 'textColor', e.target.value)} 
                        value={inputData.value[hooksActionSelected].textColor} 
                      />
                    </div>
                  </div>
                  <div className="side_properties__input side--text">
                    <div className="side_properties__input--label">
                      Ancho <Tooltip title="La proporción del ancho del formulario que debe ocupar el botón."><InfoCircleOutlined /></Tooltip> 
                    </div>
                    <div className="side_properties__input--element">
                      <Select 
                        className="side_properties__input--select" 
                        placeholder="Seleccione una opción"
                        onChange={value => {changeActionMetadata(hooksActionSelected, inputData.value, 'layout', value)}} 
                        value={inputData.value[hooksActionSelected].layout}
                      >
                        <Select.Option key={`opt-1`} value='half-width'>
                          Mitad
                        </Select.Option>
                        <Select.Option key={`opt-2`} value='full-width'>
                          Completo
                        </Select.Option>
                        
                      </Select>
                    </div>
                    
                  </div>
                  <div className="side_properties__input side--text">
                    <div className="side_properties__input--label">
                      Visible <Tooltip title="El botón solo se desplegará si la expresión ingresada retorna verdadero (true)."><InfoCircleOutlined /></Tooltip> 
                    </div>
                    <div className="side_properties__input--element">
                      <input
                        // className={inputData.isMarked ? 'wooop' : 'nopneees'} 
                        type="text" 
                        onChange={(e) => changeActionMetadata(hooksActionSelected, inputData.value, 'visible', e.target.value)} 
                        value={inputData.value[hooksActionSelected].visible} 
                      />
                    </div>
                  </div>
                  <div className="side_properties__input">
                    <Checkbox
                      checked={inputData.value[hooksActionSelected].skipValidation}
                      onChange={evt => {
                        changeActionMetadata(hooksActionSelected, inputData.value, 'skipValidation', evt.target.checked)
                      }} 
                    />
                    <label className="side_properties__input--label"> Saltarse validación del formulario <Tooltip title="Cuando el usuario presione el botón, el formulario no será validado."><InfoCircleOutlined /></Tooltip> </label>
                  </div>
                  <div className="side_properties__input">
                    <Checkbox
                      checked={inputData.value[hooksActionSelected].confirm}
                      onChange={evt => {
                        changeActionMetadata(hooksActionSelected, inputData.value, 'confirm', evt.target.checked)
                      }} 
                    />
                    <label className="side_properties__input--label"> Solicitar confirmación <Tooltip title="Cuando el usuario presione el botón, el usuario deberá confirmar para ejecutar la acción."><InfoCircleOutlined /></Tooltip> </label>
                  </div>
                  <div className="side_properties__input side--text">
                    <div className="side_properties__input--label">
                      Hooks
                      <a className="side_properties__add_option_btn" onClick={() => {addHook(hooksActionSelected, inputData.value)}} href='javascript:void(0);'><PlusCircleOutlined /></a>
                    </div>
                      {inputData.value[hooksActionSelected].hooks.map((h, i) => {
                        return (
                          <div key={i} className="side_properties__input_hook--element">
                            <input
                              style={{width: '100%'}}
                              className={inputData.isMarked ? 'wooop' : 'nopneees'} 
                              type="text" 
                              onChange={(e) => changeHook(hooksActionSelected, inputData.value, i, e.target.value)} 
                              value={h} />
                            <a className="side_properties__input--delete" onClick={() => {deleteHook(hooksActionSelected, inputData.value, i)}} href='javascript:void(0);'><DeleteOutlined /></a>
                          </div>
                        )
                      })}
                  </div>
                  <div className="side_properties__input side--text">
                    <div className="side_properties__input--label">
                      Extensiones
                    </div>
                    <div className="add-hook-container">
                      {inputData.value[hooksActionSelected].extensions && inputData.value[hooksActionSelected].extensions.map((h, i) => {
                        return (
                          <AddHookBox 
                            key={i}
                            hook={h}
                            hookId={h.hookId}
                            hookIndex={i}
                            onDelete={deleteExtension}
                            onEdit={editExtension}
                          />
                        )
                      })}
                      <div className="add-hook-box" style={{cursor: 'pointer'}} onClick={openMyHooksModal}>
                        <div className="add-hook-box-content">
                          <p className="add-hook-box-title-text"><b>agregar</b></p>
                          <div className="add-hook-box-logo add-hook-box-icon">
                            <PlusCircleOutlined />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </>
              ) :
              (
                <div className="side_properties__input--label">
                    El formulario no posee acciones
                </div>
              )
            }
          </div>
        )
      }
      default: {
        console.log(`Uknown widget type: ${inputData.type}`)
        return('')
      }
    }
  }

  const setDataToShow = (element) => {
    // setPropertiesVisibleD(currentForm.filter(el => el.elem_id === element.elem_id)[0])
    switch(element.display_type) {
      case 'text': {
        return (
          <div className="side_properties__input side--text">
            <div className="side_properties__input--label">
              {element.title} 
              {element.identifier === 'regEx' ? (
                <a href="https://regex101.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'black', paddingLeft: '6px'}} >
                  <QuestionCircleFilled />
                </a>
              )
              : ''}
            </div>
            <div className="side_properties__input--element">
              <input
                className={element.isMarked ? 'wooop' : 'nopneees'} 
                type="text" 
                onChange={evt => {
                  formChangeValueD(element.elem_id, evt.target.value, element.identifier)
                  setPropertiesVisibleD(currentForm.filter(el => el.elem_id === element.elem_id)[0])
                }} 
                placeholder={element.placeholder}
                value={element.value} 
                disabled={element.disabled}
              />
            </div>
          </div>
        )
      }
      case 'checkbox': {
        return (
          <div className="side_properties__input">
            <Checkbox 
              checked={element.value}
              disabled={element.disabled}
              onChange={evt => {
                formChangeValueD(element.elem_id, evt.target.checked, element.identifier)
                setPropertiesVisibleD(currentForm.filter(el => el.elem_id === element.elem_id)[0])
              }} 
            />
            <label className="side_properties__input--label"> {element.title} </label>
          </div>
        )
      }
      case 'textarea': {
        return (
          <div className="side_properties__input side--text">
            <div className="side_properties__input--label">
              {element.title}
              {element.title === 'Contenido' ? (
                <a href="https://www.blizwork.com/docs/guia-para-el-disenador-de-procesos/formularios/elementos-widgets#formatted-text" target="_blank" rel="noopener noreferrer" style={{ color: 'black', paddingLeft: '6px'}} >

                  <QuestionCircleFilled />
                </a>
              //   <Link to="" style={{color: 'white'}}>
              // </Link>
              )
              : ''}
            </div>
            <div className="side_properties__input--element">
              <textarea 
                value={element.value}
                disabled={element.disabled}
                onChange={evt => {
                  formChangeValueD(element.elem_id, evt.target.value, element.identifier)
                  setPropertiesVisibleD(currentForm.filter(el => el.elem_id === element.elem_id)[0])
                }} 
                placeholder={element.placeholder}
              >
                
              </textarea>
            </div>
          </div>
        )
      }
      case 'onlyText': {
        return (
          <p className="side_properties__onlytext">
            {element.title}
          </p>
        )
      }
      case 'values_selector': {
        return (
          <div className="side_properties__input">
            <div className="side_properties__input--label">
              {element.title}
              <a className="side_properties__add_option_btn" onClick={() => addOption(element)} href='javascript:void(0);'><PlusCircleOutlined /></a>
            </div>
            <div className="side_properties__columns_wrapper">
              {element.value ? element.value.map((e, i) => {
                return (
                  
                  <div key={i} className="side_properties__input_select--element">
                    <input placeholder="Valor" type="text" name="key" value={e.key} onChange={e => updateOption(i,e, element)}/>
                    <input placeholder="Etiqueta" type="text" name="value"  value={e.value} onChange={e => updateOption(i,e, element)}/>
                    <a className="side_properties__input--delete" onClick={() => deleteOption(i,element)} href='javascript:void(0);'><DeleteOutlined /></a>
                  </div>
                )
              }) : ''}
            </div>
          </div>
        )
      }
      case 'column_selector': {
        return (
          <div className="side_properties__input">
            <div className="side_properties__input--label">
              {element.title}
              <a className="side_properties__add_option_btn"onClick={() => addColumn(element)} href='javascript:void(0);'><PlusCircleOutlined /></a>
            </div>
            <div className="side_properties__columns_wrapper">
            <div className="side_properties__input_table--element">
              <label>ID único</label>
              <label style={{marginLeft:'82px'}}>Tipo</label>
              <label style={{marginLeft:'101px'}}>Props.</label>
              <label>Eliminar</label>
            </div>
            {/* <div className="side_properties__input_table--element"><label>Tipo</label></div>
            <div className="side_properties__input_table--element"><label>Props.</label></div>
            <div className="side_properties__input_table--element"><label>Eliminar</label></div> */}
              {element.value ? element.value.map((e, i) => {
                return (
                  <div key={i} className="side_properties__input_table">
                    {/* <input placeholder="Valors" type="text" name="key" value={e.name} onChange={(e) => updateColumn(i,e, element)}/> */}
                    <div className="side_properties__input_table--element">
                      <input placeholder="Valor" type="text" name="key" value={e.slug} onChange={(e) => updateColumn(i,e.target.value, element, 'slug')}/>
                    </div>
                    <div className="side_properties__input_table--element">
                      <Select
                        className="side_properties__input--table_select" 
                        placeholder="Seleccione una opción"
                        onChange={(e) => updateColumnType(i,e, element)}
                        value={e.type}
                      >
                        {tableWidgets.map((e, i) => {
                          return (
                            <Select.Option key={`opt-${i}`} value={e.type}>
                              {e.title}
                            </Select.Option>
                          )
                        })}
                        
                      </Select>
                    </div>
                    
                    <div className="side_properties__input_table--element">
                      <a className="side_properties__input--delete" onClick={() => openTableFieldPropertiesModal(element, i)} href='javascript:void(0);'><EditOutlined /></a>
                    </div>
                    <div className="side_properties__input_table--element">
                      <a className="side_properties__input--delete" onClick={() => deleteColumn(i,element)} href='javascript:void(0);'><DeleteOutlined /></a>
                    </div>
                    {/* <Tooltip title="Solo despliegue">
                      <Checkbox
                        className="side_properties__input--table_checkbox"
                        checked={e.readOnly}
                        onChange={ev => updateColumnReadOnly(i,ev, element)}
                      >S/D</Checkbox>
                    </Tooltip> */}
                    {/* <a className="side_properties__input--delete"onClick={() => deleteColumn(i,element)} ><DeleteOutlined /></a> */}
                    {/* {
                      e.type === 'select' ? (
                        <a className="side_properties__input--delete"onClick={() => openTableSelectModal(element, i)} ><EditOutlined /></a>
                      ) : e.type === 'master-data' ? (
                        <a className="side_properties__input--delete"onClick={() => openTableMasterDataModal(element, i)} ><EditOutlined /></a>
                      ) : ''
                    } */}
                    
                   
                  </div>
                )
              }) : 'WOOOT'}
            </div>
          </div>
        )
      }
      case 'selector-master-collection': {
        return (
          <div className="side_properties__input">
            <div className="side_properties__input--label">
              <b>{element.title}</b>
            </div>
            {
              masterCollections.length > 0 ?
              (
                <div  className="side_properties__input--element">
                  <div className="side_properties__input--label">
                    <Checkbox
                      className='side_properties__input--table_checkbox'
                      checked={element.value.allowNewValues}
                      onChange={evt => onMasterAllowNewValues(evt.target, element)}>
                        Permitir agregar nuevos elementos
                    </Checkbox>
                  </div>
                  <div className="side_properties__input--label">
                    Tabla de datos maestros
                  </div>
                  <ReactSelect 
                    className="side_properties__input--select"
                    styles={{
                      menuList: base => ({
                        ...base,
                        maxHeight: '150px'
                      })
                    }}
                    defaultValue={element.value.collection ? masterCollections.find(c => c.value = element.value.collection) : ''}
                    // value={masterCollections.find(c => c.value = element.value.collection)}
                    onChange={obj => {console.log(obj); onSelectMasterCollection(obj.value, element)}}
                    options={masterCollections}
                    placeholder='Buscar...'
                  />
                  <>
                    <div className="side_properties__input--label">
                      Filtro
                    </div>
                    <div className="side_properties__input--element">
                      <input
                        className={element.isMarked ? 'wooop' : 'nopneees'} 
                        type="text" 
                        onChange={evt => {
                          // formChangeValueD(element.elem_id, evt.target.value, element.identifier)
                          // setPropertiesVisibleD(currentForm.filter(el => el.elem_id === element.elem_id)[0])
                          onChangeMasterFilter(evt.target.value, element)
                        }} 
                        placeholder={element.placeholder}
                        value= { element.value.filter || '' }
                      />
                    </div>
                    <div className="side_properties__input--label">
                      Columna a guardar
                    </div>
                    {masterColumns.length > 0 ? 
                    (<ReactSelect 
                      className="side_properties__input--select"
                      styles={{
                        menuList: base => ({
                          ...base,
                          maxHeight: '150px'
                        })
                      }}
                      defaultValue={element.value.value ? masterColumns[masterColumns.findIndex(m => m.value ===element.value.value )] : ''}
                      onChange={obj => onSelectMasterValue(obj.value, element)}
                      options={masterColumns}
                      placeholder='Buscar...'
                    />): 'Seleccione una tabla'}
                    <div className="side_properties__input--label">
                      Columna a mostrar
                    </div>
                    {masterColumns.length > 0 ? 
                    (<ReactSelect 
                      className="side_properties__input--select"
                      styles={{
                        menuList: base => ({
                          ...base,
                          maxHeight: '150px'
                        })
                      }}
                      defaultValue={element.value.label ? masterColumns[masterColumns.findIndex(m => m.value ===element.value.label )] : ''}
                      onChange={obj => onSelectMasterLabel(obj.value, element)}
                      options={masterColumns}
                      placeholder='Buscar...'
                    />): 'Seleccione una tabla'}
                    <div className="side_properties__input--label">
                      Campos de destino
                      <a className="side_properties__add_option_btn" onClick={() => addMasterField(element)} href='javascript:void(0);'><PlusCircleOutlined /></a>
                    </div>
                    <div className="side_properties__columns_wrapper">
                      {element.value ? element.value.fields.map((e, i) => {
                        return (
                          <div key={i} className="side_properties__input_table--element">
                            <input placeholder="Objetivo" type="text" name="key" value={e.slug} onChange={(ev) => updateMasterFieldSlug(i, ev, element)}/>
                            <Select 
                              className="side_properties__input--table_select" 
                              placeholder="Seleccione una opción"
                              onChange={(e) => updateMasterFieldColumn(i,e, element)}
                              value={e.column}
                            >
                              {masterColumns.map((e, i) => {
                                return (
                                  <Select.Option key={`opt-${i}`} value={e.value}>
                                    {e.label}
                                  </Select.Option>
                                )
                              })}
                            </Select>
                            <a className="side_properties__input--delete" onClick={() => deleteMasterField(i,element)} href='javascript:void(0);'><DeleteOutlined /></a>
                          </div>
                        )
                      }) : 'WOOOT'}
                    </div>
                  </>
                  
                  
                </div>
              ) : <LoadingOutlined />

            }
            
          </div>
        )
      }
      default: {
        return (
          <div>MOSTRAMOS EL DEFAULT </div>
        )
      }
    }
  }

  if (JSON.stringify(initialFormData) === JSON.stringify(currentForm) 
  && JSON.stringify(initialFormMetaData) === JSON.stringify(formMetadata)) {
    dispatch(setFormModified(false))
  } else {
    dispatch(setFormModified(true))
  }
  return (
    <div className="side_properties__wrapper">
      <section className="side_properties">
        <div className="side_properties_title">
          Propiedades
        </div>
        {
          visiblePropertiesList.length === 0 ? (
            <div>
              {
                formMetadata.map((elem, index) => {
                  if (elem.name !== 'roleAssignment') {
                    return (
                      <div key={index}>
                        {printInput(elem)}
                      </div> 
                    )
                  }
                  return ''
                })
              }
            </div>
          ) : visiblePropertiesList.map((elem, index) => {
            // console.log(visiblePropertiesList)
            const data = setDataToShow(elem)
            return (
              <div key={index}>
                {data}
              </div>
            )
          })
        }
      </section>
      {tableSelectModal()}
      {tableMasterDataModal()}
      {tableFieldPropertiesModal()}
      <MyHooksModal key={myHooksModalKey} show={myHooksModalVisible} onCancel={closeMyHooksModal} onAdd={addExtension}/>
    </div>
  )
}

export default SideProperties