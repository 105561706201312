import React from 'react'
// Styles
import 'antd/dist/antd.css'
import './components/_globals/_reset.scss'
import { iconLibrary } from './utils/Icons'
// Routing
import { HashRouter } from 'react-router-dom'
import Routes from './config/routes/routes'
// Redux implementation
import { createStore, compose, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './reducers'
// Redux Middlewares
import thunk from 'redux-thunk'

import { ToastContainer } from 'react-toastify'

// Enable the redux dev tools extension
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// Create redux store
const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)))

// Disable console log in production
if (process.env.REACT_APP_AB.trim() === 'production') {
  console.log = function () {}
}

iconLibrary()

const App = () => {
  return (
    <Provider store={store}>
      <HashRouter>
        <Routes />
      </HashRouter>
      <ToastContainer />
    </Provider>
  )
}

export default App
