import React, { useState, Component, useEffect } from 'react'
import { setGlobalLoader } from '../../../actions/actions'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector, connect } from 'react-redux'
import cf from '../../../config/APIConfig'
import { Select } from 'antd'
import { FilePdfOutlined, EyeOutlined, FileExcelOutlined } from '@ant-design/icons'
import './MasterDataReport.scss'

const { Option } = Select;

function MDPDFContainer (props) {
    const companyId = useSelector(state => state.user.companyId)

    const [recipe, setRecipe] = useState('html')

    const recipeChange = (value) => {
        setRecipe(value)
    }

    return (
        <div className='mdpdf-container'>
            <span style={{ float: 'right' }}>
                Modo: <Select
                    defaultValue='html'
                    style={{ width: '134px' }}
                    onChange={recipeChange}
                >
                    <Option value='html'><EyeOutlined></EyeOutlined> Web</Option>
                    <Option value='chrome-pdf'><FilePdfOutlined></FilePdfOutlined> PDF</Option>
                    <Option value='html-to-xlsx'><FileExcelOutlined></FileExcelOutlined> MS Excel</Option>
                </Select>
            </span>
            <iframe
                src={`${cf.BASE_URL.url}master-data/report-standard/${encodeURIComponent(props.table)}/${companyId}/${recipe}`}
                height="95%"
                width="100%">
            </iframe>
        </div>
    )
}

class MasterDataReport extends Component {

    constructor(props) {
        super(props)
    }

    async componentDidMount () {
        console.log('Loading report.')
        await this.props.setGlobalLoader(false)
    }

    render () {
        const table = this.props.match.params.table
        return (
            <>
                <Helmet>
                    <title>{table} - Datos Maestros - BlizWork </title>
                </Helmet>
                <div className='mdtable-header'>
                    <span className='mdtable-backarrow' onClick={(e) => { this.props.history.push('/masterdata') }}>
                        <FontAwesomeIcon icon="arrow-left" />
                    </span>
                    Tabla: <b>{table}</b>
                </div>
                <MDPDFContainer
                    table={table}>
                </MDPDFContainer>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        userId: state.user.userId,
        companyId: state.user.companyId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setGlobalLoader: (newStatus) => dispatch(setGlobalLoader(newStatus))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterDataReport)
