import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Modal, Row, Col, Collapse, Tabs, Table, Badge, Input, Button, Form, Image,Checkbox, AutoComplete, Radio } from 'antd';
import { Link } from 'react-router-dom'
import './Register.scss'
// import axios from 'axios'
// import cf from '../../../config/APIConfig'
import axiosBW from '../../../utils/Http';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setGlobalLoader } from '../../../actions/actions'
import { Helmet } from "react-helmet";
import ErrorList from 'antd/lib/form/ErrorList';

const Register = (props) => {
  const dispatch = useDispatch();
  console.log({props})
  const isMobile = useSelector(state => state.navigation.isMobile)
  const [visible, setVisible] = useState(false)
  const [companies, setCompanies] = useState([])
  const [companyType, setCompanyType] = useState(1)
  const [step, setStep] = useState(1)
  const [stepMsg, setStepMsg] = useState('')
  const [loginLink, setLoginLink] = useState('/ingresar')
  const [errorMsg, setErrorMsg] = useState(null)
  const [form] = Form.useForm();
  const query = new URLSearchParams(props.location.search)
  console.log(query.get('hola'))

  const onFinish = async (values) => {
    console.log('Received values of form: ', values);
    dispatch(setGlobalLoader(true))
    setErrorMsg(null)
    try {
      const resp = await axiosBW.post(`/auth/register/`,
        {
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          companyName: values.companyName || '',
          companyType: values.companyType,
          campaign: query.get('campaign'),
          firstRedirect: query.get('firstRedirect')
        }
      );
      dispatch(setGlobalLoader(false))
      console.log(resp.data)
      const data = resp.data.payload
      const msgReg = 'En unos minutos recibirás un correo para confirmar tu cuenta.\nUna vez realizado podrás ingresar a Blizwork'
      const msgSol = 'Tu solicitud ha sido enviada con exito.\nUna vez la empresa acepte tu solicitud recibirás un correo para confirmar tu cuenta e ingresar a Blizwork'
      setStepMsg(values.companyType === 1 && companies.indexOf(values.companyName.trim()) !== -1 ? msgSol : msgReg)
      setStep(2)
    } catch (err) {
      localStorage.clear()
      dispatch(setGlobalLoader(false))
      if (err.response && err.response.data && err.response.data.message) {
        
        const respMsg = err.response.data.message
        setErrorMsg(respMsg)
      } else {
        setErrorMsg('Ha ocurrido un error con el registro')
      }
    }
  };
  
  const showModal = () => {
    setVisible(true)
  };
  const cancelModal = () => {
    setVisible(false)
    form.resetFields()
  }
  const onChangeCompanyType  = (e) => {
    setCompanyType(e.target.value)
  }

  // Reemplazo de componentDidMount
  useEffect( () => {
    console.log('va el UseEffect');
    if (query.get('firstRedirect') && query.get('firstRedirect').length > 0) {
      setLoginLink(`/ingresar?redirect_to=${query.get('firstRedirect')}`)
    }
    async function getRegistrationInfo() {
      try {
        const resp = await axiosBW.get(`/auth/get-company-names`);
          console.log(resp)
          const data = resp.data.payload.map(c =>({ value: c.legalName}))
          setCompanies(data)
          dispatch(setGlobalLoader(false))
      } catch (err) {
        console.log(err) 
        if (err.response && err.response.data && err.response.data.message) {
          const respMsg = err.response.data.message
          Modal.error({
            title: 'Error',
            content: respMsg
          })
        }
        localStorage.clear()
        setTimeout(() => {
          props.history.push('/')
        }, 2000);
      }
    }
    dispatch(setGlobalLoader(true))
    getRegistrationInfo()
    
    // dispatch(getMyTeamInfo())
  }, []);
  // const dispatch = useDispatch();
  // //useSelector reemplaza el mapStateToProps
  // const users = useSelector(state => state.workflowDesigner.usersByRole);
  // const companyId = useSelector(state => state.workflowDesigner.workflow.companyId);
  
  // if (!users) {
  //   return 'No USERS'
  // }
  return (
    <div className="register_back">
      <div className="register_wrapper">
        <Row>
          <Col flex="0 1 auto">
            {
              !isMobile ? (
                <Image
                  preview={false}
                  src='\img\registro.png'
                />
              ) : ''
            }
            
          </Col>
          <Col flex="auto">
            {
              step === 1 ? (
                <div className="register_form_wrapper">
                  <div className="register_form_title">
                    Bienvenido a Blizwork
                  </div>
                  <div className="register_form_subtitle">
                    <p>Orquesta tu empresa y gestiona digitalmente sus procesos.</p>
                    <p>Trabaja en red con tus socios de negocio y clientes.</p>
                  </div>
                  <div className="register_form_title_account">
                    Crea tu cuenta y comienza con tu plan FREE
                  </div>
                  <div className="register_form_subtitle_account">
                    <p>Sin tarjeta de crédito. Cierra tu cuenta en cualquier momento.</p>
                  </div>
                    <Form
                    form={form}
                    size="large"
                    name="register"
                    onFinish={onFinish}
                    preserve={false}
                    initialValues={{
                      "companyType": 1,
                    }}
                    scrollToFirstError
                  >
                    <input type="email" style={{opacity: '0', position:'absolute'}}/>
                    <input type="password" style={{opacity: '0', position:'absolute'}}/>

                    <Form.Item name="companyType" label="" >
                      <Radio.Group onChange={onChangeCompanyType}>
                        <Radio value={1}>Soy parte de una organización o empresa</Radio>
                        <Radio value={2}>Soy un particular</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <div className={!isMobile ? 'register_form_inline' : ''}>
                      <Form.Item
                        className="register_form_field"
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: 'Nombre requerido',
                          },
                        ]}
                      >
                        <Input  placeholder="Nombre" />
                      </Form.Item>
                      <Form.Item
                        className="register_form_field"
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: 'Apellido requerido',
                          }
                        ]}
                      >
                        <Input size="large" placeholder="Apellido" />
                      </Form.Item>
                    </div>
                    <div className={!isMobile ? 'register_form_inline' : ''}>
                      <Form.Item
                        className="register_form_field"
                        name="email"
                        rules={[
                          {
                            type: 'email',
                            message: 'Email inválido',
                          },
                          {
                            required: true,
                            message: 'Email requerido',
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Ingrese el Email" />
                      </Form.Item>
                      <Form.Item
                        className="register_form_field"
                        name="confirm"
                        dependencies={['email']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Por favor confirma el Email',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('email') === value) {
                                return Promise.resolve();
                              }

                              return Promise.reject('El Email no coincide');
                            },
                          }),
                        ]}
                      >
                        <Input size="large" placeholder="Confirmar el Email" />
                      </Form.Item>
                    </div>
                    <div className={!isMobile ? 'register_form_inline' : ''}>
                      <Form.Item
                        className="register_form_field"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Email requerido',
                          },
                        ]}
                      >
                        <Input.Password placeholder="Contraseña" />
                      </Form.Item>
                      <Form.Item
                        className="register_form_field"
                        name="confirmPassword"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Por favor confirma la contraseña',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }

                              return Promise.reject('Las contraseñas no coinciden');
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder="Confirmar la contraseña" />
                      </Form.Item>
                    </div>
                    {companyType === 1 ?  (
                      <Form.Item
                        className="register_form_field"
                        name="companyName"
                        style={{ width: '100% ' }}
                        rules={[
                          {
                            required: true,
                            message: 'Empresa requerida',
                          },
                        ]}
                      >
                        {/* <Input  disabled={true} placeholder="Empresa" /> */}
                        <AutoComplete
                            options={companies}
                            filterOption={(inputValue, option) =>
                              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            placeholder="Mi organización o empresa"
                          />
                      </Form.Item>
                    ): (<div style={{width: '100%', height: '64px'}}></div>)}
                    <Form.Item
                      name="agreement"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject('Debes aceptar los términos y condiciones'),
                        },
                      ]}
                    >
                      <Checkbox>
                        Acepto los <a href="https://www.blizwork.com/terminos-y-condiciones" target="_blank">términos y condiciones</a>
                      </Checkbox>
                    </Form.Item>
                    {errorMsg ? (
                      <div className="register_error_message">
                        {errorMsg}
                      </div>
                    ):(
                      <div className="register_error_message_block"></div>
                    )}
                    <Button type="primary" htmlType="submit"
                    >
                      ¡Listo!
                    </Button>
                    <div className="register_login_message">¿Ya tienes cuenta? <Link to={loginLink}>Inicia sesión</Link> o <Link to="/recuperar_password">restablece tu contraseña</Link>.</div>
                  </Form>
                </div>
              ):(
                <div className="register_form_wrapper">
                  <div className="register_form_title">
                    ¡Muchas Gracias por Registrarse!
                  </div>
                  <div className="register_form_subtitle">
                    {stepMsg}
                  </div>
                </div>
              )
            }
            
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default Register