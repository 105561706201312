/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"

import axiosBW from '../../../../../utils/Http';
import { toast } from 'react-toastify';
import { Modal, Input, Select, Tabs, InputNumber, Space, Switch, Button, Upload, Tooltip, Radio, Checkbox } from 'antd'
import { PlusCircleOutlined, LoadingOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { setGlobalLoader } from '../../../../../actions/actions'
import './MyHooks.scss'

const { TabPane } = Tabs;

const MyHooks = () => {
  const companyId = useSelector(state => state.user.companyId)
  // console.log({languages})
  const dispatch = useDispatch();
  dispatch(setGlobalLoader(false))
  const [hooksList, setHooksList] = useState([]);
  const [hookModalVisible, setHookModalVisible] = useState(false);
  const [hookData, setHookData] = useState({
    _id: null,
    companyId,
    name: '',
    description: '',
    infoLink: '',
    url: '',
    timeout: 0,
    icon: 'https://stagefilestorage.file.core.windows.net/stagefiles/atenea/615bb22710160447dc2bb773_webhook.png?sv=2019-12-12&ss=bfqt&srt=o&sp=rx&se=2030-12-19T00:08:12Z&st=2020-12-10T16:08:12Z&spr=https&sig=SnGOf1S3tfMZkZ8LqhZlPqmJ%2Bu%2FNBpjhFli6lHaZ6io%3D',
    fee: {
      monthly: 0,
      oneTime: 0,
      perUsage: 0,
      gracePeriod: 0
    },
    urlParams: [],
    dontParseBodyParams: false,
    bodyParams: [],
    active: true,
    shared: true
  })
  const [uploadingImage, setUploadingImage] = useState(false)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    console.log('va el hooks-log');
    (async () => {
      setLoading(true)
      // await getDashboard()
      await getHooksList()
      setLoading(false)

    })()

  }, [])

  const getHooksList = async () => {
    try {
      const res = await axiosBW.get(`hooks/acquired-hooks-list/${companyId}/`)
      if (res && res.data) {

        if (res.data.status === 0) {
          setHooksList(res.data.payload)
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  const resetHookData = () => {
    setHookData({
      hookId: null,
      companyId,
      name: '',
      description: '',
      infoLink: '',
      url: '',
      timeout: 0,
      icon: 'https://stagefilestorage.file.core.windows.net/stagefiles/atenea/615bb22710160447dc2bb773_webhook.png?sv=2019-12-12&ss=bfqt&srt=o&sp=rx&se=2030-12-19T00:08:12Z&st=2020-12-10T16:08:12Z&spr=https&sig=SnGOf1S3tfMZkZ8LqhZlPqmJ%2Bu%2FNBpjhFli6lHaZ6io%3D',
      fee: {
        monthly: 0,
        oneTime: 0,
        perUsage: 0,
        gracePeriod: 0
      },
      urlParams: [],
      dontParseBodyParams: false,
      bodyParams: [],
      active: true,
      shared: true
    })
  }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onerror = reject;
      fr.onload = function () {
        resolve(fr.result);
      }
      fr.readAsDataURL(file);
    })
  }

  const beforeUpload = async (file) => {
    try {
      console.log(file)
      setUploadingImage(true)

      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        // message.error('You can only upload JPG/PNG file!');
        throw new Error('Solo puede ser un archivo JPG/PNG!')
      }
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        // message.error('Image must smaller than 2MB!');
        throw new Error('La imagen debe ser menor a 1MB')
      }
      const base64File = await getBase64(file);
      console.log(base64File)

      const uploadedImage = await axiosBW.post('/attachment/upload-file', {
        module: 'hook-icon',
        filename: file.name,
        file: base64File.split('base64')[1]
      })
      setHookData({ ...hookData, icon: uploadedImage.data.payload.downloadUrl })
      setUploadingImage(false)
      // setUserImage(uploadedImage.data.payload.downloadUrl)
    } catch (error) {
      console.log(error)
      // onChange({imagePath: ''})
      setUploadingImage(false)
      toast.error(error.message)
    }
  }

  const createHook = async () => {
    try {
      if (!hookData.name || hookData.name === '') {
        throw new Error('Campo Nombre faltante')
      }
      if (!hookData.description || hookData.description === '') {
        throw new Error('Campo Descripción faltante')
      }
      if (!hookData.url || hookData.url === '') {
        throw new Error('Campo URL faltante')
      }
      await axiosBW.post('/hooks/create-hook', {
        ...hookData
      })
      setLoading(true)
      // await getDashboard()
      await getHooksList()
      cancelModal()
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const updateHook = async () => {
    try {
      if (!hookData.name || hookData.name === '') {
        throw new Error('Campo Nombre faltante')
      }
      if (!hookData.description || hookData.description === '') {
        throw new Error('Campo Descripción faltante')
      }
      if (!hookData.url || hookData.url === '') {
        throw new Error('Campo URL faltante')
      }
      await axiosBW.put('/hooks/update-hook', {
        ...hookData
      })
      setLoading(true)
      // await getDashboard()
      await getHooksList()
      cancelModal()
      setLoading(false)


    } catch (error) {
      toast.error(error.message)
    }
  }

  const switchHook = async (hook) => {
    try {
      await axiosBW.put('/hooks/switch-hook-active/', {
        hookId: hook._id,
        active: !hook.active
      })
      setLoading(true)
      // await getDashboard()
      await getHooksList()
      cancelModal()
      setLoading(false)


    } catch (error) {
      toast.error(error.message)
    }
  }

  const deleteHook = async (hookId) => {
    try {
      await axiosBW.post('/hooks/delete-hook', {
        hookId,
        companyId
      })
      setLoading(true)
      // await getDashboard()
      await getHooksList()
      setLoading(false)


    } catch (error) {
      toast.error(error.message)
    }
  }

  const setUrlParamValue = (name, value) => {
    const urlParams = [...hookData.urlParams]
    const index = urlParams.findIndex(u => u.name === name)
    urlParams[index].value = value
    setHookData({ ...hookData, urlParams })
  }

  const toggleParseBodyParameters = (check) => {
    setHookData({...hookData, dontParseBodyParams: check})
  }

  const addBodyParam = () => {
    const bodyParams = [...hookData.bodyParams]
    bodyParams.push({
      name: '',
      type: 'text',
      value: '',
      options: []
    })
    setHookData({ ...hookData, bodyParams })
  }

  const setBodyParamValue = (index, value) => {
    const bodyParams = [...hookData.bodyParams]
    bodyParams[index].value = value
    setHookData({ ...hookData, bodyParams })
  }

  const setBodyParamName = (index, name) => {
    const bodyParams = [...hookData.bodyParams]
    bodyParams[index].name = name
    setHookData({ ...hookData, bodyParams })
  }

  const deleteBodyParam = (index) => {
    const bodyParams = [...hookData.bodyParams]
    bodyParams.splice(index, 1)
    setHookData({ ...hookData, bodyParams })
  }

  const setBodyParamType = (index, type) => {
    const bodyParams = [...hookData.bodyParams]
    bodyParams[index].type = type
    if (type === 'select') {
      bodyParams[index].options = [
        { value: '', label: '', default: false },
        { value: '', label: '', default: false }
      ]
    } else {
      bodyParams[index].options = []
    }
    setHookData({ ...hookData, bodyParams })
  }

  const deleteBodyParamOption = (index, optionIndex) => {
    const bodyParams = [...hookData.bodyParams]
    bodyParams[index].options.splice(optionIndex, 1)
    setHookData({ ...hookData, bodyParams })
  }

  const addBodyParamOption = (index) => {
    const bodyParams = [...hookData.bodyParams]
    bodyParams[index].options.push({ value: '', label: '' })
    setHookData({ ...hookData, bodyParams })
  }

  const setBodyParamOptionLabel = (index, optionIndex, label) => {
    const bodyParams = [...hookData.bodyParams]
    bodyParams[index].options[optionIndex].label = label
    setHookData({ ...hookData, bodyParams })
  }

  const setBodyParamOptionValue = (index, optionIndex, value) => {
    const bodyParams = [...hookData.bodyParams]
    bodyParams[index].options[optionIndex].value = value
    setHookData({ ...hookData, bodyParams })
  }

  const setBodyParamOptionDefault = (index, optionIndex) => {
    const bodyParams = [...hookData.bodyParams]
    for (let i = 0; i < bodyParams[index].options.length; i++) {
      const opt = bodyParams[index].options[i];
      opt.default = i === optionIndex ? true : false
    }
    // bodyParams[index].options[optionIndex].value = value
    setHookData({ ...hookData, bodyParams })
  }

  const getBodyParamValueComponent = (param, i) => {
    switch (param.type) {
      case 'text':
        return (
          <div className="param-field">
            Valor:
            <Input
              type="text"
              value={param.value}
              onChange={e => {
                // setHookData({...hookData, url: e.target.value})
                setBodyParamValue(i, e.target.value)
              }}
            />
          </div>
        )
      case 'textarea':
        return (
          <div className="param-field">
            Valor:
            <Input.TextArea
              rows={7}
              value={param.value}
              onChange={e => {
                // setHookData({...hookData, url: e.target.value})
                setBodyParamValue(i, e.target.value)
              }}
            />
          </div>
        )
      case 'select':
        return (
          <div className="">
            Opciones: <PlusCircleOutlined style={{ cursor: 'pointer' }} onClick={() => addBodyParamOption(i)} />
            {/* <div className="hook-modal-param-body-field"> */}
            <div className="hook-modal-field">
              <div className="param-field-select">Etiqueta</div>
              <div className="param-field-select">Valor</div>
              <div className="param-field-select">Def.</div>
            </div>
            {/* </div> */}
            {param.options.map((opt, j) => {
              return (
                <div key={j} className="hook-modal-param-body-field">

                  <div className="param-field">
                    <Input
                      type="text"
                      value={param.options[j].label}
                      onChange={e => {
                        // setHookData({...hookData, url: e.target.value})
                        setBodyParamOptionLabel(i, j, e.target.value)
                      }}
                    />
                  </div>
                  <div className="param-field">
                    <Input
                      type="text"
                      value={param.options[j].value}
                      onChange={e => {
                        // setHookData({...hookData, url: e.target.value})
                        setBodyParamOptionValue(i, j, e.target.value)
                      }}
                    />
                  </div>
                  <div className="select-hook-parameter">
                    <Radio onChange={() => setBodyParamOptionDefault(i, j)} checked={param.options[j].default}></Radio>
                  </div>
                  <div className="delete-hook-parameter">
                    <DeleteOutlined onClick={() => {
                      deleteBodyParamOption(i, j)
                    }} />
                  </div>
                </div>
              )
            })}
          </div>
        )

      default:
        break;
    }
  }

  const setRouteParams = async (url) => {
    // await sleep(500)
    const urlParams = [...hookData.urlParams]
    if (!url && url === '') {
      setHookData({ ...hookData, urlParams: [], url })
      return
    }
    const splitedUrl = url.split('/')
    if (splitedUrl.length === 0) {
      setHookData({ ...hookData, urlParams: [], url })
      return
    }
    const params = splitedUrl.filter(s => s.charAt(0) === ':')
    if (params.length === 0) {
      setHookData({ ...hookData, urlParams: [], url })
      return
    }
    for (let i = 0; i < params.length; i++) {
      const param = params[i];
      const theParam = param.substring(1)
      console.log(theParam)
      const exist = urlParams.find(p => p.name === theParam)
      if (!exist) {
        urlParams.push({
          name: theParam,
          value: ''
        })
      }
    }
    const auxUrlParams = [...urlParams]
    for (let i = 0; i < auxUrlParams.length; i++) {
      const param = auxUrlParams[i];
      const exist = params.find(p => p.substring(1) === param.name)
      if (!exist) {
        urlParams.splice(i, 1)
      }
    }
    setHookData({ ...hookData, urlParams, url })
  }

  const hookModal = () => {
    return (
      <Modal
        className="hook-modal"
        destroyOnClose={true}
        width={586}
        height={500}
        closable={true}
        centered={true}
        visible={hookModalVisible}
        title={(
          <>
            <div style={{ alignContent: 'center', textAlign: 'center', fontSize: '30px', color: '#5C5C5C' }}>
              {hookData._id ? 'Editar' : 'Nueva'} Extensión
            </div>
          </>
        )}
        // onOk={()=>{}}
        onCancel={cancelModal}
        // cancelText="Cancelar"
        // okText="Crear"
        footer={null}
      >
        {loading ? (
          <div className="user_info_loading">
            <LoadingOutlined />
          </div>
        ) :
          (
            <>
              <div className="hook-modal-body">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Info" key="1">
                    <div className="hook-modal-field">
                      <label>Nombre:</label>
                      <Input
                        type="text"
                        name="hookTitle"
                        value={hookData.name}
                        onChange={e => setHookData({ ...hookData, name: e.target.value })}
                      />
                    </div>
                    <div className="hook-modal-field">
                      <label>Descripción:</label>
                      <Input.TextArea
                        rows={3}
                        name="description"
                        value={hookData.description}
                        onChange={e => setHookData({ ...hookData, description: e.target.value })}
                      />
                    </div>
                    <div className="hook-modal-field">
                      <label>Link documentación:</label>
                      <Input
                        type="text"
                        name="linkInfo"
                        value={hookData.infoLink}
                        onChange={e => setHookData({ ...hookData, infoLink: e.target.value })}
                      />
                    </div>
                    <div className="hook-modal-field">
                      <label>URL:</label>
                      <Input
                        type="text"
                        name="url"
                        value={hookData.url}
                        onChange={e => {
                          // setHookData({...hookData, url: e.target.value})
                          setRouteParams(e.target.value)
                        }}
                      />
                    </div>
                    <div className="hook-modal-field">
                      <label>Tiempo de espera:</label>
                      <Space direction="vertical">
                        <InputNumber addonAfter="$" defaultValue={30} onChange={e => setHookData({ ...hookData, timeout: e })} />

                      </Space>
                    </div>
                    <div className="hook-modal-field">
                      <label>Ícono:</label>
                      <Upload
                        // name="avatar"
                        listType="picture-card"
                        className="icon-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                      >
                        {hookData.icon && hookData.icon.length > 0 ? <img src={hookData.icon} alt="avatar" style={{ width: '100%' }} /> : (
                          <div>
                            {uploadingImage ? <LoadingOutlined /> : <PlusOutlined />}
                            <div style={{ marginTop: 8 }}>Subir</div>
                          </div>
                        )}
                      </Upload>
                    </div>
                  </TabPane>
                  <TabPane tab="Compartir" key="2">
                    <div className="hook-modal-field">
                      <label>Compartido:</label>
                      <Switch
                        checked={hookData.shared}
                        onChange={e => setHookData({ ...hookData, shared: e })}
                      />
                    </div>
                    {hookData.shared ? (
                      <>
                        <div className="hook-modal-field">
                          <label>Cargo mensual:</label>
                          <Space direction="horizontal">
                            <InputNumber addonAfter="$" defaultValue={0} onChange={e => setHookData({ ...hookData, fee: { ...hookData.fee, monthly: e } })} />
                            <div className="fee-description">Cargo mensual al cliente para usar la extension</div>
                          </Space>
                        </div>
                        <div className="hook-modal-field">
                          <label>Cargo único:</label>
                          <Space direction="horizontal">
                            <InputNumber addonAfter="$" defaultValue={0} onChange={e => setHookData({ ...hookData, fee: { ...hookData.fee, oneTime: e } })} />
                            <div className="fee-description">Cargo al cliente para adquirir la extension</div>
                          </Space>
                        </div>
                        <div className="hook-modal-field">
                          <label>Cargo por uso:</label>
                          <Space direction="horizontal">
                            <InputNumber addonAfter="$" defaultValue={0} onChange={e => setHookData({ ...hookData, fee: { ...hookData.fee, perUsage: e } })} />
                            <div className="fee-description">Cargo al cliente por cada vez que se use la extension</div>
                          </Space>
                        </div>
                        <div className="hook-modal-field">
                          <label>Días de gracia:</label>
                          <Space direction="horizontal">
                            <InputNumber addonAfter="$" defaultValue={0} onChange={e => setHookData({ ...hookData, fee: { ...hookData.fee, gracePeriod: e } })} />
                            <div className="fee-description">Días que no se harán cargos al cliente</div>
                          </Space>
                        </div>
                      </>
                    ) : null}
                  </TabPane>
                  <TabPane tab="Parámetros" key="3">
                    <div className="hook-parameter-title">
                      Parametros de ruta <div className="parameter-subtitle">(route params)</div>
                    </div>
                    {hookData.urlParams.length === 0 ? (
                      <div className="hook-parameter-no-params">
                        No hay parametros definidos
                      </div>
                    ) : (
                      <div className="hook-modal-field">
                        <label>Nombre</label>
                        <p>Valor por defecto</p>
                      </div>
                    )}
                    {hookData.urlParams.map((url, i) => {
                      return (
                        <div key={i} className="hook-modal-field">
                          <label>{url.name}:</label>
                          <Input
                            type="text"
                            value={url.value}
                            onChange={e => {
                              // setHookData({...hookData, url: e.target.value})
                              setUrlParamValue(url.name, e.target.value)
                            }}
                          />
                        </div>
                      )
                    })}
                    <div className="hook-parameter-title">
                      Parametros de cuerpo <div className="parameter-subtitle">(request body)</div>&nbsp;<PlusCircleOutlined style={{ cursor: 'pointer' }} onClick={() => addBodyParam()} />
                    </div>
                    <div className='hook-modal-check'>
                      <Checkbox
                        defaultChecked={hookData.dontParseBodyParams}
                        onChange={e => toggleParseBodyParameters(e.target.checked)}>No expandir expresiones (los datos se enviarán literalmente)</Checkbox>
                    </div>
                    {hookData.bodyParams.length === 0 ? (
                      <div className="hook-parameter-no-params">
                        No hay parametros definidos
                      </div>
                    ) : null}
                    {hookData.bodyParams.map((param, i) => {
                      return (
                        <div key={i} className="hook-parameter-body-container">
                          <div className="hook-modal-field">
                            <div className="param-field">Nombre</div>
                            <div className="param-field">Tipo</div>
                          </div>
                          <div className="hook-modal-param-body-field">

                            <div className="param-field">
                              <Input
                                type="text"
                                value={param.name}
                                onChange={e => {
                                  // setHookData({...hookData, url: e.target.value})
                                  setBodyParamName(i, e.target.value)
                                }}
                              />
                            </div>
                            <div className="param-field">
                              <Select
                                // className="side_properties__input--table_select" 
                                placeholder="Seleccione una opción"
                                onChange={(e) => setBodyParamType(i, e)}
                                value={param.type}
                              >
                                <Select.Option key={`opt-${i}`} value="text">Texto</Select.Option>
                                <Select.Option key={`opt-${i}`} value="textarea">Área de Texto</Select.Option>
                                <Select.Option key={`opt-${i}`} value="select">Selector</Select.Option>
                              </Select>
                            </div>

                            <div className="delete-hook-parameter">
                              <DeleteOutlined onClick={() => {
                                deleteBodyParam(i)
                              }} />
                            </div>
                          </div>
                          {getBodyParamValueComponent(param, i)}

                        </div>
                      )
                    })}

                    {/* {console.log(JSON.stringify(hookData.urlParams))} */}
                  </TabPane>
                </Tabs>


              </div>
              <div className="hooks-modal-footer">
                <button onClick={cancelModal}
                >
                  Cancelar
                </button>
                <Button type="primary" htmlType="submit" onClick={!hookData._id ? createHook : updateHook}
                >
                  {hookData._id ? 'Editar' : 'Crear'}
                </Button>
              </div>
            </>
          )}
      </Modal>
    )
  }
  const showModal = () => {
    setHookModalVisible(true)
  }
  const openUpdateHook = (hook) => {
    setHookData({ ...hook })
    setHookModalVisible(true)
  }
  const cancelModal = () => {
    setHookModalVisible(false)
    resetHookData()
  }

  if (loading) {
    return (
      <div className="analytics-loading">
        <LoadingOutlined />
      </div>
    )
  }

  return (
    <div className="hooks-management-container">
      <div className="hooks-box" style={{ cursor: 'pointer' }} onClick={() => { showModal() }}>
        <div className="hooks-box-content">
          <p className="hooks-title-new-text"><b>Nueva extensión</b></p>
          <div className="hooks-logo add-hook-icon">
            <PlusCircleOutlined />

          </div>
        </div>
      </div>
      {hooksList.map((hook, i) => {
        return (<div key={i} className="hooks-box" >
          <div className="hooks-box-content">
            <div className="hooks-logo">
              <img src={hook.icon} alt="avatar" />
            </div>
            <p className="hooks-title-text"><b>{hook.name}</b></p>

          </div>
          <div className="hook-box-footer">
            {hook.isOwner && (
              <>
                <Tooltip title="Activar/Desactivar">
                  <Switch checked={hook.active} onChange={() => {

                    Modal.confirm({
                      title: 'Confirmar',
                      content: `¿Está seguro que desea ${hook.active ? 'desactivar' : 'activar'} esta extensión? `,
                      async onOk () {
                        switchHook(hook)
                      }
                    })
                  }} />
                </Tooltip>
                <Tooltip title="Editar">
                  <EditOutlined className="box_icon_available" onClick={() => openUpdateHook(hook)} />
                </Tooltip>
                <Tooltip title="Eliminar extensión">
                  <DeleteOutlined className="box_icon_available" onClick={() => {
                    Modal.confirm({
                      title: 'Confirmar',
                      content: `¿Está seguro que desea eliminar esta extensión? `,
                      async onOk () {
                        deleteHook(hook._id)
                      }
                    })
                  }} />
                </Tooltip>
              </>
            )}
          </div>
        </div>)
      })}
      {hookModal()}
    </div>
  )
}


export default MyHooks