/* eslint-disable react/display-name */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Switch, Tooltip, Popover } from 'antd'
import ProcessStatistics from '../ProcessStatistics/ProcessStatistics'
import './ProcessBox.scss'
import { DeleteOutlined, EditOutlined, ProfileOutlined, DashboardOutlined, UnlockOutlined, LockOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import axiosBW from '../../../../../utils/Http'
import { toast } from 'react-toastify'
import { setGlobalLoader } from '../../../../../actions/actions'

const ProcessBox = ({ process, switchActive, changeState, showModalEdit, showPopover, id, loadInfo }) => {
  const dispatch = useDispatch()
  const StateIcon = process.state === 'public' ? UnlockOutlined : LockOutlined
  const companyId = useSelector(state => state.user.companyId)
  const userPermissions = useSelector(state => state.user.permissions)
  const canDisable = userPermissions.some(p => p === `process-enable|${process.slug}` || p === 'admin')
  const canEdit = userPermissions.some(p => p === `process-edit|${process.slug}` || p === 'admin')
  const canDelete = userPermissions.some(p => p === `process-delete|${process.slug}` || p === 'admin')
  const canPrivate = userPermissions.some(p => p === `process-private|${process.slug}` || p === 'admin')
  const canAnalytics = userPermissions.some(p => p === `process-analytics|${process.slug}` || p === 'admin')
  const canReports = userPermissions.some(p => p === `process-reports|${process.slug}` || p === 'admin')

  const sortable = useSortable({ id });
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = sortable;
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  const popoverProps = !showPopover || process.deleteRequest ? { visible: false } : {}

  const deleteProcess = async () => {
    try {
      dispatch(setGlobalLoader(true))
      await axiosBW.post(`/process/add-delete-process-request/`,
        {
          companyId,
          processSlug: process.slug
        })
      toast.success('Se ha solicitado la eliminación del proceso correctamente')
      await loadInfo()
      dispatch(setGlobalLoader(false))
    } catch (error) {
      dispatch(setGlobalLoader(false))
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }

  return (
    <div className="process-box"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <Popover placement="right" content={<ProcessStatistics processSlug={process.slug} />} title="Estadísticas" trigger="hover"{...popoverProps} >
        <div className="process-box-content">
          <p className="process-title-text"><b>{process.title}</b>{!process.deleteRequest ? (
            <EditOutlined style={{ fontSize: '15px', cursor: 'pointer' }} onClick={(e) => {
              e.stopPropagation()
              showModalEdit(process.slug)
            }} />
          ) : ''} </p>
          <p>({process.slug})</p>
          <div className="process-logo">
            <img src={process.imagePath} alt='Logo Proceso'></img>
          </div>
        </div>
      </Popover>
      {process.deleteRequest ? (
        <div className={`process-box-footer__deleting`}>
          Eliminando...
        </div>
      ) : (
        <div className={`process-box-footer${!process.active ? '__inactive' : process.state === 'private' ? '__private' : ''}`}>
          {canDisable ? (
            <Tooltip title="Activar/Desactivar">
              <Switch checked={process.active} onChange={() => {
                Modal.confirm({
                  title: 'Confirmar',
                  content: `¿Está seguro que desea ${process.active ? 'desactivar' : 'activar'} este proceso? `,
                  async onOk () {
                    switchActive(process.slug, process.active ? false : true)
                  }
                })
              }} />
            </Tooltip>
          ) : (<Switch className="box_switch_disable" checked={process.active} />)}
          {canPrivate ? (
            <Tooltip title="Público/Privado">
              <StateIcon className="box_icon_available" onClick={() => {
                Modal.confirm({
                  title: 'Confirmar',
                  content: `¿Está seguro que desea cambiar el estado a ${process.state === 'public' ? 'Privado' : 'Público'} de este proceso? `,
                  async onOk () {
                    changeState(process.slug, process.state === 'public' ? 'private' : 'public')
                  }
                })
              }} />
            </Tooltip>
          ) : (<StateIcon className="box_icon_disable" />)}
          {canAnalytics ? (
            <Tooltip title="Analíticas">
              <Link to={`/metricas/${process._id}/${process.slug}/1`}>
                <DashboardOutlined className="box_icon_available" />
              </Link>
            </Tooltip>
          ) : (<DashboardOutlined className="box_icon_disable" />)}
          {canReports ? (
            <Tooltip title="Reportes">
              <Link to={`/metricas/${process._id}/${process.slug}/0`}>
                <ProfileOutlined className="box_icon_available" />
              </Link>
            </Tooltip>
          ) : (<ProfileOutlined className="box_icon_disable" />)}
          {canEdit ? (
            <Tooltip title="Ir a diseño">
              <Link to={`/form-list/${process._id}/${process.slug}`}>
                <EditOutlined className="box_icon_available" />
              </Link>
            </Tooltip>
          ) : (<EditOutlined className="box_icon_disable" />)}
          {canDelete ? (
            <Tooltip title="Eliminar flujo">
              <DeleteOutlined className="box_icon_available" onClick={() => {
                Modal.confirm({
                  title: '¡¿Está seguro?!',
                  width: 500,
                  content: (
                    <>
                      <p>Tenga en cuenta que esta operación no es reversible.</p>
                      <p>El proceso, junto con todos sus casos ({process.cases}), reportes y analíticas, se eliminará en los próximos minutos.</p>
                      <p>Por favor tenga en cuenta que esta operación puede tomar hasta 1 hora.</p>
                    </>),
                  async onOk () {
                    deleteProcess()
                  }
                })
              }} />
            </Tooltip>
          ) : (<DeleteOutlined className="box_icon_disable" />)}
        </div>
      )}
    </div>
  )
}


export default ProcessBox