import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux'
import './Sidebar.scss'
import { changeNavMenu, toggleSidebar } from '../../actions/actions';

const Sidebar = props => {
  const companyId = useSelector(state => state.user.companyId)
  const toggleSidebar = () => {
    props.toggleSidebar(!props.sidebarVisible)
  }

  const changeNavMenuWrapper = (e, elem, target, history, changeNavMenu) => {
    e.preventDefault()
    changeNavMenu(elem)
    history.push(target)
    toggleSidebar()
    return
  }

  return (
    <div
      className={`
      sidebar__shadow
      ${props.sidebarVisible ? 'sidebar__shadow_show' : ''}
    `}
      onClick={(e) => { if (e.target === e.currentTarget) toggleSidebar() }}
      onTouchEnd={() => {
      }}
      onTouchMove={(e) => { console.log(e.touches) }}
    >
      <aside className={`
        sidebar__wrappper
        ${props.sidebarVisible ? 'sidebar__wrapper_show' : ''}
      `}>
        <section className="sidebar">
          <div className="sidebar__close_icon" onClick={() => toggleSidebar()}>
            <CloseOutlined />
          </div>
          <ul className="sidebar__list">
            {props.navigationList && typeof (props.navigationList) === 'object' ?
              props.navigationList.map((elem, index) => {
                const target = elem.url + (elem.urlParams ? (elem.urlParams === 'companyId' ? companyId : elem.urlParams) : '')
                return (
                  <li className="sidebar__element" key={index}>
                    <Link
                      className={`sidebar__link ${elem.isActive ? 'sidebar__link--selected' : ''}`}
                      onClick={(e) => changeNavMenuWrapper(e, elem, target, props.history, props.changeNavMenu)}
                      to={target}>{elem.text}</Link>
                  </li>
                )
              }) : 'No hay elementos de menú'
            }
          </ul>
        </section>
      </aside>
    </div >
  );
}

const mapStateToProps = state => {
  return {
    sidebarVisible: state.navigation.sidebarVisible,
    navigationList: state.navigation.navigationList
  }
}
const mapDispatchToProps = dispatch => {
  return {
    changeNavMenu: elem => dispatch(changeNavMenu(elem)),
    toggleSidebar: status => dispatch(toggleSidebar(status)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
