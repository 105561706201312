
export const setCurrentForm = (currentForm) => ({
  type: 'SET_CURRENT_FORM',
  currentForm

})
export const updateCurrentFormField = (fieldName, fieldValue) => ({
  type: 'UPDATE_CURRENT_FORM_FIELD',
  fieldName,
  fieldValue
})

export const setCurrentProcessId = (processId) => ({
  type: 'SET_CURRENT_PROCESS_ID',
  processId
})

export const setCurrentCaseNumber = (caseNumber) => ({
  type: 'SET_CURRENT_CASE_NUMBER',
  caseNumber
})

export const plusAttachmentNumber = () => ({
  type: 'PLUS_ATTACHMENT_NUMBER'
})

export const setAttachmentList = (newAttachment) => ({
  type: 'SET_ATTACHMENT_LIST',
  newAttachment
})

export const setCaseCurrentTab = (currentTab) => ({
  type: 'SET_CASE_CURRENT_TAB',
  currentTab
})

export const setTableWidgetList = (tableWidgetList) => ({
  type: 'SET_TABLE_WIDGET_LIST',
  tableWidgetList
})

export const addRowToTableWidget = (tableSlug, columns) => ({
  type: 'ADD_ROW_TO_TABLE_WIDGET',
  tableSlug,
  columns
})

export const changeTableField = (fieldName, fieldValue, rowIndex, tableSlug, tableInfo) => ({
  type: 'CHANGE_TABLE_FIELD',
  fieldName, 
  fieldValue, 
  rowIndex, 
  tableSlug,
  tableInfo
})

export const removeRowToTableWidget = (tableSlug, rowIndex) => ({
  type: 'REMOVE_ROW_TO_TABLE_WIDGET',
  tableSlug,
  rowIndex
})