/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import axiosBW from '../../../../../utils/Http';
import { Row, Modal, Input, Collapse, Tooltip } from 'antd';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { setGlobalLoader } from '../../../../../actions/actions'
import { Link  } from 'react-router-dom'
import {
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {arrayMove} from "@dnd-kit/sortable";
import './ReportList.scss'

const ReportList = ({ processSlug, processTitle, history }) => {
  const dispatch = useDispatch();
  const [analyticsList, setAnalyticsList] = useState([]);
  const [reportsList, setReportsList] = useState([]);
  const [showModal, setModal] = useState(false)
  const [showModalEdit, setModalEdit] = useState(false)
  const [renameSectionId, setRenameSectionId] = useState(null)
  const [newSectionName, setNewSectionName] = useState('')
  const [loading, setLoading] = useState(false);
  const companyId = useSelector(state => state.user.companyId)

  const getReportList = async() => {
    try {
      const resAnalytics = await axiosBW.get(`reports/list/${companyId}/${processSlug}/`)
      if(resAnalytics && resAnalytics.data) {
        if(resAnalytics.data.status === 0) {
          setReportsList(resAnalytics.data.payload)
        }
      }
    } catch(ex) {
      console.log(ex);
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true)
      await getReportList()
      setLoading(false)
    })();  
  }, []);

  const createNewSection = async () => {
    try {
      const resAnalytics = await axiosBW.post(`analytics/create-section/`, {
        companyId,
        processSlug,
        title: newSectionName
      })
      if (resAnalytics && resAnalytics.data) {
        if(resAnalytics.data.status === 0) {
          setAnalyticsList(resAnalytics.data.payload)
        }
      }
      setModal(false)
      setNewSectionName('')
    } catch (error) {
      console.log(error);
      setLoading(false)
      setModal(false)
      setNewSectionName('')
    }
  }

  const renameSection = async () => {
    try {
      const resAnalytics = await axiosBW.post(`analytics/rename-section/`, {
        companyId,
        processSlug,
        sectionId: renameSectionId,
        title: newSectionName
      })
      if(resAnalytics && resAnalytics.data) {

        if(resAnalytics.data.status === 0) {
          setAnalyticsList(resAnalytics.data.payload)
        }
      }
      setModalEdit(false)
      setNewSectionName('')
      setRenameSectionId(null)
    } catch (error) {
      console.log(error);
      setModalEdit(false)
      setNewSectionName('')
      setRenameSectionId(null)
    }
  }

  const switchActive = async (companyId, processSlug, sectionId, analyticId, active) =>{
    try {
      const resAnalytics = await axiosBW.post(`analytics/switch-active/`, {
        companyId,
        processSlug,
        sectionId,
        analyticId,
        active
      })
      if (resAnalytics && resAnalytics.data) {

        if (resAnalytics.data.status === 0) {
          setAnalyticsList(resAnalytics.data.payload)
          toast.success('Analítica modificada correctamente')
        }
      }
    } catch (error) {
      toast.error('Error al activar/desactivar la anlítica')
      return
    }
  }
  const removeReport = async (reportId) =>{
    try {
      const resAnalytics = await axiosBW.post(`reports/delete-report/`, {
        reportId
      })
      if (resAnalytics && resAnalytics.data) {
        if (resAnalytics.data.status === 0) {
          await getReportList()
          toast.success('Reporte eliminado correctamente')
        }
      }
    } catch (error) {
      toast.error('Error al eliminar el reporte')
      return
    }
  }

  if (loading) {
      return 'Loading...'
  }

  const reorderAnalytics = async (sectionId, analyticId, newPositionId) =>{
    try {
      const resAnalytics = await axiosBW.post(`analytics/reorder-analytics/`, {
        companyId,
        processSlug,
        sectionId,
        analyticId,
        newPositionId
      })
      if (resAnalytics && resAnalytics.data) {
        if (resAnalytics.data.status === 0) {
          setAnalyticsList(resAnalytics.data.payload)
          toast.success('Analítica ordenada correctamente')
        }
      }
    } catch (error) {
      toast.error('Error al ordenar la anlítica')
      return
    }
  }

  /*
  const handleDragEnd = async(sectionId, event) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const analytics = analyticsList.find(l => l._id === sectionId).analytics
      const sectionIndex = analyticsList.findIndex(l => l._id === sectionId)
      const oldIndex = analytics.findIndex((i) => i._id === active.id);
      const newIndex = analytics.findIndex((i) => i._id === over.id);
      const orderedList = arrayMove(analytics, oldIndex, newIndex);
      const newList = [...analyticsList]
      newList[sectionIndex].analytics = orderedList
      setAnalyticsList(newList)
      dispatch(setGlobalLoader(true))
      await reorderAnalytics(sectionId, active.id, over.id)
      dispatch(setGlobalLoader(false))
    }
  }
  */
 
  const ReportBox = ({isNew, report}) => {
    if (isNew) {
      return (
        <div className="report-box" style={{cursor: 'pointer'}} onClick={() => { history.push({ 
          pathname: '/report-designer/nueva', 
          state: { 
            companyId,
            processSlug,
            processTitle,
            analytic: {}
          }
        })}}>
          <div className="report-box-content">
            <p className="report-title-text new-report"><b>Nuevo reporte</b></p>
            <div className="report-logo add-analytic-icon">
              <PlusCircleOutlined />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="report-box">
      <div className="report-box-content">
        <p className="process-title-text"><b>{report.name}</b></p>
      </div>
      {/* <div className={`report-box-footer${!report.active ? '__inactive' : ''}`}> */}
      <div className={`report-box-footer`}>
        {/* <Tooltip title="Activar/Desactivar">
          <Switch checked={analytic.active} onChange={() => {
                    
            Modal.confirm({
              title: 'Confirmar',
              content: `¿Está seguro que desea ${analytic.active ? 'desactivar' : 'activar'} esta analítica? `,
              async onOk() {
                switchActive(companyId, processSlug, sectionId, analyticId, analytic.active ? false : true)
              }
            })
          } } />
        </Tooltip> */}
        <Tooltip title="Ir a diseño">
          <Link to={{
            pathname: '/report-designer', 
            state: { 
              companyId,
              processSlug,
              processTitle,
              report
            }
          }}>
            <EditOutlined style={{fontSize: '22px', color: '#FFFFFF', paddingLeft: '11px'}} />
          </Link>
        </Tooltip>
        <Tooltip title="Eliminar">
          <DeleteOutlined style={{fontSize: '22px', color: '#FFFFFF', paddingLeft: '11px', cursor: 'pointer'}} onClick={() => {
            Modal.confirm({
              title: 'Confirmar',
              content: `¿Está seguro que desea eliminar este Reporte? `,
              async onOk() {
                removeReport(report._id)
              }
            })
          } } />
        </Tooltip>
      </div>
    </div>
    )
  }

  return (
    <div className="analytics-list-wrapper">
      <Row className="analytics-list-title">
        Secciones
        <button className="new-section-button" onClick={ () => {
          setModal(true)
        }}
        >
          Nueva sección
        </button>
      </Row>
      <div className="analytics-list-panel">
        <ReportBox isNew={true}/>
        {reportsList.map((report, i) => {
          return(
            <ReportBox 
              report={report} 
              switchActive={switchActive}
              removeReport={removeReport}
              index={i}
              key={i}
            />
          )
        })}
      </div>
      <Modal
        width={360}
        closable={false}
        centered={true}
        visible={showModal}
        title={(<div style={{alignContent: 'center', textAlign: 'center'}}>Nueva sección</div>)}
        onOk={createNewSection}
        onCancel={() => { setModal(false); setNewSectionName('') }}
        cancelText="Cancelar"
        okText="Crear"
      >
        <div className="modal-form">
          <div className="label">
            Nombre
          </div>
          <Input
            type="text"
            name="newSectionName"
            value={newSectionName}
            onChange={(e) => setNewSectionName(e.target.value)}
          />
        </div>
      </Modal>
      <Modal
        width={360}
        closable={false}
        centered={true}
        visible={showModalEdit}
        title={(<div style={{alignContent: 'center', textAlign: 'center'}}>Editar sección</div>)}
        onOk={renameSection}
        onCancel={() => { setModalEdit(false); setNewSectionName('') }}
        cancelText="Cancelar"
        okText="Crear"
      >
        <div className="modal-form">
          <div className="label">
            Nombre
          </div>
          <Input
            type="text"
            name="newSectionName"
            value={newSectionName}
            onChange={(e) => setNewSectionName(e.target.value)}
          />
        </div>
      </Modal>
    </div>
  )
}


export default ReportList