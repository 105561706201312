import React, { Component } from 'react'
import './Table.scss'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

class Table extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // the current page of the navigation, by default is 0 (first page)
      currentPage: 0,
      // array of all data, spliced by num per page
      splicedArray: [],
      // pivot of the data props
      data: [],
      // pivot of the pagenum prop
      numOfPages: 0,
      // show a message when table has no data
      noDataMessage: '',
      // revert or not the sort
      isReversed: false,
      columns: [],
    }
    this.prevRef = React.createRef()
    this.nextRef = React.createRef()
  }
  
  componentDidMount() {
    let formattedArray = []
    this.setState(
      {
        data: this.props.data,
        // Set number of elements per page, if the props isn't exists take 10000 elements per page
        numOfPages: this.props.paginatenum ? this.props.paginatenum : 10000,
        // Set Message for no data in table. If the prop isn't exist, pass a default value

        noDataMessage: this.props.noDataMessage
          ? this.props.noDataMessage
          : 'No existen registros actualmente en la tabla',
        columns: this.props.columns ? this.props.columns : []
      },
      () => {
        this.state.data.forEach(e => {
          // add the show children prop to check if show the children prop or not (start by default all collapsed)
          e.showChildren = false
          formattedArray.push(e)
        })
        // first array splitting to divide in pages
        this.chunkArray(formattedArray, this.state.numOfPages)
      }
    )
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.state.data) {
      let formattedArray = []
      this.setState(
        {
          data: nextProps.data,
          numOfPages: this.props.paginatenum ? this.props.paginatenum : 1000,
          currentPage: 0,
        },
        () => {
          nextProps.data.forEach(e => {
            // add the show children prop to check if show the children prop or not (start by default all collapsed)
            e.showChildren = false
            formattedArray.push(e)
          })
          // first array splitting to divide in pages
          this.chunkArray(formattedArray, this.state.numOfPages)
        }
      )
    }
  }
  chunkArray(myArray, chunk_size) {
    var index = 0
    var arrayLength = myArray.length
    var tempArray = []
    for (index = 0; index < arrayLength; index += chunk_size) {
      let myChunk = myArray.slice(index, index + chunk_size)
      // Do something if you want with the group
      tempArray.push(myChunk)
    }
    this.setState({
      splicedArray: tempArray,
    })
  }
  generatePagination() {
    const pages = []

    // Flecha anterior
    pages.push(
      <div
      
        className={`pagination__num pagination__prev${this.state.blockCursor ? ' h_block_cursor': ''}`}
        ref={this.prevRef}
        onClick={() => {
          this.changePage('minus')
        }}
        onMouseOver={(e)=> {
          if(this.state.currentPage === 0) {
            this.setState({
              blockCursor: true
            })
          } else {
            this.setState({
              blockCursor: false
            })
          }
        }}
      >
        <LeftOutlined />
      </div>
    )
    const amount = this.state.splicedArray.length
    if(amount <= 5) {
      // mostrar todo
      for (let i = 0; i < amount; i++) {
        pages.push(
          <div
            className={`pagination__num ${
              this.state.currentPage === i ? 'page_active' : ''
              }`}
            onClick={e => {
              this.setState({
                currentPage: i,
              })
            }}
          >
            {i + 1}
          </div>
        )
      }
    } else {
      // mostrar dinamico
      if(this.state.currentPage === 0) {
        // Primer elemento
        pages.push(
          <div
            className={`pagination__num ${
              this.state.currentPage === 0 ? 'page_active' : ''
              }`}
            onClick={e => {
              this.setState({
                currentPage: 0,
              })
            }}
          >
            {1}
          </div>
        )
        // spreads
        pages.push(
          <div
            className={`pagination__spread`}
            onClick={e => {
            }}
          >
            ...
          </div>
        )
        // Elemento Medio - 1
        pages.push(
          <div
            className={`pagination__num`}
            onClick={e => {
              this.setState({
                currentPage: Math.trunc(amount/2) - 2,
              })
            }}
          >
            {Math.trunc(amount / 2) - 1}
          </div>
        )
        // Elemento Medio
        pages.push(
          <div
            className={`pagination__num`}
            onClick={e => {
              this.setState({
                currentPage: Math.trunc(amount / 2) - 1,
              })
            }}
          >
            {Math.trunc(amount / 2)}
          </div>
        )
        // Elemento Medio + 1
        pages.push(
          <div
            className={`pagination__num`}
            onClick={e => {
              this.setState({
                currentPage: Math.trunc(amount / 2),
              })
            }}
          >
            {Math.trunc(amount / 2) + 1}
          </div>
        )
        // spreads
        pages.push(
          <div
            className={`pagination__spread`}
            onClick={e => {
            }}
          >
            ...
          </div>
        )
        // Elemento Final
        pages.push(
          <div
            className={`pagination__num`}
            onClick={e => {
              this.setState({
                currentPage: amount - 1,
              })
            }}
          >
            {amount }
          </div>
        )
      }
      else if(this.state.currentPage === 1) {
        // Primer elemento
        pages.push(
          <div
            className={`pagination__num ${
              this.state.currentPage === 0 ? 'page_active' : ''
              }`}
            onClick={e => {
              this.setState({
                currentPage: 0,
              })
            }}
          >
            {1}
          </div>
        )
        // Elemento Actual (pagina 2)
        pages.push(
          <div
            className={`pagination__num page_active`}
            onClick={e => {
              this.setState({
                currentPage: 1,
              })
            }}
          >
            {2}
          </div>
        )
        // Elemento Medio + 1
        pages.push(
          <div
            className={`pagination__num`}
            onClick={e => {
              this.setState({
                currentPage: 2,
              })
            }}
          >
            {3}
          </div>
        )
        // spreads
        pages.push(
          <div
            className={`pagination__spread`}
            onClick={e => {
            }}
          >
            ...
          </div>
        )
        // Elemento Final
        pages.push(
          <div
            className={`pagination__num`}
            onClick={e => {
              this.setState({
                currentPage: amount-1,
              })
            }}
          >
            {amount}
          </div>
        )
      }
      else if (this.state.currentPage === amount-1) {
        // Primer elemento
        pages.push(
          <div
            className={`pagination__num ${
              this.state.currentPage === 0 ? 'page_active' : ''
              }`}
            onClick={e => {
              this.setState({
                currentPage: 0,
              })
            }}
          >
            {1}
          </div>
        )
        // spreads
        pages.push(
          <div
            className={`pagination__spread`}
            onClick={e => {
            }}
          >
            ...
          </div>
        )
        // Elemento Actual
        pages.push(
          <div
            className={`pagination__num`}
            onClick={e => {
              this.setState({
                currentPage: amount-2,
              })
            }}
          >
            {amount-1}
          </div>
        )
        // Elemento Medio + 1
        pages.push(
          <div
            className={`pagination__num page_active`}
            onClick={e => {
              this.setState({
                currentPage: amount-1,
              })
            }}
          >
            {amount }
          </div>
        )
      }
      else if(this.state.currentPage === amount) {
        // Primer elemento
        pages.push(
          <div
            className={`pagination__num`}
            onClick={e => {
              this.setState({
                currentPage: 0,
              })
            }}
          >
            {1}
          </div>
        )
        // Elemento Medio - 1
        pages.push(
          <div
            className={`pagination__num`}
            onClick={e => {
              this.setState({
                currentPage: Math.trunc(amount/2) - 1,
              })
            }}
          >
            {Math.trunc(amount / 2)}
          </div>
        )
        // Elemento Medio
        pages.push(
          <div
            className={`pagination__num`}
            onClick={e => {
              this.setState({
                currentPage: Math.trunc(amount / 2),
              })
            }}
          >
            {Math.trunc(amount / 2) + 1}
          </div>
        )
        // Elemento Medio + 1
        pages.push(
          <div
            className={`pagination__num`}
            onClick={e => {
              this.setState({
                currentPage: Math.trunc(amount / 2) + 1,
              })
            }}
          >
            {Math.trunc(amount / 2) + 2 }
          </div>
        )

        // Elemento Final
        pages.push(
          <div
            className={`pagination__num page_active`}
            onClick={e => {
              this.setState({
                currentPage: amount - 1,
              })
            }}
          >
            {amount}
          </div>
        )
      }
      // casos intermedios
      else {
        // Primer elemento
        pages.push(
          <div
            className={`pagination__num`}
            onClick={e => {
              this.setState({
                currentPage: 0,
              })
            }}
          >
            {1}
          </div>
        )
        // spreads
        pages.push(
          <div
            className={`pagination__spread`}
            onClick={e => {
            }}
          >
            ...
          </div>
        )
        // Elemento Medio - 1
        pages.push(
          <div
            className={`pagination__num`}
            onClick={e => {
              this.setState({
                currentPage: this.state.currentPage - 1,
              })
            }}
          >
            {this.state.currentPage}
          </div>
        )
        // Elemento Medio
        pages.push(
          <div
            className={`pagination__num page_active`}
            onClick={e => {
              this.setState({
                currentPage: this.state.currentPage,
              })
            }}
          >
            {this.state.currentPage + 1 }
          </div>
        )
        // Elemento Medio + 1
        if(this.state.currentPage + 2 !== amount) {
          pages.push(
            <div
              className={`pagination__num`}
              onClick={e => {
                this.setState({
                  currentPage: this.state.currentPage + 1,
                })
              }}
            >
              {this.state.currentPage + 2}
            </div>
          )
          // spreads
          pages.push(
            <div
              className={`pagination__spread`}
              onClick={e => {
              }}
            >
              ...
          </div>
          )
        }
        
          // Elemento Final
          pages.push(
            <div
              className={`pagination__num`}
              onClick={e => {
                this.setState({
                  currentPage: amount - 1,
                })
              }}
            >
              {amount}
            </div>
          )  
        
        
      }
    }
    // Flecha Siguiente
    pages.push(
      <div
        className={`pagination__num pagination__next${this.state.blockCursor ? ' h_block_cursor': ''}`}
        onClick={() => {
          this.changePage('plus')
        }}
        ref={this.nextRef}
        onMouseOver={(e)=> {
          if(this.state.currentPage+1 === this.state.splicedArray.length) {
            this.setState({
              blockCursor: true
            })
          } else {
            this.setState({
              blockCursor: false
            })
          }
        }}
      >
        <RightOutlined />
      </div>
    )
    return pages
  }

  changePage(type) {
    if (type === 'minus') {
      if (this.state.currentPage <= 0) {
        return
      } else {
        this.setState({
          currentPage: this.state.currentPage - 1,
        })
      }
    } else if (type === 'plus') {
      if (this.state.currentPage >= this.state.splicedArray.length - 1) {
        return
      } else {
        this.setState({
          currentPage: this.state.currentPage + 1,
        })
      }
    }
  }
  sortArray(e, compare) {
    let sorted = []
    if (this.state.isReversed) {
      sorted = this.state.data.sort(compare)
    } else {
      sorted = this.state.data.sort(compare).reverse()
    }
    this.setState(
      {
        data: sorted,
        isReversed: !this.state.isReversed,
      },
      () => {
        let formattedArray = []
        this.state.data.forEach(e => {
          // add the show children prop to check if show the children prop or not (start by default all collapsed)
          e.showChildren = false
          formattedArray.push(e)
        })
        // first array splitting to divide in pages
        this.chunkArray(formattedArray, this.state.numOfPages)
      }
    )
  }

  render() {
    return (
      <div className="at_table__wrapper">
        <table className="at_table">
          <thead className="at_table__head">
            <tr>
              {
                this.state.columns.map((elem, index) => {
                  return (<td  style={elem.headStyle} key={`thead-${index}`}>
                    {elem.title}
                  </td>)
                })
              }
            </tr>
          </thead>
          <tbody className="ft-table__body">
            {!this.state.splicedArray[this.state.currentPage] ? (
              <tr>
                <td
                  className="ft-table__no_content"
                  colSpan={this.props.columns.length}
                >
                  {this.state.noDataMessage}
                </td>
              </tr>
            ) : this.state.splicedArray[this.state.currentPage].length > 0 ? (
              this.state.splicedArray[this.state.currentPage].map((d, k) => {
                return (
                  <>
                    <tr
                      key={`tr-b-${k}`}
                      className={`
                      ft-table__row 
                      ${k % 2 === 0 ? 'ft-table__row--even' : ''} 
                      ${d.children ? 'ft-table__row--with_children' : ''}`}
                    >
                      {this.props.columns.map((col, ke) => {
                        if (col.render) {
                          return (
                            <>
                              <td key={`colo-${col.key}-${ke}`}>
                                {col.render(d)}
                              </td>
                            </>
                          )
                        } else {
                          return (
                            <>   
                              <td
                                key={`${col.key}-${ke}`}
                                className="ft-table__column"
                              >
                                {d[col.key]}
                              </td>
                            </>
                          )
                        }
                      })}
                    </tr>
                  </>
                )
              })
            ) : (
                  <tr />
                )}
          </tbody>
        </table>
        <div className="ft-table__stats">
          <div className="ft-table__left">
            {this.props.paginatenum ? (<div>Página: <span className="ft-table__text_color">{this.state.currentPage + 1} de {this.state.splicedArray.length}</span></div>) : ''}
          </div>
          {
            this.props.showTotalRegistries ? (
              <div className="ft-table__right">
                <div>
                  Total de Registros: <span className="ft-table__text_color">{this.state.data.length}</span>
                </div>
              </div>
            ) : null
          }
          
        </div>
        {this.props.enablePagination ? (
          <div className="ft-table__pagination">
            {this.generatePagination()}
          </div>
        ) : null}
      </div>
    )
  }
}

export default Table
