import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Modal, Input, Select } from 'antd'
import { PlusCircleOutlined, LoadingOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axiosBW from '../../utils/Http';
import HookBox from './components/HookBox/HookBox';
import './MyHooksModal.scss'

const MyHooksModal = ({show, onCancel, onAdd}) =>   {
  const companyId = useSelector(state => state.user.companyId)
  const [loading, setLoading] = useState(false);
  const [hooksList, setHooksList] = useState([]);
  const [info, setInfo] = useState(null);
  const [step, setStep] = useState(1);

  useEffect(() => {
    console.log('va el hooks-log');
    (async () => {
      setLoading(true)
      // await getDashboard()
      await getHooksList()
      setLoading(false)

    })();
    
  }, []);
  const getHooksList = async() => {
    try {
      const res = await axiosBW.get(`hooks/acquired-hooks-list/${companyId}/`)
      if(res && res.data) {

        if(res.data.status === 0) {
          setHooksList(res.data.payload)
        }
      }
    } catch(ex) {
      console.log(ex);
    }
  }
  const selectHook = (hook) => {
    console.log('SELECTING')
    setInfo(hook)
    setStep(1)
  }
  const setUrlParam = (index, value) => {
    const urlParams = [...info.urlParams]
    urlParams[index].value = value
    setInfo({...info, urlParams})
  }
  const setBodyParam = (index, value) => {
    const bodyParams = [...info.bodyParams]
    bodyParams[index].value = value
    setInfo({...info, bodyParams})
  }
  const getBodyParamValueComponent = (param, i) => {
    switch (param.type) {
      case 'text':
        return(
          <Input
            type="text"
            value={param.value}
            onChange={e => {
              // setHookData({...hookData, url: e.target.value})
              setBodyParam(i, e.target.value)
            }}
          />
        )
        break;
      case 'textarea':
        return(
          <Input.TextArea
            value={param.value}
            onChange={e => {
              // setHookData({...hookData, url: e.target.value})
              setBodyParam(i, e.target.value)
            }}
          />
        )
        break;
      case 'select':
        return(
          <Select 
            onChange={value => {setBodyParam(i, value)}}
            value={param.value}
          >
            {
              param.options ? (
                param.options.map((elem, index) => {
                  return (
                    <Select.Option key={`optSel-${index}`} value={elem.value}>
                      {elem.label}
                    </Select.Option>
                  )
                })
              ) : ''
            }
          </Select>
          // <Input
          //   type="text"
          //   value={param.value}
          //   onChange={e => {
          //     // setHookData({...hookData, url: e.target.value})
          //     setBodyParam(i, e.target.value)
          //   }}
          // />
        )
        break;
    
      default:
        break;
    }
  }
  const hookInfoData = (info) => {
    if (step === 1) {
      return (
        <>
          <div className="hook-store-description-content">
            <div className="hook-store-description-company">
              {info.company}
            </div>
            <div className="hook-store-description-title">
              {info.name}
            </div>
            <div className="hook-store-description-desc">
              {info.description}
            </div>
            <div className="hook-store-description-more-info">
              <a href={info.infoLink} without rel="noopener noreferrer" target="_blank">Más información</a>
            </div>
            <div className="hook-store-description-price">
              {info.fee.monthly === 0 && info.fee.oneTime === 0 && info.fee.perUsage === 0 && info.fee.gracePeriod === 0 ? 
              'Gratis': 'Valores'}
            </div>
          </div>
          <div className="hook-store-description-actions">
          <button onClick={()=>onAdd(info)}>Utilizar</button>
            {/* {info.urlParams.length > 0 || info.bodyParams.length > 0 ? (
              <button onClick={()=>setStep(2)}>Siguiente</button>
            ): (
              <button onClick={()=>onAdd(info)}>Utilizar</button>
            )} */}
              
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="hook-store-description-content">
            <div className="hook-store-param-title">
              Parametros de ruta <div className="parameter-subtitle">(route params)</div>
            </div>
            {info.urlParams.length === 0 ? (
              <div className="hook-store-param-no-param">No hay parametros</div>
            ): null}
            {info.urlParams.map((u,i) => {
              return(
                <div className="hook-store-param-field">
                  <div className="hook-store-param-label">
                    {u.name}
                  </div>
                  <div className="hook-store-param-value">
                    <Input
                      type="text"
                      value={u.value}
                      onChange={e => {
                        // setHookData({...hookData, url: e.target.value})
                        setUrlParam(i, e.target.value)
                      }}
                    />
                  </div>
                </div>
              )
            })}
            <div className="hook-store-param-title">
              Parametros de cuerpo <div className="parameter-subtitle">(request body)</div>
            </div>
            {info.bodyParams.length === 0 ? (
              <div className="hook-store-param-no-param">No hay parametros</div>
            ): null}
            {info.bodyParams.map((u,i) => {
              return(
                <div className="hook-store-param-field">
                  <div className="hook-store-param-label">
                    {u.name}
                  </div>
                  <div className="hook-store-param-value">
                    {getBodyParamValueComponent(u,i)}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="hook-store-description-actions">
              <button onClick={()=>onAdd(info)}>Utilizar</button>
          </div>
        </>
      )
    }
  }


  return (
    <Modal
        className="hook-store-modal"
        destroyOnClose={true}
        width={1000}
        height={500}
        closable={true}
        centered={true}
        visible={show}
        title={(
          <>
          <div style={{alignContent: 'center', textAlign: 'center', fontSize: '30px', color: '#5C5C5C'}}>
             Extensión
          </div>
          </>
        )}
        // onOk={()=>{}}
        onCancel={onCancel}
        // cancelText="Cancelar"
        // okText="Crear"
        footer={null}
      >
        {loading ? (
          <div className="user_info_loading">
            <LoadingOutlined />
          </div>
        ):
        (
          <div className="hook-store-container">
            <div className="hook-store-list">
              {hooksList.map((hook, i) => {
                return (
                  <HookBox key={i} hookId={hook._id} onClick={selectHook}/>
                )
              })}
            </div>
            <div className="hook-store-description">
              {info ? (
                hookInfoData(info)
                
              ): (
                <div className="hook-store-description-no-info">Seleccione una extensión</div>
              )}
            </div>
          </div>
        )}
      </Modal>
  )
}

export default MyHooksModal