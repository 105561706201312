/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";
import axiosBW from '../../../../utils/Http';
import Charts from '../../../TestPages/Charts';
import AnalyticChart from '../AnalyticChart/AnalyticChart';
import { Table, Row, Col, Modal, Input, Collapse, Select, Tabs } from 'antd';
import Editor from "react-simple-code-editor";
import { PlusCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setGlobalLoader } from '../../../../actions/actions'
// import HooksLogDetail from '../HooksLogDetail/HooksLogDetail'
import moment from 'moment'
import './Analytics.scss'

const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;

const Analytics = (props) => {
  console.log({props})
  // console.log({languages})
  const dispatch = useDispatch();
  dispatch(setGlobalLoader(false))
  // if(!location.state.companyId) history.push('/')
//   const usersList = useSelector(state => state.myTeam.usersList)
  const [analyticsList, setAnalyticsList] = useState([]);
  const [oldAnalytics, setOldAnalytics] = useState(null);

  const [chartProperties, setChartProperties] = useState({});
  const [series, setSeries] = useState([]);
  const [type, setType] = useState('bar');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [showChart, setShowChart] = useState(false);
  const [showModal, setModal] = useState(false)
  const [newSectionName, setNewSectionName] = useState('')
  const [hooksLogDetail, setHooksLogDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [detailPage, setDetailPage] = useState(false);
  const companyId = useSelector(state => state.user.companyId)
  let { processSlug, processId } = useParams();



  useEffect(() => {
    console.log('va el hooks-log');
    (async () => {
      setLoading(true)
      await getDashboard()
      await getAnalyticsList()
      setLoading(false)

    })();
    
  }, []);
  const getAnalyticsList = async() => {
    try {
      const resAnalytics = await axiosBW.get(`analytics/list/${companyId}/${processSlug}/`)
      if(resAnalytics && resAnalytics.data) {

        if(resAnalytics.data.status === 0) {
          setAnalyticsList(resAnalytics.data.payload)
        }
      }
    } catch(ex) {
      console.log(ex);
    }
  }
  const getDashboard = async() => {
    try {
      const res = await axiosBW.get(`/dashboard/definition/${processId}`)
      if(res && res.data && res.data.status === 0 && res.data.payload  && res.data.payload.analytics && res.data.payload.analytics.length > 0) {
        setOldAnalytics(res.data.payload)
      }
    } catch(ex) {
      console.log(ex);
    }
  }

 

  const isJson = (text) => {
    try {
      JSON.parse(text)
      return true
    } catch (error) {
      return false
    }
  }
  // const evaluateChart = async() => {
  //   try {
      
  //     if(!isJson(properties)) console.log('ERROR JSON FOMRAT')
  //     const parsedProperties = JSON.parse(properties)
  //     dispatch(setGlobalLoader(true))
  //     const resEvaluate = await axiosBW.post(`analytics/evaluate-analytic/`, {
  //       companyId,
  //       processSlug,
  //       code
  //     })
  //     if(resEvaluate && resEvaluate.data) {
  //       if(resEvaluate.data.status === 0) {
  //         const evaluatedData = resEvaluate.data.payload
  //         parsedProperties.type = type
  //         if(parsedProperties.type === 'bar'|| parsedProperties.type === 'hbar' || parsedProperties.type === 'line') {
  //           if (parsedProperties.scaleX){
  //             parsedProperties.scaleX.labels = evaluatedData.separatorLabels
  //           } else {
  //             parsedProperties.scaleX = { labels: evaluatedData.separatorLabels }
  //           }

  //         }
  //         parsedProperties.series = evaluatedData.series
  //         parsedProperties.title = {
  //           "text" : title
  //         }
  //         parsedProperties.subtitle = {
  //           "text" : description,
  //           "font-weight" : "normal"
  //         }
  //         setSeries(evaluatedData.series)
  //         setChartProperties(parsedProperties)
  //         console.log('seteamos show')
  //         setShowChart(true)
  //       }
  //     }
  //     dispatch(setGlobalLoader(false))
      
  //   } catch (error) {
      
  //   }
  // }

  if (loading) {
      return (
        <div className="analytics-loading">
          <LoadingOutlined />
        </div>
      )
  }
  const callback = () => {

  }
 
  return (
    <div className="">
      {oldAnalytics ? (
        <Tabs defaultActiveKey="0" onChange={callback}>
          <TabPane tab="Principal" key="0">
            <Charts processId={processId}/>
          </TabPane>
          {analyticsList.map((elem, i) => {
            return (
            <TabPane tab={elem.title} key={(i + 1).toString()}>
              <div className="analytics-list-panel">
                {elem.analytics.filter(a => a.active).map((analytic, j) => {
                  return(
                    <AnalyticChart key={j} companyId={companyId} processSlug={processSlug} analytic={analytic}/>
                  )
                })}
              </div>
            </TabPane>
            )
          })}
        </Tabs>
      ): analyticsList.length > 0 ? (
        <Tabs defaultActiveKey="0" onChange={callback}>
          {analyticsList.map((elem, i) => {
            return (
            <TabPane tab={elem.title} key={(i).toString()}>
              <div className="analytics-list-panel">
                {elem.analytics.filter(a => a.active).map((analytic, j) => {
                  return(
                    <AnalyticChart key={j} companyId={companyId} processSlug={processSlug} analytic={analytic}/>
                  )
                })}
              </div>
            </TabPane>
            )
          })}
        </Tabs>
      ): (
        <div className="no-analytics">Este proceso no tiene analíticas definidas</div>
      )}
    </div>
  )
}


export default Analytics