import { removeThounsandsSeparator } from '../utils/Helpers'

const initialState = {
  // get the current form
  currentForm: [],
  // get the current field list. This will be modified after every onChange Inputs
  currentFields: [],
  // active case number
  currentCaseNumber: '',
  currentAttachmentNumber: 1,
  attachmentList: [],
  setCaseCurrentTab: 1,
  tableWidgetList: []
}

const process = (state = initialState, action) => {
  switch(action.type) {
    case 'SET_CURRENT_FORM': {
      return {
        ...state,
        currentForm: action.currentForm,
        currentFields: action.currentForm.fields
      }
    }
    case 'UPDATE_CURRENT_FORM_FIELD': {
      const pivotCurrentFormFields = [...state.currentFields]
      const newCurrentFormFields = []
      pivotCurrentFormFields.forEach((elem) =>  {
        if(elem.name === action.fieldName) {
          elem.defaultValue = action.fieldValue
        }
        newCurrentFormFields.push(elem)
      })
      return {
        ...state,
        currentForm: newCurrentFormFields,
        currentFields: newCurrentFormFields.fields
      }
    }
    case 'SET_CURRENT_CASE_NUMBER': {
      return {
        ...state,
        currentCaseNumber: action.caseNumber
      }
    }
    case 'PLUS_ATTACHMENT_NUMBER': {
      return {
        ...state,
        currentAttachmentNumber: state.currentAttachmentNumber + 1
      }
    }
    case 'SET_ATTACHMENT_LIST': {
      return {
        ...state,
        attachmentList: state.attachmentList.concat(action.newAttachment)
      }
    }
    case 'SET_CASE_CURRENT_TAB': {
      return {
        ...state,
        caseCurrentTab: action.currentTabl
      }
    }
    case 'SET_TABLE_WIDGET_LIST': {
      return {
        ...state,
        tableWidgetList: action.tableWidgetList
      }
    }
    case 'ADD_ROW_TO_TABLE_WIDGET': {
      const tableList = [...state.tableWidgetList]
      const newTableList = []
      const tableBySlug = tableList.filter(tbs => {
        return tbs.slug === action.tableSlug 
      })[0]
      const newObj = {}
      action.columns.forEach(col => {
        newObj[col.slug] = ''
      })
      // newObj.key = uuid()
      // tableBySlug.data = tableBySlug && tableBySlug.data ? [...tableBySlug.data, newObj] : [newObj]
      const myNewTable = tableBySlug && tableBySlug.data ? [...tableBySlug.data, newObj] : [newObj]
      tableList.forEach(tl => {
        if(tl.slug === action.tableSlug) {
          tl.data = myNewTable
        }
        newTableList.push(tl)
      })
      console.log('newTableList', newTableList)
      return {
        ...state,
        tableWidgetList: newTableList
      }
    }
    case 'CHANGE_TABLE_FIELD': {
      const prevTableForms = [...state.tableWidgetList]
      const tableIndex = prevTableForms.findIndex(t => t.slug === action.tableSlug)
      prevTableForms[tableIndex].data[action.rowIndex][action.fieldName] = action.fieldValue
      const columns = action.tableInfo.columns
      // Remove thousand separator from numeric columns. Object passeed for evaluation.
      let row = {}
      for (let [key, value] of Object.entries(prevTableForms[tableIndex].data[action.rowIndex])) {
        const theCol = columns.find(thisCol => thisCol.slug === key)
        if (theCol) {
          row[key] = theCol.numberFormat ? removeThounsandsSeparator(value) : value
        } else {
          console.log(`Column ${key} not found in table ${action.tableSlug}.`)
          row[key] = value
        }
      }
      // Evaluate possible formulas.
      if (columns.find(c => c.expression && c.expression !== '' )) {
        for (let i = 0; i < columns.length; i++) {
          const column = columns[i];
          if (column.expression && column.expression !== '') {
            try {
              /*eslint-disable-next-line no-eval*/
              let result = eval(column.expression)
              prevTableForms[tableIndex].data[action.rowIndex][column.slug] = result
            } catch (error) {
              console.log(`Error evaluating formula ${column.expression} for column ${column.slug} in table ${action.tableSlug}.`)
              console.log(error)
              prevTableForms[tableIndex].data[action.rowIndex][column.slug] = '#ERROR'
            }
          }          
        }
      }
      return {
        ...state,
        tableWidgetList: prevTableForms
      }
    }
    case 'REMOVE_ROW_TO_TABLE_WIDGET': {
      const tableWidgetListPivot = [...state.tableWidgetList]
      // const tableIndex = tableWidgetListPivot.findIndex(t => t.slug === action.tableSlug)
      // tableWidgetListPivot[tableIndex].data.splice(action.rowIndex, 1)
      // console.log(JSON.stringify(tableWidgetListPivot))

      const newTableWidget = []
      tableWidgetListPivot.forEach(tw => {
        if(action.tableSlug === tw.slug) {
          const withoutRemoved = tw.data.filter((d, i) => i !== action.rowIndex)
          newTableWidget.push({...tw, data: withoutRemoved})
        } else {
          newTableWidget.push({...tw})
        }
      })
      return {
        ...state,
        tableWidgetList: newTableWidget
      }
    }
    default: {
      return {...state}
    }
  }
}

export default process