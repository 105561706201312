import React from 'react'
import { useDrop } from 'react-dnd';
import { ItemTypes } from '../../../utils/items';
import './TaskCard.scss'

const BoxTarget = props => {
  // el hook useDrop
  const[{isOver}, drop] = useDrop({
    accept: [ItemTypes.CARD, ItemTypes.INSIDE_CARD],
    collect: monitor => ({
      isOver: !!monitor.isOver()
    })
  })
  return (
    <div className={`boxer ${isOver ? 'boxer--isover' : ''}`} ref={drop}>
      {props.children}
    </div>
  )
}

export default BoxTarget