
export const initialState = {
  requestedServicesOpenedFilter: null,
  requestedServicesOpenedByUserFilter: null,
  requestedServicesClosedFilter: null,
  toAttendServicesOpenedFilter: null,
  toAttendServicesAssignedOpenedFilter: null,
  toAttendServicesClosedFilter: null,
  requestedServicesOpenedFilterText: '',
  requestedServicesOpenedByUserFilterText: '',
  requestedServicesClosedFilterText: '',
  toAttendServicesOpenedFilterText: '',
  toAttendServicesAssignedOpenedFilterText: '',
  toAttendServicesClosedFilterText: '',
}

const home = (state = initialState, action) => {
  switch(action.type) {
    case 'CHANGE_FILTER': {
      return {
        ...state,
        [action.filter]: action.value,
        [`${action.filter}Text`]:action.text
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}

export default home