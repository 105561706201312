import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Tabs from '../../../components/Library/Tabs/Tabs';
import './UpgradePlan.scss'
import { CheckOutlined, FileTextOutlined } from '@ant-design/icons';
import { setGlobalLoader } from '../../../actions/actions'
import { Collapse } from 'antd';
import { Checkbox, Modal } from 'antd';
import axiosBW from '../../../utils/Http'
import { Helmet } from 'react-helmet';


class UpgradePlan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: this.props.match && this.props.match.params.stage ? parseInt(this.props.match.params.stage) : 1,
      plans: [],
      paymentMethods: [],
      selectedPayment: '1',
      acceptToS: false,
      errorList: [],
      billingInfo: {},
      cardInfo: {},
      planSelected: '',
      planSelectedName: '',
      periodSelected:''

    }
  }

  async componentDidMount() {
    try {
      this.props.setGlobalLoader(true)
      if(!this.props.token) {
        Modal.error({
          title: 'Error',
          content: `Debe Iniciar Sesión para Continuar`,
        })
        setTimeout(() => {
          this.props.history.push('/ingresar?redirect_to=upgradeplan/1')
        }, 2000)
        return
      }
      let accountInfo = await axiosBW.get(`/companies/account-info/${this.props.companyId}`)
      accountInfo = accountInfo.data.payload
      let planInfo = await axiosBW.get(`/billing/plan-info/`)
      
      planInfo = planInfo.data.payload
      this.props.setGlobalLoader(false)

      const plans = []
      for (let i = 0; i < planInfo.length; i++) {
        const plan = planInfo[i];
        if (plan.planId !== 'free') {
          
          plan.moreInfoLink = '#'
          plan.isSelected = false
          plans.push(plan)
        }
      }
      this.setState({
        plans
      })
      this.setState({
        billingInfo: accountInfo,
        cardInfo: accountInfo.creditCard ? accountInfo.creditCard : {},
        paymentMethods: [
          {
            id: 1,
            name: 'Tarjeta de Crédito',
            slug: 'creditCard',
            description: accountInfo.creditCard ? (
              <div className="card__info">
                {/* <span><img src="/img/card_example.png" alt="visa" /> {accountInfo.creditCard.creditCardType} </span> */}
                <span> xxxx-xxxx-xxxx-{accountInfo.creditCard.last4CardDigits} </span>
              </div>
            ) : (
              <div>Sin Tarjeta Asociada.</div>
            ),
            selected: true
          },
          // {
          //   id: 2,
          //   name: 'Transferencia',
          //   slug: 'transfer',
          //   description: <div>Pago a través de transferencia.</div>,
          //   selected: false
          // },
          // {
          //   id: 3,
          //   name: 'Orden de Compra',
          //   slug: 'buyOrder',
          //   description: <div>Pago sujeto a previa confirmación.</div>,
          //   selected: false
          // },
        ],
        hasCreditCard: accountInfo.creditCard ? true : false
      })
    } catch (error) {
      console.log(error);
    }
  }

  updatePlan(plan, period) {
    console.log('plan', plan)
    this.props.history.push(`/upgradeplan/2/${plan.planId}/${period}`)
    this.setState({
      currentStep: 2,
      planSelected: plan.planId,
      planSelectedName: plan.name,
      periodSelected: period
    })
    console.log(this.props.history);
  }

  firstTab() {
    return (
      <div className="phase1">
          <div className="plan_selector__wrapper">
          { 
            this.state.plans.map((e, i) => {
              return (
                <div className="plan_selector__element_wrapper" key={`plansel-${i}`}>
                  <div className="plan_selector_element">
                    <header className="plan_selector__header">
                      <div className="plan_selector__title">
                        {e.name}
                      </div>
                      <div className="plan_selector__subtitle">
                        USD ${e.price.month}/mes
                      </div>
                    </header>
                    <div className="plan_selector__features">
                      {
                        e.features.map((el, index) => {
                          return (
                            <div  key={`feat-${i}-${index}`} className="plan_selector__feature">
                              <CheckOutlined className="icon_purple" /> {el}
                            </div>
                          );
                        })
                      }
                    </div>
                    <footer className="plan_selector__footer">
                      <div className="plan_selector__button_wrapper">
                        <button className="plan_selector__button" onClick={evt => this.updatePlan(e, 'monthly')}> Actualizar </button>
                      </div>
                    </footer>
                    
                  </div>
                </div>
              );
            })
          }
          </div>
          
      </div>
    );
  }

  secondTab() {
    return (
      <div className="phase2">
          <div className="plan_selector__wrapper">
          { 
            this.state.plans.map((e, i) => {
              return (
                <div className="plan_selector__element_wrapper" key={`plansel-${i}`}>
                  <div className="plan_selector_element">
                    <header className="plan_selector__header">
                      <div className="plan_selector__title">
                        {e.name}
                      </div>
                      <div className="plan_selector__subtitle">
                        USD ${e.price.year}/año
                      </div>
                    </header>
                    <div className="plan_selector__features">
                      {
                        e.features.map((el, index) => {
                          return (
                            <div key={`feat-${i}-${index}`} className="plan_selector__feature">
                              <CheckOutlined className="icon_purple" /> {el}
                            </div>
                          );
                        })
                      }
                    </div>
                    <footer className="plan_selector__footer">
                      <div className="plan_selector__button_wrapper">
                        <button className="plan_selector__button" onClick={evt => this.updatePlan(e, 'yearly')}> Actualizar </button>
                      </div>
                    </footer>
                    
                  </div>
                </div>
              );
            })
          }
          </div>
          
      </div>
    );
  }

  tabsServices() {
    return [
      {
        title: `Planes Mensuales`,
        content: this.firstTab()
      },
      {
        title: 'Planes Anuales',
        content: this.secondTab()
      }
    ]
  }

  changePaymentMethod(payment) {
    console.log('changePaymentMethod')
    let newPaymentArray = []
    let selected = "1"
    this.state.paymentMethods.forEach(elem => {
      if(payment === elem.id) {
        newPaymentArray.push({
        ...elem, selected: true
        })
        selected = elem.id
      } else {
        newPaymentArray.push({
          ...elem, selected: false
        })
      }
    })
    this.setState({
      paymentMethods: newPaymentArray,
      selectedPayment: selected.toString()
    })
  }

  changeToS(value) {
    this.setState({
      acceptToS: value.target.checked
    })
  }

  confirmTransfer() {

  }
  confirmOrder() {

  }
  async payNow() {
    let errorList = []
    if(!this.state.acceptToS) {
      errorList.push({
        code: '101',
        title: 'Términos de Servicio',
        content: 'Para poder proceder con la compra, debe acpetar los términos y condiciones del servicio'
      })
    }
    if(!this.state.hasCreditCard) {
      errorList.push({
        code: '101',
        title: 'Asociar Tarjeta de Crédito',
        content: <div>Para poder proceder con la compra, debe ingresar una tarjeta de crédito válida. Puede agregar una tarjéta de crédito haciendo <Link to="/micuenta"> Click Aquí </Link></div>
      })
    }

    this.setState({
      errorList
    })
    if (errorList.length === 0) {
      try {
        console.log('billing',this.state.billingInfo);
        const postObject = {
          billingData: {
            legalName: this.state.billingInfo.legalName,
            businessActivity: this.state.billingInfo.businessActivity,
            taxId: this.state.billingInfo.taxId,
            addressLine: this.state.billingInfo.addressLine,
            state: this.state.billingInfo.state,
            city: this.state.billingInfo.city,
            commune: this.state.billingInfo.commune,
            country: this.state.billingInfo.country,
            phoneNumber: this.state.billingInfo.phoneNumber,
            billingContactUser: this.state.billingInfo.billingContactUser,
            contactEmail: this.state.billingInfo.contactEmail,
          },
          paymentMethod: this.state.selectedPayment === '1' ? 'creditCard' : this.state.selectedPayment === '2' ? 'transfer' : 'buyOrder',
          modality: this.state.periodSelected,
          planId: this.state.planSelected
        }
        console.log({postObject});
        this.props.setGlobalLoader(true)
        const payment = await axiosBW.post('/billing/upgrade-plan', postObject)
        this.props.setGlobalLoader(false)
        if (payment) {
          Modal.success({
            content: "¡El pago ha sido exitoso!",
            onOk: (e) => {
              e();
              this.props.history.push(`/`)
              window.location.reload(false);
            }
          });
          console.log('pago',payment);
        }
        
      } catch (error) {
        this.props.setGlobalLoader(false)
        console.log(error);
        Modal.error({
          content: "¡Ha ocurrido un error con su pago!"
        });
      }
    }
  }
  render() {
    console.log('momo', this.state.billingInfo)
    return (
      <div className="upplan__wrapper">
        <section className="upplan">
          <div className="upplan__breadcrumbs">
            <div className={`upplan__breadcrumbs_element ${this.state.currentStep === 1 ? 'upplan__breadcrumbs_selected' : ''}`}>
              <div className="upplan__breadcrumbs_num">
                1
              </div>
              <div className="upplan__breadcrumbs_desc">
                Selecciona Plan
              </div>
            </div>
            <div className={`upplan__breadcrumbs_element ${this.state.currentStep === 2 ? 'upplan__breadcrumbs_selected' : ''}`}>
              <div className="upplan__breadcrumbs_num">
                2
              </div>
              <div className="upplan__breadcrumbs_desc">
                Datos de Facturación
              </div>
            </div>
            <div className={`upplan__breadcrumbs_element ${this.state.currentStep === 3 ? 'upplan__breadcrumbs_selected' : ''}`}>            
              <div className="upplan__breadcrumbs_num">
                3
              </div>
              <div className="upplan__breadcrumbs_desc">
                Pago
              </div>
            </div>
          </div>
          {
            this.state.currentStep === 1 ? (
              <div className="table_of_values">
                <Tabs key={this.state.currentForm} tabs={this.tabsServices()} isMobile={this.props.isMobile} /> 
              </div>
            ) : null
          }
          {
            this.state.currentStep === 2 ? (
              <div className="table_update">
                <Collapse className="table_update__collapse" defaultActiveKey={['1', '2']}>
                  <Collapse.Panel header="Datos de Facturación" key="1">
                    {/* <FormBilling hideButton={true} key={this.state.billingInfo} currentData={this.state.billingInfo} /> */}
                    <section className="myacc__billing_info">
                      <div className="myacc__subtitle">
                        <FileTextOutlined />
                        <h2>
                          Datos de Facturación
                        </h2>
                      </div>
                      <form className="billing_info__form">
                        <div className="billing_info__element">
                          <div className="flow_form__label">
                            Razón Social o Nombre Legal de la Empresa
                          </div>
                          <div className="flow_form__field">
                            <input 
                            className="flow_form__default flow_form__input_text"
                            type="text"
                            value={this.state.billingInfo.legalName}
                            disabled={true}
                            />
                          </div>
                        </div>
                        <div className="billing_info__element">
                          <div className="flow_form__label">
                            RUT o Identificación de Impuestos de la empresa
                          </div>
                          <div className="flow_form__field">
                            <input 
                            className={`flow_form__default flow_form__input_text`}
                            type="text" 
                            value={this.state.billingInfo.taxId}
                            disabled={true}
                            />
                          </div>
                        </div>
                        <div className="billing_info__element">
                          <div className="flow_form__label">
                            Giro
                          </div>
                          <div className="flow_form__field">
                            <input 
                            className={`flow_form__default flow_form__input_text`}
                            type="text" 
                            value={this.state.billingInfo.businessActivity}
                            disabled={true}
                            />
                          </div>
                        </div>
                        <div className="billing_info__element">
                          <div className="flow_form__label">
                            Dirección
                          </div>
                          <div className="flow_form__field">
                            <input 
                            className="flow_form__default flow_form__input_text"
                            type="text" 
                            value={this.state.billingInfo.addressLine}
                            disabled={true}
                            />
                          </div>
                        </div>
                        <div className="billing_info__element">
                          <div className="flow_form__label">
                            País
                          </div>
                          <div className="flow_form__field">
                            <div className="flow_form__field">
                              <input 
                              className="flow_form__default flow_form__input_text"
                              type="text" 
                              value={this.state.billingInfo.country}
                              disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="billing_info__element">
                          <div className="flow_form__label">
                            Región
                          </div>
                          <div className="flow_form__field">
                            <input 
                            className="flow_form__default flow_form__input_text"
                            type="text" 
                            value={this.state.billingInfo.state}
                            disabled={true}
                            />
                          </div>
                        </div>
                        <div className="billing_info__element">
                          <div className="flow_form__label">
                            Ciudad
                          </div>
                          <div className="flow_form__field">
                            <input 
                            className="flow_form__default flow_form__input_text"
                            type="text" 
                            value={this.state.billingInfo.city}
                            disabled={true}
                            />
                          </div>
                        </div>
                        <div className="billing_info__element">
                          <div className="flow_form__label">
                            Comuna
                          </div>
                          <div className="flow_form__field">
                            <input 
                            className="flow_form__default flow_form__input_text"
                            type="text" 
                            value={this.state.billingInfo.commune}
                            disabled={true}
                            />
                          </div>
                        </div>
                        <div className="billing_info__element">
                          <div className="flow_form__label">
                            Nombre contacto
                          </div>
                          <div className="flow_form__field">
                            <input 
                            className={`flow_form__default flow_form__input_text`}
                            type="text" 
                            value={this.state.billingInfo.billingContactUser} 
                            disabled={true}
                            />
                          </div>
                        </div>
                        <div className="billing_info__element">
                          <div className="flow_form__label">
                            Teléfono contacto
                          </div>
                          <div className="flow_form__field">
                            <input 
                            className="flow_form__default flow_form__input_text"
                            type="text" 
                            value={this.state.billingInfo.phoneNumber}
                            disabled={true}
                            />
                          </div>
                        </div>
                        <div className="billing_info__element">
                          <div className="flow_form__label">
                            Correo Electrónico contacto
                          </div>
                          <div className="flow_form__field">
                            <input 
                            className="flow_form__default flow_form__input_text"
                            type="text" 
                            value={this.state.billingInfo.contactEmail}
                            disabled={true}
                            />
                          </div>
                        </div>
                        <>
                          <div className="flow_form__advisor">
                            Si desea cambiar sus datos de facturación, puede hacerlo <Link to="/micuenta">  aquí </Link>.
                          </div>
                        </>
                      </form>
                    </section>
                  </Collapse.Panel>
                  <Collapse.Panel header="Seleccionar Método de Pago" key="2">
                    <div className="payment__wrapper">
                      <div className="payment">
                      {
                        this.state.paymentMethods.map((payment, index) => {
                          return (
                            <div className={`payment__element ${payment.selected ? 'payment__element--selected' : ''}`} key={`payment-${payment.id}`} onClick={e => this.changePaymentMethod(payment.id)}>
                              <div className="payment__element_left">
                                <div className={`payment__radioCheck ${payment.selected ? 'payment__radioCheck--checked' : ''}`}></div>
                              </div>
                              <div className="payment__element_right">
                                <div className="payment__element_title">
                                  {payment.name}
                                </div>
                                <div className="payment__element_desc">
                                  {payment.description}
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }

                      </div>
                      <div className="payment__additional">
                        {
                          this.state.selectedPayment === '1' ? (
                            <div className="payment__additional_element elem--credit_card">
                              <div className="elem--credit_card__checkbox">
                                <Checkbox onChange={val => this.changeToS(val)} checked={this.state.acceptToS}>Acepto los términos y condiciones. <a href="https://www.blizwork.com/terminos-y-condiciones/" target="_blank" rel="noopener noreferrer"  >Leer aquí</a>.</Checkbox>
                              </div>
                              <div className="elem--credit_card__button_wrapper">
                                <button className="elem--credit_card__button" onClick={evt => this.payNow(evt)}> Pagar Ahora </button>
                              </div>
                            </div>
                          ) : null
                        }
                        {
                          this.state.selectedPayment === '2' ? (
                            <div className="payment__additional_element elem--transferencia">
                              <div className="elem--transferencia__title">
                                <h2> Transferencia </h2>
                              </div>
                              
                              <div className="elem--transferencia__text">
                                <p>Por favor hacer transferencia con los siguientes datos: </p>
                                <p>Transferencia desde fuera de Chile: Código SWIFT XXXXXXXXXX</p>
                                <p>
                                  Cuenta Corriente Nº 207447816 <br />
                                  RUT 76.608.864-3 <br />
                                  Ready2Rent SpA <br />
                                  Banco Itaú
                                </p>
                                <p>Por favor enviar su comprobante de transferencia a <a href="mailto:ventas@blizwork.com">ventas@blizwork.com</a> </p>
                                <p>Recuerde que los impuestos son de su cargo, por lo que le solicitamos considerarlos al momento de calcular el monto de la transferencia.</p>
                                <p>
                                  <strong>
                                    Su suscripción se activará al momento de aprobar su orden.
                                  </strong>
                                </p>
                              </div>
                              <div className="elem--credit_card__button_wrapper">
                                <button className="elem--credit_card__button" onClick={evt => this.confirmTransfer(evt)}> Confirmar </button>
                              </div>
                            </div>
                          ) : null
                        }
                        {
                          this.state.selectedPayment === '3' ? (
                            <div className="payment__additional_element elem--orden_compra">
                              <div className="elem--orden_compra__title">
                                <h2> Orden de Compra </h2>
                              </div>
                              
                              <div className="elem--transferencia__text">
                                <p>Por favor enviar su orden de compra a <a href="mailto:ventas@blizwork.com">ventas@blizwork.com</a> </p>
                                <p>Recuerde que los impuestos son de su cargo, por lo que le solicitamos considerarlos al momento de calcular el monto de la Orden.</p>
                                <p>
                                  <strong>
                                    Las Órdenes de Compra están sujetas a evaluación. Su suscripción se activará al momento de aprobar su orden.
                                  </strong>
                                </p>
                              </div>
                              <div className="elem--credit_card__button_wrapper">
                                <button className="elem--credit_card__button" onClick={evt => this.confirmOrder(evt)}> Confirmar </button>
                              </div>
                            </div>
                          ) : null
                        }
                      </div>
                      {
                        this.state.errorList.length > 0 ? (
                          <div className="payment__errors">
                            {
                              this.state.errorList.map((err, ind) => {
                                return (
                                  <div key={`err-${ind}`} className="payment__error">
                                    <p>Código: {err.code} - {err.title}</p>
                                    <p>{err.content}</p>
                                  </div>
                                )
                              })
                            }
                          </div>
                        ) : null
                      }
                      

                    </div>
                  </Collapse.Panel>
                </Collapse>
              </div>
            ) : null
          }
          
        </section>
        <Helmet>
          <title> Actualizar Plan - Blizwork </title>
        </Helmet>
      </div>
    )
  } 
}

const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState))
})
const mstp = state => ({
  companyId: state.user.companyId,
  token: state.user.token
})

export default connect(mstp, mapDispatchToProps)(UpgradePlan)