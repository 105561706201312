import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Modal from '../../../../components/Library/Modal/Modal';
import { Table } from 'antd';
// import Table from '../../../../components/Library/Table/Table'
import cf from '../../../../config/APIConfig'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

import './Reports.scss'
import { setGlobalLoader } from '../../../../actions/actions';
import Axios from 'axios';
import axiosBW from '../../../../utils/Http';
import { FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import moment from 'moment-timezone'
import xlsx from 'xlsx'
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

pdfMake.vfs = pdfFonts.pdfMake.vfs

class Reports extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterModalVisible: true,
      currentData: {},
      tableCols: [],
      cleanTableCols: [],
      tableData: [],
      filters: [],
      filterErrors: [],
    }
    this.buscarRef = React.createRef()
  }
  async componentDidMount() { 
    this.props.setGlobalLoader(true)   
    let resReport
    try { 
      resReport = await Axios.get(`reports/${this.props.match.params.processSlug}/${this.props.userId}` , {
        baseURL: cf.BASE_URL.analytics,
        timeout: 30000,
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${this.props.token}`
        }
      })
    } catch (ex) {
      console.log('error reports', ex)
    }
    if(!resReport || !resReport.data) {
      Modal.error({
        title: 'Error',
        content: 'Error al cargar data de reportes'
      })
      return
    }
    if(resReport.data.payload) {
      let currentCols = []
      let cleanTableCols = []
      let currentReport = resReport.data.payload.filter(e => e._id === this.props.match.params.reportId)
      // setColumns
      console.log('columni', currentReport[0])
      currentReport[0].colums.forEach(col => {
        currentCols.push({
          key: col.key,
          title: col.label,
          dataIndex: col.key.split('.')
        })
        cleanTableCols.push({
          key: col.key.split('.').length === 2 ? col.key.split('.')[1] : col.key,
          title: col.label
        })
      })
      console.log('currentData', currentReport[0])
      console.log('currentCols', currentCols)
      this.setState({
        currentData: currentReport[0],
        filters: currentReport[0].filters,
        tableCols: currentCols,
        cleanTableCols: cleanTableCols
      })
      if(currentReport[0].filters.length === 0) {
        this.buscarRef.current.click()
        this.setState({
          filterModalVisible: false
        })
      }
      this.props.setGlobalLoader(false)
    }
    
  }
  onCancel() {
    return
  }
  async onOk() {
    this.props.setGlobalLoader(true)
    let errors = []
    this.state.filters.forEach(elm => {
      if(elm.mandatory && elm.value.length <= 0) {
        errors.push({
          field: elm.slug,
          message: `${elm.label} no puede quedar vacío`
        })
      }
    })
    this.setState({
      filterModalVisible: false
    })
    if(errors.length === 0) {
      // Consume API
      if(this.state.currentData && this.state.currentData.dataSource && this.state.currentData.dataSource !== '') {
        try {
          const dataSourceReport = await Axios.post(this.state.currentData.dataSource, {
            processSlug: this.state.currentData.processSlug,
            filters: this.state.filters,
            query:  this.state.currentData.query || null
          },  {
            baseURL: cf.BASE_URL.analytics,
            timeout: 120000,
            headers: {
              'Content-Type': 'application/json',
              'Authorization' : `Bearer ${this.props.token}`
            }
          })
          const currentTableData = this.generateData(dataSourceReport.data.payload, this.state.tableCols)
          console.log(currentTableData)
          this.setState({
            tableData: currentTableData
          })
          toast.success('Se ha cargado el reporte correctamente')
        } catch (ex) {
          console.log('error dataSourceReport', ex)
          toast.error('Error al cargar data de reportes')
        }
        
      }
      else {
        try {
          const resRep = await axiosBW.post(`reports/evaluate/${this.props.companyId}/${this.state.currentData.processSlug}`, {
            filters: this.state.filters,
            query: this.state.currentData.query
          }, { timeout: 300000 })
          const currentTableData = this.generateData(resRep.data.payload, this.state.tableCols)
          console.log(currentTableData)
          this.setState({
            tableData: currentTableData
          })
          toast.success('Se ha cargado el reporte correctamente')
        } catch (error) {
          console.log('error dataSourceReport', error)
          toast.error('Error al cargar data de reportes')
        }
      }
    } else {
      this.setState({
        filterErrors: errors
      })
    }
    this.props.setGlobalLoader(false)
  }

  changeFilter(evt, element) {
    //get filter element by slug
    let current = this.state.filters.filter(elm => elm.slug === element.slug)
    let newArrFilters = []
    if(current.length > 0) {
      this.state.filters.forEach(filt => {
        if(filt.slug !== element.slug) {
          newArrFilters.push(filt)
        } else {
          newArrFilters.push({
            ...filt,
            value: evt.target.value
          })
        } 
      })
      this.setState({
        filters: newArrFilters
      })
    }
    
  }
  
  generateData(currentData, tableCols) {
    let newData = []
    if (currentData) {
      newData = [...currentData]
      for (let i = 0; i < newData.length; i++) {
        const row = newData[i];
        for (const prop in row) {
          if(prop.indexOf('Time') !== -1) row[prop] = moment(row[prop]).tz(this.props.timeZone).format('YYYY-MM-DD HH:mm:ss')
        }
      }
      // currentData.forEach(cd => {
      //   let newx = {}
      //   tableCols.forEach(tc => {
      //     let mySplit = tc.key.split('.')
      //     let currento = cd.fieldValues.filter(curr => curr.slug === mySplit[1])
      //     if(mySplit.length === 1) {
      //       newx[tc.key] = cd[tc.key]
      //     }
      //     else if(mySplit.length === 2) {
      //       if(currento.length > 0) {
      //         newx[mySplit[1]] = currento[0].defaultValue
      //       } else {
      //         newx[mySplit[1]] = ""
      //       }
      //     }
      //     else if (mySplit.length > 2) {
      //       if(currento.length > 0) {
      //         if(currento[0].option && currento[0].option.value) {
      //           newx[tc.key] = currento[0].option.value
      //         }
              
      //       } 
            
      //     }
          
      //   })
      //   // let formatData = {...newx, createTimeStamp: newx.createTimeStamp ? moment(newx.createTimeStamp).tz(this.props.timeZone).format('YYYY-MM-DD HH:mm:ss') : undefined}
      //   newData.push(newx)
      //   for (const prop in newx) {
      //     if(prop.indexOf('Time') !== -1) newx[prop] = moment(newx[prop]).tz(this.props.timeZone).format('YYYY-MM-DD HH:mm:ss')
      //   }
      //   // console.log('el formatData', formatData)
      // })
    }
    return newData
  }

  formattingData() {
    let formattedTable = []
    // SET COLUMNS
    let myNewArr = []
    this.state.cleanTableCols.forEach(f => {
        myNewArr.push(f.title)
    })
    formattedTable.push(myNewArr)
    // get all column names
    // let objects = Object.values(this.state.cleanTableCols)
    let objects = Object.values(this.state.tableCols)
    // SET DATA FOR TABLE
    this.state.tableData.forEach(cd => {
        let myObject = []
        objects.forEach(e => {
          try {
            const value = e.dataIndex.reduce((a,v) => a[v], cd)
            myObject.push(value || '')
          } catch (error) {
            myObject.push('')
          }
        })
        formattedTable.push(myObject)
    })
    console.log('formattedTable', formattedTable)
    return formattedTable
}

  generateExcel() {
    console.log('waaat')
    var ws_name = this.state.currentData.name

    /* make worksheet */
    let paaa = this.formattingData()
    var ws = xlsx.utils.aoa_to_sheet(paaa)
    // create a new workbook

    var wb = {
        SheetNames: [],
        Sheets: {},
        Props: {
            Title: 'Reportes blizwork',
            Subject: 'ANDES-CS',
            Author: 'bw',
            CreatedDate: new Date(),
        },
    }

    /* Add the worksheet to the workbook */
    xlsx.utils.book_append_sheet(wb, ws, ws_name)
    let currDate = moment().tz(this.props.timeZone).format('YYYY-DD-MM HH:mm:ss')

    xlsx.writeFile(wb, `Report_Clasificados_${currDate}.xlsx`)
  }
  generateDataPDF() {
      let formattedTable = []
      // SET COLUMNS
      let myNewArr = []
      this.state.cleanTableCols.forEach(f => {
          myNewArr.push({
              text: f.title,
              style: 'filledHeader',
          })
      })
      formattedTable.push(myNewArr)
      // get all column names
      // let objects = Object.values(this.state.cleanTableCols)
      let objects = Object.values(this.state.tableCols)
      // SET DATA FOR TABLE
      this.state.tableData.forEach(cd => {
          let myObject = []
          objects.forEach(e => {
            try {
              const value = e.dataIndex.reduce((a,v) => a[v], cd)
              myObject.push({
                text: value || '',
                style: 'col01',
              })
            } catch (error) {
              myObject.push({
                  text: '',
                  style: 'col01',
              })
            }
          })
          formattedTable.push(myObject)
      })
      // SET TITLE FOR REPORT
      let reportTitle = {
          text: this.state.currentData.name,
          style: 'header',
      }
      // SET PRETITLE
      let preTitle = {
          text: this.state.currentData.name,
          style: 'preheader',
      }
      /*'If you specify both width and height - image will be stretched',
  {
    image: 'logoDr.png',
    width: 150,
    height: 150,
  },*/
      this.setState(
          {
              dd: {
                  content: [
                      /*{
                          alignment: 'justify',
                          columns: [
                              {
                                  text: {
                                      image: 'logoDr.png',
                                      width: 150,
                                      height: 150,
                                  },
                                  width: 150,
                              },
                              {
                                  text: 'Col 02',
                                  width: '*',
                              },
                          ],
              },*/
                      reportTitle,
                      {
                          table: {
                              body: formattedTable,
                          },
                      },
                  ],
                  styles: {
                      filledHeader: {
                          bold: true,
                          fontSize: 12,
                          color: 'white',
                          fillColor: '#004f9e',
                          alignment: 'left',
                      },
                      col01: {
                          bold: false,
                          fontSize: 12,
                          color: '#222222',
                          fillColor: '#eeeeee',
                          alignment: 'left',
                      },
                      header: {
                          bold: true,
                          fontSize: 24,
                          color: 'black',
                          alignment: 'center',
                          margin: [0, 0, 0, 25],
                          border: [false, false, false, true],
                      },
                      preheader: {
                          bold: false,
                          fontSize: 18,
                          alignment: 'center',
                          margin: [0, 0, 0, 5],
                      },
                  },
              },
          },
          () => {
              let currDate = moment().tz(this.props.timeZone).format('YYYY-DD-MM HH:mm:ss')
              pdfMake
                  .createPdf(this.state.dd)
                  .download(`Report_${currDate}.pdf`)
          }
      )
  }
  render() {
    return (
      <div className="report__wrapper">
        <Modal title="Filtrar Búsqueda" isVisible={this.state.filterModalVisible} onCancel={this.onCancel} onOk={this.onOk}>
          <div className="">
            <div className="report__forms">
              {
                this.state.filters.map((e, i) => {
                  return (
                    <div key={`filt-${i}`} className="report__form_element">
                      <label><span>{e.label}</span></label>
                      <input className="guest__form_input" onChange={evt => this.changeFilter(evt, e)} type="text" value={e.value} />
                    </div>
                  )
                })
              }
                
              
            </div>
            <div className="report__form_button_wrapper">
              <button onClick={e => this.onOk()} ref={this.buscarRef} className="report__form_button">Buscar</button>
            </div>
          </div>
        </Modal>
        <div className="report_list__wrapperx">
          <div className="report_list__exports">
              <div className="report_list__export" onClick={e => this.generateDataPDF()}>
                <FilePdfOutlined />
              </div>
              <div className="report_list__export" onClick={e => this.generateExcel()}>
                <FileExcelOutlined />
              </div>
            </div>
          <div className="report_list">
            <div className="report_list__title">
              Reporte de {this.state.currentData.name}
            </div>
            
            <div className="report_list__table_wrapper">
              {/* <Table enablePagination={true} paginatenum={20} key={this.state.tableCols} columns={this.state.cleanTableCols} data={this.state.tableData} /> */}
              <Table scroll={{x: true}} dataSource={this.state.tableData} columns={this.state.tableCols} pagination={{showSizeChanger: false}}/>;
            </div>
          </div>
        </div>
        <Helmet>
          <title> Reportes - Blizwork</title>
        </Helmet>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState))
})
const mapStateToProps = (state) => ({
  userId: state.user.userId,
  companyId: state.user.companyId,
  timeZone: state.user.timeZone,
  token: state.user.token
})

export default connect(mapStateToProps, mapDispatchToProps)(Reports)