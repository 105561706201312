import {useDraggable} from '@dnd-kit/core';
import React from 'react';

export default function Draggable({children, id}, props) {
  const {attributes, listeners, setNodeRef, isDragging} = useDraggable({
    id,
  });
  const style = {
    opacity: isDragging ? 0.5 : undefined,
  };
  // console.log({attributes})
  // console.log(listeners.onPointerDown)
  // listeners.onPointerDown = (e, i) => {
  //     console.log(e)
  // }
  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes} {...props}>
      {children}
    </div>
  );
}
