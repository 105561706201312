import React, { Component } from 'react'
import axios from 'axios';
import cf from '../../config/APIConfig'
import { connect } from 'react-redux'
import { setGlobalLoader } from '../../actions/actions'


class TestAsync extends Component {

  async componentDidMount() {
    this.props.setGlobalLoader(false)
    try {
      const res = await axios.get(`/process/next-exec/5e61160ea91d75065a88db22`, {
        baseURL: cf.BASE_URL.url,
        timeout: 30000,
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${localStorage.getItem('token')}`
        }
      })

      console.log('llamada 01', res.data)
    } catch(ex) {
      if(ex.response && ex.response.data) {
        console.log('response', ex.response.status)
        console.log('estatus', ex.response.data.status)
        console.log('message', ex.response.data.message)
      }
    }
    
    // PARTE 2
    let res2;
    try {
        res2 = await axios.get(`/case/getCompanyByProcess/5e04ed0ba9511c00245bb774`, {
        baseURL: cf.BASE_URL.url,
        timeout: 30000,
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${localStorage.getItem('token')}`
        }
      })

      console.log('llamada 02', res2.data)
    } catch(ex) {
      if(ex.response && ex.response.data) {
        console.log('response', ex.response.status)
        console.log('estatus', ex.response.data.status)
        console.log('message', ex.response.data.message)
      }
    }

    if(res2.data) {
      console.log('soy la respuestita', res2.data.payload)
    }
    

    let hola = "asd"
    try {
      console.log('objec', parseInt(hola))
    } catch(ex) {
      console.log('wut', ex)
    }
    
  }


  render() {
    return (
      <div>
        <h1>Hola</h1>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  showGlobalLoader: state.navigation.showGlobalLoader
})

const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (showLoader) => dispatch(setGlobalLoader(showLoader))
})

export default connect(mapStateToProps, mapDispatchToProps)(TestAsync)