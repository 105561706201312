/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import axiosBW from '../../../utils/Http';
import { Table, Row, Col, Modal, Input } from 'antd';
import Editor from "react-simple-code-editor";
import Prism from 'prismjs';
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-coy.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setGlobalLoader } from '../../../actions/actions'
import { toast } from 'react-toastify';
import { CaretUpOutlined,CaretDownOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';


import 'react-toastify/dist/ReactToastify.css';
import './ReportsDesigner.scss'



const ReportsDesigner = (props) => {

  const dispatch = useDispatch();
  
  const [columns, setColumns] = useState([
    {
      label: '',
      key: ''
    },
    {
      label: '',
      key: ''
    }
  ]);
  const [code, setCode] = useState(`
  []
  `);
  const [showTable, setShowTable] = useState(false);
  const [evaluateColumns, setEvaluateColumns] = useState([]);
  const [evaluateData, setEvaluateData] = useState([]);
  const [filters, setFilters] = useState([{label: '', slug: '', type: 'text', value:''}]);
  const [evaluateFilters, setEvaluateFilters] = useState([]);
  const [registers, setRegisters] = useState([]);
  const [title, setTitle] = useState('');
  const [reportId, setReportId] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    console.log('va el hooks-log');
    if (!props.location.state){
      props.history.push('/')
      return;
    } 

    if(!props.match.params.flag) {
      const analyticData = props.location.state.report
      console.log(analyticData.colums)
      if (analyticData.query && analyticData.query !== '') setCode(analyticData.query)
      if (analyticData.name && analyticData.name !== '') setTitle(analyticData.name)
      if (analyticData.colums && analyticData.colums.length > 0) setColumns(analyticData.colums)
      if (analyticData.filters && analyticData.filters.length > 0) setFilters(analyticData.filters)
      if (analyticData._id && analyticData._id !== '') setReportId(analyticData._id)
    }
    dispatch(setGlobalLoader(false))
    
  }, []);

  const saveReport = async () => {
    try {
      if(!title || title === '') throw new Error('No se ha guardado: Titulo faltante')
      dispatch(setGlobalLoader(true))
      let resReports
      if(!reportId) {
        
        resReports = await axiosBW.post(`reports/create-report/`, {
          companyId: props.location.state.companyId,
          processSlug: props.location.state.processSlug,
          query:code,
          columns,
          filters,
          name: title
        })
      } else {
        resReports = await axiosBW.post(`reports/update-report/`, {
          _id: reportId,
          companyId: props.location.state.companyId,
          processSlug: props.location.state.processSlug,
          query:code,
          columns,
          filters,
          name: title
        })
      }
      dispatch(setGlobalLoader(false))
      
      if(resReports && resReports.data) {

        if(resReports.data.status === 0) {
          setReportId(resReports.data.payload)
          toast.success('Se ha guardado el reporte correctamente')

        }
      }
      
    } catch (error) {
      console.log(error);
      toast.error(error.message)
      dispatch(setGlobalLoader(false))
    }
  }
  const moveColumn = (index, to) => {
    let theColumns = [...columns]
    const orderArray =  (arr, old_index, new_index) => {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; 
    }
    switch (to) {
      case 'up':
        if (index === 0) {
          return
        }
        theColumns = orderArray(theColumns, index, index-1)
        break;
      case 'down':
        if (index === columns.length - 1) {
          return
        }
        theColumns = orderArray(theColumns, index, index+1)
        break;
      default:
        return
    }
    setColumns(theColumns)

  }
  const isJson = (text) => {
    try {
      JSON.parse(text)
      return true
    } catch (error) {
      const msg = error.message
      const regs = [...registers]
      regs.push(msg)
      setRegisters(regs)
      return false
    }
  }
  const evaluateReport = async() => {
    try {
      if (!isJson(code)) {
        return
      }
      if (filters.length > 0) {
        setEvaluateFilters([...filters])
        setShowFilterModal(true)
      } else {
        await callReport()
      }
      
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }
  
  const callReport = async() => {
    try {
      setShowFilterModal(false)
      dispatch(setGlobalLoader(true))
      const resRep = await axiosBW.post(`reports/evaluate/${props.location.state.companyId}/${props.location.state.processSlug}`, {
        filters: evaluateFilters,
        query: code
      })
      console.log(resRep)
      setEvaluateColumns(columns.map((column, i) => {
        return {
          title: column.label,
          key: column.key,
          dataIndex: column.key.split('.'),
        }
      }))
      setEvaluateData(resRep.data.payload)
      setShowTable(true)
      const regs = [...registers]
      regs.push('OK')
      setRegisters(regs)
      dispatch(setGlobalLoader(false))
    } catch (error) {
      setShowFilterModal(false)
      let msg
      if (error.response && error.response.data && error.response.data.message) {
        msg = error.response.data.message
      } else {
        msg = error.message
      }
      const regs = [...registers]
      regs.push(msg)
      setRegisters(regs)
      dispatch(setGlobalLoader(false))
    }
  }

  if (loading) {
      return 'Loading...'
  }
 
  return (
    <div className="analytics-list-wrapper">
      <Row className="analytics-designer-header">
        <div className="analytics-designer-title">
          <div className="workflow__back_arrow" onClick={
            () => {
              props.history.goBack()
            }
          }
          >
            <FontAwesomeIcon icon="arrow-left" />
          </div>
          Creador de reportes
        </div>
        <div>
          <button className="new-section-button" onClick={ () => {
            saveReport()
          }}
          >
            Guardar
          </button>
          <button className="new-section-button" onClick={ () => {
            evaluateReport()
          }}
          >
            Evaluar
          </button>

        </div>
      </Row>
      <Row>
        <Col flex="0 450px">
          <div className="analytics-designer-properties-wrapper">
            <div className="analytics-designer-properties-label">
              Título
            </div>
            <Input
              type="text"
              name=""
              value={title}
              onChange={(e) => {setTitle(e.target.value)}}
            />
            <div className="analytics-designer-properties-label">
              Columnas
            </div>
            <div className="reports-designer-columns-container">
              <div className="reports-designer-columns-labels">
                <span>Etiqueta</span>
                <span style={{marginLeft: '110px'}}>Valor</span>
              </div>
              {columns.map((column, i) => {
                return(
                  <div className="report-column-row">
                    <div className="move-column-icons">
                    <CaretUpOutlined
                      onClick={(e) => {
                        moveColumn(i, 'up')
                      }}
                     />
                    <CaretDownOutlined
                      onClick={(e) => {
                        moveColumn(i, 'down')
                      }}
                    />
                      {/* <DeleteOutlined
                        onClick={(e) => {
                          const cols = [...columns]
                          cols.splice(i, 1)
                          setColumns(cols)
                        }}
                      /> */}
                    </div>
                    <Input
                      type="text"
                      value={column.label}
                      onChange={(e) => {
                        const cols = [...columns]
                        cols[i].label = e.target.value
                        setColumns(cols)
                      }}
                    />
                    <Input
                      type="text"
                      value={column.key}
                      onChange={(e) => {
                        const cols = [...columns]
                        cols[i].key = e.target.value
                        setColumns(cols)
                      }}
                    />
                    <div className="delete-column-icon">
                      <DeleteOutlined
                        onClick={(e) => {
                          const cols = [...columns]
                          cols.splice(i, 1)
                          setColumns(cols)
                        }}
                      />
                    </div>
                  </div>
                )
              })}
              <div className="report-column-row">
                <div className="add-new-column">
                  <PlusCircleOutlined
                    onClick={() => {
                      const cols = [...columns]
                      cols.push({label: '', key: ''})
                      setColumns(cols)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col flex="auto">
          <div className="analytics-designer-properties-label">
            Consulta BD (MongoDB)
          </div>
          <div className="reports-designer-code-editor-container">
            <Editor
              value={code}
              onValueChange={(code) => setCode(code)}
              highlight={(code) => Prism.highlight(code, Prism.languages.json, 'json')}
              padding={10}
              className="analytics-designer-editor"
            />
          </div>
        </Col>
        <Col flex="0 500px">
          <div className="analytics-designer-properties-wrapper">
            <div className="analytics-designer-properties-label">
              Filtros
            </div>
            <div className="reports-designer-filters-container">
              <div className="reports-designer-columns-labels">
                <span>Etiqueta</span>
                <span style={{marginLeft: '142px'}}>Valor</span>
              </div>
              {filters.map((filter, i) => {
                return(
                  <div className="report-filter-row">
                    <Input
                      type="text"
                      value={filter.label}
                      onChange={(e) => {
                        const filts = [...filters]
                        filts[i].label = e.target.value
                        setFilters(filts)
                      }}
                    />
                    <Input
                      type="text"
                      value={filter.slug}
                      onChange={(e) => {
                        const filts = [...filters]
                        filts[i].slug = e.target.value
                        setFilters(filts)
                      }}
                    />
                    <div className="delete-column-icon">
                      <DeleteOutlined
                        onClick={(e) => {
                          const filts = [...filters]
                          filts.splice(i, 1)
                          setFilters(filts)
                        }}
                      />
                    </div>
                  </div>
                )
              })}
              <div className="report-column-row">
                <div className="add-new-column">
                  <PlusCircleOutlined
                    onClick={() => {
                      const filts = [...filters]
                      filts.push({label: '', slug: '', type: 'text', value:''})
                      setFilters(filts)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="analytics-designer-properties-label">
              Consola de registros
            </div>
            <div className="reports-designer-console-container">
              {registers.map(reg => {
                return(
                  <p>{reg}</p>
                )
              })}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="evaluation-title">Evaluación</div>
        {showTable && (
          <div className="evaluation-table">
            <Table scroll={{x: true}} dataSource={evaluateData} columns={evaluateColumns} pagination={{showSizeChanger: false}}/>;
          </div>
        )}
      </Row>
      <Modal
        width={360}
        closable={false}
        centered={true}
        visible={showFilterModal}
        title={(<div style={{alignContent: 'center', textAlign: 'center'}}>Filtrar busqueda</div>)}
        // onOk={createNewSection}
        onCancel={() => { setShowFilterModal(false) }}
        // cancelText="Cancelar"
        // okText="Crear"
        footer={false}
      >
        {evaluateFilters.map((filter, i) => {
          return(
            <div className="filter-modal-form">
              <label>{filter.label}</label>
              <Input
                type="text"
                value={filter.value}
                onChange={(e) => {
                  const filts = [...evaluateFilters]
                  filts[i].value = e.target.value
                  setEvaluateFilters(filts)
                }}
              />
            </div>
          )
        })}
        <div className="filter-modal-footer">
          <button className="filter-modal-button" onClick={() => {
            callReport()
          }}>
            Filtrar
          </button>
        </div>
      </Modal>
    </div>
  )
}


export default ReportsDesigner