// import axios from 'axios'
// import cf from '../config/APIConfig'
import axiosBW from '../utils/Http';


export const GET_MY_TEAM_INFO_BEGIN   = 'GET_MY_TEAM_INFO_BEGIN';
export const getMyTeamInfoBegin = () => ({
  type: GET_MY_TEAM_INFO_BEGIN
});

export const GET_MY_TEAM_INFO_SUCCESS = 'GET_MY_TEAM_INFO_SUCCESS';
export const getMyTeamInfoSuccess = data => ({
  type: GET_MY_TEAM_INFO_SUCCESS,
  payload: data
});

export const GET_MY_TEAM_INFO_FAILURE = 'GET_MY_TEAM_INFO_FAILURE';
export const getMyTeamInfoFailure = error => ({
  type: GET_MY_TEAM_INFO_FAILURE,
  payload: { error }
});

export const getMyTeamInfo = () => {
  return dispatch => {
    dispatch(getMyTeamInfoBegin());
    return axiosBW.get(`/users/my-team-info/`)
    .then(resp =>{
      console.log(resp.data);
      dispatch(getMyTeamInfoSuccess(resp.data.payload));
    })
    .catch(err =>{
      dispatch(getMyTeamInfoFailure(err.response.data));
    })
  }
}