
export const SET_USER_DATA = 'SET_USER_DATA';
export const setUserData = (user, token) => ({
  type: SET_USER_DATA,
  payload: { user, token }
});
export const RESET_USER_DATA = 'RESET_USER_DATA';
export const resetUserData = () => ({
  type: RESET_USER_DATA,
  payload: {}
});

