import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FormBuilder from './FormBuilder.view'
import { Helmet } from 'react-helmet';
// import axios from 'axios'
// import cf from '../../../config/APIConfig'
import axiosBW from '../../../utils/Http';
import { Prompt } from 'react-router'
import { 
  setGlobalLoader, 
  changeFormMetadata,
  retrieveFormData,
  clearAllData,
  setInitialFormMetaData,
  setAllFormsFields
} from '../../../actions/actions'


const FormBuilderContainer = (props) => {
  const dispatch = useDispatch();
  const companyId = useSelector(state => state.user.companyId)
  const formMetadata = useSelector(state => state.formBuilder.formMetadata)
  const currentForm = useSelector(state => state.formBuilder.currentForm)
  const formModified = useSelector(state => state.formBuilder.formModified)
  const [formData, setFormData] = useState([])
  const [rawFormData, setRawFormData] = useState([])
  const changeFormMetadataD = (id, value) => dispatch(changeFormMetadata(id, value))
  const setActionMetadataD = (value) => dispatch(changeFormMetadata(value))

  useEffect(() => {
    const prep = async () => {
      // console.log('HOLA')
      dispatch(setGlobalLoader(true))
      // Clear previous forms
      dispatch(clearAllData())
      // load form data
      const isDraft = props.match.params.draft &&  props.match.params.draft === 'draft' ? true : false 
      let formListRes
      let allFieldsFromForms
      try {
        formListRes = await axiosBW.get(`process/form-data/${companyId}/${props.match.params.processSlug}/${props.match.params.formSlug}${isDraft ? '?draft=true': ''}`)
        allFieldsFromForms = await axiosBW.get(`process/fields-from-forms/${companyId}/${props.match.params.processSlug}${isDraft ? '?draft=true': ''}`)
      } catch (ex) {
        console.log('error ',ex)
      }
      if(!formListRes || !formListRes.data) {
        return
      }
      setFormData(formListRes.data.payload)
      // set form metadata
      const formRes = formListRes.data.payload
      if (formRes.fields && formRes.fields.length > 0) {
        dispatch(retrieveFormData(formRes.fields))
      }
      changeFormMetadataD('title', formRes.title || '')

      changeFormMetadataD('slug', formRes.slug || '')
      
      if(formRes.actionOnTimeout && formRes.actionOnTimeout.timeout.toString().length > 0) {
        changeFormMetadataD('timeout', parseInt(formRes.actionOnTimeout.timeout))
      }
      // 
      if(formRes.actionOnTimeout && formRes.actionOnTimeout.option) {
        if (formRes.actionOnTimeout.option === 'execute-action') {
          changeFormMetadataD('action', formRes.actionOnTimeout.action)
        }else {
          changeFormMetadataD('action', formRes.actionOnTimeout.option)
        }
      } else {
        changeFormMetadataD('action', 'nothing')
      }
      if(formRes.actions) {
        setActionMetadataD(formRes.actions)
        changeFormMetadataD('hooks', formRes.actions)
      }

      changeFormMetadataD('description', formRes.description || '')

      if(formRes.roleAssignment) {
        changeFormMetadataD('roleAssignment', formRes.roleAssignment)
      }
      changeFormMetadataD('userAssignment', formRes.userAssignment || '')
     
      setRawFormData(formRes)
      if (allFieldsFromForms && allFieldsFromForms.data) {
        dispatch(setAllFormsFields(allFieldsFromForms.data.payload))
      }
      // dispatch(setInitialFormData(JSON.parse(JSON.stringify(currentForm))))
      dispatch(setInitialFormMetaData(JSON.parse(JSON.stringify(formMetadata))))
      // console.log('LISTO')
      dispatch(setGlobalLoader(false))
    }
    prep()
    
  }, []);
  useEffect(() => {
    // console.log('props.formModified',props.formModified)
    if (formModified) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }, [formModified]);


  
  

    return (
      <div className="form_builder__wrapper">
        
          <FormBuilder 
            isDraft={props.match.params.draft &&  props.match.params.draft === 'draft' ? true : false}
            formData={formData ? formData : []}
            processSlug={props.match.params.processSlug}
            formSlug={props.match.params.formSlug}
            rawFormData={rawFormData}
          />
        <Helmet>
          <title> Constructor de Formularios - Blizwork</title>
        </Helmet>
        <Prompt
          when={formModified}
          message='Si ha hecho cambios y no los ha guardado, estos se perderán'
        />
        
      </div>
    )
    
}

export default FormBuilderContainer
