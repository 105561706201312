import React, { useState } from "react";
import TaskCard from "./TaskCard";
import BoxTarget from "./BoxTarget";
import { connect } from 'react-redux'
import { setGlobalLoader } from '../../../actions/actions'

const Tasks = (props) => {
  props.setGlobalLoader(false)
  const [taskList, setTaskList] = useState([
    {
      _id: 1,
      status: 'wip',
      category: 'Clase A',
      title: 'Comprar comía pa perroh',
      details: 'Puro Doko, naa de weas caras'
    },
    {
      _id: 2,
      status: 'wip',
      category: 'Clase B',
      title: 'Ir al passapoga',
      details: 'No daré más detalles'
    }, 
    {
      _id: 3,
      status: 'wip',
      category: 'Clase A',
      title: 'Hacerme un pan',
      details: 'Echarle tomate, manjar, vinagre y extracto de vainilla'
    }
  ])

  const markAsDone = _id => {
    const task = taskList.filter((task, i) => task._id === _id)
    task[0].status = 'done'
    setTaskList(taskList.filter((task, i) => task._id !== _id).concat(task[0]))
  }

  return (
    <>
    <div className="tasklist">
      {taskList
        .filter((task, i) => task.status === 'wip')
        .map((task, i) => {
          return (
            <TaskCard
              key={task._id.toString()}
              _id={task._id}
              category={task.category}
              title={task.title}
              details={task.details}
            />
          )
        })
      }
    </div>
    <div className="donelist">
    <BoxTarget>
      {taskList
        .filter((task, i) => task.status === 'done')
        .map((task, i) => {
          return (
            <TaskCard
              key={task._id.toString()}
              _id={task._id}
              category={task.category}
              title={task.title}
              details={task.details}
            />
          )
        })
      }
    </BoxTarget>
    
    </div>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState))
})

export default connect(null, mapDispatchToProps)(Tasks)