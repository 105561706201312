
export const initialState = {
  sidebarVisible: false,
  navigationList: [
    {
      url: '/',
      text: 'Escritorio',
      isActive: true,
      showDesktop: true,
      icoUrl: '/img/ico-escritorio.png',
      icoUrlSelected: '/img/ico-escritorio-hover.png'
    },
    {
      url: '/hall/',
      urlParams: 'companyId',
      text: 'Organización',
      isActive: false,
      showDesktop: true,
      icoUrl: '/img/ico-recepcion.png',
      icoUrlSelected: '/img/ico-recepcion-hover.png',
      margin: '-8px'
    },
    {
      url: '/procesos/',
      text: 'Procesos',
      isActive: false,
      showDesktop: true,
      icoUrl: '/img/ico-flujos-de-trabajo-menu.png',
      icoUrlSelected: '/img/ico-flujos-de-trabajo-menu-hover.png'
    },
    {
      url: '/hooks/',
      text: 'Extensiones',
      isActive: false,
      showDesktop: true,
      icoUrl: '/img/ico-extensiones-menu.png',
      icoUrlSelected: '/img/ico-extensiones-menu-hover.png'
    },
    {
      url: '/masterdata/',
      text: 'Datos Maestros',
      showDesktop: true,
      icoUrl: '/img/ico-masterdata.png',
      icoUrlSelected: '/img/ico-masterdata-hover.png'
    },
    {
      url: '/cerrar-sesion',
      text: 'Salir',
      isActive: false,
      showDesktop: true,
      icoUrl: '/img/ico-salir.png',
      icoUrlSelected: '/img/ico-salir-hover.png'
    }
  ],
  isMobile: true,
  searchBarText: '',
  showGlobalLoader: true,
  homeSelectedTab: 0
}

const navigation = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_SIDEBAR': {
      return {
        ...state,
        sidebarVisible: action.isEnabled
      }
    }
    case 'CHANGE_NAV_MENU': {
      let newMenu = [...state.navigationList]
      console.log('action element', action.menuElement)
      newMenu.forEach(nm => {
        if (action.menuElement === nm) {
          nm.isActive = true
        } else {
          nm.isActive = false
        }
      })
      return {
        ...state,
        navigationList: newMenu
      }
    }
    case 'SET_IS_RESPONSIVE': {
      return {
        ...state,
        isMobile: action.bool
      }
    }
    case 'CHANGE_SEARCH_TEXT': {
      return {
        ...state,
        searchBarText: action.newText
      }
    }
    case 'SET_GLOBAL_LOADER': {
      return {
        ...state,
        showGlobalLoader: action.newState
      }
    }
    case 'SET_HOME_SELECTED_TAB': {
      return {
        ...state,
        homeSelectedTab: action.selectedTab
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}

export default navigation