import React, { useState } from 'react'
import { connect } from 'react-redux'
import { createPortal } from 'react-dom'
import './Loader.scss'
import { setGlobalLoader } from '../../actions/actions';
const Loader = (props) => {
  return createPortal(
    <div className="loader__wrapper" style={{opacity: props.showGlobalLoader ? '1' : '0', visibility: props.showGlobalLoader ? 'visible' : 'hidden'}}>
      <div className="loader">
        <div className="bliz_logo">
          <img src="/img/blizwork-mini.png" alt="bliz" />
        </div>
        <h1>
          <span>Cargando</span>
          <span className="dot01">.</span>
          <span className="dot02">.</span>
          <span className="dot03">.</span>
        </h1>
      </div>
    </div>,
    document.getElementById('loader')
  )
}

const mapStateToProps = state => ({
  showGlobalLoader: state.navigation.showGlobalLoader
})

const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState))
})

export default connect(mapStateToProps, mapDispatchToProps)(Loader)