/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ZingChart from 'zingchart-react'
import axiosBW from '../../../utils/Http'
import { Row, Col, Input, Select } from 'antd'
import Editor from 'react-simple-code-editor'
import Prism from 'prismjs'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-json'
import 'prismjs/themes/prism-coy.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setGlobalLoader } from '../../../actions/actions'
import { toast } from 'react-toastify'
import { LoadingOutlined, LineChartOutlined } from '@ant-design/icons'

import 'react-toastify/dist/ReactToastify.css'
import './AnalyticsDesigner.scss'

const { Option } = Select

const AnalyticsDesigner = (
  props,
  { processSlug, environment, location, history }
) => {
  const dispatch = useDispatch()
  const [properties, setProperties] = useState(`{
    "series" : [ ],
    "plotarea" : {
      "marginBottom" : "25%",
      "marginTop" : "20%",
      "adjustLayout" : true
    },
    "crosshairX" : {
      "plotLabel" : {
        "padding" : 10,
        "text" : "%t: %npv%",
        "decimals" : 1
      },
      "scaleLabel" : {
        "alpha" : 0,
        "text" : "%npv%",
        "transform" : {
          "type" : "date",
          "all" : "%M %d, %Y<br>%g:%i %a"
        },
        "fontFamily" : "Georgia"
      }
    }
  }`)
  const [code, setCode] = useState(`
  const list = await casesFromProcess([])
  const openCases = list.filter(c => c.status === 'open')
  const closedCases= list.filter(c => c.status !== 'open')
  const resp = {
    separatorLabels: [],
    series: [
    {
      text: 'Abiertos',
      values: [openCases.length]
    },
    {
      text: 'Cerrados',
      values: [closedCases.length]
    }
    ]
  }
  return resp
  `)
  const [chartProperties, setChartProperties] = useState({})
  const [series, setSeries] = useState([])
  const [type, setType] = useState('pie')
  const [title, setTitle] = useState('')
  const [analyticId, setAnalyticId] = useState(null)
  const [description, setDescription] = useState('')
  const [showChart, setShowChart] = useState(false)
  const [chartLoading, setChartLoading] = useState(false)
  /* eslint no-unused-vars: [0, { "argsIgnorePattern": "setLoading" }] */
  const [loading, setLoading] = useState(false)
  const companyId = useSelector((state) => state.user.companyId)

  useEffect(() => {
    if (!props.location.state) {
      props.history.push('/')
      return
    }

    if (!props.match.params.flag) {
      const analyticData = props.location.state.analytic
      console.log({ analyticData })
      if (analyticData.code && analyticData.code !== '')
        setCode(analyticData.code)
      if (analyticData.parameters && analyticData.parameters !== '')
        setProperties(analyticData.parameters)
      if (analyticData.title && analyticData.title !== '')
        setTitle(analyticData.title)
      if (analyticData.description && analyticData.description !== '')
        setDescription(analyticData.description)
      if (analyticData.type && analyticData.type !== '')
        setType(analyticData.type)
      if (analyticData._id && analyticData._id !== '')
        setAnalyticId(analyticData._id)
    }
    dispatch(setGlobalLoader(false))
  }, [])

  const saveAnalytic = async () => {
    try {
      if (type !== 'simple') {
        if (!isJson(properties))
          throw new Error('No se ha guardado: Mal formato de las propiedades')
      } else {
        setProperties({})
      }
      if (!title || title === '')
        throw new Error('No se ha guardado: Titulo faltante')
      dispatch(setGlobalLoader(true))
      let resAnalytics
      const analyticData = props.location.state
      if (!analyticId) {
        resAnalytics = await axiosBW.post(`analytics/create-analytic/`, {
          companyId: analyticData.companyId,
          processSlug: analyticData.processSlug,
          sectionId: analyticData.sectionId,
          analytic: {
            title,
            description,
            type,
            code,
            parameters: properties,
          },
        })
      } else {
        resAnalytics = await axiosBW.post(`analytics/update-analytic/`, {
          companyId: analyticData.companyId,
          processSlug: analyticData.processSlug,
          sectionId: analyticData.sectionId,
          analyticId,
          analytic: {
            _id: analyticId,
            title,
            description,
            type,
            code,
            parameters: properties,
          },
        })
      }
      dispatch(setGlobalLoader(false))
      if (resAnalytics && resAnalytics.data) {
        if (resAnalytics.data.status === 0) {
          setAnalyticId(resAnalytics.data.payload)
          toast.success('Se ha guardado la analítica correctamente')
        }
      }
    } catch (error) {
      console.log(error)
      toast.error(error.message)
      dispatch(setGlobalLoader(false))
    }
  }

  const isJson = (text) => {
    try {
      JSON.parse(text)
      return true
    } catch (error) {
      return false
    }
  }

  const evaluateChart = async () => {
    try {
      dispatch(setGlobalLoader(true))
      setShowChart(false)
      setChartLoading(true)
      let parsedProperties
      if (type !== 'simple') {
        if (!isJson(properties))
          throw new Error('Mal formato de las propiedades')
        parsedProperties = JSON.parse(properties)
      } else {
        parsedProperties = {}
        setProperties({})
      }
      const resEvaluate = await axiosBW.post(`analytics/evaluate-analytic/`, {
        companyId,
        processSlug: props.location.state.processSlug,
        code,
      })
      if (resEvaluate && resEvaluate.data) {
        if (resEvaluate.data.status === 0) {
          const evaluatedData = resEvaluate.data.payload
          parsedProperties.type = type
          if (
            parsedProperties.type === 'bar' ||
            parsedProperties.type === 'hbar' ||
            parsedProperties.type === 'line'
          ) {
            if (parsedProperties.scaleX) {
              parsedProperties.scaleX.labels = evaluatedData.separatorLabels
            } else {
              parsedProperties.scaleX = {
                labels: evaluatedData.separatorLabels,
              }
            }
          }
          parsedProperties.series = evaluatedData.series
          parsedProperties.title = {
            text: title,
          }
          parsedProperties.subtitle = {
            text: description,
            'font-weight': 'normal',
          }
          setSeries(evaluatedData.series)
          setChartProperties(parsedProperties)
          setShowChart(true)
          setChartLoading(false)
        }
      }
      dispatch(setGlobalLoader(false))
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }
      dispatch(setGlobalLoader(false))
    }
  }

  if (loading) {
    return 'Loading...'
  }

  return (
    <div className="analytics-list-wrapper">
      <Row className="analytics-designer-header">
        <div className="analytics-designer-title">
          <div
            className="workflow__back_arrow"
            onClick={() => {
              props.history.goBack()
            }}
          >
            <FontAwesomeIcon icon="arrow-left" />
          </div>
          Secciones
        </div>
        <div>
          <button
            className="new-section-button"
            onClick={() => {
              saveAnalytic()
            }}
          >
            Guardar
          </button>
          <button
            className="new-section-button"
            onClick={() => {
              evaluateChart()
            }}
          >
            Evaluar
          </button>
        </div>
      </Row>
      <Row>
        <Col flex="0 450px">
          <div className="analytics-designer-properties-wrapper">
            <div className="analytics-designer-properties-label">Título</div>
            <Input
              type="text"
              name=""
              value={title}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
            />
            <div className="analytics-designer-properties-label">
              Descripción
            </div>
            <Input.TextArea
              name=""
              value={description}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
            />
            <div className="analytics-designer-properties-label">Tipo</div>
            <Select
              value={type}
              style={{ width: '100%' }}
              onChange={(value) => setType(value)}
            >
              <Option value="bar">Barras</Option>
              <Option value="line">Lineas</Option>
              <Option value="pie">Torta</Option>
              <Option value="simple">Simple</Option>
            </Select>
            <div className="analytics-designer-properties-label">
              Propiedades
            </div>
            <div className="analytics-designer-properties-editor-container">
              {type !== 'simple' ? (
                <Editor
                  value={properties}
                  onValueChange={(properties) => setProperties(properties)}
                  highlight={(properties) =>
                    Prism.highlight(properties, Prism.languages.json, 'json')
                  }
                  padding={10}
                  className="analytics-designer-editor"
                />
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    cursor: 'not-allowed',
                  }}
                ></div>
              )}
            </div>
          </div>
        </Col>
        <Col flex="auto">
          <div className="analytics-designer-properties-label">
            Serie(s) de dato(s) (código)
          </div>
          <div className="analytics-designer-code-editor-container">
            <Editor
              value={code}
              onValueChange={(code) => setCode(code)}
              highlight={(code) =>
                Prism.highlight(code, Prism.languages.javascript, 'javascript')
              }
              padding={10}
              className="analytics-designer-editor"
            />
          </div>
        </Col>
        <Col flex="0 500px">
          <div className="analytics-designer-chart">
            <div className="analytics-designer-properties-label">
              Previsualización
            </div>
            <div className="analytics-chart-container">
              {showChart ? (
                chartProperties.type !== 'simple' ? (
                  <ZingChart
                    width="468px"
                    height="284px"
                    data={chartProperties}
                    series={series}
                  />
                ) : (
                  <div className="simple_analytic" style={{ width: '400px' }}>
                    <div className="simple_analytic_image">
                      {<LineChartOutlined />}
                    </div>
                    <div className="simple_analytic_data">
                      <div className="simple_analytic_number">
                        {series[0] ? series[0].values[0] : '0'}
                      </div>
                      <div className="simple_analytic_text">
                        <div className="simple_analytic_title">
                          {chartProperties.title.text}
                        </div>
                        <div className="simple_analytic_description">
                          {chartProperties.subtitle.text}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ) : chartLoading ? (
                <LoadingOutlined className="analytics-chart-loading" />
              ) : (
                <div>
                  Haga click en &quot;Evaluar&quot; para visualizar el gráfico.
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AnalyticsDesigner
