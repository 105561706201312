import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from 'react';

export default function Sortable({children, id, className, itsNew}, props) {
  const sortable = useSortable({ id });
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging
    } = sortable;
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      opacity: isDragging && itsNew ? 0 : 1,
      // opacity: isDragging ? 0 : undefined,
      cursor: isDragging ? 'grabbing' : 'grab'
      // backgroundColor: "#ddd",
      // padding: ".5rem",
      // width: "100px",
      // margin: "0 1rem 1rem 0"
    };

  return (
    <div ref={setNodeRef} className={className} style={style} {...listeners} {...attributes} {...props}>
      {children}
    </div>
  );
}
