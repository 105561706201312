import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import FormWidget from '../FormWidget/FormWidget.view';
import FormFieldsList from '../FormFieldsList/FormFieldsList.view';
import { Row, Collapse } from 'antd';
import Draggable from '../Draggable';
const { Panel } = Collapse;

const WidgetList = props => {
  const listOfWidgets = useSelector(state => state.formBuilder.listOfWidgets)
  const currentForm = useSelector(state => state.formBuilder.currentForm)
  const allFormsFields = useSelector(state => state.formBuilder.allFormsFields)
  const [widgetList, setWidgetList] = useState(listOfWidgets)

  useEffect(() => {
    setWidgetList(listOfWidgets)
  }, [currentForm]);
  return (
    <>
    <Row className="form_builder__sidebar">
    
      <Collapse
        accordion
        bordered={false}
        defaultActiveKey={['1']}
        expandIconPosition={'right'}
      >
        <Panel header="Elementos de Formulario" className="" key="1" >
          {
            widgetList ? 
              (
                widgetList.map((elem, ind) => {
                  return (
                    <Draggable onClick={(e) => {e.preventDefault(); e.stoppropagation()}} key={ind} id={`centerWidget-${elem.widgetId}-${Math.floor((Math.random() * 99999) + 1)}`}>
                      <FormWidget  key={ind} {...elem} />                
                    </Draggable>
                  )
                })
                ) : 
              null
            }
        </Panel>
        <Panel header="Datos del Flujo" className="form_fields_list" key="2" >
        {
          allFormsFields ? 
            (
              allFormsFields.map((elem, ind) => {
                if (elem.slug !== 'final-form' && elem.slug !== props.formSlug) {
                  return (
                    <FormFieldsList key={ind} currentForm={props.currentForm}  {...elem} />                
                  )
                }
                
              })
            ) : 
            null
          }
        </Panel>
      </Collapse>
    </Row>
    </>
  )
}

export default WidgetList