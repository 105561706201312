/* eslint-disable react/display-name */
import React, { useState } from 'react'
import { Modal } from 'antd';
import './FormBox.scss'
import { DeleteOutlined, EditOutlined, UndoOutlined } from '@ant-design/icons';
import { Link  } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { useDrag, useDrop } from "react-dnd";
import { setDraggingStatus, changeFormPosition, createNewActionBegin, createNewActionEnd, editingActionSetStartPosition, editingActionEnd, deleteForm, setNewActionSlug, changeActiveProperties, createNewActionCancel, editingActionCancel } from '../../../../../actions/WorkflowDesignerActions'
import { socket } from '../../../../../utils/Socket'
import { setGlobalLoader } from '../../../../../actions/actions';
const ObjectID = require('bson').ObjectID;

const EditButton = ({intro, formSlug, processSlug, companyId, draft}) => {
  if(draft) {
    return (
      <div className={`${intro ? 'edit-intro': 'edit-form'}`}>
        <Link to={`/form-builder/${companyId}/${processSlug}/${formSlug}/draft`}>
          <p> <EditOutlined /> </p>
        </Link>
        {/* <p> <EditOutlined /> </p> */}
      </div>
    )
  } else {
    return (
      <div className="edit-intro">
        <Link to={`/form-builder/${companyId}/${processSlug}/${formSlug}`}>
          <p> <EditOutlined /> </p>
        </Link>
        {/* <p> <EditOutlined /> </p> */}
      </div>
    )
  }
}
const DeleteButton = ({form, processSlug, thisFormIsEndOfAction, createLines, companyId, token}) => {
  const dispatch = useDispatch();
  const onOkDelete = () => {
    if (thisFormIsEndOfAction(form.slug)) {
      Modal.error({
        title: 'Error',
        content: `Debe eliminar las acciones que se dirigen a este formulario `,
      })
    } else {
      // console.log('SI ELIMINAMOS');
      dispatch(deleteForm({_id: form._id}))
      socket.emit('client:wf-delete-form', {
        companyId,
        processSlug, 
        token,
        payload: {_id: form._id}
      })
      createLines()
      dispatch(changeActiveProperties('blank' ,null))
    }
    
  }

  return (
    <div className="delete-form" onClick={(e) => {
      e.stopPropagation()
      Modal.confirm({
        title: 'Confirmar Eliminación',
        content: `¿Está seguro que desea eliminar el formulario? `,
        onOk() {
          onOkDelete()
        }
      })
    }}>
      <p> <DeleteOutlined /> </p>
    </div>
  )
}

const FormBox = React.forwardRef( ({ form, processSlug, empty, roleName, position, setMaxColumns, createLines, updateSelected, thisFormIsEndOfAction, lines, unselectLines, selectAction }, ref) => {
  // let limit = false
  // const psss = useMousePosition()
  // const actionPositions = ['topOne','topTwo','topThree','bottomOne','bottomTwo','bottomThree','leftOne','leftTwo','leftThree','rightOne','rightTwo','rightThree']
  const actionPositions =[
    // {position: 'topOne', style: {top: '-10px', right: '93px'}},
    {position: 'topOne', style: { position: 'absolute', float: 'right', margin: '-14px 0px 0px 20px', transform: 'rotate(-90deg)' }},
    {position: 'topTwo', style: { position: 'absolute', float: 'right', margin: '-14px 0px 0px 60px', transform: 'rotate(-90deg)' }},
    {position: 'topThree', style: { position: 'absolute', float: 'right', margin: '-14px 0px 0px 100px', transform: 'rotate(-90deg)' }},
    {position: 'bottomOne', style: { position: 'absolute', float: 'right', margin: '100px 0px 0px 20px', transform: 'rotate(90deg)' }},
    {position: 'bottomTwo', style: { position: 'absolute', float: 'right', margin: '100px 0px 0px 60px', transform: 'rotate(90deg)' }},
    {position: 'bottomThree', style: { position: 'absolute', float: 'right', margin: '100px 0px 0px 100px', transform: 'rotate(90deg)' }},
    {position: 'leftOne', style: { position: 'absolute', float: 'right', margin: '20px 0px 0px -10px', transform: 'rotate(180deg)' }},
    {position: 'leftTwo', style: { position: 'absolute', float: 'right', margin: '40px 0px 0px -10px', transform: 'rotate(180deg)' }},
    {position: 'leftThree', style: { position: 'absolute', float: 'right', margin: '60px 0px 0px -10px', transform: 'rotate(180deg)' }},
    {position: 'rightOne', style: { position: 'absolute', float: 'right', margin: '20px 0px 0px 135px', transform: 'rotate(0deg)' }},
    {position: 'rightTwo', style: { position: 'absolute', float: 'right', margin: '40px 0px 0px 135px', transform: 'rotate(0deg)' }},
    {position: 'rightThree', style: { position: 'absolute', float: 'right', margin: '60px 0px 0px 135px', transform: 'rotate(0deg)' }},
    // {position: 'topTwo', style: {top: '-10px', right: '63px'}},
    // {position: 'topThree', style: {top: '-10px', right: '33px'}},
    // {position: 'bottomOne', style: {bottom: '-10px', right: '93px'}},
    // {position: 'bottomTwo', style: {bottom: '-10px', right: '63px'}},
    // {position: 'bottomThree', style: {bottom: '-10px', right: '33px'}},
    // {position: 'leftOne', style: {bottom: '62px', left: '-10px'}},
    // {position: 'leftTwo', style: {bottom: '47px', left: '-10px'}},
    // {position: 'leftThree', style: {bottom: '32px', left: '-10px'}},
    // {position: 'rightOne', style: {bottom: '62px', right: '-10px'}},
    // {position: 'rightTwo', style: {bottom: '47px', right: '-10px'}},
    // {position: 'rightThree', style: {bottom: '32px', right: '-10px'}}
  ]
  const availableStartActions = []
  const dispatch = useDispatch();
  const draftMode = useSelector(state => state.workflowDesigner.draftMode)
  const draggingStatus = useSelector(state => state.workflowDesigner.draggingStatus)
  const createNewAction = useSelector(state => state.workflowDesigner.createNewAction)
  const createNewActionSlug = useSelector(state => state.workflowDesigner.createNewActionSlug)
  const newAction = useSelector(state => state.workflowDesigner.newAction)
  const editingActionStatus = useSelector(state => state.workflowDesigner.editingActionStatus)
  const editingAction = useSelector(state => state.workflowDesigner.editingAction)
  const versionType = useSelector(state => state.workflowDesigner.versionType)
  const workflow = useSelector(state => state.workflowDesigner.workflow)
  const companyId = useSelector(state => state.user.companyId)
  const token = useSelector(state => state.user.token)
  let actionToSave
  if (lines) {
    actionToSave = lines.find(l => l.from === form.slug && l.to === form.slug)
    if (actionToSave) {
      const actionPos = actionPositions.find(ac => ac.position === actionToSave.action.startLine)
      actionToSave.style = actionPos ? actionPos.style : {}
    }
  }
  if (form) {
    
    for (let i = 0; i < actionPositions.length; i++) {
      const actionPos = actionPositions[i];
      const formActions = form.actions
      if ( createNewAction && newAction.startSlug === form.slug) {
        if (newAction.startPosition === actionPos.position) {
          availableStartActions.push(actionPos)
        }
      } else if (editingActionStatus && editingAction.newAction.startLine !== '' && editingAction.newAction.startSlug === form.slug) {
          if (editingAction.newAction.startLine === actionPos.position) {
            availableStartActions.push(actionPos)
          }
      } else {
        const actionOcupied = formActions.some( a => a.startLine === actionPos.position )
        const actionEnd = workflow.forms.some(wForm => wForm.actions.some(action => action.formSlug === form.slug && action.endLine === actionPos.position))
        // console.log  (actionEnd)
        if (!actionOcupied && !actionEnd) {
          availableStartActions.push(actionPos)
        }
        
      }
    }
  }

  const [{ isDragging }, drag] = useDrag({
    item: { roleName, position, formSlug: !empty ? form.slug : '', type: 'FORM_BOX' },
    collect: (monitor) => ({
        isDragging: monitor.isDragging(),
    }),
    begin: () => {
      console.log('Start DRAG');
      dispatch(setDraggingStatus(true))
    },
    end: (item, monitor) => {
      console.log('END DRAG');
      dispatch(setDraggingStatus(false))
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        if (item.formSlug === 'final-form' && dropResult.roleName !== 'customer') {
          Modal.error({
            title: 'Error',
            content: 'El formulario final no se puede cambiar del rol CUSTOMER'
          })
        } else {
          const obj = {
            from: {
              formSlug: item.formSlug
            },
            to: dropResult
          }
          dispatch(changeFormPosition(obj))
          socket.emit('client:wf-form-position', {
            companyId: companyId,
            processSlug, 
            token: token,
            payload: obj})
          setMaxColumns()
        }
      }
    }
  });
  const [, drop] = useDrop({
    accept: 'FORM_BOX',
    drop: () => ({roleName, position})
    // drop(item, monitor) {
    //   const offset = monitor.getClientOffset();
    //   console.log(offset);
    //   if (offset && ref.current) {
    //     const dropTargetXy = ref.current.getBoundingClientRect();
    //     console.log("local", {
    //       x: offset.x - dropTargetXy.left,
    //       y: offset.y - dropTargetXy.top
    //     });
    //   }
    // }
  });

  const opacity = isDragging ? 0.4 : 1;
  // drag(drop(ref))
  const sleepTransition = async () => {
    dispatch(changeActiveProperties('blank' ,null))
    dispatch(setGlobalLoader(true))
    await new Promise(r => setTimeout(r, 500));
    dispatch(setGlobalLoader(false))
  }

  const newActionStart = async(position) => {
    dispatch(createNewActionBegin({slug:form.slug, position}))
    dispatch(setNewActionSlug(''))
  }
  const newActionEnd = async(e, position) => {
    e.preventDefault()
    const startFormActions = workflow.forms.find( f => f.slug === newAction.startSlug).actions
    if (!startFormActions.find(a => a.formSlug === form.slug)) {
      const newId = new ObjectID()
      await sleepTransition()
      dispatch(createNewActionEnd({_id:newId, slug:form.slug, position, name: `Acción ${form.actions.length + 1}`}))
      socket.emit('client:wf-new-action', {
        companyId: companyId,
        processSlug, 
        token: token,
        payload: {
          _id: newId,
          startSlug:newAction.startSlug,
          label: `Acción ${form.actions.length + 1}`,
          formSlug: form.slug,
          startLine: newAction.startPosition,
          endLine: position
        }
      })                
      createLines()
      dispatch(setNewActionSlug(''))
    } else {
      dispatch(setNewActionSlug(''))
      dispatch(createNewActionCancel())
      Modal.error({
        title: 'Error',
        content: 'Un formulario no puede tener dos acciones dirigidas a un mismo formulario'
      })
    }
  }
  const onEditingActionEnd = async(position) => {
    const startFormActions = workflow.forms.find( f => f.slug === editingAction.newAction.startSlug).actions
    if (!startFormActions.find(a => a.formSlug === form.slug)) {
      socket.emit('client:wf-edit-action-position', {
        companyId: companyId,
        processSlug, 
        token: token,
        payload: {
          ...editingAction.originalAction,
          formSlug: form.slug,
          startLine: editingAction.newAction.startLine,
          endLine: position
  
        }
      })
      dispatch(editingActionEnd({formSlug: form.slug , endLine: position}))
      createLines()
    } else {
      
      createLines()
      Modal.error({
        title: 'Error',
        content: 'Un formulario no puede tener dos acciones dirigidas a un mismo formulario'
      })
      dispatch(editingActionCancel())
      createLines()
    }
    
  }
  const draggable = !!(draftMode && form && form.slug !== 'intro-form')

  return (
    <div className="form-box-container" >
      {!empty ?
      (
        <div className="form-box"ref={ref} onClick={() => {
          if (draftMode && !editingActionStatus && !createNewAction) {
            if (createNewActionSlug === form.slug) {
              dispatch(setNewActionSlug(''))
            } else {
              dispatch(setNewActionSlug(form.slug))
            }
          }
        }} >
          
          <div ref={draggable ? drag: null} style={{ opacity }} onMouseEnter={(e) => {e.preventDefault()}}>
              {draftMode && createNewActionSlug === form.slug ? availableStartActions.map((act, i) => (
                <div key={i} className="" style={act.style} onClick={() => {
                  newActionStart(act.position)
                }}>
                  <img style={{height: '15px', width: '15px'}} src="/img/arrow.png"></img>
                </div>
              )): ''}
            {actionToSave ? (
              <UndoOutlined style={{...actionToSave.style, cursor: 'pointer', color: actionToSave.color ? actionToSave.color : 'black', fontSize: '20px'}} onClick = {(e) => {
                e.stopPropagation(); //so only the click event on the box will fire on not on the conainer itself
                unselectLines(lines)
                actionToSave.selected = true
                selectAction(actionToSave.action)
              }}/>
            ): ''}
            {draftMode && createNewAction ? availableStartActions.map((act, i) => (
              <div key={i} className="" style={act.style} onClick={async (e) => newActionEnd(e, act.position)}><img style={{height: '15px', width: '15px', transform: 'rotate(180deg)'}} src="/img/arrow.png"></img></div>
            )): ''}
            {draftMode && editingActionStatus && editingAction.newAction.startSlug !== '' && editingAction.newAction.startLine === '' ? availableStartActions.map((act, i) => (
              <div key={i} className="" style={act.style} onClick={() => {
                dispatch(editingActionSetStartPosition(act.position))
              }}><img style={{height: '15px', width: '15px'}} src="/img/arrow.png"></img></div>
            )): ''}
            {/* {draftMode && editingActionStatus && editingAction.newAction.startSlug !== '' && editingAction.newAction.startLine !== '' && editingAction.newAction.startSlug !== form.slug ? availableStartActions.map((act, i) => ( */}
            {draftMode && editingActionStatus && editingAction.newAction.startSlug !== '' && editingAction.newAction.startLine !== '' ? availableStartActions.map((act, i) => (
              <div key={i} className="" style={act.style} onClick={() => onEditingActionEnd(act.position)}><img style={{height: '15px', width: '15px', transform: 'rotate(180deg)'}} src="/img/arrow.png"></img></div>
            )): ''}
            {/* <div className="action-badge" style={{bottom: '32px', right: '-10px'}}></div> */}
            
            <div className="form-box-header">
              {/* {draftMode ? 
                <div className="create-action" onClick={() => {
                  setCreateAction(!createAction)
                }}>
                  <p> <ToTopOutlined /> </p>
                </div>
              :''} */}
             
            </div>
            <div className={`form-box-content ${draggable ? 'draggable': ''} ${isDragging ? 'grabbable' : ''}`}>
              {form.slug === 'intro-form'
                ? (<p className='ini-end-text'><b>Inicio</b></p>)
                : form.slug === 'final-form'
                  ? (<p className='ini-end-text'><b>Fin</b></p>)
                  : (<><p className="form-title-text"><b>{form.title}</b></p><p>({form.slug})</p></>)
              }
            </div>
            <div className={`form-box-footer${form.slug === 'intro-form' || versionType === 'published' ? '__intro' : ''}`}>
              {/* {draftMode 
              ? form.slug === 'intro-form' 
                ? (<EditButton intro={true} formSlug={form.slug} processSlug={processSlug}/>)
                : form.slug === 'final-form'
                  ? ''
                  : (<><EditButton formSlug={form.slug} processSlug={processSlug}/><DeleteButton form={form} processSlug={processSlug} thisFormIsEndOfAction={thisFormIsEndOfAction} /></>)

              : ''} */}
              {draftMode 
              ? form.slug === 'intro-form' 
                ? (<EditButton draft={true} intro={true} formSlug={form.slug} processSlug={processSlug} companyId={companyId}/>)
                : form.slug === 'final-form'
                  ? ''
                  : (<><EditButton draft={true} formSlug={form.slug} processSlug={processSlug} companyId={companyId}/><DeleteButton form={form} processSlug={processSlug} thisFormIsEndOfAction={thisFormIsEndOfAction} createLines={createLines} companyId={companyId} token={token} /></>)

              : versionType === 'published'
                ? form.slug === 'intro-form' 
                  ? (<EditButton  intro={true} formSlug={form.slug} processSlug={processSlug} companyId={companyId}/>)
                  : form.slug === 'final-form'
                    ? ''
                    : (<><EditButton formSlug={form.slug} processSlug={processSlug} companyId={companyId}/></>)
              : ''}
            </div>
          </div>
        </div>

      ): draggingStatus ? (
        <div className="form-box grabbable" ref={drop}>
          <div ></div>
        </div>
      ): ''}
    </div>
  )
})


export default FormBox