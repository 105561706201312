/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import axiosBW from '../../../../../utils/Http'
import { Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HooksLogDetail from '../HooksLogDetail/HooksLogDetail'
import moment from 'moment-timezone'
import './HooksLog.scss'

const HooksLog = ({ processSlug, environment }) => {
  const [hooksLog, setHooksLog] = useState([]);
  const [hooksLogDetail, setHooksLogDetail] = useState(null)
  const [loading, setLoading] = useState(false)
  const [onError, setOnError] = useState(false)
  const [detailPage, setDetailPage] = useState(false)
  const companyId = useSelector(state => state.user.companyId)
  const timeZone = useSelector(state => state.user.timeZone)
  const pageSize = environment === 'draft' ? 4 : 10


  useEffect(() => {
    (async () => {
      const saveTO = axiosBW.options.timeout
      axiosBW.options.timeout = 1000 * 60 * 5
      try {
        setLoading(true)
        setOnError(false)
        const resHooks = await axiosBW.get(`case/hooks-log-by-process/${companyId}/${processSlug}/${environment}`)
        setLoading(false)
        if (resHooks && resHooks.data) {
          if (resHooks.data.status === 0) {
            setHooksLog(resHooks.data.payload)
          }
        }
      } catch (ex) {
        setLoading(false)
        setOnError(true)
        console.log(ex);
      }
      axiosBW.options.timeout = saveTO
    })();

  }, []);
  const getHookLogDetail = async (hookLogId) => {
    try {
      setLoading(true)
      const resHookLogDetail = await axiosBW.get(`case/hooks-log-detail/${hookLogId}`)
      setLoading(false)
      if (resHookLogDetail && resHookLogDetail.data) {
        if (resHookLogDetail.data.status === 0) {
          setHooksLogDetail(resHookLogDetail.data.payload)
          setDetailPage(true)
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
  const back = () => {
    setHooksLogDetail(null)
    setDetailPage(null)
  }
  const data = []
  const columns = [
    // {
    //     title: "My custom property one",
    //     sorter: (a, b) => a.custom.one-b.custom.one,
    //     render: (record) => record.custom.one,
    // },
    {
      title: 'Nro. Caso',
      // dataIndex: 'caseNumber',
      key: 'caseNumber',
      render: obj => obj.caseInfo ? obj.caseInfo.caseNumber : ''
    },
    {
      title: 'Formulario',
      // dataIndex: 'caseNumber',
      key: 'activity',
      render: obj => obj.caseInfo ? `${obj.caseInfo.formTitle} (${obj.caseInfo.formSlug})` : ''
    },
    {
      title: 'Acción',
      // dataIndex: 'caseNumber',
      key: 'action',
      render: obj => obj.caseInfo ? `${obj.caseInfo.actionLabel} (${obj.caseInfo.actionEnd})` : ''
    },
    {
      title: 'Ambiente',
      dataIndex: 'environment',
      key: 'environment',
      render: text => text === 'production' ? 'Producción' : 'Borrador',
    },
    {
      title: 'Fecha-Hora',
      //   dataIndex: 'invocation.execDate',
      key: 'execDate',
      render: obj => moment(obj.invocation.execDate).tz(timeZone).format('YYYY-MM-DD HH:mm')
    },
    {
      title: 'Nombre Hook',
      //   dataIndex: 'webHook.url',
      key: 'hookTitle',
      render: obj => obj.webHook.title
    },
    {
      title: 'Hook',
      //   dataIndex: 'webHook.url',
      key: 'url',
      render: obj => obj.webHook.url
    },
    {
      title: 'Estado HTTP',
      //   dataIndex: 'response.httpStatus',
      key: 'httpStatus',
      render: obj => obj.response.httpStatus
    },
    {
      title: 'Ver',
      render: obj => (
        <div style={{ cursor: 'pointer' }} onClick={() => getHookLogDetail(obj._id)}><FontAwesomeIcon icon="search" /></div>
      )
    },
  ]
  const locale = {
    emptyText: (
      <span>
        <p>
          No hay solicitudes pendientes
        </p>
      </span>
    )
  }
  if (loading) {
    return 'Cargando...'
  }
  if (onError) {
    return 'Ha ocurrido un error al cargar los datos. Por favor informe a través de la Mesa de Ayuda. Gracias.'
  }
  if (detailPage) {
    return (
      <HooksLogDetail hookLogDetail={hooksLogDetail} back={back} environment={environment} />
    )
  }
  return (
    <div className="table-wrapper" >
      <Table pagination={{ pageSize }} locale={locale} columns={columns} dataSource={hooksLog} />
    </div>
  )
}


export default HooksLog