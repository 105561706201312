import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axiosBW from '../../../utils/Http'
import { setGlobalLoader } from '../../../actions/actions'
import { Modal, Input } from 'antd'
import { toast } from 'react-toastify'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet'
import MasterDataBox from './components/MasterDataBox/MasterDataBox'
import './MasterDataList.scss'

const MasterDataList = (props) => {
  const dispatch = useDispatch()
  const companyId = useSelector(state => state.user.companyId)
  const userPermissions = useSelector(state => state.user.permissions)
  const userEmail = useSelector(state => state.user.email)
  const [tableList, setTableList] = useState([])
  const [tableIdList, setTableIdList] = useState([])
  const [visible, setVisible] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [newTableName, setNewTableName] = useState('')

  const loadInfo = async() => {
    let tablesByCompany
    try {
      tablesByCompany = await axiosBW.get(`/master-data/master-collections-by-company/${companyId}`)
    } catch (ex) {
      console.log('error at process-by-company', ex)
    }
    if (!tablesByCompany || !tablesByCompany.data) {
      Modal.error({
        title: 'Falla',
        content: 'Ha ocurrido una falla al obtener la lista de tablas. Intente más tarde.'
      })
      return
    }
    // console.log('res', resProcessByCompany.data)
    setTableList(tablesByCompany.data.payload)
    setTableIdList(tablesByCompany.data.payload.map(p => p._id))
  }

  useEffect(() => {
    (
      async () => {
        console.log(userPermissions)
        if (!userPermissions.some( p => p === 'admin' || p === 'table-list')) {
          toast.error('No posees los permisos para acceder a esta sección')
          props.history.push('/')
        }
        dispatch(setGlobalLoader(false))
        await loadInfo()
    })()
  }, [])

  const showModal = () => {
    setVisible(true)
  }

  const showModalEditFn = (tableName) => {
    setShowModalEdit(true)
    setNewTableName(tableName)
  }

  const cancelModal = () => {
    setVisible(false)
    setShowModalEdit(false)
    setNewTableName('')
  }

  const createNewTable = async () => {
    if (newTableName.trim() === '') {
      cancelModal()
      Modal.error({
        title: 'Error',
        content: 'Debe ingresar un nombre de tabla válido.'
      })
      return
    }
    try {
      const name = newTableName
      cancelModal()
      dispatch(setGlobalLoader(true))   
      await axiosBW.post(`/master-data/table-create/${companyId}`,{
        table: name
      })
      await loadInfo()
      dispatch(setGlobalLoader(false)) 
    } catch (error) {
      console.log(error.response.data);
      let errMsg = 'Ha ocurrido un problema al crear la tabla'
      if (error.response && error.response.data) {
        const data = error.response.data
        if (data.status == -10) {
          errMsg += `: tabla '${newTableName}' ya existe.`
        } else {
          errMsg += ': ' + data.message
        }
      }
      dispatch(setGlobalLoader(false)) 
      cancelModal()
      Modal.error({
        title: 'Error',
        content: errMsg
      })
      return
    }
  }

  const MasterDataListContainer = () => (
    <div className="table-list-container">
      <Modal
        width={360}
        closable={false}
        centered={true}
        visible={visible}
        title={(<div style={{alignContent: 'center', textAlign: 'center'}}>Nueva Tabla de Datos Maestros</div>)}
        onOk={createNewTable}
        onCancel={cancelModal}
        cancelText="Cancelar"
        okText="Crear"
      >
        <div className="modal-form">
          <div className="label">
            Nombre de la tabla
          </div>
          <Input
            type="text"
            name="newTableName"
            value={newTableName}
            onChange={e => setNewTableName(e.target.value)}
          />
          <div className="label">
            <br/>
            Los nombres de tabla son sensitivos a mayúsculas y minúsculas ('Tabla' es distinto de 'tabla').
          </div>
        </div>
      </Modal>
      {
        tableList.map((table, i) => {
          return (
            <MasterDataBox 
              table={table} 
              showModalEditFunc={showModalEditFn} 
              key={table._id}
              id={table._id}
              reloadFunc={loadInfo}
            />
          )
        })
      }
      <div className="table-box" style={{cursor: 'pointer'}} onClick={() => {showModal()}}>
        <div className="table-box-content">
          <p className="table-title-text"><b>Nueva Tabla</b></p>
          <div className="table-icon add-workflow-icon">
            <PlusCircleOutlined />              
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <Helmet>
          <title> Lista de Tablas Maestras - Blizwork </title>
      </Helmet>
      { MasterDataListContainer() }
    </>
  )
}

export default MasterDataList