import React from 'react'
import './FormWidget.scss'

const FormWidget = props => {

  return (
    <div className="form_widget__wrapper">
      <div className={`form_widget`}>
        <img className="form_widget__icon" src={props.ico_url} alt={props.title}/> {props.title}
      </div>
    </div>
  )
}

export default FormWidget