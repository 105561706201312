/* eslint-disable react/display-name */
import React, { useEffect, useState }from 'react'
import axiosBW from '../../../../utils/Http';
import { Modal, Switch, Tooltip } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import ZingChart from 'zingchart-react'
import './AnalyticChart.scss'
import { LoadingOutlined, LineChartOutlined } from '@ant-design/icons';
import { Link  } from 'react-router-dom'

const AnalyticChart = ({analytic, companyId, processSlug  }) => {
  const dispatch = useDispatch();
  // const StateIcon = process.state === 'public' ? UnlockOutlined : LockOutlined
  const [showAnalytic, setShowAnalytic] = useState(false);
  const [series, setSeries] = useState([]);
  const [chartProperties, setChartProperties] = useState({});

  useEffect(() => {
    
    (async () => {

      await evaluateChart()

    })();
    
  }, []);
  const evaluateChart = async() => {
    try {
      console.log('va el AnalyticChart');
      const parsedProperties = analytic.type !== 'simple' ? JSON.parse(analytic.parameters) : {}
      // dispatch(setGlobalLoader(true))
      const resEvaluate = await axiosBW.post(`analytics/evaluate-analytic/`, {
        companyId,
        processSlug,
        code: analytic.code
      })
      console.log('va el AnalyticCharst');
      if(resEvaluate && resEvaluate.data) {
        if(resEvaluate.data.status === 0) {
          const evaluatedData = resEvaluate.data.payload
          parsedProperties.type = analytic.type
          if(parsedProperties.type === 'bar'|| parsedProperties.type === 'hbar' || parsedProperties.type === 'line') {
            if (parsedProperties.scaleX){
              parsedProperties.scaleX.labels = evaluatedData.separatorLabels
            } else {
              parsedProperties.scaleX = { labels: evaluatedData.separatorLabels }
            }

          }
          parsedProperties.series = evaluatedData.series
          parsedProperties.title = {
            "text" : analytic.title
          }
          parsedProperties.subtitle = {
            "text" : analytic.description,
            "font-weight" : "normal"
          }
          setSeries(evaluatedData.series)
          setChartProperties(parsedProperties)
          console.log('seteamos show')
          setShowAnalytic(true)
        }
      }
      
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="analytic-chart-container" >
      
        {showAnalytic ? ( chartProperties.type !== 'simple' ? (
          <div className="analytic-chart">
            <ZingChart className="zingchart-style" style={{borderRadius: '22px'}} width="100%" height="100%" data={chartProperties} series={series}   />
          </div>
        ) : 
          (
            <div className="simple_analytic" style={{width: "100%"}}>
               <div className="simple_analytic_image">
                 {
                   // <img src={e.parameters.imgPath} />
                   <LineChartOutlined />
                 }
               </div>
               <div className="simple_analytic_data">
                 <div className="simple_analytic_number">
                   {series[0] ? series[0].values[0]: '0'}
                 </div>
                 <div className="simple_analytic_text">
                  <div className="simple_analytic_title">
                    {chartProperties.title.text}
                  </div>
                  <div className="simple_analytic_description">
                    {chartProperties.subtitle.text}
                  </div>
                 </div>
               </div>
             </div>
          )
        ): (
          <div className="analytic-chart"><LoadingOutlined /></div>
        )}
      
    </div>
  )
}


export default AnalyticChart