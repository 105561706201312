import React, { Component } from 'react'
import { connect } from 'react-redux'
// import axios from 'axios';
// import cf from '../../../config/APIConfig'
import axiosBW from '../../../utils/Http';
import { Link } from 'react-router-dom'
import './FinishCase.scss'
import ReactMarkdown from 'react-markdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone'
// import Tabs from '../../../components/Library/Tabs/Tabs';
import Table from '../../../components/Library/Table/Table';
import { setGlobalLoader } from '../../../actions/actions'
import { notificationValidationHandler } from '../../../utils/Helpers';
import { Helmet } from 'react-helmet';
import { Modal, Collapse, Tabs} from 'antd';

const { TabPane } = Tabs;

const { Panel } = Collapse;

class FinishCase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      finalMessage: '',
      finalTitle: '',
      overviewData: [],
      attachedLabel: '',
      messagingAttachmentFilename: '',
      messagingUrlAttachment: '',
      messagingButtonDisabled: '',
      sendMessageStatus: '',
      messageList: [],
      processName: '',
      caseNumber: '',
      overviewForm: [],
      formsList: [],
      activeTab: '0'
    }
    this.onChangeTab = this.onChangeTab.bind(this)
  }  

  async componentDidMount() {
    let resCloseCase
    try {
      resCloseCase = await axiosBW.post('/case/close-case', {
        caseId: this.props.match.params.caseId
      })
    } catch (ex) {
      console.log('Error resCloseCase', ex)
    }
    if(!resCloseCase || !resCloseCase.data) {
      Modal.error({
        title: 'Error',
        content: 'Error al Cerrar Caso, intente nuevamente'
      })
      return
    }
    this.setState({
      finalTitle: '¡Caso Cerrado Exitosamente!',
    })

    let resCaseOverview
    try {
      resCaseOverview = await axiosBW.get(`/case/overview/${this.props.match.params.caseId}`)
    }  catch (ex) {
      console.log('Error resCloseCase', ex)
      if(!ex || !ex.response || ex.response.status === 403) {
        notificationValidationHandler('Usted no tiene permisos para ver este caso')
        setTimeout(() => {
          this.props.history.push('/')
        }, 2000)
      }
    }
    if(!resCaseOverview || !resCaseOverview.data) {
      Modal.error({
        title: 'Error',
        content: 'Error al Mostrar el Resumen del Caso, intente nuevamente'
      })
      return
    }
    this.setState({
      overviewData: resCaseOverview.data.payload && resCaseOverview.data.payload.fieldValues.length > 0 ? resCaseOverview.data.payload.fieldValues : [],
      overviewForm: resCaseOverview.data.payload ? resCaseOverview.data.payload : [],
      finalMessage: `Muchas gracias por utilizar el sistema de ${resCaseOverview.data.payload.processName}`
    })
    let resMessages
    try {
      resMessages = await  axiosBW.get(`/messaging/messages-by-case/${this.props.match.params.caseId}`)
    } catch (ex) {
      console.log('Error en MessagesByCase', ex)
    }
    if(!resMessages || !resMessages.data) {
      Modal.error({
        title: 'Error',
        content: 'Error al Mostrar los mensajes del Caso, intente nuevamente'
      })
      return
    }
    this.setState({
         messageList: resMessages.data.payload
    })
    // getFormsExecuted API call
    let resGetFormsExecuted
    try {
      resGetFormsExecuted = await axiosBW.get(`/case/getFormsExecuted/${this.props.match.params.caseId}`)
    } catch (ex) {
      console.log('error resGetFormsExecuted', ex)
    }
    if(!resGetFormsExecuted || !resGetFormsExecuted.data) {
      Modal.error({
        title: 'Error',
        content: `No fue posible obtener la lista de formularios`,
      })
      return
    }
    this.setState({
      formsList: resGetFormsExecuted.data.payload
    })
    this.props.setGlobalLoader(false)
  }

  async onChangeTab(tabId) {
    this.setState({
      activeTab: tabId
    })
  }

  firstTab() {
    return (
      <div className="form_case__wrapper">
        <header className="form_case__header">
          <h1>{this.state.finalTitle}</h1>
          <div className="">
            <p>{this.state.finalMessage}</p>
          </div>
        </header>
        <div className="overview">
        {
          this.state.overviewData && this.state.overviewData.length > 0 ? (
            
                <div key={`overv`} className="overview_case__form">
                  <div className="overview_case__form_title">
                    Resumen
                  </div>
                  <div className="overview_case__form_content">
                    {
                      this.state.overviewData.map((e, i) => {
                        return (
                            <div key={`form-${e._id}`} className="overview_case__form_data">
                            {e.type !== 'markdown' && e.type !== 'hidden' ? (
                            <div className="overview_case__form_label">
                              {e.name}
                            </div>
                            ) : ''}
                            
                            <div className="overview_case__form_value">
                              {
                                e.type === 'markdown' ? (
                                  <ReactMarkdown escapeHtml={false} source={e.defaultValue} />
                                ): ''
                              }
                             {
                                e.type === 'select' ? (
                                  <span>{e.option ? e.option.value : 'none'}</span>
                                ) : ('')
                              }
                              {
                                e.type === 'hidden' ? (
                                  <div></div>
                                ) : null
                              }
                              {
                                e.type !== 'markdown' && 
                                e.type !== 'select' && 
                                e.type !== 'table' && 
                                e.type !== 'hidden' ? (
                                  <span>{e.defaultValue} {e.fileUrl && e.fileUrl.length > 0 ? <a href={e.fileUrl}> Ver archivo </a> : ''} </span>     
                                ) : ('')
                              } 
                            </div>
                          </div>
                        )
                      })
                    }
                    {
                      this.state.overviewData.filter(e => e.type === 'hidden').length > 0 ? (
                        <Collapse style={{margin: '20px 0'}}>
                          <Panel header="Campos Ocultos" key="1">
                            {
                              this.state.overviewData.filter(e => e.type === 'hidden').map((elem, index) => {
                                return (
                                  <div key={`form-${elem._id}`} className="overview_case__form_data">
                                    <div className="overview_case__form_label">
                                      {elem.name}
                                    </div>
                                    <div className="overview_case__form_value">
                                      {elem.defaultValue}
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </Panel>
                        </Collapse>
                      ) : null
                    }
                  </div>
                  
                </div>
              ) : ''
            }
        </div>
        
        <div>
          <Link to="/">Ir a mi Escritorio</Link>
        </div>
      </div>
    )
  }

  secondTab() {
    return (
      <div className="form_case__inside">
        <div className="form_case__wrapper form_case__wrapper--case form_case__wrapper--history">
          <header className="form_case__header">
            <div className="form_case__back_arrow" onClick={(e) => this.props.history.push('/')}>
              <FontAwesomeIcon icon="arrow-left" />
            </div>
            <div className="messagebox__wrapper">
              <section className="messagebox">
              {
                this.state.messageList.map((elem, ind) => {
                  return (
                    <div key={`message-${ind}`} className={`messagebox__element ${this.props.userId === elem.userId ? 'messagebox__element--counterpart' : '' }`} >
                  <header className="messagebox__element_header">
                    <div className="messagebox__element_username">
                      {elem.firstName}
                    </div>
                    <div className="messagebox__element_date">
                      {moment(elem.messageDate).tz(this.props.timeZone).format('YYYY-MM-DD H:m')}
                    </div>
                  </header>
                  <div className="messagebox__element_content">
                    <p> {elem.message} </p>
                  </div>
                  <footer className="messagebox__element_footer">
                  {elem.fileUrl && elem.fileUrl.length > 0 ? (
                    <div className="messagebox__element_attachmentlist">
                      <div className="messagebox__element_attachment_wrapper">
                        <div className="messagebox__element_attachment_title">
                          Adjunto
                        </div>
                        <div className="messagebox__element_attachment">
                          <a href={elem.fileUrl}>{elem.filename} <FontAwesomeIcon icon="eye" /> </a>
                        </div>
                      </div>
                    </div>
                  ) : ''}
                  </footer>
                </div>
                  )
                })
              }
              </section>
            </div>
          </header>
          <div className="messaging__wrapper">
            <section className="messaging">
              <h2>El caso está cerrado, no puede seguir interactuando</h2>
            </section>
          </div>
        </div>
      </div>
    )
  }
  thirdTab() {
    const headStyle =  {
      fontSize: '13px',
      color: '#333333',
      background: 'white',
      fontWeight:'bold',
      borderBottom: '1px solid rgba(0,0,0,.2)'
    }
    return (
      <div className="form_case__inside">
        <div className="form_case__wrapper form_case__wrapper--case form_case__wrapper--history">
          <header className="form_case__header case_history">
            <div className="overview_case__back_arrow" onClick={() => this.props.history.push('/')}>
              <FontAwesomeIcon icon="arrow-left" />
            </div>
            <div className="form_case__title">
              <h1>
                Historial de Formularios
              </h1>
            </div>
          </header>
          <div className="case_history__table_wrapper">
            {
              this.state.formsList.length > 0 ? (
                <Table
                  data={this.state.formsList}
                  columns={ [{
                    key: 'fecha',
                    title: 'Fecha',
                    headStyle,
                    render: (r) => {
                      return (
                        <div className="case_list__datatable">
                          {r.createAudit.timeStamp ? moment(r.createAudit.timeStamp).tz(this.props.timeZone).format('YYYY-MM-DD hh:mm:ss') : 'N/A'}
                        </div>
                      )
                    }
                  },
                  {
                    key: 'Usuario',
                    title: 'Usuario',
                    headStyle,
                    render: (r) => {
                      return (
                        <div className="case_list__datatable">
                          {r.usuario.firstName} {r.usuario.lastName}
                        </div>
                      )
                    }
                  }, 
                  {
                    key: 'Actividad',
                    title: 'Actividad',
                    headStyle,
                    render: (r) => {
                      return (
                        <div className="case_list__datatable">
                          {r.formTitle}
                        </div>
                      )
                    }
                  }
                ]}
                />
              ) : (
                <div className="h_error">
                  Debe completar al menos un formulario para poder ver el historial.
                </div>
              )
            }
            
          </div>
        </div>
      </div>
    )
  }

  tabsServices() {
    return [
      {
        title: `Resumen del Caso ${this.state.overviewForm && this.state.overviewForm.caseNumber ? this.state.overviewForm.caseNumber : ''}`,
        content: this.firstTab()
      },
      {
        title: 'Mensajería',
        content: this.secondTab()
      },
      {
        title: 'Historial del Caso',
        content: this.thirdTab()
      }
    ]
  }
  

  render() {
    const tabs = this.tabsServices();
    return (
      <>
        {/* <Tabs key={this.state.currentForm} tabs={this.tabsServices()} isMobile={this.props.isMobile} /> */}
        <Tabs
            onChange={this.onChangeTab}
            activeKey={this.state.activeTab}
            className={`tabs_finish_case ${this.props.isMobile ? 'tabs-mobile' : ''}`}
            // type="line"
            // pagination={false}
          >
            {tabs.map((pane, i) => (
              <TabPane tab={pane.title} key={i}>
                {pane.content}
              </TabPane>
            ))}
          </Tabs>
        <Helmet>
          <title> Caso Finalizado - Blizwork</title>
        </Helmet>
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState))
})
const mapStateToProps = (state) => ({
  userId: state.user.userId,
  timeZone: state.user.timeZone
})

export default connect(mapStateToProps, mapDispatchToProps)(FinishCase)