import React from 'react'
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend'
import Tasks from './tasks';
function newDnd({Component, pageProps}) {
  return (
    <DndProvider backend={Backend}>
      <div className="wrapper">
        <h1>Holaaa</h1>
        <Tasks />
      </div>
    </DndProvider>
  )
}
export default newDnd