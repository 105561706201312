import React from 'react'
import { Route } from 'react-router-dom'
// This component will be used to set a previous layout to wrap the component.
function RouteWithLayout({ layout, component, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        React.createElement(
          layout,
          props,
          React.createElement(component ? component : render, props)
        )
      }
    />
  )
}

export default RouteWithLayout
