import {
  SET_USER_DATA,
  RESET_USER_DATA,
} from '../actions/userActions';



const initialState = {
  token: undefined,
  companyId: undefined,
  fullName: undefined,
  userId: undefined,
  logoUrl: undefined,
  fantasyName: undefined,
  legalName: undefined,
  nameInitials: undefined,
  profileImage: undefined,
  companyPlan: undefined,
  permissions: [],
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  haveToCompleteData: undefined,
  firstRedirect: undefined,
  adminMessage: undefined,
  adminMessageClosed: false,
  timeZone: 'America/Santiago'
};



const user = (state = initialState, action) => {
  switch(action.type) {
    case SET_USER_DATA: {
      const userInfo = action.payload.user
      const userToken = action.payload.token
      return {
        ...state,
        token: userToken || state.token,
        companyId: userInfo.companyId,
        fullName: `${userInfo.firstName} ${userInfo.lastName}`,
        userId: userInfo._id,
        logoUrl: userInfo.companyImage,
        fantasyName: userInfo.companyName,
        legalName: userInfo.legalName,
        nameInitials: `${userInfo.firstName.charAt(0).toUpperCase()}${userInfo.lastName.charAt(0).toUpperCase()}`,
        profileImage: userInfo.profileImage,
        companyPlan: userInfo.companyPlan,
        permissions: userInfo.permissions || state.permissions,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        haveToCompleteData: userInfo.haveToCompleteData,
        firstRedirect: userInfo.firstRedirect,
        adminMessage: userInfo.adminMessage,
        timeZone: userInfo.timeZone,
        featuredCollapse: userInfo.featuredCollapse || false,
      };
    }
    case RESET_USER_DATA:
      return initialState
    default: {
      return {...state}
    }
  }
}

export default user