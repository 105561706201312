import posed from 'react-pose'

//posed Animations
export const Collapsed = posed.div({
  hidden: { opacity: 0, height: 0, applyAtEnd: { display: 'none' } },
  visible: {
    opacity: 1,
    height: 'auto',
    applyAtStart: { display: 'block' },
  },
})

export const CollapsedTr = posed.tr({
  hidden: { opacity: 0, height: 0, applyAtEnd: { display: 'none' } },
  visible: {
    applyAtStart: { display: 'table-row' },
    opacity: 1,
    height: 'auto',
  },
})

export const Dissapear = posed.div({
  hidden: { applyAtEnd: { display: 'none' }, opacity: 0 },
  visible: { applyAtStart: { display: 'block' }, opacity: 1 },
})

export const DissapearFlex = posed.div({
  hidden: { applyAtEnd: { visibility: 'hidden' }, opacity: 0 },
  visible: { applyAtStart: { visibility: 'visible' }, opacity: 1 },
})
