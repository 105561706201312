import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Input, Button, Form, Image } from 'antd';
import { Link } from 'react-router-dom'
import './Login.scss'
// import axios from 'axios'
// import cf from '../../../config/APIConfig'
import axiosBW from '../../../utils/Http';
import { setGlobalLoader } from '../../../actions/actions'
import { setUserData } from '../../../actions/userActions'

const Login = (props) => {
  const dispatch = useDispatch()
  const isMobile = useSelector(state => state.navigation.isMobile)
  // eslint-disable-next-line no-unused-vars
  const [loginLink, setLoginLink] = useState('/ingresar')
  const [errorMsg, setErrorMsg] = useState(null)
  const [form] = Form.useForm();
  const query = new URLSearchParams(props.location.search)
  console.log(query.get('hola'))

  const onFinish = async (values) => {
    console.log('Received values of form: ', values);
    dispatch(setGlobalLoader(true))
    setErrorMsg(null)
    try {
      const resp = await axiosBW.post(`/auth/login`,
        {
          password: values.password,
          email: values.email
        }
      );
      dispatch(setGlobalLoader(false))
      console.log(resp.data)
      const data = resp.data
      if (data.message.length === 0) {
        dispatch(setUserData(data.payload.user, data.payload.token))
        // this.setState({
        //   messageForm: {
        //     title: 'Bienvenido',
        //     description: `${this.props.userFullName}. Será redireccionado en un momento`,
        //     icon: <FontAwesomeIcon style={{color: 'green'}} icon="check-circle" />
        //   }
        // })
        redirectTo(data.payload.user)
      }

    } catch (err) {
      localStorage.clear()
      dispatch(setGlobalLoader(false))
      if (err.response && err.response.data && err.response.data.message) {

        const respMsg = err.response.data.message
        setErrorMsg(respMsg)
        switch (respMsg) {
          case 'No user': {
            setErrorMsg('El usuario que está intentando ingresar no existe')
            break
          }
          case 'Admin approval pending': {
            setErrorMsg('Su inscripción está a la espera de la activación para poder ingresar. Cuando su cuenta está activada recibirá un aviso por correo electrónico.')
            break
          }
          case 'Admin approval rejected': {
            setErrorMsg('Su solicitud de inscripción ha sido rechazada. Para más detalles favor de contactar a la mesa de ayuda o a la empresa que lo ha derivado a la plataforma.')
            break
          }
          case 'Account confirmation pending': {
            setErrorMsg('Para comenzar a utilizar la plataforma es necesario confirmar su correo electrónico. Se ha enviado un mensaje de confirmación a su correo.')
            break
          }
          case 'Not remaining attempts': {
            setErrorMsg('Ha excedido el máximo de intentos de inicio de sesión. Por motivos de seguridad, deberá Restablecer su contraseña.')
            break
          }
          case 'Bad pass': {
            setErrorMsg('Correo electrónico y/o Contraseña incorrecta.')
            break
          }
          default: {
            setErrorMsg(`Hubo un error al intentar iniciar sesión. Código de Error: ${err.response.status}`)
            break
          }
        }
      } else {
        setErrorMsg('Ha ocurrido un error con el registro')
      }
    }
  };
  const redirectTo = (user) => {
    const query = new URLSearchParams(props.location.search)
    if (user.haveToCompleteData) {
      props.history.push(`/ultimo_paso`)
    } else if (user.firstRedirect) {
      props.history.push(`/${user.firstRedirect}`)
    } else {
      if (query.get('redirect_to') !== null) {
        const redirectTo = query.get('redirect_to')
        const msgId = query.get('msgId')
        if (query.get('msgId') !== null) {
          props.history.push(`/${redirectTo}&msgId=${msgId}`)
        } else {
          props.history.push(`/${redirectTo}`)
        }
      } else {
        props.history.push(`/#`)
      }
    }
  }

  // Reemplazo de componentDidMount
  useEffect(() => {
    if (query.get('firstRedirect') && query.get('firstRedirect').length > 0) {
      setLoginLink(`/ingresar?redirect_to=${query.get('firstRedirect')}`)
    }
    async function getUserInfo () {
      try {
        const res = await axiosBW.get(`/auth/user-info`);
        console.log(res)
        const user = res.data.payload.user
        const token = res.data.payload.token
        if (res.data.message.length === 0) {
          dispatch(setUserData(user, token))
        }
        redirectTo(user)
        dispatch(setGlobalLoader(false))
      } catch (err) {
        console.log(err)
        dispatch(setGlobalLoader(false))

      }
    }
    dispatch(setGlobalLoader(true))
    getUserInfo()

    // dispatch(getMyTeamInfo())
  }, []);
  // const dispatch = useDispatch();
  // //useSelector reemplaza el mapStateToProps
  // const users = useSelector(state => state.workflowDesigner.usersByRole);
  // const companyId = useSelector(state => state.workflowDesigner.workflow.companyId);

  // if (!users) {
  //   return 'No USERS'
  // }
  const LoginForm = () => (
    <>
      <div className="login_form_title">
        Bienvenid@ a tu espacio de trabajo
      </div>
      <div className="login_form_subtitle">
        <p>BlizWork.com el No.Software de las nuevas empresas</p>
      </div>
      <Form
        form={form}
        size="large"
        name="login"
        onFinish={onFinish}
        preserve={false}
        initialValues={{
          "companyType": 1,
        }}
        scrollToFirstError
      >

        <Form.Item
          className="login_form_field"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Email inválido',
            },
            {
              required: true,
              message: 'Email requerido',
            },
          ]}
        >
          <Input placeholder="Ingrese el Email" />
        </Form.Item>
        <Form.Item
          className="login_form_field"
          name="password"
          rules={[
            {
              required: true,
              message: 'Contraseña requerido',
            },
          ]}
        >
          <Input.Password placeholder="Contraseña" />
        </Form.Item>
        <div className="login_login_message">¿Has olvidado tu contraseña? <Link to="/recuperar_password">Recuperala</Link>.</div>
        {errorMsg ? (
          <div className="login_error_message">
            {errorMsg}
          </div>
        ) : (
          <div className="login_error_message_block"></div>
        )}
        <Button className="login-button" type="primary" htmlType="submit"
        >
          Inicia sesión
        </Button>
        <p style={{ fontSize: "11px", textAlign: "center" }}>Rel. 3.3.1</p>
      </Form>
    </>
  )
  if (isMobile) {
    return (
      <div className="login_form_wrapper_mobile">
        <LoginForm />
      </div>
    )
  }
  return (
    <div className="login_back">
      <div className="header-login">

        <Image
          className="bliz-logo"
          preview={false}
          src='\img\blizwork-logo.png'
        />
        {!isMobile ? (<div className="login_register_message"><Link to="/registrar">Unirse ahora, crea tu cuenta gratuita</Link>.</div>) : ''}

      </div>
      <div className="login_wrapper">
        <Row>
          {/* <Col flex="0 1 auto">
            {
              !isMobile ? (
                <Image
                  preview={false}
                  src='\img\registro.png'
                />
              ) : ''
            }
            
          </Col> */}
          <Col flex="auto">

            <div className="login_form_wrapper">
              <LoginForm />
            </div>

          </Col>
        </Row>
      </div>
      <div className="footer-login">
        <div className="footer-element"><a target="_blank" rel='noreferrer' href="https://www.blizwork.com/blog/">Blog</a></div>
        <div className="footer-element"><a target="_blank" rel='noreferrer' href="https://www.blizwork.com/casos-de-exito-blizwork/">Casos de exito</a></div>
        <div className="footer-element"><a target="_blank" rel='noreferrer' href="https://www.youtube.com/channel/UCzR3k_tqlVHpjKg8fhKlUjQ">Tutorial</a></div>
        <div className="footer-element"><a target="_blank" rel='noreferrer' href="https://www.blizwork.com/docs/blizwork-api/">API</a></div>
        <div className="footer-element"><a target="_blank" rel='noreferrer' href="https://www.linkedin.com/company/blizwork?">Publicaciones</a></div>
      </div>
    </div>
  )
}
export default Login