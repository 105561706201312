import React, { Component } from 'react'
import FormList from './FormList.view'
import { connect } from 'react-redux'
import { setGlobalLoader, setSelectedForm } from '../../../actions/actions' 
// import axios from 'axios'
// import cf from '../../../config/APIConfig'
import axiosBW from '../../../utils/Http';
import { Helmet } from 'react-helmet'


class FormListContainer extends Component {
  constructor(props) {
    super(props)
    this.state={
      formList: [],
      processTitle: '',
      processSlug: '',
      processVersion: '',
      existDraft: false,
      newFormSlug: '',
      showModalNew: false
    }
  }
  async componentDidMount() {
    this.props.setGlobalLoader(false)
    // form data request
    let formListRes
    try {
      formListRes = await axiosBW.get(`/process/form-list/${this.props.match.params.processId}/`)
    } catch (ex) {
      console.log('error formList', ex)
    }
    if(!formListRes || !formListRes.data) {
      return
    }
    this.setState({
      formList: formListRes.data.payload.forms,
      processTitle: formListRes.data.payload.title,
      processSlug: formListRes.data.payload.slug,
      processVersion: formListRes.data.payload.version,
      existDraft: formListRes.data.payload.draft,

    })
  }

  changeFormSlug(value) {
    this.setState({
      newFormSlug: value
    })
  }
  goBack = () => {
    this.props.history.push(`/procesos`)
  }

  createNewForm(formList) {
    const myObject = {
      type: 'exec',
      slug: this.state.newFormSlug,
      roleAssignment: '',
      title: '',
      description: '',
      actionOnTimeout: {
        timeout: 0
      },
      actions: [],
      fields: [],
      userId: '',
    }
    if(this.state.newFormSlug.length > 0) {
      axiosBW.post(`/process/create-form/${this.props.companyId}/${this.props.match.params.processSlug}`, myObject)
      .then(res => {
          console.log('res.data', res.data)
          this.setModalNew(false)
          this.props.history.push(`/form-builder/${this.props.companyId}/${this.props.match.params.processSlug}/${this.state.newFormSlug}`)
      })
      .catch(err => {
        console.log('error', err)
      })
      this.props.setSelectedForm(formList)
  
    }
    return
  }

  selectForm(id) {
    const filteredElement = this.state.formList.filter(el => el._id === id)
    console.log('filtered', filteredElement)
    this.props.setSelectedForm(filteredElement[0] ? filteredElement[0] : [])
    this.props.history.push(`/form-builder/${this.props.companyId}/${this.props.match.params.processSlug}/${filteredElement[0].slug}`)
  }

  setModalNew(status) {
    this.setState({
      showModalNew: status
    })
  }
  
  render() {
    return (
      <div>
        <FormList 
          {...this.state} 
          createNewForm={(formList) => this.createNewForm(formList)}
          selectForm={(id) => this.selectForm(id)}
          isLoading={this.props} 
          newFormSlug={this.props.newFormSlug}
          changeFormSlug={e => this.changeFormSlug(e)}
          showModalNew={this.state.showModalNew}
          setModalNew={status => this.setModalNew(status)}
          processSlug={this.props.match.params.processSlug}
          processTitle= {this.state.processTitle}
          processVersion= {this.state.processVersion}
          existDraft= {this.state.existDraft}
          goBack= {this.goBack}
          history={this.props.history}
          userPermissions={this.props.userPermissions}
        />
        <Helmet>
          <title> Lista de Formularios - Blizwork </title>
        </Helmet>
      </div>
    )
  } 
}

const mstp = state => ({
  showGlobalLoader: state.navigation.showGlobalLoader,
  formList: state.formList.selectedForm,
  companyId: state.user.companyId,
  userPermissions: state.user.permissions
})

const mdtp = dispatch => ({
  setGlobalLoader: status => dispatch(setGlobalLoader(status)),
  setSelectedForm: formList => dispatch(setSelectedForm(formList))
})

export default connect(mstp, mdtp)(FormListContainer) 