let base
const env = process.env.REACT_APP_AB ? process.env.REACT_APP_AB.trim() : 'development'
switch (env) {
  case 'development': {
    base = {
      url: 'http://localhost:3003/',
      analytics: 'http://localhost:3002/'
    }
    break
  }
  case 'stage': {
    base = {
      url: 'https://webbackend_stage.blizwork.com/',
      analytics: 'https://analytics_stage.blizwork.com/'
    }
    break
  }
  case 'production': {
    base = {
      url: 'https://webbackend.blizwork.com/',
      analytics: 'https://analytics.blizwork.com/'
    }
    break
  }
  default: {
    base = {
      url: 'http://localhost:3003/',
      analytics: 'http://localhost:3002/'
    }
    break
  }
}

const obj = {
  BASE_URL: base,
}

export default obj
