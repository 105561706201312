/* eslint-disable react/display-name */
import React from 'react'
// import { connect } from 'react-redux'
import {Row, Col, Modal } from 'antd';
import { ScrollSyncPane } from 'react-scroll-sync';
import './RoleSwimlane.scss'
import FormBox from '../FormBox/FormBox'
import { useSelector } from "react-redux";
import { MinusCircleOutlined, UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import ScrollContainer from 'react-indiana-drag-scroll'
// import { deleteWorkflowRole, moveUpWorkflowRole, moveDownWorkflowRole } from '../../../../../actions/WorkflowDesignerActions'

const RoleSwimlane = React.forwardRef(({ roleName, forms, processSlug, selectRole, emitDeleteRole, emitMoveDownRole, emitMoveUpRole, setMaxColumns, createLines, updateSelected, thisFormIsEndOfAction, lines, unselectLines, selectAction }, ref) => {
  const draftMode = useSelector(state => state.workflowDesigner.draftMode)
  const workflow = useSelector(state => state.workflowDesigner.workflow)
  let canMoveUp = false
  let canMoveDown = false
  let canDeleteRole = false
  if (roleName !== 'customer' && workflow && draftMode) {
    const roleIndex = workflow.roles.findIndex(r => r === roleName)
    const rolesLength = workflow.roles.length
    canMoveUp = roleIndex > 1 || false
    canMoveDown = roleIndex < rolesLength - 1 || false
    canDeleteRole = true
  }
  const onClick = () => {
    selectRole(roleName)
  }
  const deleteRole = () => {
    if (forms.find(f => f !== undefined)) {
      Modal.error({
        title: 'Error',
        content: 'No se puede borrar un Rol con formularios'
      })
      return
    } else {
      // dispatch(deleteWorkflowRole(roleName))
      emitDeleteRole(roleName)
    }
  }
  const moveDownRole = () => {
    // dispatch(moveDownWorkflowRole(roleName))
    emitMoveDownRole(roleName)
  }
  const moveUpRole = () => {
    // dispatch(moveUpWorkflowRole(roleName))
    emitMoveUpRole(roleName)
  }

  return (
    <Row style={{flexWrap: 'nowrap'}} className="role-swimlane">
      <Col flex="0 1 50px" className="role-options">
      <ul className ="option-list">
        <li >
          {canMoveUp ? (
            <p className="option-action" onClick={()=> moveUpRole()}>
              <UpCircleOutlined />
            </p>
          ): ''}
        </li>
        <li>
          {canDeleteRole ? (
            <p className="option-action" onClick={() => deleteRole()}>
              <MinusCircleOutlined />
            </p>
          ): ''}
        </li>
        <li>
          {canMoveDown ? (
            <p className="option-action" onClick={()=> moveDownRole()}>
              <DownCircleOutlined />
            </p>
          ): ''}
        </li>
      </ul>
        
        
        
      </Col>
      <Col flex="0 1 50px" className="role-name" onClick={onClick}>
          <span>{roleName}</span>
      </Col>
      <ScrollSyncPane>
        <ScrollContainer ignoreElements=".form-box" id="swimlane" flex="auto" className="role-box">
          {forms.map((form, i) => {
            if (form) {
              return(
                <FormBox empty={false} form={form} processSlug={processSlug} key={i} ref={ref[form.slug]} id={form.slug} roleName={roleName} position={i} setMaxColumns={setMaxColumns} createLines={createLines} updateSelected={updateSelected} thisFormIsEndOfAction={thisFormIsEndOfAction} lines={lines} selectAction={selectAction} unselectLines={unselectLines} ></FormBox>
              )
            }
            return(
              <FormBox empty={true} roleName={roleName} position={i} key={i} setMaxColumns={setMaxColumns} ></FormBox>
            )
          })}
        </ScrollContainer>
      </ScrollSyncPane>
    </Row>

  )
})
// const mapStateToProps = (state) => {
//   const { workflow, draftMode } = state.workflowDesigner

//   return {
//     workflow,
//     draftMode
//   }
// }

// export default connect(mapStateToProps, null, null, {forwardRef : true})(RoleSwimlane)
export default RoleSwimlane
