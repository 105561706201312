import React, { useState, useEffect, useRef, Component } from 'react'
// import { VariableSizeGrid as Grid } from 'react-window'
import { useSelector, connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Table, Modal } from 'antd'
import classNames from 'classnames'
import ResizeObserver from 'rc-resize-observer'
import { setGlobalLoader } from '../../../actions/actions'
import axiosBW from '../../../utils/Http';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './MasterDataTable.scss'

let columns = [
  {
    title: 'A',
    dataIndex: 'key'
  }
]

const data = [
  {
    key: 'Cargando..'
  }
]

const assignWidthToColumns = (columnArray, tableWidth) => {
  const widthColumnCount = columnArray.filter(({ width }) => !width).length
  return columnArray.map((col) => col.width ? col : { ...col, width: Math.floor(tableWidth / widthColumnCount) })
}

function VirtualTable (props) {
  const { table, columns, scroll } = props
  const [currentColumns, setCurrentColumns] = useState(columns)
  const [tableWidth, setTableWidth] = useState(0)
  const widthColumnCount = currentColumns.filter(({ width }) => !width).length
  const mergedColumns = currentColumns.map((column) => {
    if (column.width) {
      return column;
    }
    return { ...column, width: Math.floor(tableWidth / widthColumnCount) };
  });
  const gridRef = useRef();
  const [connectObject] = useState(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => null,
      set: (scrollLeft) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft,
          });
        }
      },
    });
    return obj;
  });

  const companyId = useSelector(state => state.user.companyId)

  const setCurrentColumnsHook = () => {
    console.log('Loading table fiedls')
    axiosBW.get(`/master-data/schema/${table}/${companyId}`)
      .then((response) => {
        if (response) {
          const fieldList = response.data.payload.fields
          const newColumns = fieldList.map((field) => {
            return {
              title: field.name,
              dataIndex: field.name,
              key: field.name,
              width: 150
            }
          })
          setCurrentColumns(newColumns)
          // Load data
          console.log('Loading table data')
          axiosBW.get(`/master-data/data/${table}/${companyId}`)
            .then((response) => {
              this.dataSource = response.data.payload
            })
            .catch((error) => {
              Modal.error({
                title: 'Lo sentimos...',
                content: `Ha ocurrido un error al cargar los datos: ${error}`
              })
            })
        }
      })
      .catch((error) => {
        Modal.error({
          title: 'Lo sentimos...',
          content: `Ha ocurrido un error al cargar las columnas: ${error}`
        })
      })
  }

  useEffect(() => setCurrentColumnsHook(), [])

  const resetVirtualGrid = () => {
    gridRef.current.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    })
  }

  useEffect(() => resetVirtualGrid, [tableWidth])

  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
    ref.current = connectObject;
    const totalHeight = rawData.length * 54;
    let viewHeight = document.getElementById('table-container') ? document.getElementById('table-container').clientHeight : scroll.y
    viewHeight = viewHeight - 50
    return (
      <p>
        Hello world!
      </p>
    );
  };

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <Table
        {...props}
        className="virtual-table"
        columns={mergedColumns}
        pagination={false}
        components={{
          body: renderVirtualList,
        }}
      />
    </ResizeObserver>
  );
}

class MasterDataTable extends Component {

  constructor(props) {
    super(props)
  }

  async componentDidMount () {
    await this.props.setGlobalLoader(false)
  }


  render () {
    return (
      <>
        <Helmet>
          <title>{this.props.match.params.table} - Datos Maestros - BlizWork </title>
        </Helmet>
        <div className='mdtable-header'>
          <span className='mdtable-backarrow' onClick={(e) => { this.props.history.push('/masterdata') }}>
            <FontAwesomeIcon icon="arrow-left" />
          </span>
          Tabla: <b>{this.props.match.params.table}</b>
        </div>
        <div id='table-container' className='mdtable-content'>
          <VirtualTable
            table={this.props.match.params.table}
            columns={columns}
            dataSource={data}
            scroll={{
              y: 600,
              x: '100vw',
            }}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    userId: state.user.userId,
    companyId: state.user.companyId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setGlobalLoader: (newStatus) => dispatch(setGlobalLoader(newStatus))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterDataTable)
