import React from 'react'
import { connect } from 'react-redux';
import { BellOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';
import './Header.scss'
import { toggleSidebar, changeSearchText } from '../../actions/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

const Header = props => {
  const openMenu = () => {
    props.toggleSidebar(!props.sidebarVisible)
  }

  return <>
    <header className="header__wrapper">
      <div className="header">
        <section className="header__menu_icon" onClick={() => openMenu()}>
          <MenuOutlined />
        </section>
        <section className="header__searchbar">
          <input onChange={e => { props.changeSearchText(e.target.value)} } value={props.searchBarText} />
          <div className="header__search_ico">
            <FontAwesomeIcon icon="search" />
          </div>
        </section>
        <section className="header__alert_icon">
          <BellOutlined />
        </section>
        <section className="header__profile_icon">
          <Link to="/micuenta" style={{color: 'white'}}>
            <UserOutlined />
          </Link>
        </section>
      </div>
    </header>
  </>;
}

const mapStateToProps = state => {
  return {
    sidebarVisible: state.navigation.sidebarVisible,
    searchBarText: state.navigation.searchBarText
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleSidebar: isEnabled => dispatch(toggleSidebar(isEnabled)),
    changeSearchText: newText => dispatch(changeSearchText(newText))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)