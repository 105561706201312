import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Table, Row, Col, Modal, Input, Collapse, Select, Tabs, InputNumber, Space, Switch, Button, Upload, Tooltip } from 'antd';
import { PlusCircleOutlined, LoadingOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import './AddHookBox.scss'
import axiosBW from '../../utils/Http';
import { toast } from 'react-toastify';

const AddHookBox = ({hook, hookId, hookIndex, onDelete, readOnly, onEdit}) =>   {
  const [loading, setLoading] = useState(false);
  const [hookInfo, setHookInfo] = useState({});
  const [editHookVisible, setEditHookVisible] = useState(false);
  const [urlParams, setUrlParams] = useState([]);
  const [bodyParams, setBodyParams] = useState([]);

  useEffect(() => {
    console.log('va el hooks-log');
    console.log(hook);
    (async () => {
      setLoading(true)
      // await getDashboard()
      await getHookInfo()
      setLoading(false)
      if (hook.firstAdd) {
        showModal()
      }

    })();
    
  }, []);
  const getHookInfo = async() => {
    try {
      const res = await axiosBW.get(`hooks/hooks-info/${hookId}`)
      if(res && res.data) {

        if(res.data.status === 0) {
          // console.log(res.data.payload)
          setHookInfo(res.data.payload)
          setParams(res.data.payload)
        }
      }
    } catch(ex) {
      console.log(ex);
    }
  }
  const setParams = (hookData) => {
    const defUrlParams = [...hookData.urlParams]
    const defBodyParams = [...hookData.bodyParams]

    for (let i = 0; i < defUrlParams.length; i++) {
      const defParam = defUrlParams[i];
      const savedParam = hook.urlParams.find(p => p.name === defParam.name)
      defParam.value = savedParam ? savedParam.value : defParam.value
    }
    setUrlParams(defUrlParams)
    for (let i = 0; i < defBodyParams.length; i++) {
      const defParam = defBodyParams[i];
      const savedParam = hook.bodyParams.find(p => p.name === defParam.name)
      defParam.value = savedParam ? savedParam.value : defParam.value
    }
    setBodyParams(defBodyParams)
    
  }
  const setBodyParamValue = (index, value) => {
    const auxBodyParams = [...bodyParams]
    auxBodyParams[index].value = value
    setBodyParams(auxBodyParams)
  }
  const setUrlParamValue = (index, value) => {
    const auxUrlParams = [...urlParams]
    auxUrlParams[index].value = value
    setUrlParams(auxUrlParams)
  }
  const getBodyParamValueComponent = (param, i) => {
    switch (param.type) {
      case 'text':
        return(
          <Input
            type="text"
            value={param.value}
            onChange={e => {
              // setHookData({...hookData, url: e.target.value})
              setBodyParamValue(i, e.target.value)
            }}
          />
        )
        break;
      case 'textarea':
        return(
          <Input.TextArea
            value={param.value}
            onChange={e => {
              // setHookData({...hookData, url: e.target.value})
              setBodyParamValue(i, e.target.value)
            }}
          />
        )
        break;
      case 'select':
        return(
          <Select 
            style={{width: '100%'}}
            onChange={value => {setBodyParamValue(i, value)}}
            value={param.value}
          >
            {
              param.options ? (
                param.options.map((elem, index) => {
                  return (
                    <Select.Option key={`optSel-${index}`} value={elem.value}>
                      {elem.label}
                    </Select.Option>
                  )
                })
              ) : ''
            }
          </Select>
          // <Input
          //   type="text"
          //   value={param.value}
          //   onChange={e => {
          //     // setHookData({...hookData, url: e.target.value})
          //     setBodyParam(i, e.target.value)
          //   }}
          // />
        )
        break;
    
      default:
        break;
    }
  }
  const hookModal = () => {
    return(
      <Modal
        className="hook-modal"
        destroyOnClose={true}
        width={586}
        height={500}
        closable={true}
        centered={true}
        visible={editHookVisible}
        title={(
          <>
          <div style={{alignContent: 'center', textAlign: 'center', fontSize: '30px', color: '#5C5C5C'}}>
            Extensión
          </div>
          </>
        )}
        // onOk={()=>{}}
        onCancel={cancelModal}
        // cancelText="Cancelar"
        // okText="Crear"
        footer={null}
      >
        {loading ? (
          <div className="user_info_loading">
            <LoadingOutlined />
          </div>
        ):
        (
          <>
            <div className="hook-modal-body-add">
              <div className="hook-parameter-title">
                Parametros de ruta <div className="parameter-subtitle">(route params)</div>
              </div>
              {urlParams.length === 0 ? (
                <div className="hook-parameter-no-params">
                  No hay parametros definidos
                </div>
              ): (
                <div className="hook-modal-field">
                  <label>Nombre</label>
                  <p>Valor</p>
                </div>
              )}
              {urlParams.map((url, i) => {
                return(
                  <div key={i} className="hook-modal-field">
                    <label>{url.name}:</label>
                    <Input
                      type="text"
                      value={url.value}
                      onChange={e => {
                        // setHookData({...hookData, url: e.target.value})
                        setUrlParamValue(i, e.target.value)
                      }}
                    />
                  </div>
                )
              })}
              <div className="hook-parameter-title">
                Parametros de cuerpo <div className="parameter-subtitle">(request body)</div>
              </div>
              {bodyParams.length === 0 ? (
                <div className="hook-parameter-no-params">
                  No hay parametros definidos
                </div>
              ): (
                <div className="hook-modal-field">
                  <label>Nombre</label>
                  <p>Valor</p>
                </div>
              )}
              {bodyParams.map((param,i) => {
                return(
                  <div key={i} className="hook-modal-field">
                    <label>{param.name}:</label>
                    {getBodyParamValueComponent(param, i)}
                  </div>
                )
              })}
            </div>
            {/* <div className="hooks-modal-footer">
              <button  onClick={cancelModal}
              >
                Cancelar
              </button>
              <Button type="primary" htmlType="submit" onClick={() => {
                onEdit(urlParams, bodyParams, hookIndex)
                cancelModal()
              }}
              >
                Guardar
              </Button>
            </div>   */}
          </>
        )}
      </Modal>
    )
  }
  const showModal = () => {
    setEditHookVisible(true)
  };
  const cancelModal = () => {
    onEdit(urlParams, bodyParams, hookIndex)
    setEditHookVisible(false)
    toast.success('Se han guardado los cambios correctamente')
  };
  return (
    <div className="add-hook-box">
      {!loading ? (
        <div className="add-hook-box-content">
          <p className="add-hook-box-title-text"><b>{hookInfo.name}</b></p>
          <div className="add-hook-box-logo">
            <img src={hookInfo.icon} alt="avatar" />
          </div>
          {!readOnly ? (
            <div className="add-hook-box-footer">
              <DeleteOutlined className="" onClick={() => onDelete(hookIndex)} />
              <EditOutlined className="" onClick={() => showModal()} />
            </div>
          ): null}
        </div>
      ): 'Cargando'}
      {hookModal()}
    </div>
  )
}

export default AddHookBox