import React, { Component } from 'react'
import HeaderComp from '../components/Header/Header'
import Sidebar from '../components/Sidebar/Sidebar'
import { connect } from 'react-redux'
import '../components/_globals/layouts.scss';
import { setIsMobile } from '../actions/actions'
import { setUserData } from '../actions/userActions'
import DesktopHeader from '../components/DesktopHeader/DesktopHeader';
import DesktopSidebar from '../components/DesktopSidebar/DesktopSidebar';
import axiosBW from '../utils/Http';
import { Layout, Menu, Breadcrumb } from 'antd';
const { Header, Content, Sider } = Layout;

class OnlySidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    if (window.innerWidth < 768) {
      this.props.setIsMobile(true)
    } else {
      this.props.setIsMobile(false)
    }
    window.addEventListener('resize', () => {
      this.resize()
    })
    await this.syncUserData()
  }
  async syncUserData() {
    try {
      console.log('USER INFO')
      const res = await axiosBW.get('/auth/user-info')
      this.setState({
        messageForm: {
          title: '',
          description: '',
          icon: ''
        }
      })
      const user = res.data.payload.user
      const token = res.data.payload.token
      if(res.data.message.length === 0) {
        this.props.setUserData(user, token)
        if (user.haveToCompleteData) {
          this.props.history.push('/ultimo_paso')
        } else if (user.firstRedirect) {
          this.props.history.push(user.firstRedirect)
        }
      }
    } catch (err) {
      console.log(err.response)
      if (err.response) {
        
      }
      localStorage.clear()
      this.props.history.push('/ingresar')
    }
  }
  
  resize() {
    if (window.innerWidth < 768) {
      this.props.setIsMobile(true)
    } else {
      this.props.setIsMobile(false)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {
      this.resize()
    })
  }

  render() {
    if(!this.props.isMobile) {
      return (
        <Layout className="desktop_layout">
          <Header className="desktop_layout__header" style={{ position: 'fixed', zIndex: 1, width: '100%', 'max-width': '1580px', padding: '0px'}}>
            <DesktopHeader type='minimal' />
          </Header>
          <Layout>
            <Sider className="desktop_layout__side">
              <DesktopSidebar history={this.props.history} />
            </Sider>
          </Layout>
          <Layout>
            <Content className="desktop_layout__content--noheader">
            { this.props.children }
            </Content>
          </Layout>
        </Layout>
        // <div className="desktop_layout__wrapper">
        //   <div className="desktop_layout__side">
        //     <DesktopSidebar />
        //   </div>
        //   <div className="desktop_layout__main">
        //     <main className={`desktop_layout__content--noheader`}>
        //       { this.props.children }
        //     </main>
        //   </div>
          
        // </div>
      )
    } else {
      return (
        <div className="main_layout__wrapper">
          <div className="main_layout__header">
            <HeaderComp />
          </div>
          <main className="main_layout__content">
            { this.props.children }
          </main>
        </div>
      )
    }
    
  }
}

const mapStateToProps = (state) => ({
  isMobile: state.navigation.isMobile,
  email: state.user.email,
  companyId: state.user.companyId,
  firstName: state.user.firstName,
  lastName: state.user.lastName,
  adminMessage: state.user.adminMessage,
  token: state.user.token
})

const mapDispatchToProps = (dispatch) => ({
  setIsMobile: (bool) => dispatch(setIsMobile(bool)),
  setUserData: (user, token) => dispatch(setUserData(user, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(OnlySidebar)