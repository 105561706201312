export const changeNavMenu = elem => ({
  type: 'CHANGE_NAV_MENU',
  menuElement: elem
})

export const toggleSidebar = isEnabled => ({
  type: 'TOGGLE_SIDEBAR',
  isEnabled,
})

export const setIsMobile = bool => ({
  type: 'SET_IS_RESPONSIVE',
  bool
})

export const changeSearchText = newText => ({
  type: 'CHANGE_SEARCH_TEXT',
  newText
})

export const setGlobalLoader = newState => ({
  type: 'SET_GLOBAL_LOADER',
  newState
})

export const setHomeSelectedTab = selectedTab => ({
  type: 'SET_HOME_SELECTED_TAB',
  selectedTab
})