import React from 'react'
import { connect } from 'react-redux'
import './DesktopSidebar.scss'
import { Link, withRouter } from 'react-router-dom'
import { changeNavMenu } from '../../actions/actions';

const DesktopSidebar = (props) =>  {
  let navigationList = [...props.navigationList]
  navigationList = props.userPermissions.some(p => p === 'process-list' || p === 'admin') ? navigationList : navigationList.filter(n => n.url !== '/procesos/')
  navigationList = props.userPermissions.some(p => p === 'admin') ? navigationList : navigationList.filter(n => n.url !== '/hooks/')
  return (
    <div className="desktop_sidebar__wrapper">
      <div className="desktop_header__logo">
          <Link to="/">
            <img src="/img/blizwork-mini.png" alt="blizwork logo" />
          </Link>
        </div>
      <ul className="desktop_sidebar__list">
        {navigationList && typeof(navigationList) === 'object' ? 
          navigationList.map((elem, index) => {
            return (elem.showDesktop ? (
                <li className={`desktop_sidebar__element ${elem.isActive ? 'desktop_sidebar__element--selected' : ''}`} key={index}>
                  <Link 
                    className={`desktop_sidebar__link ${elem.isActive ? 'desktop_sidebar__link--selected' : 'desktop_sidebar__link--non_selected'}`} 
                    onClick={() => props.changeNavMenu(elem)} 
                    to={elem.urlParams ? `${elem.url}${props[elem.urlParams]}` : elem.url }>
                      <img src={elem.isActive ? elem.icoUrlSelected : elem.icoUrl} alt="icoElem" />
                  </Link>
                  <span style={elem.margin ? {marginLeft: elem.margin} : {}}>{elem.text}</span>
                </li>
              ) : null
            )
          }) : 'No hay elementos de menú'
        }
        </ul>
    </div>
  )
}

const mapStateToProps = state => ({
  navigationList: state.navigation.navigationList,
  companyId: state.user.companyId,
  userPermissions: state.user.permissions
})

const mapDispatchToProps = dispatch => ({
  changeNavMenu: elem => dispatch(changeNavMenu(elem)),
  
})

export default connect(mapStateToProps, mapDispatchToProps)(DesktopSidebar)