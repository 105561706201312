
const resetFormMetadata = [
  {
    id: '1',
    name: 'title',
    label: 'Título',
    placeholder: 'Formulario 1',
    type: 'text',
    mandatory: true,
  },
  {
    id: '2',
    name: 'description',
    label: 'Descripción',
    placeholder: 'Formulario de reclamos',
    type: 'textarea',
    tooltip:'código HTML está permitido',
    mandatory: true,
  },
  {
    id: '3',
    name: 'roleAssignment',
    label: 'Rol Asignado',
    placeholder: 'id-rol',
    type: 'text',
    mandatory: true,
  },
  {
    id: '4',
    name: 'timeout',
    label: 'Tiempo Asignado (en hrs)',
    placeholder: '4 hrs',
    type: 'text',
    mandatory: true,
  },
  {
    id: '5',
    name: 'action',
    label: 'Acción al agotarse el tiempo asignado',
    type: 'select',
    mandatory: true,
    options: [
      {
        key: 'nothing',
        value: 'Sin Acción'
      },
      {
        key: 'close',
        value: 'Cerrar el caso',
      }
      
    ]
  },
  {
    id: '6',
    name: 'userAssignment',
    label: 'Asignación de usuario',
    placeholder: 'usuario@empresa.com',
    type: 'text',
    mandatory: true,
  },
  {
    id: '7',
    name: 'hooks',
    label: 'Acciones',
    type: 'hooks',
    mandatory: true,
    data: []
  }
]

export const initialState = {
  listOfWidgets: [
    {
      widgetId: 1,
      ico_url: '/assets/form-builder/ico-texto.png',
      title: 'Campo de Texto',
      type: 'text',
      mandatory: false,
      readOnly: false,
      hideInOverview: false,
      name: '',
      slug: '',
      defaultValue: '',
      expression: '',
      visible: ''
    },
    {
      widgetId: 2,
      ico_url: '/assets/form-builder/ico-area-texto.png',
      title: 'Área de Texto',
      type: 'textarea',
      mandatory: false,
      readOnly: false,
      hideInOverview: false,
      name: '',
      slug: '',
      defaultValue: '',
      visible: ''
    },
    {
      widgetId: 3,
      ico_url: '/assets/form-builder/ico-rut.png',
      title: 'RUT',
      type: 'rut',
      mandatory: false,
      readOnly: false,
      hideInOverview: false,
      name: '',
      slug: '',
      defaultValue: '',
      options: [],
      visible: ''
    },
    {
      widgetId: 5,
      ico_url: '/assets/form-builder/ico-texto-validado.png',
      title: 'Texto Validado',
      type: 'validated',
      mandatory: false,
      readOnly: false,
      hideInOverview: false,
      regEx: '',
      name: '',
      slug: '',
      defaultValue: '',
      options: [],
      visible: ''
    },
    {
      widgetId: 8,
      ico_url: '/assets/form-builder/ico-subir-archivo.png',
      title: 'Subir Archivo',
      type: 'attachment',
      mandatory: false,
      readOnly: false,
      hideInOverview: false,
      name: '',
      slug: '',
      defaultValue: '',
      options: [],
      visible: ''
    },
    {
      widgetId: 9,
      ico_url: '/assets/form-builder/ico-campo-oculto.png',
      title: 'Campo Oculto',
      type: 'hidden',
      mandatory: false,
      readOnly: false,
      hideInOverview: false,
      name: '',
      slug: '',
      defaultValue: '',
      options: [],
      visible: ''
    },
    {
      widgetId: 10,
      ico_url: '/assets/form-builder/ico-texto-plano.png',
      title: 'Texto Con Formato',
      type: 'markdown',
      mandatory: false,
      readOnly: false,
      hideInOverview: false,
      name: '',
      slug: '',
      defaultValue: '',
      options: [],
      visible: ''
    },
    {
      widgetId: 11,
      ico_url: '/assets/form-builder/ico-selector.png',
      title: 'Selector',
      type: 'select',
      mandatory: false,
      readOnly: false,
      hideInOverview: false,
      name: '',
      slug: '',
      visible: '',
      defaultValue: '',
      options: [
        {
          key:'',
          value:''
        },
        {
          key:'',
          value:''
        }
      ]
    },
    {
      widgetId: 12,
      ico_url: '/assets/form-builder/ico-tabla.png',
      title: 'Tabla',
      type: 'table',
      mandatory: false,
      readOnly: false,
      hideInOverview: false,
      name: '',
      slug: '',
      defaultValue: '',
      visible: '',
      columns: [
        {
          name: 'Columna 1',
          type: 'text',
          mandatory: false,
          options: [],
          fileUrl: '',
          slug: 'columna-1',
          readOnly: false,
          width: 0
        },
        {
          name: 'Columna 2',
          type: 'text',
          mandatory: false,
          options: [],
          fileUrl: '',
          slug: 'columna-2',
          readOnly: false,
          width: 0
        }
      ]
    },
    {
      widgetId: 13,
      ico_url: '/assets/form-builder/ico-master-data.png',
      title: 'Selector Maestro',
      type: 'master-data',
      mandatory: false,
      readOnly: false,
      hideInOverview: false,
      name: '',
      slug: '',
      defaultValue: '',
      options: [],
      visible: '',
      masterDataConfig: {
        collection: '',
        value: '',
        label:'',
        filter: '',
        fields: [
          {
            slug: '',
            column: ''
          }
        ]
      }
    },
    {
      widgetId: 14,
      ico_url: '/assets/form-builder/geolocation.png',
      title: 'Ubicación',
      type: 'geolocation',
      mandatory: true,
      readOnly: false,
      hideInOverview: false,
      name: '',
      slug: '',
      defaultValue: '',
      visible: ''
    },
    {
      widgetId: 15,
      ico_url: '/assets/form-builder/ico-fecha.png',
      title: 'Fecha',
      type: 'date',
      mandatory: false,
      readOnly: false,
      hideInOverview: false,
      name: '',
      slug: '',
      defaultValue: '',
      visible: ''
    }
  ],
  tableWidgets: [
    {
      ico_url: '/assets/form-builder/ico-texto.png',
      title: 'Texto',
      type: 'text'
    },
    {
      ico_url: '/assets/form-builder/ico-area-texto.png',
      title: 'Área de Texto',
      type: 'textarea'
    },
    {
      ico_url: '/assets/form-builder/ico-rut.png',
      title: 'RUT',
      type: 'rut'
    },
    {
      ico_url: '/assets/form-builder/ico-subir-archivo.png',
      title: 'Subir Archivo',
      type: 'attachment'
    },
    {
      ico_url: '/assets/form-builder/ico-selector.png',
      title: 'Selector',
      type: 'select'
    },
    {
      ico_url: '/assets/form-builder/ico-master-data.png',
      title: 'Datos Maestros',
      type: 'master-data'
    }
  ],
  currentForm: [],
  visiblePropertiesList: [],
  formIndexes: 2,
  formMetadata: JSON.parse(JSON.stringify(resetFormMetadata)),
  currentComboboxKey: '',
  currentComboboxValue: '',
  currentColumnIdentifier: '',
  currentColumnName: '',
  currentColumType: '',
  formModified: false,
  initialFormData: [],
  initialFormMetaData: [],
  allFormsFields: [],
  currentFieldSelected: ''

}
const orderArray =  (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
          arr.push(undefined);
      }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

const formBuilder = (state = initialState, action) => {
  switch(action.type) {
    case 'ADD_TO_CURRENT_FORM': {
      let currentFormx = []
      if (action.addAll) {
        currentFormx = [...state.currentForm, {...action.widget, elem_id: state.formIndexes, id: state.formIndexes} ]
      } else {
        currentFormx = [...state.currentForm.slice(0, action.index), {...action.widget, elem_id: state.formIndexes, id: state.formIndexes}, ...state.currentForm.slice(action.index)  ]

      }
      // [...thelist.slice(0, toIndex), {...newItem, _id: Math.floor((Math.random() * 99999) + 1)}, ...thelist.slice(toIndex)]
      return { ...state, formIndexes: state.formIndexes + 1,  currentForm: currentFormx, }
    }
    case 'REPLACE_TO_CURRENT_FORM': {
      let currentFormx = [...state.currentForm ]
      const index = currentFormx.findIndex(f => f.slug === action.widget.slug)
      const { elem_id, id } = currentFormx[index]
      currentFormx[index] = {...action.widget, elem_id, id }
      return { ...state, currentForm: currentFormx }
    }
    case 'SET_PROPERTIES_VISIBLE': {
      let listOfData = [
        
      ]
      switch(action.element._type) {
        case 'text': {
          listOfData = [
            ...listOfData, 
            {
              title: 'Campo de texto',
              display_type: 'onlyText'
            },
            {
              identifier: 'name',
              display_type: 'text',
              title: 'Nombre (etiqueta) de Campo',
              value: action.element.name,
              placeholder: 'Ejemplo: Nombre del Cliente',
              elem_id: action.element.elem_id,
              disabled: false,
              isMarked: action.element.isMarked
            },
            {
              identifier: 'slug',
              display_type: 'text',
              title: 'Identificador Único (solo caracteres, números y guiones)',
              value: action.element.slug,
              placeholder: 'Ejemplo: nombre-cliente',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'defaultValue',
              display_type: 'text',
              title: 'Valor inicial',
              value: action.element.defaultValue || '',
              placeholder: 'Ejemplo: prueba',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'expression',
              display_type: 'text',
              title: 'Fórmula',
              value: action.element.expression || '',
              placeholder: 'Ejemplo: form[\'cantidad\'] * form[\'precio\']',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'visible',
              display_type: 'text',
              title: 'Visible',
              value: action.element.visible,
              placeholder: 'Ejemplo: form[\'tipo-pedido\'] === \'URGENTE\'',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'mandatory',
              display_type: 'checkbox',
              title: 'Obligatorio',
              value: action.element.mandatory,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'hideInOverview',
              display_type: 'checkbox',
              title: 'Ocultar en resumen del caso',
              value: action.element.hideInOverview,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'readOnly',
              display_type: 'checkbox',
              title: 'Solo despliegue',
              value: action.element.readOnly,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            }
          ]
          break
        }  
        case 'textarea': {
          listOfData = [
            ...listOfData, 
            {
              title: 'Área de texto',
              display_type: 'onlyText'
            },
            {
              identifier: 'name',
              display_type: 'text',
              title: 'Nombre de Campo',
              value: action.element.name,
              placeholder: 'Ejemplo: Detalle del caso',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'slug',
              display_type: 'text',
              title: 'Identificador Único (solo caracteres, números y guiones)',
              value: action.element.slug,
              placeholder: 'Ejemplo: detalle',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'defaultValue',
              display_type: 'textarea',
              title: 'Valor inicial',
              value: action.element.defaultValue || '',
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'visible',
              display_type: 'text',
              title: 'Visible',
              value: action.element.visible,
              placeholder: 'Ejemplo: form[\'tipo-pedido\'] === \'URGENTE\'',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'mandatory',
              display_type: 'checkbox',
              title: 'Obligatorio',
              value: action.element.mandatory,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'hideInOverview',
              display_type: 'checkbox',
              title: 'Ocultar en resumen del caso',
              value: action.element.hideInOverview,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'readOnly',
              display_type: 'checkbox',
              title: 'Solo despliegue',
              value: action.element.readOnly,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            }
          ]
          break
        }
        case 'rut': {
          listOfData = [
            ...listOfData, 
            {
              title: 'RUT',
              display_type: 'onlyText'
            },
            {
              identifier: 'name',
              display_type: 'text',
              title: 'Nombre de Campo',
              value: action.element.name,
              placeholder: 'Ejemplo: Rut del Cliente',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'slug',
              display_type: 'text',
              title: 'Identificador Único (solo caracteres, números y guiones)',
              value: action.element.slug,
              placeholder: 'Ejemplo: rut-cliente',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'defaultValue',
              display_type: 'text',
              title: 'Valor inicial',
              value: action.element.defaultValue,
              placeholder: 'Ejemplo: 12345678-K',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'visible',
              display_type: 'text',
              title: 'Visible',
              value: action.element.visible,
              placeholder: 'Ejemplo: form[\'tipo-pedido\'] === \'URGENTE\'',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'mandatory',
              display_type: 'checkbox',
              title: 'Obligatorio',
              value: action.element.mandatory,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'hideInOverview',
              display_type: 'checkbox',
              title: 'Ocultar en resumen del caso',
              value: action.element.hideInOverview,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'readOnly',
              display_type: 'checkbox',
              title: 'Solo despliegue',
              value: action.element.readOnly,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            }
          ]
          break
        }
        case 'select': {
          listOfData = [
            ...listOfData, 
            {
              title: 'Selector',
              display_type: 'onlyText'
            },
            {
              identifier: 'name',
              display_type: 'text',
              title: 'Nombre de Campo',
              value: action.element.name,
              placeholder: 'Ejemlo: Seleccione tipo',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'slug',
              display_type: 'text',
              title: 'Identificador Único (solo caracteres, números y guiones)',
              value: action.element.slug,
              placeholder: 'Ejemplo: selector-tipo',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'defaultValue',
              display_type: 'text',
              title: 'Valor inicial',
              value: action.element.defaultValue,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'visible',
              display_type: 'text',
              title: 'Visible',
              value: action.element.visible,
              placeholder: 'Ejemplo: form[\'tipo-pedido\'] === \'URGENTE\'',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'mandatory',
              display_type: 'checkbox',
              title: 'Obligatorio',
              value: action.element.mandatory,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'hideInOverview',
              display_type: 'checkbox',
              title: 'Ocultar en resumen del caso',
              value: action.element.hideInOverview,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'readOnly',
              display_type: 'checkbox',
              title: 'Solo despliegue',
              value: action.element.readOnly,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'options',
              display_type: 'values_selector',
              title: 'Opciones',
              value: action.element.options,
              elem_id: action.element.elem_id,
              disabled: false 
            }
            
          ]
          // listOfData = [
          //   ...listOfData, 
          //   {
          //     title: <b>Actualmente no es posible editar este campo de selección</b>,
          //     display_type: 'onlyText'
          //   }
          // ]
          break
        }
        case 'validated': {
          listOfData = [
            ...listOfData, 
            {
              title: 'Texto validado',
              display_type: 'onlyText'
            },
            {
              identifier: 'name',
              display_type: 'text',
              title: 'Nombre de Campo',
              value: action.element.name,
              placeholder: 'Ejemplo: Email',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'slug',
              display_type: 'text',
              title: 'Identificador Único (solo caracteres, números y guiones)',
              value: action.element.slug,
              placeholder: 'Ejemplo: email-cliente',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'defaultValue',
              display_type: 'text',
              title: 'Valor inicial',
              value: action.element.defaultValue,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'regEx',
              display_type: 'text',
              title: 'Expresión regular',
              value: action.element.regEx,
              placeholder: '([A-Z])',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'visible',
              display_type: 'text',
              title: 'Visible',
              value: action.element.visible,
              placeholder: 'Ejemplo: form[\'tipo-pedido\'] === \'URGENTE\'',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'mandatory',
              display_type: 'checkbox',
              title: 'Obligatorio',
              value: action.element.mandatory,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'hideInOverview',
              display_type: 'checkbox',
              title: 'Ocultar en resumen del caso',
              value: action.element.hideInOverview,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'readOnly',
              display_type: 'checkbox',
              title: 'Solo despliegue',
              value: action.element.readOnly,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            }
          ]
          break
        }
        case 'date': {
          listOfData = [
            ...listOfData, 
            {
              title: 'Campo de texto',
              display_type: 'onlyText'
            },
            {
              identifier: 'name',
              display_type: 'text',
              title: 'Nombre de Campo',
              value: action.element.name,
              placeholder: 'Ejemplo: Fecha de Cierre',
              elem_id: action.element.elem_id,
              disabled: false,
              isMarked: action.element.isMarked
            },
            {
              identifier: 'slug',
              display_type: 'text',
              title: 'Identificador Único (solo caracteres, números y guiones)',
              value: action.element.slug,
              placeholder: 'Ejemplo: fecha-cierre',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'defaultValue',
              display_type: 'text',
              title: 'Valor inicial',
              value: action.element.defaultValue || '',
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'visible',
              display_type: 'text',
              title: 'Visible',
              value: action.element.visible,
              placeholder: 'Ejemplo: form[\'tipo-pedido\'] === \'URGENTE\'',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'mandatory',
              display_type: 'checkbox',
              title: 'Obligatorio',
              value: action.element.mandatory,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'hideInOverview',
              display_type: 'checkbox',
              title: 'Ocultar en resumen del caso',
              value: action.element.hideInOverview,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'readOnly',
              display_type: 'checkbox',
              title: 'Solo despliegue',
              value: action.element.readOnly,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            }
          ]
          break
        }
        case 'attachment': {
          listOfData = [
            ...listOfData, 
            {
              title: 'Subir archivo',
              display_type: 'onlyText'
            },
            {
              identifier: 'name',
              display_type: 'text',
              title: 'Nombre de Campo',
              value: action.element.name,
              placeholder: 'Ejemplo: Copia de Factura',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'slug',
              display_type: 'text',
              title: 'Identificador Único (solo caracteres, números y guiones)',
              value: action.element.slug,
              placeholder: 'Ejemplo: copia-factura',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'defaultValue',
              display_type: 'text',
              title: 'Valor inicial',
              value: action.element.defaultValue,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: true
            },
            {
              identifier: 'visible',
              display_type: 'text',
              title: 'Visible',
              value: action.element.visible,
              placeholder: 'Ejemplo: form[\'tipo-pedido\'] === \'URGENTE\'',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'mandatory',
              display_type: 'checkbox',
              title: 'Obligatorio',
              value: action.element.mandatory,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'hideInOverview',
              display_type: 'checkbox',
              title: 'Ocultar en resumen del caso',
              value: action.element.hideInOverview,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'readOnly',
              display_type: 'checkbox',
              title: 'Solo despliegue',
              value: action.element.readOnly,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            }
          ]
          break
        }
        case 'hidden': {
          listOfData = [
            ...listOfData, 
            {
              title: 'Campo oculto',
              display_type: 'onlyText'
            },
            {
              identifier: 'name',
              display_type: 'text',
              title: 'Nombre de Campo',
              value: action.element.name,
              placeholder: 'Ejemplo: Tipo Proceso',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'slug',
              display_type: 'text',
              title: 'Identificador Único (solo caracteres, números y guiones)',
              value: action.element.slug,
              placeholder: 'tipo-proceso',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'defaultValue',
              display_type: 'text',
              title: 'Valor inicial',
              value: action.element.defaultValue,
              placeholder: 'Ejemplo: TIPO-A',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'mandatory',
              display_type: 'checkbox',
              title: 'Obligatorio',
              value: action.element.mandatory,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: true
            },
            {
              identifier: 'hideInOverview',
              display_type: 'checkbox',
              title: 'Ocultar en resumen del caso',
              value: action.element.hideInOverview,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: true
            },
            {
              identifier: 'readOnly',
              display_type: 'checkbox',
              title: 'Solo despliegue',
              value: action.element.readOnly,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: true
            }
          ]
          break
        }
        case 'table': {
          listOfData = [
            ...listOfData, 
            {
              title: 'Tabla',
              display_type: 'onlyText'
            },
            {
              identifier: 'name',
              display_type: 'text',
              title: 'Nombre de Campo',
              value: action.element.name,
              placeholder: 'Ejemplo: Tabla de Productos',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'slug',
              display_type: 'text',
              title: 'Identificador Único (solo caracteres, números y guiones)',
              value: action.element.slug,
              placeholder: 'tabla-productos',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'defaultValue',
              display_type: 'text',
              title: 'Valor inicial',
              value: action.element.defaultValue,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'visible',
              display_type: 'text',
              title: 'Visible',
              value: action.element.visible,
              placeholder: 'Ejemplo: form[\'tipo-pedido\'] === \'URGENTE\'',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'mandatory',
              display_type: 'checkbox',
              title: 'Obligatorio',
              value: action.element.mandatory,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'hideInOverview',
              display_type: 'checkbox',
              title: 'Ocultar en resumen del caso',
              value: action.element.hideInOverview,
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'readOnly',
              display_type: 'checkbox',
              title: 'Solo despliegue',
              value: action.element.readOnly,
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'tableOptions.disableAddRowButton',
              display_type: 'checkbox',
              title: 'No permitir agregar filas',
              value: action.element.tableOptions ? action.element.tableOptions.disableAddRowButton : false,
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'tableOptions.disableDeleteRowButton',
              display_type: 'checkbox',
              title: 'No permitir eliminar filas',
              value: action.element.tableOptions ? action.element.tableOptions.disableDeleteRowButton : false,
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'columns',
              display_type: 'column_selector',
              title: 'Columnas',
              value: action.element.columns,
              elem_id: action.element.elem_id,
              disabled: false 
            }
            
          ]
          /*listOfData = [
            ...listOfData, 
            {
              identifier: 'name',
              display_type: 'text',
              title: 'Nombre de Campo',
              value: action.element.name,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'slug',
              display_type: 'text',
              title: 'Identificador Unico (solo caracteres, números y guiones)',
              value: action.element.slug,
              placeholder: 'campo-001',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'mandatory',
              display_type: 'checkbox',
              title: 'Campo Obligatorio',
              value: action.element.mandatory,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'hideInOverview',
              display_type: 'checkbox',
              title: 'Ocultar en resumen del caso',
              value: action.element.hideInOverview,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'columns',
              display_type: 'column_selector',
              title: 'Selector de Columnas'
            }
          ] */
          // listOfData = [
          //   ...listOfData, 
          //   {
          //     title: <b>Actualmente no es posible editar este campo de tabla</b>,
          //     display_type: 'onlyText'
          //   }
          // ]
          break
        }
        case 'markdown': {
          listOfData = [
            ...listOfData, 
            {
              title: 'Texto con formato',
              display_type: 'onlyText'
            },
            {
              identifier: 'name',
              display_type: 'text',
              title: 'Nombre de Campo',
              value: action.element.name,
              placeholder: 'Ejemplo: Instrucciones de llenado',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'slug',
              display_type: 'text',
              title: 'Identificador Único (solo caracteres, números y guiones)',
              value: action.element.slug,
              placeholder: 'Ejemplo: instrucciones-llenado',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'defaultValue',
              display_type: 'textarea',
              title: 'Contenido',
              value: action.element.defaultValue,
              placeholder: 'Ejemplo: # Instrucciones...',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'visible',
              display_type: 'text',
              title: 'Visible',
              value: action.element.visible,
              placeholder: 'Ejemplo: form[\'tipo-pedido\'] === \'URGENTE\'',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'mandatory',
              display_type: 'checkbox',
              title: 'Obligatorio',
              value: action.element.mandatory,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: true
            },
            {
              identifier: 'hideInOverview',
              display_type: 'checkbox',
              title: 'Ocultar en resumen del caso',
              value: action.element.hideInOverview,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'readOnly',
              display_type: 'checkbox',
              title: 'Solo despliegue',
              value: action.element.readOnly,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: true
            }       
          ]
          break
        }
        case 'master-data': {
          listOfData = [
            ...listOfData, 
            {
              title: 'Selector Maestro',
              display_type: 'onlyText'
            },
            {
              identifier: 'name',
              display_type: 'text',
              title: 'Nombre de Campo',
              value: action.element.name,
              placeholder: 'Ejemplo: Seleccione Cliente',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'slug',
              display_type: 'text',
              title: 'Identificador Único (solo caracteres, números y guiones)',
              value: action.element.slug,
              placeholder: 'Ejemplo: cliente',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'visible',
              display_type: 'text',
              title: 'Visible',
              value: action.element.visible,
              placeholder: 'Ejemplo: form[\'tipo-pedido\'] === \'URGENTE\'',
              elem_id: action.element.elem_id,
              disabled: false
            },
            // {
            //   identifier: 'defaultValue',
            //   display_type: 'text',
            //   title: 'Valor inicial',
            //   value: action.element.defaultValue,
            //   placeholder: '-',
            //   elem_id: action.element.elem_id,
            //   disabled: false
            // },
            {
              identifier: 'mandatory',
              display_type: 'checkbox',
              title: 'Obligatorio',
              value: action.element.mandatory,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'hideInOverview',
              display_type: 'checkbox',
              title: 'Ocultar en resumen del caso',
              value: action.element.hideInOverview,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'readOnly',
              display_type: 'checkbox',
              title: 'Solo despliegue',
              value: action.element.readOnly,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'masterDataConfig',
              display_type: 'selector-master-collection',
              title: 'Fuente de datos maestros',
              value: action.element.masterDataConfig,
              elem_id: action.element.elem_id,
              disabled: false 
            }
            
          ]
          // listOfData = [
          //   ...listOfData, 
          //   {
          //     title: <b>Actualmente no es posible editar este campo de selección</b>,
          //     display_type: 'onlyText'
          //   }
          // ]
          break
        }
        case 'geolocation': {
          listOfData = [
            ...listOfData, 
            {
              title: 'Ubicación',
              display_type: 'onlyText'
            },
            {
              identifier: 'name',
              display_type: 'text',
              title: 'Nombre de Campo',
              value: action.element.name,
              placeholder: 'Ejemplo: Ubicación de la entrega',
              elem_id: action.element.elem_id,
              disabled: false,
              isMarked: action.element.isMarked
            },
            {
              identifier: 'slug',
              display_type: 'text',
              title: 'Identificador Único (solo caracteres, números y guiones)',
              value: action.element.slug,
              placeholder: 'Ejemplo: ubicacion-entrega',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'defaultValue',
              display_type: 'text',
              title: 'Valor inicial',
              value: action.element.defaultValue || '',
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'visible',
              display_type: 'text',
              title: 'Visible',
              value: action.element.visible,
              placeholder: 'Ejemplo: form[\'tipo-pedido\'] === \'URGENTE\'',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'mandatory',
              display_type: 'checkbox',
              title: 'Obligatorio',
              value: action.element.mandatory,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: true
            },
            {
              identifier: 'hideInOverview',
              display_type: 'checkbox',
              title: 'Ocultar en resumen del caso',
              value: action.element.hideInOverview,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            },
            {
              identifier: 'readOnly',
              display_type: 'checkbox',
              title: 'Solo despliegue',
              value: action.element.readOnly,
              placeholder: '',
              elem_id: action.element.elem_id,
              disabled: false
            }
          ]
          break
        }  
        default: {
          listOfData = [...listOfData]
          break
        }
      }
      return { ...state, visiblePropertiesList: listOfData, currentFieldSelected: action.element._id}
    }
    case 'FORM_CHANGE_ORDER': {
      const orderedArray = [...orderArray(state.currentForm, action.itemToObtain, action.positionToMove)]
      return { ...state, currentForm: orderedArray}
    }
    case 'CHANGE_FORM_METADATA': {
      const formMetadata = [...state.formMetadata]
      const newFormMetadata = []
      
      formMetadata.forEach(f => {
        if(f.name === action.name) {
          f.value = action.value
        }
        newFormMetadata.push(f)
      })
      return {
        ...state,
        formMetadata: newFormMetadata
      }
    }
    case 'SET_ACTION_METADATA': {
      const formMetadata = [...state.formMetadata]
      const options = action.value
      const index = formMetadata.findIndex((f) => f.name === 'action')
      const act = formMetadata[index]
      // const newOptions = []
      const initialOptionsIndex = resetFormMetadata.findIndex((f) => f.name === 'action')
      const initialOptions = resetFormMetadata[initialOptionsIndex].options
      if (JSON.stringify(initialOptions) !== JSON.stringify(act.options)) {
        act.options = [...initialOptions]
      }
      for (let i = 0; i < options.length; i++) {
        const elem = options[i];
        act.options.push({
          key: elem.formSlug,
          value: `${elem.label} (${elem.formSlug})`
        })
      }
      return {
        ...state,
        formMetadata: formMetadata
      }
    }
    case 'FORM_CHANGE_VALUE': {
      const currentForm = [...state.currentForm]

      const newCurrentForm = []
      currentForm.forEach(f => {
        if (f.elem_id === action.elem_id) {
          // Handle complex identifiers. Supports only 1 dot.
          if (action.identifier.indexOf('.') > -1) {
            const propParts = action.identifier.split('.')
            if (!f[propParts[0]]) {
              f[propParts[0]] = {}
            }
            f[propParts[0]][propParts[1]] = action.value
          } else {
            f[action.identifier] = action.value
          }
        }
        newCurrentForm.push(f)
      })
      return {
        ...state,
        currentForm: newCurrentForm
      }
    }
    case 'SHOW_FORM_METADATA': {
      return {
        ...state, visiblePropertiesList: [], currentFieldSelected: ''
      }
    }
    case 'SET_COMBOBOX_KEY': {
      return {
        ...state,
        currentComboboxKey: action.value
      }
    }
    case 'SET_COMBOBOX_VALUE': {
      return {
        ...state,
        currentComboboxValue: action.value
      }
    }
    case 'ADD_NEW_COLUMN_KEY': {
      const newOption = {
        key: state.currentComboboxKey,
        value: state.currentComboboxValue
      }
      const currForm = [...state.currentForm]
      const find = currForm.filter(f => f.elem_id === action.elem_id)
      find[0].options = [...find[0].options, newOption]
      return {
        ...state,
        currentForm: currForm,
        currentComboboxKey: '',
        currentComboboxValue: '',
      }
    }
    case 'DELETE_WIDGET': {
      const currForm = [...state.currentForm]
      const newCurrForm = currForm.filter(f => f.elem_id !== action.elem_id)
      return {
        ...state,
        currentForm: newCurrForm
      }
    }
    case 'RETRIEVE_FORM_DATA': {
      const currForm = [...state.currentForm]
      let ids = state.formIndexes + 1
      action.widgets.forEach(elem => {
        let currentTitle = state.listOfWidgets.filter(f => elem.type === f.type)[0]
        let newObj = {
          ...elem, 
          type: 'card',
          _type: elem.type,
          slug: elem.slug,
          elem_id: ids,
          title: currentTitle ? currentTitle.title : 'none',
          ico_url: currentTitle ? currentTitle.ico_url : 'none',
          isMarked: false
        }
        ids = ids + 1
        currForm.push(newObj)
      })
      return {
        ...state,
        currentForm: currForm,
        initialFormData: JSON.parse(JSON.stringify(currForm)),
        formIndexes: ids + 1
      }
    }
    case 'SET_ALL_FORMS_FIELDS': {
      const forms = [...action.value]
      for (let i = 0; i < forms.length; i++) {
        const form = forms[i];
        for (let j = 0; j < form.fields.length; j++) {
          let field = form.fields[j];
          const currentTitle = state.listOfWidgets.filter(f => field.type === f.type)[0]
          const auxField = {
            ...field,
            _type: field.type,
            slug: field.slug,
            elem_id: null,
            title: currentTitle ? currentTitle.title : 'none',
            widgetId: currentTitle ? currentTitle.widgetId : 0,
            ico_url: currentTitle ? currentTitle.ico_url : 'none',
            isMarked: false
          }
          forms[i].fields[j] = auxField
        }
        
      }
      return {
        ...state,
        allFormsFields: forms
      }
    }
    case 'SET_COLUMN_IDENTIFIER': {
      return {
        ...state,
        currentColumnIdentifier: action.value,
      }
    }
    case 'SET_COLUMN_NAME': {
      return {
        ...state,
        currentColumnName: action.value,
      }
    }
    case 'SET_COLUMN_TYPE': {
      return {
        ...state,
        currentColumType: action.value,
      }
    }
    case 'UPDATE_FORM': {
      return {
        ...state,
        company_id: action.companyId,
        process_slug: action.processSlug,
        form_slug: action.formSlug
      }
    }
    case 'CLEAR_ALL_DATA': {
      return {
        ...state,
        currentForm: [],
        visiblePropertiesList: [],
        formIndexes: 2,
        currentComboboxKey: '',
        currentComboboxValue: '',
        currentColumnIdentifier: '',
        currentColumnName: '',
        currentColumType: '',
        formMetadata: JSON.parse(JSON.stringify(resetFormMetadata))
      }
    }
    case 'SET_FORM_MODIFIED': {
      return {
        ...state,
        formModified: action.value
      }
    }
    case 'SET_INITIAL_FORM_DATA': {
      return {
        ...state,
        initialFormData: action.value
      }
    }
    case 'SET_INITIAL_FORM_META_DATA': {
      const formMetadata = [...state.formMetadata]
      return {
        ...state,
        initialFormMetaData: JSON.parse(JSON.stringify(formMetadata))
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default formBuilder