/* eslint-disable no-script-url */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Prompt } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table as AntdTable, Collapse, Select, DatePicker, Modal, Upload, Progress, Popover, Input, Avatar, Tooltip, ConfigProvider } from 'antd'
import locale from 'antd/lib/locale/es_ES';
import { DeleteOutlined, DownloadOutlined, InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import axiosBW from '../../../utils/Http';
import './NewCase.scss'
import {
  addRowToTableWidget,
  changeTableField,
  plusAttachmentNumber,
  setAttachmentList,
  setCaseCurrentTab,
  setCurrentCaseNumber,
  setGlobalLoader,
  setTableWidgetList,
  updateCurrentFormField,
} from '../../../actions/actions'
import moment from 'moment'
import momentTZ from 'moment-timezone';
import Table from '../../../components/Library/Table/Table';
import {
  notificationValidationHandler,
  validaRut,
  formatNumber,
  tableClearNumberFormat
} from '../../../utils/Helpers';
import { Helmet } from "react-helmet";
import CaseHeader from './CaseHeader/CaseHeader';
import TableWidget from './TableWidget/TableWidget';
import HorizontalCollapse from './HorizontalCollapse/HorizontalCollapse';
import { socket } from '../../../utils/Socket'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { toast } from 'react-toastify';
import FileDownload from 'js-file-download';
import axios from 'axios';


import { MapContainer, TileLayer, Marker } from 'react-leaflet'

import 'react-toastify/dist/ReactToastify.css';
moment.locale('es')
momentTZ.defineLocale('es', moment.localeData()._config);
momentTZ.locale('es')

// const { TabPane } = Tabs;

const { Panel } = Collapse;

const Option = Select.Option

const { Dragger } = Upload;

const successOpts = {
  position: "top-center",
  autoClose: 7000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  className: 'toast-case'
}

function UserInfo ({ userInfo }) {
  const envi = process.env.REACT_APP_AB.trim()
  const baseUrl = envi === 'dev' ? 'http://localhost:3000/#/hall/' : envi === 'stage' ? 'http://atdemo.andes-cs.com/#/hall/' : 'https://app.blizwork.com/#/hall/'
  return (
    <div className="user-info-container">
      <div className="user-info-item">
        <div className="user-info-label">
          Email:
        </div>
        <div className="user-info-content">
          {userInfo.email}
        </div>
      </div>
      <div className="user-info-item">
        <div className="user-info-label">
          Cargo:
        </div>
        <div className="user-info-content">
          {userInfo.jobTitle}
        </div>
      </div>
      <div className="user-info-item">
        <div className="user-info-label">
          Organización:
        </div>
        <div className="user-info-content">
          <a href={`${baseUrl}${userInfo.companyId}`} without rel="noopener noreferrer" target="_blank">{userInfo.fantasyName} </a>
        </div>
      </div>
      <div className="user-info-item">
        <div className="user-info-label">
          RUT/RUC:
        </div>
        <div className="user-info-content">
          {userInfo.taxId}
        </div>
      </div>
      <div className="user-info-item">
        <div className="user-info-label">
          País:
        </div>
        <div className="user-info-content">
          {userInfo.country}
        </div>
      </div>
    </div>
  )
}

class NewCase extends Component {

  constructor(props) {
    super(props)
    this.state = {
      // check if it's a valid form/process
      validForm: true,
      // set the current step (current form by slug)
      currentStep: 'intro',
      // all the form data when intro is called (begin_case)
      stepData: {},
      // all the current form info (important)
      currentForm: {},
      // current case info
      caseInfo: {},
      // current process info
      processInfo: {},
      // role has permissions to see the current form?
      roleApproved: true,
      // case number (it's handled locally and setted globally too)
      caseNumber: '',
      // title for attachment Filename
      attachmentFilenameTitle: '',
      // messaging textarea char counters
      charCounter: 0,
      messagingAttachmentFilename: '',
      messagingAttachmentProgress: 20,
      messagingAttachmentFilenameTitle: '',
      messagingUrlAttachment: '',
      messagingButtonDisabled: false,
      // messaging textarea value
      textareaMessage: '',
      // messaging alert text
      sendMessageStatus: '',
      // messaging list of previous messages
      messageList: [],
      // list of dynamics attachments (ie: when form contains more than one attachment)
      attachmentListx: [],
      // show of hide message list (ie: hide at init form)
      showMessages: true,
      // list of executed forms for case history
      formsList: [],
      // company of the process creator
      companyId: '',
      // form colors extracted from company hall
      formColors: {
        primaryColor: '',
        secondaryColor: '',
        thirdColor: '',
      },
      // when user came to message from email notification, set to message number to go directly
      msgId: '',
      // style for component tab header (is different than other tabs at app)
      tabHeadStyle: {
        maxWidth: '640px',
        margin: '0 auto',
        width: '90%'
      },
      // setted form time indicators 
      timeIndicators: [],
      fechita: 0,
      // show timers or not (depend if form was made for customers or not)
      showTimer: false,
      activeTab: '0',
      caseStatus: 'intro',
      actualTableFileUpload: '',
      actualTableFileUploadMsg: '',
      actualTableFileUploadUrl: '',
      TAB_FORM: '0',
      TAB_MESSAGING: '1',
      TAB_HISTORY: '2',
      // MESSAGING
      newMessages: 0,
      isTyping: false,
      typingUser: '',
      masterDataObj: {},
      masterDataLoading: false,
      processName: '',
      processSlug: '',
      showPrompt: true,
      uploadingFile: false,
      messagingLoading: false,
      historicLoading: false,
      applicantCompanyInfo: null,
      applicantCompanyInfoLoading: true,
      applicantCasesInfo: null,
      applicantCasesInfoLoading: true,
      applicantCasesInfoFilter: null,
      applicantCompanyContactsLoading: true,
      applicantCompanyContacts: null,
      caseLoading: true,
      casePanelCollapse: [false, false, true, true, true],
      casePanelAccess: false,
      canUnassign: false,
      checkFields: [],
      // Window state: band/full-screen
      fullScreen: false
    }
    this.messageRefAttachment = React.createRef()
    this.processAttachmentRef = React.createRef()
    this.onChangeTab = this.onChangeTab.bind(this)
    // this.getMessaging = this.getMessaging(this)
    this.typingTimeout = undefined
    this.messagesEndRef = React.createRef()
    // this.onMessageList = this.onMessageList(this)
  }

  uploadPropsAttachment = {
    multiple: false,
    showUploadList: false,
    async customRequest ({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials,
    }) {
      // EXAMPLE: post form-data with 'axios'
      // eslint-disable-next-line no-undef
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isLt4M) {
        toast.error('El archivo debe ser menor a 4MB');
        onError()
        return
      }
      await this.attachmentMessageUpload(file)
      onSuccess(null, file);
    },
  };

  static getDerivedStateFromProps (props, state) {
    if (props.match.params.step !== state.currentStep) {
      props.setGlobalLoader(true)

      let atList = []
      let i = 0
      if (state.currentForm.fields) {
        state.currentForm.fields.forEach((e) => {
          if (e.type === 'attachment') {
            atList.push({
              ref: React.createRef(),
              num: i,
              name: e.name,
              blockedButton: false,
              message: 'wutttt',
              progress: 0,
              status: 'pending',
              fileName: ''
            })
            i = i + 1
          }
        })
      }
      return {
        currentStep: props.match.params.step,
        currentForm: state.currentForm,
        attachmentListx: atList,
        showMessages: props.match.params.step === 'intro-form' ? false : true
      }
    }

    return null
  }

  async onChangeTab (tabId) {
    this.setState({
      activeTab: tabId
    })
    if (tabId === this.state.TAB_MESSAGING) {
      socket.emit('client:mark-as-read', { caseId: this.props.match.params.caseId, token: this.props.token })
      this.setState({
        newMessages: 0
      })
    }
  }

  markAsRead = async () => {
    socket.emit('client:mark-as-read', { caseId: this.props.match.params.caseId, token: this.props.token })
    this.setState({
      newMessages: 0
    })
  }

  startTyping () {
    socket.emit('client:typing', { caseId: this.props.match.params.caseId, user: this.props.fullName, typing: true, token: this.props.token })
    clearTimeout(this.typingTimeout)
    this.typingTimeout = setTimeout(() => {
      this.stopTyping()
    }, 2000)
  }

  stopTyping () {
    socket.emit('client:typing', { caseId: this.props.match.params.caseId, user: this.props.fullName, typing: false, token: this.props.token })
  }

  async toMount () {
    // get step form data
    const getType = this.props.match.params.step === 'intro-form' ? 'intro' : this.props.match.params.step
    if (getType === 'intro') {
      let resIntro
      try {
        resIntro = await axiosBW.get(`/process/${getType}/${this.props.match.params.processId}?${this.props.match.params.draft && this.props.match.params.draft === 'draft' ? 'draft=true' : ''}`,)
      } catch (ex) {
        if (ex.response) {
          Modal.error({
            title: `Error`,
            content: `No fue posible ejecutar la solicitud. Será redireccionado al inicio`
          })
        } else {
          console.log('Error get Process Intro', ex)
          Modal.error({
            title: `Error`,
            content: `No fue posible ejecutar la solicitud. Será redireccionado al inicio (Not Response)`
          })
        }
        setTimeout(() => {
          this.props.history.push('/')
        }, 3000)
        console.log('message', ex)
      }
      if (resIntro && resIntro.data) {
        if (resIntro.data.status === -1) {
          this.setState({
            validForm: false,
          })
        }
        if (resIntro.data.status === 0) {
          this.setState({
            stepData: resIntro.data.payload,
            validForm: true,
            processForm: resIntro.data.payload.processName,
            processName: resIntro.data.payload.processName,
            processSlug: resIntro.data.payload.processSlug
          })
        }
      }
    }
  }

  async getIntroForm () {
    try {
      const resIntro = await axiosBW.get(`/process/intro/${this.props.match.params.processId}?${this.props.match.params.draft && this.props.match.params.draft === 'draft' ? 'draft=true' : ''}`)
      if (resIntro && resIntro.data) {
        if (resIntro.data.status === -1) {
          this.setState({
            validForm: false,
          })
        }
        if (resIntro.data.status === 0) {
          this.setState({
            stepData: resIntro.data.payload,
            validForm: true,
            processName: resIntro.data.payload.processName,
            processSlug: resIntro.data.payload.processSlug,
            showMessages: false
          })
        }
      }
      this.props.setCurrentCaseNumber(null)
    } catch (ex) {
      console.log(ex);
      if (ex.response) {
        throw new Error('No fue posible ejecutar la solicitud. Será redireccionado al inicio')
      } else {
        console.log('Error get Process Intro', ex)
        throw new Error('No fue posible ejecutar la solicitud. Será redireccionado al inicio (Not Response)')
      }
    }
  }

  getLocation = async () => {
    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        console.log("Available");
        navigator.geolocation.getCurrentPosition((position) => {
          resolve(`${position.coords.latitude},${position.coords.longitude}`);
        }, (err) => {
          console.log(err);
          reject('none');
        });
      } else {
        console.log('Not geolocation available')
        reject('none');
      }
    })
  }

  setMasterDataCheckFields (filter) {
    const checkFields = [...this.state.checkFields]
    const matchFieldList = filter.match(/(?!fields\[)'(.*?)'/g)
    if (matchFieldList) {
      for (let i = 0; i < matchFieldList.length; i++) {
        const matched = matchFieldList[i];
        const field = matched.replace(/'/g, '')
        if (checkFields.indexOf(field) === -1) {
          checkFields.push(field)
        }
      }
    }
    this.setState({
      checkFields
    })
  }

  async getForm () {
    try {
      const resNextExec = await axiosBW.get(`/case/next-exec/${this.props.match.params.caseId}?${this.props.match.params.draft && this.props.match.params.draft === 'draft' ? 'draft=true' : ''}`)
      if (resNextExec && resNextExec.data) {
        const atList = []
        const tableForms = []
        let i = 0
        let currForm = resNextExec.data.payload.nextForm
        this.setState({
          casePanelAccess: resNextExec.data.payload.casePanelsAccess
        })
        if (currForm && currForm.fields) {
          currForm.fields.forEach(async (e) => {
            if (e.type === 'table') {
              tableForms.push({ slug: e.slug, data: e.defaultValue ? e.defaultValue : [] })
              this.props.setTableWidgetList(tableForms)
              for (let i = 0; i < e.columns.length; i++) {
                const column = e.columns[i];
                if (column.type === 'master-data' && column.masterDataConfig && !e.readOnly) {
                  await this.setMasterData(column.masterDataConfig, `${e.slug}__${column.slug}`, currForm)
                }
              }
            }
            if (e.type === 'attachment') {
              atList.push({
                ref: React.createRef(),
                num: i,
                name: e.name,
                blockedButton: false,
                message: 'Esperando Subida de Archivo',
                slug: e.slug,
                progress: 0,
                status: 'pending',
                fileName: ''
              })
              i = i + 1
            }
            if (e.type === 'master-data' && e.masterDataConfig && !e.readOnly) {
              console.log('column', e)
              await this.setMasterData(e.masterDataConfig, e.slug, currForm)
            }
          })
        }
        const response = resNextExec.data.payload
        // set necessary data
        if (response.status === 'open') {
          if (response.roleApproved && response.nextForm) {
            this.setState({
              caseStatus: 'form'
            })
            // load and format timeout data
            const startTime = momentTZ(response.nextForm.startTime).tz(this.props.timeZone).format('YYYY-MM-DD HH:mm:ss')
            const hoursLeft = response.nextForm.actionOnTimeout.timeout.toString().replace(/h/g, "");
            this.setState({
              roleApproved: response.roleApproved,
              currentForm: response.nextForm,
              caseInfo: response.caseInfo,
              canUnassign: response.canUnassign,
              processInfo: response.processInfo,
              processName: response.processName,
              processSlug: response.processSlug,
              attachmentListx: atList,
              showTimer: response.nextForm.actionOnTimeout.timeout ? true : false,
              timeIndicators: [
                {
                  id: 1,
                  title: 'Inicio',
                  value: momentTZ(startTime).format('YYYY-MM-DD HH:mm'),
                  conditionalStyle: {}
                },
                {
                  id: 2,
                  title: 'Plazo',
                  value: hoursLeft,
                  conditionalStyle: {}
                },
                {
                  id: 3,
                  title: 'Restante',
                  value: 'cargando',
                  conditionalStyle: {
                    backgroundColor: '#EE6A3B'
                  }
                }
              ]
            })
            //first call
            const objToCounter = { timeout: hoursLeft, date: startTime }
            this.makeCounter(objToCounter, this)
            this.setCounter = setInterval(() => this.makeCounter(objToCounter, this), 60000)
            this.props.setCurrentCaseNumber(response.caseNumber)
          } else {
            // load and format timeout data
            // const startTime = momentTZ(response.nextForm.startTime).format('YYYY-MM-DD HH:mm:ss')
            // const hoursLeft = response.nextForm.actionOnTimeout.timeout.toString().replace(/h/g, "");
            this.setState({
              caseStatus: 'overview-open',
              roleApproved: response.roleApproved,
              currentForm: response.nextForm,
              caseInfo: response.caseInfo,
              canUnassign: response.canUnassign,
              processInfo: response.processInfo,
              overview: response.overview || [],
              processName: response.processName || '',
              processSlug: response.processSlug || '',
              attachmentListx: atList,
              showTimer: true,
              timeIndicators: [
                {
                  id: 1,
                  title: 'Abierto',
                  value: '',
                  conditionalStyle: {
                    backgroundColor: '#3CB166'
                  }
                }
              ]
            })
            this.props.setCurrentCaseNumber(response.caseNumber)
          }
        } else {
          this.setState({
            caseStatus: 'overview-closed',
            roleApproved: response.roleApproved,
            overview: response.overview,
            caseInfo: response.caseInfo,
            canUnassign: response.canUnassign,
            processInfo: response.processInfo,
            processName: response.processName,
            processSlug: response.processSlug,
            attachmentListx: atList,
            showTimer: true,
            timeIndicators: [
              {
                id: 1,
                title: 'Cerrado',
                value: '',
                conditionalStyle: {}
              }
            ]
          })
          //first call
          this.props.setCurrentCaseNumber(response.caseNumber)
        }
      }
      // check if it is a valid step
      if (!this.props.match.params.step) {
        return <div> Debes ingresar un paso válido </div>
      }
      this.setState({
        currentStep: this.props.match.params.step
      })
    } catch (ex) {
      console.log('Error', ex)
      let errMessage = ''
      if (ex.response && ex.response.data.message === 'Plan Limit Exceeded.') {
        errMessage = 'Ha alcanzado el máximo de su plan. Por favor contactar a soporte para extender el plan.'
      } else if (ex.response && ex.response.status === 403) {
        errMessage = 'Hubo un error al ingresar. Es probable que usted no tenga acceso a crear o visualizar un caso '
      } else {
        errMessage = 'Hubo un error al intentar obtener el caso. por favor intente más tarde'
      }
      Modal.error({
        title: 'Error',
        content: errMessage,
      })
      setTimeout(() => {
        this.props.history.push('/')
      }, 2000)
      return
    }
  }

  reloadMasterData = async (fields, except) => {
    const masterDataInfo = { ...this.state.masterDataObj }
    for (const slug in masterDataInfo) {
      // If specified, don't load MD with the 'except' slug.
      if (!except || except !== slug) {
        const data = masterDataInfo[slug]
        await this.setMasterData(data.config, slug, { fields })
      }
    }
  }

  setMasterData = async (masterDataConfig, slug, currForm) => {
    try {
      let theFilter = {}
      if (masterDataConfig.filter && masterDataConfig.filter !== '') {
        this.setMasterDataCheckFields(masterDataConfig.filter)
        const fields = {}
        currForm.fields.forEach(f => {
          fields[f.slug] = f.defaultValue
        })
        try {
          /*eslint-disable-next-line no-eval*/
          const evaluated = eval('`' + masterDataConfig.filter + '`')
          const filter = JSON.parse(evaluated)
          theFilter = filter
        } catch (error) {
          console.log(error)
        }
      }
      this.setState({ masterDataLoading: true })
      const resMaster = await axiosBW.post(`/master-data/data-value-label/${masterDataConfig.collection}`, {
        value: masterDataConfig.value,
        label: masterDataConfig.label,
        filter: theFilter
      })
      this.setState({
        masterDataObj: {
          ...this.state.masterDataObj,
          [slug]: {
            config: masterDataConfig,
            data: resMaster.data.payload
          }
        },
        masterDataLoading: false
      })
    } catch (error) {
      console.log('setMasterData')
      console.log(error);
      notificationValidationHandler('Ha ocurrido un error al consultar los datos maestros')
      this.setState({
        masterDataObj: {
          ...this.state.masterDataObj,
          [slug]: null
        },
        masterDataLoading: false
      })
    }
  }

  async setUnreadMessages (list) {
    const email = this.props.email
    // console.log({email});
    const unreadMessages = list.filter(l => !l.readBy.includes(email) && !l.readBy.includes('all')).length
    this.setState({
      newMessages: unreadMessages
    })
  }

  onMessageList = (data) => {
    this.setState({
      messageList: data.payload,
      messagingLoading: false
    })
    console.log(data.payload)
    // Si el tab activo no es Mensajería seteamos mensajes sin leer, si no marcamos como leído.
    if (this.state.activeTab !== this.state.TAB_MESSAGING) {
      this.setUnreadMessages(data.payload)
    } else {
      socket.emit('client:mark-as-read', { caseId: this.props.match.params.caseId, token: this.props.token })
    }
  }

  onTyping = (data) => {
    this.setState({
      isTyping: data.typing,
      typingUser: data.typing ? data.user : ''
    })
  }

  onSocketError = (data) => {
    console.log({ data })
    Modal.error({
      title: 'Error',
      content: `Ocurrió un error con los mensajes`,
    })
  }

  async getMessaging () {
    try {
      // Ingresamos a la sala del caso
      socket.emit('client:join-messaging-room', { caseId: this.props.match.params.caseId, token: this.props.token })

      // Recibimos la lista de mensajes
      socket.on('message-list', this.onMessageList)
      // Recibimos la lista de mensajes
      socket.on('server:typing', this.onTyping)
      socket.on('server:message-error', this.onSocketError)
      const query = new URLSearchParams(this.props.location.search)
      this.setState({
        msgId: query.get('msgId')
      })

    } catch (ex) {
      console.log('error resMessagesByCase', ex)
      throw new Error('No fue posible obtener los mensajes del caso')
    }
  }

  async getHistoric () {
    try {
      const resGetFormsExecuted = await axiosBW.get(`/case/getFormsExecuted/${this.props.match.params.caseId}`)
      if (!resGetFormsExecuted || !resGetFormsExecuted.data) {
        throw new Error('No fue posible obtener la lista de formularios')
      }
      this.setState({
        formsList: resGetFormsExecuted.data.payload,
        historicLoading: false
      })
    } catch (ex) {
      console.log('error resGetFormsExecuted', ex)
      throw new Error('No fue posible obtener la lista de formularios')
    }


  }

  async downloadFile (url) {
    try {
      const fileFromUrl = url.substring(url.lastIndexOf('/') + 1)
      const fileName = fileFromUrl.substring(fileFromUrl.indexOf('_') + 1, fileFromUrl.indexOf('?'))
      const response = await axios({
        url,
        method: 'GET',
        // headers: {
        //   "Access-Control-Allow-Origin": "*"
        // },
        responseType: 'blob', // Important
      })
      FileDownload(response.data, fileName);
    } catch (ex) {
      console.log('downloadFile', ex)
    }


  }

  async componentDidMount () {
    console.log('componentDidMount');
    try {
      await this.props.setGlobalLoader(true)
      this.setState({ messagingLoading: true, historicLoading: true, caseLoading: true })
      // Clean the sockets first
      if (socket) {
        this.disconnectSockets()
      }
      const resGetCompanyByProcess = await axiosBW.get(`/case/getCompanyByProcess/${this.props.match.params.processId}?${this.props.match.params.draft && this.props.match.params.draft === 'draft' ? 'draft=true' : ''}`)
      const resGetCasePanelCollapse = await axiosBW.get(`/users/case-panel-collapse/`)
      const companyId = resGetCompanyByProcess.data.payload.companyId
      this.setState({
        companyId,
        casePanelCollapse: resGetCasePanelCollapse.data.payload
      })
      // get the hall info to obtain the colors of the company
      const resHall = await axiosBW.get(`/companies/hall/${companyId}`)
      this.setState({
        formColors: {
          primaryColor: resHall.data.payload.portraitColor,
          secondaryColor: resHall.data.payload.circleColor,
          thirdColor: resHall.data.payload.thirdColor,
          fourthColor: resHall.data.payload.lightColor
        }
      })
      if (this.props.match.params.caseId === 'inicio') {
        await this.getIntroForm()
        this.setState({ caseLoading: false })
        this.props.setGlobalLoader(false)
      } else {
        await this.getForm()
        this.setState({ caseLoading: false })
        this.props.setGlobalLoader(false)
        if (!this.props.match.params.draft || this.props.match.params.draft !== 'draft') {
          await this.getMessaging()
          await this.getHistoric()
          if (!this.state.msgId && this.messagesEndRef.current) {
            this.scrollToBottom()
          }
          let element = document.getElementById(this.state.msgId)
          if (element) {
            element.scrollIntoView(false)
          }
        }
        // aditional panes
        if (this.state.casePanelAccess) {
          await this.getCompanyInfo(this.state.caseInfo.applicantCompanyId)
          await this.getapplicantCompanyCases(companyId, this.state.caseInfo.applicantCompanyId)
          await this.getApplicantCompanyContacts(this.state.caseInfo.applicantCompanyId)
        }

      }
      // Buscamos el tab en el query
      const query = new URLSearchParams(this.props.location.search)
      const active = query.get('activeTab')
      const myArr = ['0', '1', '2']
      if (myArr.includes(active)) {
        this.onChangeTab(active)
        this.setState({
          activeTab: active
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  disconnectSockets = () => {
    socket.off('message-list', this.onMessageList)
    socket.off('server:typing', this.onTyping)
    socket.off('server:message-error', this.onSocketError)
  }

  componentDidUpdate () {
    window.onbeforeunload = () => {
      this.disconnectSockets()
    }
    if (this.state.caseStatus === 'form' && this.state.showPrompt) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }

  }

  makeCounter (currtime, state) {
    const qtyTimeout = parseInt(currtime.timeout)
    const datez = momentTZ(currtime.date).add(qtyTimeout, 'hours')
    const currDate = momentTZ()
    const timeInMinutes = momentTZ.duration(datez.diff(currDate)).asMinutes()
    const totalHours = timeInMinutes / 60
    const totalMinutes = parseInt((timeInMinutes) % 60)
    const setTimeToPercentage = totalHours / 24 + totalMinutes / 60 / 24
    const timerStyle = {}
    if ((setTimeToPercentage * 100) <= 20 && setTimeToPercentage * 100 > 5) {
      timerStyle.background = '#FFAA00'
    } else if (setTimeToPercentage * 100 <= 5) {
      timerStyle.background = '#EE6A3B'
    } else {
      timerStyle.background = '#3CB166'
    }
    const finalValue = totalHours < 0 || totalMinutes < 0 ? `VENCIDO` : `${parseInt(totalHours) > 9 ? parseInt(totalHours) : '0' + parseInt(totalHours)}:${totalMinutes > 9 ? totalMinutes : '0' + totalMinutes} `
    if (state && state.setState) {
      state.setState({
        timeIndicators: [
          ...state.state.timeIndicators.filter(e => e.id !== 3),
          {
            ...state.state.timeIndicators.filter(e => e.id === 3)[0],
            value: finalValue,
            conditionalStyle: timerStyle

          }
        ]
      })
    }
  }

  componentWillUnmount () {
    clearInterval(this.setCounter)
  }

  setMessageAttachment (currElem, file, message) {
    let newElem = { ...currElem }
    let listElem = [...this.state.attachmentListx]
    let pivotList = []
    listElem.forEach(e => {
      if (e.slug === newElem.slug) {
        e.message = message
      }
      pivotList.push(e)
    })
    this.setState({
      attachmentListx: pivotList
    })
  }

  setUrlAttachment (currElem, file, url) {
    let newElem = { ...currElem }
    let listElem = [...this.state.attachmentListx]
    let pivotList = []
    listElem.forEach(e => {
      if (e.slug === newElem.slug) {
        e.fileUrl = url
      }
      pivotList.push(e)
    })
    this.setState({
      attachmentListx: pivotList
    })
  }

  setProgress = (elemSlug, progress) => {
    const elemIndex = this.state.attachmentListx.findIndex(p => p.slug === elemSlug)
    const newAttachList = [...this.state.attachmentListx]
    newAttachList[elemIndex].progress = progress
    this.setState({
      attachmentListx: newAttachList
    })
  }

  setAttachmentStatus = (elemSlug, status) => {
    const elemIndex = this.state.attachmentListx.findIndex(p => p.slug === elemSlug)
    const newAttachList = [...this.state.attachmentListx]
    newAttachList[elemIndex].status = status
    this.setState({
      attachmentListx: newAttachList
    })
  }

  clearAttachment = (elemSlug) => {
    this.changeAttachment(elemSlug, '', '')
    toast.success("Adjunto eliminado correctamente")
  }

  uploadFile = async (file, type, slugName, currElem) => {
    this.setState({
      uploadingFile: true
    })
    if (type === 'messaging') {
      this.setState({
        // messagingAttachmentFilename: `Cargando Archivo ${file.name}`,
        messagingAttachmentFilename: `Subiendo Archivo ${file.name}`,
        messagingAttachmentFilenameTitle: file.name
      })
    } else {
      if (currElem) {
        this.setMessageAttachment(currElem, file, `Subiendo Archivo ${file.name}`)
      }
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      axiosBW.post('/attachment/upload-file', {
        module: 'form',
        filename: file.name,
        file: reader.result.split('base64')[1]
      })
        .then(async (res) => {
          if (res.data.status === 0) {
            this.setState({
              uploadingFile: false
            })
            if (type === 'messaging') {
              this.setState({
                messagingAttachmentProgress: 100,
              })
              await new Promise(r => setTimeout(r, 500));
              this.setState({
                messagingAttachmentFilename: 'Archivo Subido Correctamente',
                messagingButtonDisabled: false,
                messagingUrlAttachment: res.data.payload.downloadUrl
              })
            } else {
              this.setProgress(slugName, 100)
              await new Promise(r => setTimeout(r, 500));
              if (currElem) {
                this.setMessageAttachment(currElem, file, `Archivo Subido Correctamente ${file.name}`)
              }
              this.changeAttachment(slugName, file.name, res.data.payload.downloadUrl)
              this.setAttachmentStatus(slugName, 'pending')

            }
            toast.success("Archivo adjuntado correctamente")

          } else {
            this.setState({
              uploadingFile: false
            })
            if (type === 'messaging') {
              this.setState({
                messagingAttachmentFilename: 'Hubo un error al subir el archivo, por favor intentar nuevamente',
                messagingButtonDisabled: false,
                messagingUrlAttachment: ''
              })
            } else {
              this.setState({
                attachmentFilenameTitle: file.name,
              })
              this.setAttachmentStatus(slugName, 'pending')

            }
            toast.error("Hubo un problema al adjuntar el archivo")

          }
        })
        .catch(err => {
          console.log('error', err)
          this.setState({
            uploadingFile: false
          })
          if (type === 'messaging') {
            this.setState({
              messagingAttachmentFilename: 'Hubo un error al subir el archivo, por favor intentar nuevamente',
              messagingButtonDisabled: false,
              messagingUrlAttachment: ''
            })
          } else {
            this.setState({
              attachmentFilenameTitle: file.name,
            })
            this.setAttachmentStatus(slugName, 'pending')
          }
          toast.error("Hubo un problema al adjuntar el archivo")

        })
    }
    reader.onerror = function (error) {
      console.log(error)
    }
  }

  readAsDataURL (file) {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onerror = reject;
      fr.onload = function () {
        resolve(fr.result);
      }
      fr.readAsDataURL(file);
    });
  }

  async uploadTableFile (file, obj, objKey, tableSlug) {
    this.setState({
      actualTableFileUpload: objKey,
      actualTableFileUploadMsg: 'Cargando'
    })

    const readedFile = await this.readAsDataURL(file)
    if (readedFile) {
      const fileUpload = await axiosBW.post('/attachment/upload-file', {
        module: 'form',
        filename: file.name,
        file: readedFile.split('base64')[1]
      })
      if (fileUpload) {
        this.setState({
          actualTableFileUploadUrl: fileUpload.data.payload.downloadUrl
        })
        this.changeField(obj.objKey ? obj.objKey : obj.name, fileUpload.data.payload.downloadUrl, true, tableSlug, obj.index)


      }
    }
    this.setState({
      actualTableFileUpload: '',
      actualTableFileUploadMsg: ''
    })
  }

  updateCalculatedFields (changeSourceFieldSlug) {
    // TODO: Dependency analysis w/circular reference detection.
    const pivotCurrentFormFields = [...this.state.currentForm.fields]
    pivotCurrentFormFields.forEach((currentField) => {
      if (currentField.expression && currentField.expression !== '') {
        const foundExpression = currentField.expression
        if (foundExpression.includes(changeSourceFieldSlug)) {
          const currentFormValues = {}
          this.state.currentForm.fields.forEach((formWidget) => {
            if (formWidget.type === 'table') {
              currentFormValues[formWidget.slug] = this.props.tableWidgetList.filter(tableWidget => tableWidget.slug === formWidget.slug)[0].data
            } else {
              currentFormValues[formWidget.slug] = formWidget.defaultValue
            }
          })
          let evaluatedExpression = ''
          try {
            /*eslint-disable-next-line no-unused-vars*/
            const form = currentFormValues
            /*eslint-disable-next-line no-eval*/
            evaluatedExpression = eval(foundExpression)
          } catch (error) {
            console.log(error)
            evaluatedExpression = '#ERROR'
          }
          currentField.defaultValue = evaluatedExpression
        }
      }
    })
  }

  changeField (fieldName, fieldValue, isFromTable, tableSlug, rowIndex) {
    if (isFromTable) {
      const tableInfo = this.state.currentForm.fields.find(f => f.slug === tableSlug)
      this.props.changeTableField(fieldName, fieldValue, rowIndex, tableSlug, tableInfo)
      this.updateCalculatedFields(tableSlug)
    } else {
      const pivotCurrentFormFields = [...this.state.currentForm.fields]
      const newCurrentFormFields = []
      // Update field with user input.
      let updatedFieldSlug = ''
      pivotCurrentFormFields.forEach((currentField) => {
        if (currentField.name === fieldName) {
          currentField.defaultValue = fieldValue
          updatedFieldSlug = currentField.slug
        }
        newCurrentFormFields.push(currentField)
      })
      // Update calculated fields
      this.updateCalculatedFields(updatedFieldSlug)
      this.setState({
        currentForm: { ...this.state.currentForm, fields: newCurrentFormFields }
      })
    }
  }

  changeFieldSelect (fieldName, fieldValue, options, isFromTable, tableSlug, rowIndex) {
    if (!isFromTable) {
      let reloadMaster = false
      const pivotCurrentFormFields = [...this.state.currentForm.fields]
      const newCurrentFormFields = []

      let value = options.filter(el => el.key === fieldValue)[0] ? options.filter(el => el.key === fieldValue)[0].value : ''

      pivotCurrentFormFields.forEach((elem) => {
        let elemPivot = { ...elem }
        if (elemPivot.name === fieldName) {
          elemPivot.defaultValue = fieldValue
          elemPivot.selectedOption = {
            key: fieldValue,
            value: value
          }
          if (this.state.checkFields.indexOf(elemPivot.slug) !== -1) {
            reloadMaster = true
          }
        }
        newCurrentFormFields.push(elemPivot)
      })
      this.setState({
        currentForm: { ...this.state.currentForm, fields: newCurrentFormFields }
      })
      if (reloadMaster) {
        this.reloadMasterData(newCurrentFormFields)
      }
    } else {
      const tableInfo = this.state.currentForm.fields.find(f => f.slug === tableSlug)
      this.props.changeTableField(fieldName, fieldValue, rowIndex, tableSlug, tableInfo)
    }
  }

  changeAttachment (fieldSlug, fieldValue, fieldUrl) {
    const pivotCurrentFormFields = [...this.state.currentForm.fields]
    const newCurrentFormFields = []
    pivotCurrentFormFields.forEach((elem) => {
      if (elem.slug === fieldSlug) {
        elem.defaultValue = fieldValue
        elem.fileUrl = fieldUrl
      }
      newCurrentFormFields.push(elem)
    })

    this.setState({
      currentForm: { ...this.state.currentForm, fields: newCurrentFormFields }
    })
  }

  resetValidation (type, id) {
    switch (type) {
      case 'text': {
        return (document.getElementById(id).className = 'flow_form__default')
      }
      case 'textarea': {
        return (document.getElementById(id).className = 'flow_form__default flow_form__textarea')
      }
      case 'rut': {
        return (document.getElementById(id).className = 'flow_form__default')
      }
      default: {
        return (console.log('agregar más componentes'))
      }
    }
  }

  addRowToTableWidget (tableSlug, columns) {
    this.props.addRowToTableWidget(tableSlug, columns)
  }

  async getMasterKeyValue (masterConfig, fieldValue) {
    return axiosBW.get(`/master-data/key-value/${masterConfig.collection}`, {
      params: {
        value: fieldValue,
        key: masterConfig.value
      }
    })
  }

  async changeFieldMaster (masterSlug, fieldValue, fieldName, isFromTable, tableSlug, rowIndex) {
    this.props.setGlobalLoader(true)
    const masterConfig = isFromTable ? this.state.masterDataObj[`${tableSlug}__${masterSlug}`].config : this.state.masterDataObj[masterSlug].config
    if (!isFromTable) {
      const pivotCurrentFormFields = [...this.state.currentForm.fields]
      const newCurrentFormFields = []
      pivotCurrentFormFields.forEach((elem) => {
        if (elem.name === fieldName) {
          elem.defaultValue = fieldValue
        }
        newCurrentFormFields.push(elem)
      })
      try {
        const resMaster = await this.getMasterKeyValue(masterConfig, fieldValue)
        if (resMaster.data.payload) {
          for (let i = 0; i < masterConfig.fields.length; i++) {
            const masterField = masterConfig.fields[i];
            const formFieldIndex = newCurrentFormFields.findIndex(f => f.slug === masterField.slug)
            if (formFieldIndex !== -1 && resMaster.data.payload[masterField.column]) {
              newCurrentFormFields[formFieldIndex].defaultValue = resMaster.data.payload[masterField.column].toString()
            }
          }
        }
      } catch (error) {
        console.log('changeFieldMaster')
        console.log(error);
        notificationValidationHandler('Ha ocurrido un error al consultar los datos maestros')
      }
      this.setState({
        currentForm: { ...this.state.currentForm, fields: newCurrentFormFields }
      })
      // Reload master data in case there are other MD selects depending on
      // this MD select, but don't reload this MD.
      this.reloadMasterData(newCurrentFormFields, masterSlug)
      // We're done.
      this.props.setGlobalLoader(false)
    } else {
      const table = this.state.currentForm.fields.find(f => f.slug === tableSlug)
      const columns = table.columns
      const tableInfo = this.state.currentForm.fields.find(f => f.slug === tableSlug)
      this.props.changeTableField(fieldName, fieldValue, rowIndex, tableSlug, tableInfo)
      const resMaster = await this.getMasterKeyValue(masterConfig, fieldValue)
      if (resMaster.data.payload) {
        for (let i = 0; i < masterConfig.fields.length; i++) {
          const masterField = masterConfig.fields[i];
          const columnField = columns.find(c => c.name === masterField.slug)
          if (columnField && resMaster.data.payload[masterField.column]) {
            this.props.changeTableField(columnField.slug, resMaster.data.payload[masterField.column].toString(), rowIndex, tableSlug, tableInfo)
          }
        }
      }
      this.props.setGlobalLoader(false)
    }
  }

  /** Returns a ReactSelect y the field is not allowed to add new Items. OTOH, ig the field
   * allows new values, it returns a CreatableSelect.
   */
  getSelectComponent (fieldObj, masterSlug, isFromTable, tableSlug) {
    if (fieldObj.masterDataConfig && fieldObj.masterDataConfig.allowNewValues) {
      return (
        <CreatableSelect
          id={fieldObj._id}
          className="flow_form flow_form__select"
          name={fieldObj.name}
          onChange={value => { this.changeFieldMaster(fieldObj.slug || fieldObj.objKey, value.value.toString(), fieldObj.objKey ? fieldObj.objKey : fieldObj.name, isFromTable, tableSlug, fieldObj.index) }}
          styles={{
            menu: base => ({
              ...base,
              minWidth: '300px'
            })
          }}
          disabled={fieldObj.readOnly}
          defaultValue={{ label: fieldObj.defaultValue, value: 0 }}
          options={this.state.masterDataObj[masterSlug] && this.state.masterDataObj[masterSlug].data ? this.state.masterDataObj[masterSlug].data : []}
          placeholder='Buscar...'
          formatCreateLabel={(inputValue) => `Crear ${fieldObj.name}: ${inputValue}`}
        />
      )
    } else {
      return (
        <ReactSelect
          id={fieldObj._id}
          className="flow_form flow_form__select"
          name={fieldObj.name}
          onChange={value => { this.changeFieldMaster(fieldObj.slug || fieldObj.objKey, value.value.toString(), fieldObj.objKey ? fieldObj.objKey : fieldObj.name, isFromTable, tableSlug, fieldObj.index) }}
          styles={{
            menu: base => ({
              ...base,
              minWidth: '300px'
            })
          }}
          disabled={fieldObj.readOnly}
          defaultValue={{ label: fieldObj.defaultValue, value: 0 }}
          options={this.state.masterDataObj[masterSlug] && this.state.masterDataObj[masterSlug].data ? this.state.masterDataObj[masterSlug].data : []}
          placeholder='Buscar...'
          noOptionsMessage={() => 'No hay coincidencias'}
        />
      )
    }
  }

  getFieldByObj (obj, isFromTable, tableSlug) {
    switch (obj.type) {
      case 'text': {
        return (
          <div className="flow_form__wrapper">
            {
              !isFromTable ? (
                <div className="flow_form__label">
                  {obj.name}{obj.mandatory && '*'}
                </div>
              ) : null
            }

            <div className={isFromTable ? 'flow_form__table_field' : 'flow_form__field'}>
              <input
                id={obj._id}
                className={`flow_form__default flow_form__input_text${obj.align}`}
                type="text"
                name={obj.name}
                onChange={e => { this.changeField(obj.objKey ? obj.objKey : obj.name, e.target.value, isFromTable, tableSlug, obj.index) }}
                value={obj.numberFormat ? formatNumber(obj.defaultValue, obj.decimalPlaces ? obj.decimalPlaces : 0) : obj.defaultValue}
                disabled={obj.readOnly}
              />
            </div>
          </div>
        )
      }
      case 'date': {
        return (
          <div className="flow_form__wrapper">
            {
              !isFromTable ? (
                <div className="flow_form__label">
                  {obj.name}{obj.mandatory && '*'}
                </div>
              ) : null
            }

            <div className={isFromTable ? 'flow_form__table_field' : 'flow_form__field'}>
              <ConfigProvider locale={locale}>
                <DatePicker
                  className={`flow_form__default flow_form__input_text`}
                  id={obj._id}
                  onChange={(date, dateString) => {
                    console.log(date, dateString)
                    this.changeField(obj.objKey ? obj.objKey : obj.name, dateString, isFromTable, tableSlug, obj.index)
                  }}
                  value={obj.defaultValue !== '' ? moment(obj.defaultValue, 'YYYY-MM-DD') : ''}
                  disabled={obj.readOnly}
                  format={'YYYY-MM-DD'}
                />
              </ConfigProvider>
            </div>
          </div>
        )
      }
      case 'validated': {
        return (
          <div className="flow_form__wrapper">
            {
              !isFromTable ? (
                <div className="flow_form__label">
                  {obj.name}{obj.mandatory && '*'}
                </div>
              ) : null
            }

            <div className={isFromTable ? 'flow_form__table_field' : 'flow_form__field'}>
              <input
                id={obj._id}
                className={`flow_form__default flow_form__input_text`}
                type="text"
                name={obj.name}
                onChange={e => { this.changeField(obj.objKey ? obj.objKey : obj.name, e.target.value, isFromTable, tableSlug, obj.index) }}
                value={obj.defaultValue}
                disabled={obj.readOnly}
              />
            </div>
          </div>
        )
      }
      case 'rut': {
        return (
          <div className="flow_form__wrapper">
            <div className="flow_form__label">
              {obj.name}{obj.mandatory && '*'}
            </div>
            <div className={isFromTable ? 'flow_form__table_field' : 'flow_form__field'}>
              <input
                id={obj._id}
                className={`flow_form__default flow_form__input_text`}
                type="rut"
                name={obj.name}
                onChange={e => { isFromTable ? this.changeField(obj.objKey ? obj.objKey : obj.name, e.target.value, isFromTable, tableSlug, obj.index) : this.changeField(obj.name, e.target.value) }}
                value={obj.defaultValue}
                disabled={obj.readOnly}
                onBlur={() => !isFromTable ? this.resetValidation(obj.type, obj._id) : null}
              />
            </div>
          </div>
        )
      }
      case 'select': {
        return (
          <div className="flow_form__wrapper">
            <div className="flow_form__label">
              {obj.name}{obj.mandatory && '*'}
            </div>
            <div className={isFromTable ? 'flow_form__table_field' : 'flow_form__field'}>
              <Select
                id={obj._id}
                className="flow_form flow_form__select"
                name={obj.name}
                onChange={value => { this.changeFieldSelect(obj.objKey ? obj.objKey : obj.name, value, obj.options, isFromTable, tableSlug, obj.index) }}
                disabled={obj.readOnly}
                value={obj.defaultValue}
              >
                {
                  obj.options ? (
                    obj.options.map((elem, index) => {
                      return (
                        <Option key={`optSel-${index}`} value={elem.key}>
                          {elem.value}
                        </Option>
                      )
                    })
                  ) : ''
                }
              </Select>
            </div>
          </div>
        )
      }
      case 'textarea': {
        return (
          <div className="flow_form__wrapper">
            <div className="flow_form__label">
              {obj.name}{obj.mandatory && '*'}
            </div>
            <div className={isFromTable ? 'flow_form__table_field' : 'flow_form__field'}>
              <textarea
                id={obj._id}
                className="flow_form__default flow_form__textarea"
                name={obj.name}
                disabled={obj.readOnly}
                // onChange={e => {this.changeField(obj.name, e.target.value)}} 
                onChange={e => { this.changeField(obj.objKey ? obj.objKey : obj.name, e.target.value, isFromTable, tableSlug, obj.index) }}
                value={obj.defaultValue}
              // onBlur ={() => this.resetValidation(obj.type, obj._id)}
              >

              </textarea>
            </div>
          </div>
        )
      }
      case 'markdown': {
        return (
          <ReactMarkdown className="flow_form__markdown" escapeHtml={false} source={obj.defaultValue} />
        )
      }
      case 'attachment': {
        if (!isFromTable) {
          const elemIndex = this.state.attachmentListx.findIndex(p => p.slug === obj.slug)
          let currElem = this.state.attachmentListx[elemIndex]
          const setUploading = (elemIndex, fileName) => {
            const newAttachList = [...this.state.attachmentListx]
            newAttachList[elemIndex].status = 'uploading'
            newAttachList[elemIndex].fileName = fileName
            this.setState({
              attachmentListx: newAttachList
            })
          }
          const uploadFile = this.uploadFile
          const setProgress = this.setProgress
          const uploadProps = {
            multiple: false,
            showUploadList: false,
            async customRequest ({
              action,
              data,
              file,
              filename,
              headers,
              onError,
              onProgress,
              onSuccess,
              withCredentials,
            }) {
              // EXAMPLE: post form-data with 'axios'
              // eslint-disable-next-line no-undef
              const isLt4M = file.size / 1024 / 1024 < 4;
              if (!isLt4M) {
                toast.error('El archivo debe ser menor a 4MB');
                onError()
                return
              }
              setUploading(elemIndex, file.name)
              setProgress(obj.slug, 20)
              await uploadFile(
                file,
                'field',
                obj.slug,
                currElem
              )
              onSuccess(null, file);
            },
          };
          return (
            <div className="attachment_field">
              <h3 style={{ width: '100%', display: 'block' }}>{obj.name}{obj.mandatory && '*'}</h3>
              {
                obj.fileUrl && obj.fileUrl.length > 0 ? (
                  <>
                    {/* <a onClick={() => this.downloadFile(obj.fileUrl)}>
                      Ver Archivo
                    </a> */}
                    <div className="messaging__attachment_input_wrapper">
                      <div className="messaging__attachment_button">
                        <p>{obj.defaultValue}</p>
                        <div className="messaging__attachment_actions">
                          <span><a className="messaging__attachment_link" onClick={() => this.downloadFile(obj.fileUrl)}><DownloadOutlined /></a></span> {!obj.readOnly ? (<span onClick={e => this.clearAttachment(obj.slug)}><DeleteOutlined /></span>) : ''}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {
                      obj.readOnly ? (
                        <div>No existe adjunto disponible</div>
                      ) : currElem.status === 'uploading' ? (
                        <>
                          <div className="messaging__attachment_input_wrapper">
                            <div className="messaging__attachment_button">
                              <p>{currElem.fileName}</p>

                            </div>
                            <Progress strokeWidth={3} percent={currElem.progress} showInfo={false} />
                          </div>
                        </>
                      ) : (
                        <div style={{ width: '100%' }}>
                          <Dragger ref={currElem && currElem.ref ? currElem.ref : undefined} {...uploadProps}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Clickea aquí o arrastra un archivo para subirlo. (Max. 4MB)</p>
                            {/* <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                band files
                              </p> */}
                          </Dragger>
                        </div>

                      )
                    }

                  </>
                )
              }
            </div>

          )
        } else {
          return (
            <div className="attachment_field">
              {
                (obj.defaultValue && obj.defaultValue.length > 0) ? (
                  <>
                    <a disabled={false} href={obj.defaultValue} without rel="noopener noreferrer" target="_blank" className="attachment_field__button">
                      <div className="icon_bg">
                        <FontAwesomeIcon icon="paperclip" />
                      </div>
                      <label className="attachment_field__filename">
                        Ver archivo
                      </label>
                    </a>
                  </>
                ) : (
                  <>
                    {
                      obj.readOnly ? (
                        <div>Sin archivo</div>
                      ) : (
                        <>
                          <input ref={obj.ref} style={{ display: 'none' }} type="file" onChange={
                            async (e) => {
                              const file = e.target.files[0]
                              const isLt4M = file.size / 1024 / 1024 < 4;
                              if (!isLt4M) {
                                toast.error('El archivo debe ser menor a 4MB');

                              } else {
                                await this.uploadTableFile(file, obj, `${obj.objKey}-${obj.index}`, tableSlug)
                              }
                            }
                          } />
                          <Tooltip title="Hasta 4 MB">
                            <button disabled={this.state.actualTableFileUploadMsg !== ''} onClick={() => obj && obj.ref ? obj.ref.current.click() : console.log('no hay attachment asociado')} className="attachment_field__button">
                              <div className="icon_bg">
                                <FontAwesomeIcon icon="paperclip" />
                              </div>
                              <label className="attachment_field__filename">
                                {
                                  `${obj.objKey}-${obj.index}` === this.state.actualTableFileUpload ? (
                                    this.state.actualTableFileUploadMsg
                                  ) : (
                                    'Adjuntar'
                                  )
                                }
                              </label>
                            </button>
                          </Tooltip>
                        </>
                      )
                    }
                  </>
                )
              }
            </div>
          )
        }

      }
      case 'table': {
        const columns = []
        for (let columnIndex = 0; columnIndex < obj.columns.length; columnIndex++) {
          const column = obj.columns[columnIndex];
          columns.push({
            title: column.name,
            width: column.width ? `${column.width}%` : null,
            render: (t, r, i) => {
              const columnSlug = column.slug
              const tableFromState = this.props.tableWidgetList.filter(tf => tf.slug === obj.slug)[0].data
              const rowFromTable = tableFromState[i]
              if (rowFromTable) {
                // pass new props to find object
                const preparedRow = {
                  ...rowFromTable,
                  type: column.type,
                  defaultValue: rowFromTable[columnSlug] || '',
                  name: column.title,
                  objKey: columnSlug,
                  options: column.options,
                  readOnly: obj.readOnly || column.readOnly,
                  align: column.align,
                  numberFormat: column.numberFormat,
                  decimalPlaces: column.decimalPlaces,
                  mandatory: column.mandatory,
                  fileUrl: column.fileUrl,
                  ref: React.createRef(),
                  index: i
                }
                return (
                  <div>{this.getFieldByObj(preparedRow, true, obj.slug)}</div>
                )
              }
            }
          })
        }
        const tableFiltered = this.props.tableWidgetList.filter(tf => tf.slug === obj.slug)[0]
        const tableFromState = tableFiltered ? tableFiltered.data : []
        const isTableDisabled = obj.readOnly
        const hideAddButton = isTableDisabled || ((typeof obj.tableOptions !== 'undefined') ? obj.tableOptions.disableAddRowButton : false)
        return (<div className="flow_form__table">
          <h3> {obj.name}{obj.mandatory && '*'} </h3>
          {
            hideAddButton
              ? null
              : (
                  <button className="desktop_header__search_bar_button" style={{ fontSize: '12px' }} onClick={() => this.addRowToTableWidget(obj.slug, obj.columns)}>
                    Agregar Fila
                  </button>
                )
          }
          <TableWidget columns={columns}
            dataSource={tableFromState}
            hideDeleteButton={(typeof obj.tableOptions !== 'undefined') ? obj.tableOptions.disableDeleteRowButton : false}
            disableTableFields={isTableDisabled}
            slug={obj.slug} />
        </div>)
      }
      case 'hidden': {
        return (
          <div className="flow_form__hidden">
            <input type="hidden" value={obj.defaultValue} />
          </div>
        )
      }
      case 'master-data': {
        const masterSlug = isFromTable ? `${tableSlug}__${obj.objKey}` : obj.slug
        // const masterOptions = this.state.masterDataObj[masterSlug] && this.state.masterDataObj[masterSlug].data ? this.state.masterDataObj[masterSlug].data : []
        return (
          <div className="flow_form__wrapper">
            <div className="flow_form__label">
              {obj.name}{obj.mandatory && '*'}
            </div>
            <div className={isFromTable ? 'flow_form__table_field' : 'flow_form__field'}>
              {obj.readOnly ?
                <input
                  id={obj._id}
                  className={`flow_form__default flow_form__input_text`}
                  type="text"
                  name={obj.name}
                  value={obj.defaultValue}
                  disabled={true}
                />
                :
                this.getSelectComponent(obj, masterSlug, isFromTable, tableSlug)
              }

            </div>
          </div>
        )
      }
      case 'geolocation': {
        if (obj.readOnly) {
          return (
            <div className="flow_form__wrapper">
              <div className="flow_form__label">
                {obj.name}
              </div>
              <div className="flow_form__field" style={{ height: '300px' }}>
                {obj.defaultValue === '' || obj.defaultValue === 'none' || obj.defaultValue.indexOf(',') === -1 ? (
                  'No fue posible obtener la ubicación'
                ) : (
                  <div className="geolocation_container" onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${obj.defaultValue}`, "_blank")}>
                    <MapContainer center={[obj.defaultValue.split(',')[0], obj.defaultValue.split(',')[1]]} zoom={15} scrollWheelZoom={false}>
                      <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker position={[obj.defaultValue.split(',')[0], obj.defaultValue.split(',')[1]]}>
                      </Marker>
                    </MapContainer>
                  </div>
                )}
              </div>
            </div>
          )
        }
        return ''
      }
      default: {
        return (
          <div className="no_flow_element">No ha seleccionado un elemento de formulario válido</div>
        )
      }
    }
  }

  toBeginCase (e, processId, nextForm) {
    e.preventDefault()
    this.props.setGlobalLoader(true)
    axiosBW.post('/case/init-case', {
      "processId": processId,
      "nextForm": nextForm,
      draft: !!(this.props.match.params.draft && this.props.match.params.draft === 'draft')

    })
      .then((res) => {
        localStorage.setItem('caseId', res.data.payload.caseId)
        localStorage.setItem('formId', res.data.payload._id)
        this.props.setCurrentCaseNumber(res.data.payload.caseNumber)
        // FLAGG. This must be removed
        this.props.history.push({
          pathname: `/`,
          state: { caseId: res.data.payload.caseId, caseNumber: res.data.payload.caseNumber }
        })
        console.log(`/servicios/caso/${this.props.match.params.processId}/${res.data.payload.caseId}/${this.props.match.params.draft && this.props.match.params.draft === 'draft' ? 'draft' : ''}`)
        this.props.history.push({
          pathname: `/servicios/caso/${this.props.match.params.processId}/${res.data.payload.caseId}/${this.props.match.params.draft && this.props.match.params.draft === 'draft' ? 'draft' : ''}`,
          state: { caseId: res.data.payload.caseId, caseNumber: res.data.payload.caseNumber }
        })
        this.props.setGlobalLoader(false)
      })
      .catch((err) => {
        this.props.setGlobalLoader(false)
        console.log('error', err)
      })
  }

  isJsonString (str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  execForm = async (nextForm, skipValidation = false) => {
    await this.props.setGlobalLoader(true)
    let formattedFields = []
    let failMandatory = false
    let failRut = false
    let failRegEx = false
    let msgRegEx = ''
    for (let i = 0; i < this.state.currentForm.fields.length; i++) {
      const elem = this.state.currentForm.fields[i];
      if (elem.type === 'geolocation' && !elem.readOnly) {
        try {
          const location = await this.getLocation()
          this.changeField(elem.name, location, null, null, null)
        } catch (error) {
          this.changeField(elem.name, 'none', null, null, null)
        }
      }
      if (this.isFieldVisible(elem)) {
        if (elem.type === 'text' || elem.type === 'rut' || elem.type === 'textarea' || elem.type === 'select' || elem.type === 'validated') {
          document.getElementById(elem._id).className = document.getElementById(elem._id).className.replace('flow_form__default-required', '')
          document.getElementById(elem._id).className = document.getElementById(elem._id).className.replace('flow_form__textarea', '')
          document.getElementById(elem._id).className = document.getElementById(elem._id).className.replace('ant-select-selection--single-required', '')
        }
        if (elem.type === 'table') {
          const tableValues = this.props.tableWidgetList.find(d => d.slug === elem.slug)
          if (elem.mandatory && tableValues.data.length === 0) {
            failMandatory = true
          }
          elem.defaultValue = tableValues.data
          tableValues.data.forEach(row => {
            for (const key in row) {
              if (Object.prototype.hasOwnProperty.call(row, key) && key !== 'key') {
                const value = row[key];
                const column = elem.columns.find(c => c.slug === key)
                if (column) {
                  if (!column.readOnly) {
                    if (column.type === 'rut' && !validaRut(value)) {
                      failRut = true
                    } else if (elem.mandatory && typeof value === 'string' && value.trim().length === 0) {
                      failMandatory = true
                    }
                  }
                }
              }
            }
          })
        }
        if (!elem.readOnly) {
          if (elem.type === 'rut' && !validaRut(elem.defaultValue)) {
            document.getElementById(elem._id).className = document.getElementById(elem._id).className + ' flow_form__default-required'
            failRut = true
          } else if (elem.type === 'attachment' && (elem.mandatory) && (elem.fileUrl === undefined || elem.fileUrl.trim().length === 0)) {
            failMandatory = true
          } else if (elem.type !== 'table' && (elem.mandatory) && (elem.defaultValue === undefined || elem.defaultValue.toString().trim().length === 0)) {
            if (elem.type === 'text' || elem.type === 'validated' || elem.type === 'date') {
              document.getElementById(elem._id).className = document.getElementById(elem._id).className + ' flow_form__default-required'
            } else if (elem.type === 'textarea') {
              document.getElementById(elem._id).className = document.getElementById(elem._id).className + ' flow_form__default-required flow_form__textarea'
            } else if (elem.type === 'select') {
              document.getElementById(elem._id).className = document.getElementById(elem._id).className + ' flow_form ant-select-selection--single-required'
            }
            failMandatory = true
          }
          if ((elem.regEx) && elem.regEx.length !== 0 && elem.defaultValue.length !== 0) {
            let regEx = new RegExp(elem.regEx, 'g')
            if (!regEx.test(elem.defaultValue)) {
              document.getElementById(elem._id).className = document.getElementById(elem._id).className + ' flow_form__default-required'
              failRegEx = true
              if (elem.regExDescrip) {
                msgRegEx = elem.regExDescrip
              }
            }
          }
        }
        if (elem.type === 'table') {
          tableClearNumberFormat(elem, this.props)
        }
        formattedFields.push({
          name: elem.name,
          columns: elem.columns,
          slug: elem.slug,
          // defaultValue: typeof(elem.defaultValue) === 'object' ? JSON.stringify(elem.defaultValue) : elem.defaultValue,
          defaultValue: elem.defaultValue,
          fileUrl: elem.fileUrl,
          option: elem.selectedOption,
          type: elem.type,
          hideInOverview: elem.hideInOverview ? elem.hideInOverview : false
        })
      }
    }
    // this.props.tableWidgetList
    if (!skipValidation && (failRut || failMandatory || failRegEx)) {
      if (failMandatory) {
        notificationValidationHandler('Faltan campos obligatorios por llenar')
      }
      if (failRut) {
        notificationValidationHandler('Rut no válido (NNNNNNNN-D)')
      }
      if (failRegEx) {
        notificationValidationHandler('No cumple con la regla de validación')
        if (msgRegEx.length > 0) {
          notificationValidationHandler(`(${msgRegEx})`)
        }
      }
      await this.props.setGlobalLoader(false)
    } else {
      this.setState({
        showPrompt: false
      })
      let resExecForm
      try {
        resExecForm = await axiosBW.post('/case/exec-form/', {
          formId: this.state.currentForm._id,
          processId: this.props.match.params.processId,
          formSlug: this.state.currentForm.slug,
          caseId: this.props.match.params.caseId,
          dueDate: momentTZ().format('YYYY-MM-DD hh:mm:ss'),
          executedAction: nextForm,
          request: formattedFields,
          fields: formattedFields,
          draft: !!(this.props.match.params.draft && this.props.match.params.draft === 'draft')
        })
      } catch (ex) {
        console.log('error exec-form', ex)
      }
      if (!resExecForm || !resExecForm.data) {
        Modal.error({
          title: 'Error',
          content: 'No ha sido posible enviar el formulario, por favor intente más tarde'
        })
      }
      const payload = resExecForm.data.payload
      if (payload.status === 'closed') {
        toast.success("¡Ha finalizado el caso con éxito!", successOpts)
      } else if (!payload.roleApproved && payload.overview) {
        toast.success(`Su información se ha recibido exitósamente y será atendida en la siguiente actividad: ${payload.nextForm.title}`, successOpts)
      } else {
        toast.success("Su información se ha recibido exitósamente", successOpts)
      }
      this.props.history.push(`/`)
      this.props.history.push(`/servicios/caso/${this.props.match.params.processId}/${this.props.match.params.caseId}/${this.props.match.params.draft && this.props.match.params.draft === 'draft' ? 'draft' : ''}`)
      this.setState({
        currentForm: resExecForm.data.payload.nextForm ? resExecForm.data.payload.nextForm : {},
        roleApproved: resExecForm.data.payload.roleApproved
      })
      await this.props.setGlobalLoader(false)
    }
  }

  unassignUser = async () => {
    try {
      const res = await axiosBW.post('/case/unassign-user/', {
        caseId: this.props.match.params.caseId
      })
      toast.success("¡Ha quitado la asignación del caso con éxito!")
      // const payload = res.data.payload
      this.props.history.push(`/`)
      this.props.history.push(`/servicios/caso/${this.props.match.params.processId}/${this.props.match.params.caseId}/${this.props.match.params.draft && this.props.match.params.draft === 'draft' ? 'draft' : ''}`)
    } catch (error) {
      toast.error("¡Ha ocurrido un error al quitar la asiganción, vuelva a intentarlo o recargue la página!")
    }

  }

  openAttachment () {
    this.messageRefAttachment.current.click()
  }

  onChangeTextarea (e) {
    this.setState({
      textareaMessage: e.target.value,
      charCounter: e.target.value.length
    })
    this.startTyping()
  }

  async cancelMessageAttachment () {
    this.setState({
      messagingUrlAttachment: '',
      messagingAttachmentFilename: '',
      messagingAttachmentFilenameTitle: ''
    })
  }

  async sendMessage () {
    if (this.state.textareaMessage.length > 0) {
      this.props.setGlobalLoader(true)
      const message = {
        caseId: this.props.match.params.caseId,
        message: this.state.textareaMessage,
        filename: this.state.messagingAttachmentFilenameTitle,
        fileUrl: this.state.messagingUrlAttachment,
      }
      const tempMessageList = [...this.state.messageList, {
        id: this.state.messageList.length,
        userId: this.props.userId,
        firstName: this.props.firstName,
        messageDate: new Date(),
        message: this.state.textareaMessage,
        filename: this.state.messagingAttachmentFilenameTitle,
        fileUrl: this.state.messagingUrlAttachment
      }]
      this.setState({
        messageList: tempMessageList
      })
      socket.emit('client:send-message', { message, token: this.props.token })
      this.setState({
        textareaMessage: '',
        messagingUrlAttachment: '',
        messagingAttachmentFilename: '',
        messagingAttachmentFilenameTitle: '',
        filename: '',
        fileUrl: ''
      })
    } else {
      this.setState({
        sendMessageStatus: 'Debe ingresar un mensaje'
      })
    }
    this.props.setGlobalLoader(false)
  }

  attachmentMessageUpload = async (file) => {
    this.setState({
      messagingButtonDisabled: true
    })
    await this.uploadFile(
      file,
      'messaging'
    )
  }

  isFieldVisible = (field) => {
    let isVisible = false
    if (field.visible) {
      if (field.visible === 'true' || field.visible.trim() === '') {
        isVisible = true
      } else if (field.visible === 'false') {
        isVisible = false
      } else {
        const form = {}
        /*eslint-disable-next-line no-unused-vars*/
        const caseInfo = this.state.caseInfo
        /*eslint-disable-next-line no-unused-vars*/
        const processInfo = this.state.processInfo
        // Extract fields to simple structure
        this.state.currentForm.fields.forEach(value => {
          form[value.slug] = value.defaultValue
        })
        try {
          /*eslint-disable-next-line no-eval*/
          const evaluatedVisible = eval(field.visible)
          isVisible = evaluatedVisible
        } catch (error) {
          console.log(error)
          isVisible = true
        }

      }
    } else {
      isVisible = true
    }
    return isVisible
  }

  firstTab () {
    switch (this.state.caseStatus) {
      case 'intro':
        const isEmptyObject = !(Object.entries(this.state.stepData).length === 0 && this.state.stepData.constructor === Object)
        if (!this.state.validForm) {
          return (
            <div className="case_center">
              <h1>Proceso No encontrado</h1>
              <div> El proceso al cual está intentando ingresar no está disponible o no es válido</div>
              <div><Link className="back_to" to={this.props.match.params.draft && this.props.match.params.draft === 'draft' ? `/workflow/${this.state.processSlug}/draft` : '/'}> Volver al Escritorio</Link></div>
            </div>
          )
        }
        return (
          isEmptyObject ? (
            <div className="case_center begin_case__inside">
              <div className="begin_case__wrapper">
                {
                  this.state.stepData.fields.map((elem) => {
                    return (
                      <div key={elem._id} className="begin_case__markdown_description">
                        <ReactMarkdown escapeHtml={false} source={elem.defaultValue} />
                      </div>
                    )
                  })
                }
                <div className="begin_case__buttons">
                  {
                    this.state.stepData.actions.map((elem) => {
                      return (
                        <div key={elem._id} className="begin_case__button_cont">
                          <div className="begin_case__button" style={{ backgroundColor: elem.backgroundColor || null, color: elem.textColor || null }} onClick={e => this.toBeginCase(e, this.props.match.params.processId, elem.formSlug)}> {elem.label} </div>
                        </div>
                      )
                    })
                  }
                  <div key={`btn-return`} className="begin_case__button_cont begin_case__button_cont--back">
                    <Link className="begin_case__button" to={this.props.match.params.draft && this.props.match.params.draft === 'draft' ? `/workflow/${this.state.processSlug}/draft` : '/'}>Volver al Escritorio</Link>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="loading-pane">
              <LoadingOutlined />
            </div>
          )
        )
      case 'form':
        return (
          <div className="case_center form_case__inside">
            <p className="form_case_description">{this.state.currentForm.description ? this.state.currentForm.description : 'Sin Descripción'} </p>
            <div className="form_case__wrapper">
              <div className="form_case__list">
                {
                  this.state.currentForm && this.state.currentForm.fields ? (
                    this.state.currentForm.fields.map((form_elem, index) => {
                      if (this.isFieldVisible(form_elem)) {
                        return (
                          <div key={`form_elem-${index}`} className="form_case__element">
                            {this.getFieldByObj(form_elem)}
                          </div>
                        )
                      } else { return '' }
                    })
                  ) : (
                    ''
                  )

                }
              </div>
              {this.state.currentForm && this.state.currentForm.fields && this.state.currentForm.fields.find(f => f.type === 'geolocation' && !f.readOnly) ? (
                <div key={`form_elem-${this.state.currentForm.fields.length}`} className="form_case__element">
                  *Este formulario solicitará su ubicación
                </div>
              ) : null}
              <div className="form_case__actions">
                {
                  this.state.currentForm && this.state.currentForm.fields ? (
                    this.state.currentForm.actions.map((action, index) => {
                      if (!this.isFieldVisible(action)) {
                        return null
                      }
                      const customStyle = {
                        backgroundColor: action.backgroundColor || null,
                        color: action.textColor || null
                      }
                      return (
                        <div key={`acti-${action.formSlug}-${index}`} className="form_case__button_wrapper" style={{ width: action.layout && action.layout === 'half-width' ? '50%' : null }}>
                          <div onClick={() => {
                            if (!this.state.uploadingFile) {
                              if (action.confirm) {
                                const execForm = this.execForm
                                Modal.confirm({
                                  title: 'Confirmación',
                                  content: `¿Está seguro de avanzar con la acción '${action.label}'?`,
                                  onOk () {
                                    execForm(action.formSlug, action.skipValidation)
                                  }
                                })
                              } else {
                                this.execForm(action.formSlug, action.skipValidation)
                              }
                            }
                          }}
                            className="form_case__button"
                            style={customStyle}
                            to={`caso-${action.formSlug}`}
                          >{action.label}</div>
                        </div>
                      )
                    })
                  ) : ''
                }
              </div>
              {/* <div key={`btn-return`} className="">
                  <Link className="back_down_button" to={this.props.match.params.draft && this.props.match.params.draft === 'draft' ? `/workflow/${this.state.processSlug}/draft` : '/' }><FontAwesomeIcon icon="arrow-left" />Volver</Link>
                </div> */}
            </div>
          </div>
        )
      case 'overview-closed':
      case 'overview-open':
        return (

          <div key={`overv`} className="case_center overview_case__form">
            <div className="overview_case__form_content">
              {
                this.state.overview.map((e, i) => {
                  const columns = []
                  const tableForms = []
                  if (e.type === 'table') {
                    tableForms.push({ slug: e.slug, data: e.defaultValue ? e.defaultValue : [] })
                    e.columns.map((column, index) => {
                      columns.push({
                        title: column.name,
                        width: column.width ? `${column.width}%` : null,
                        render: (t, r, i) => {
                          const columnSlug = column.slug
                          const tableFromState = tableForms.filter(tf => tf.slug === e.slug)[0].data
                          const rowFromTable = tableFromState[i]
                          if (rowFromTable) {
                            const preparedRow = {
                              ...rowFromTable,
                              type: column.type,
                              defaultValue: rowFromTable[columnSlug] || '',
                              name: column.title,
                              objKey: columnSlug,
                              options: column.options,
                              readOnly: true,
                              align: column.align,
                              numberFormat: column.numberFormat,
                              decimalPlaces: column.decimalPlaces,
                              mandatory: column.mandatory,
                              fileUrl: column.fileUrl,
                              ref: React.createRef(),
                              index: i
                            }
                            return (
                              <div>{this.getFieldByObj(preparedRow, true, e.slug)}</div>
                            )
                          }
                        }
                      })
                    })
                  }
                  if (!e.hideInOverview) {
                    let fileName
                    if (e.fileUrl && e.fileUrl.length > 0) {
                      const fileFromUrl = e.fileUrl.substring(e.fileUrl.lastIndexOf('/') + 1)
                      fileName = fileFromUrl.substring(fileFromUrl.indexOf('_') + 1, fileFromUrl.indexOf('?'))
                      console.log(fileName)
                    }
                    return (
                      <div key={`form-${e._id}`} className="overview_case__form_data">
                        {e.type !== 'markdown' && e.type !== 'hidden' ? (
                          <div className="overview_case__form_label">
                            {e.name}
                          </div>
                        ) : ''}
                        <div className="overview_case__form_value">
                          {
                            e.type === 'table' ? (
                              <>
                                <div> {<AntdTable pagination={false} columns={columns ? columns : []} dataSource={e.defaultValue} locale={{ emptyText: 'Actualmente no existen datos en esta tabla', }} />} </div>
                              </>
                            ) : null
                          }
                          {
                            e.type === 'markdown' ? (
                              <ReactMarkdown escapeHtml={false} source={e.defaultValue} />
                            ) : ('')
                          }
                          {
                            e.type === 'select' ? (
                              <span>{e.option ? e.option.value : 'none'}</span>
                            ) : ('')
                          }
                          {
                            e.type === 'textarea' ? (
                              <p className="textarea_form_value">{e.defaultValue}</p>
                            ) : ('')
                          }
                          {
                            e.type === 'hidden' ? (
                              <div></div>
                            ) : null
                          }
                          {
                            e.type === 'geolocation' ? (
                              <div className="flow_form__field" style={{ height: '300px' }}>
                                {e.defaultValue === '' || e.defaultValue === 'none' || e.defaultValue.indexOf(',') === -1 ? (
                                  'No fue posible obtener la ubicación'
                                ) : (
                                  <div className="geolocation_container" onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${e.defaultValue}`, "_blank")}>
                                    <MapContainer center={[e.defaultValue.split(',')[0], e.defaultValue.split(',')[1]]} zoom={15} scrollWheelZoom={false}>
                                      <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                      />
                                      <Marker position={[e.defaultValue.split(',')[0], e.defaultValue.split(',')[1]]}>
                                      </Marker>
                                    </MapContainer>

                                  </div>
                                )}
                              </div>
                            ) : null
                          }
                          {
                            e.type !== 'markdown' &&
                              e.type !== 'select' &&
                              e.type !== 'table' &&
                              e.type !== 'textarea' &&
                              e.type !== 'geolocation' &&
                              e.type !== 'hidden' ? (
                              <>
                                <span>{e.defaultValue}</span>
                                <span>{e.fileUrl && e.fileUrl.length > 0 ? <a onClick={() => this.downloadFile(e.fileUrl)}> Ver archivo </a> : ''} </span>
                              </>
                            ) : ('')
                          }
                        </div>
                      </div>
                    )
                  } else {
                    return (<div />)
                  }
                })
              }
              {
                this.state.overview.length === 0 ? (
                  <strong>El usuario aún no ha completado ningún formulario </strong>
                ) : (null)
              }
              {
                this.state.overview.filter(e => e.type === 'hidden').length > 0 ? (
                  <Collapse>
                    <Panel header="Campos Ocultos" key="1">
                      {
                        this.state.overview.filter(e => e.type === 'hidden').map((elem, index) => {
                          return (
                            <div key={`form-${elem._id}`} className="overview_case__form_data">
                              <div className="overview_case__form_label">
                                {elem.name}
                              </div>
                              <div className="overview_case__form_value">
                                {elem.defaultValue}
                              </div>
                            </div>
                          )
                        })
                      }
                    </Panel>
                  </Collapse>
                ) : null
              }

            </div>
            {/* <div key={`btn-return`} className="">
              <Link className="back_down_button" to={this.props.match.params.draft && this.props.match.params.draft === 'draft' ? `/workflow/${this.state.processSlug}/draft` : '/' }><FontAwesomeIcon icon="arrow-left" />Volver</Link>
            </div> */}
          </div>
        )
      default:
        break;
    }

  }

  thirdTab () {
    const headStyle = {
      fontSize: '13px',
      color: '#333333',
      background: 'white',
      fontWeight: 'bold',
      borderBottom: '1px solid rgba(0,0,0,.2)'
    }
    return (
      <div className="case_center form_case__inside">
        <div className="form_case__wrapper form_case__wrapper--case form_case__wrapper--history">
          <div className="case_history__table_wrapper">
            {
              this.state.formsList.length > 0 ? (
                <Table
                  data={this.state.formsList}
                  columns={[{
                    key: 'fecha',
                    title: 'Fecha',
                    headStyle,
                    render: (r) => {
                      return (
                        <div className="case_list__datatable">
                          {r.date ? momentTZ(r.date).tz(this.props.timeZone).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
                        </div>
                      )
                    }
                  },
                  {
                    key: 'Usuario',
                    title: 'Usuario',
                    headStyle,
                    render: (r) => {
                      if (r.userInfo) {
                        return (
                          <Popover placement="right" content={<UserInfo userInfo={r.userInfo} />} title="Información de usuario" trigger="hover" >
                            <div className="case_list__datatable" style={{ cursor: 'pointer' }}>
                              {r.name}
                            </div>
                          </Popover>
                        )
                      } else {
                        return (
                          <div className="case_list__datatable">
                            {r.name}
                          </div>
                        )

                      }
                    }
                  },
                  {
                    key: 'Actividad',
                    title: 'Actividad',
                    headStyle,
                    render: (r) => {
                      return (
                        <div className="case_list__datatable">
                          {r.formTitle}
                        </div>
                      )
                    }
                  },
                  {
                    key: 'Accion',
                    title: 'Acción Ejecutada',
                    headStyle,
                    render: (r) => {
                      return (
                        <div className="case_list__datatable">
                          {r.actionExecuted}
                        </div>
                      )
                    }
                  }
                  ]}
                />
              ) : (
                <div className="h_error">
                  Debe completar al menos un formulario para poder ver el historial.
                </div>
              )
            }

          </div>
        </div>
        {/* <div key={`btn-return`} className="">
          <Link className="back_down_button" to={this.props.match.params.draft && this.props.match.params.draft === 'draft' ? `/workflow/${this.state.processSlug}/draft` : '/' }><FontAwesomeIcon icon="arrow-left" />Volver</Link>
        </div> */}
      </div>
    )
  }

  secondTab () {
    return (
      <div className="case_center form_case__inside">
        <div className="form_case__wrapper form_case__wrapper--case form_case__wrapper--history">
          <header className="form_case__header">
            {/* <div className="form_case__back_arrow" onClick={() => this.props.history.push('/')}>
              <FontAwesomeIcon icon="arrow-left" />
            </div> */}
            {/* <div className="form_case__title">
              <h1>
                NOMBRE MODULO #{this.props.currentCaseNumber}
              </h1>
              <h1>
                Historial de Mensajes
              </h1>
            </div> */}

            <div className="messagebox__wrapper">
              <section className="messagebox">
                {
                  this.state.messageList.map((elem, ind) => {
                    return (
                      <div id={elem._id} key={`message-${ind}`} className={`messagebox__element ${this.props.userId === elem.userId ? 'messagebox__element--counterpart' : ''}
                    ${this.state.msgId === elem._id ? 'messagebox__animated_message' : ''}`}>
                        {ind === this.state.messageList.length - 1 ? (<div ref={this.messagesEndRef} />) : null}
                        <header className="messagebox__element_header">
                          <div className="messagebox__element_username">
                            {elem.firstName}
                          </div>
                          <div className="messagebox__element_date">
                            {momentTZ(elem.messageDate).tz(this.props.timeZone).format('YYYY-MM-DD HH:mm')}
                          </div>
                        </header>
                        <div className="messagebox__element_content">
                          <p> {elem.message} </p>
                        </div>
                        <footer className="messagebox__element_footer">
                          {elem.fileUrl && elem.fileUrl.length > 0 ? (
                            <div className="messagebox__element_attachmentlist">
                              <div className="messagebox__element_attachment_wrapper">
                                <div className="messagebox__element_attachment_title">
                                  Adjunto
                                </div>
                                <div className="messagebox__element_attachment">
                                  <a onClick={() => this.downloadFile(elem.fileUrl)}>{elem.filename} <FontAwesomeIcon icon="eye" /> </a>
                                </div>
                              </div>
                            </div>
                          ) : ''}
                        </footer>
                      </div>
                    )
                  })
                }

                <div style={{ height: '30px', alignSelf: 'center', color: '#999999' }}>
                  {this.state.isTyping && <div className="dots">{this.state.typingUser} está escribiendo<span>.</span><span>.</span><span>.</span></div>}

                </div>

              </section>
            </div>
            {
              this.state.caseStatus !== 'overview-closed' ? (
                <div className="messaging__wrapper">
                  <section className="messaging">
                    <div className="messaging__textarea_wrapper">
                      <div className="messaging__textarea_title">
                        Nuevo Mensaje
                      </div>
                      <textarea className="messaging__textarea" placeholder="Escriba su mensaje" onChange={e => this.onChangeTextarea(e)} value={this.state.textareaMessage}>
                      </textarea>
                      <div className="messaging__char_counter">
                        {this.state.charCounter}
                      </div>
                    </div>
                    <div className="messaging__attachment_wrapper">
                      <div className="messaging__attachment_title">
                        <p>Adjuntar Documento</p>
                      </div>
                      {this.state.messagingUrlAttachment.length > 0 ? (
                        <div className="messaging__attachment_input_wrapper">
                          <div className="messaging__attachment_button">
                            <p>{this.state.messagingAttachmentFilenameTitle}</p>
                            <div className="messaging__attachment_actions">
                              <span><a className="messaging__attachment_link" href={this.state.messagingUrlAttachment} without rel="noopener noreferrer" target="_blank"><DownloadOutlined /></a></span> <span onClick={e => this.cancelMessageAttachment()}><DeleteOutlined /></span>
                            </div>
                          </div>
                        </div>
                      ) : this.state.messagingButtonDisabled ? (
                        <div className="messaging__attachment_input_wrapper">
                          <div className="messaging__attachment_button">
                            <p>{this.state.messagingAttachmentFilenameTitle}</p>
                          </div>
                          <Progress strokeWidth={3} percent={this.state.messagingAttachmentProgress} showInfo={false} />
                        </div>
                      ) : (
                        <div className="messaging__attachment_dragg" style={{ width: '100%' }}>
                          <Dragger ref={this.messageRefAttachment} multiple={false} showUploadList={false} customRequest={async ({
                            action,
                            data,
                            file,
                            filename,
                            headers,
                            onError,
                            onProgress,
                            onSuccess,
                            withCredentials,
                          }) => {
                            const isLt4M = file.size / 1024 / 1024 < 4;
                            if (!isLt4M) {
                              toast.error('El archivo debe ser menor a 4MB');
                              onError()
                              return
                            }
                            // EXAMPLE: post form-data with 'axios'
                            // eslint-disable-next-line no-undef
                            await this.attachmentMessageUpload(file)
                            onSuccess(null, file);
                          }}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Clickea aquí o arrastra un archivo para subirlo (Max. 4MB)</p>
                            {/* <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                band files
                              </p> */}
                          </Dragger>
                        </div>
                        // <div className="messaging__attachment_input_wrapper">
                        //   <input type="file" style={{display: 'none'}} name="message_attachment" ref={this.messageRefAttachment} onChange={e => this.attachmentMessageUpload(e.target.files[0])} />
                        //   <button className="messaging__attachment_button" onClick={() => this.openAttachment()}>
                        //     <p>Examinar</p>
                        //     <div className="messaging__attachment_input_icon">
                        //       <FontAwesomeIcon icon="paperclip" />
                        //     </div>
                        //   </button>
                        //   <div className="messaging__attachment_status">
                        //     {this.state.messagingButtonDisabled ?  (
                        //       <div> <img style={{height: '45px'}} src="/img/loading-attachment.gif"/>{this.state.messagingAttachmentFilename}</div>
                        //       ) : ''}
                        //   </div>
                        // </div>
                      )}

                    </div>
                    <div className="messaging__button_wrapper">
                      <button onClick={() => this.sendMessage()} disabled={this.state.messagingButtonDisabled} className="messaging__button">Enviar Mensaje</button>

                    </div>
                    {this.state.sendMessageStatus.length > 0 ? (
                      <div className="messaging__undermessage">
                        {this.state.sendMessageStatus}
                      </div>
                    ) : ''}
                  </section>
                </div>
              ) : (
                <div>
                  <div className="messaging__closed">
                    El caso está cerrado. No es posible seguir interactuando.
                  </div>
                </div>

              )
            }

          </header>
        </div>
        {/* <div key={`btn-return`} className="">
          <Link className="back_down_button" to={this.props.match.params.draft && this.props.match.params.draft === 'draft' ? `/workflow/${this.state.processSlug}/draft` : '/' }><FontAwesomeIcon icon="arrow-left" />Volver</Link>
        </div> */}
      </div>
    )
  }

  loadingPane () {
    return (
      <div className="loading-pane">
        <LoadingOutlined />
      </div>
    )
  }

  getApplicantCompanyContacts = async (companyId) => {
    try {
      this.setState({ applicantCompanyContactsLoading: true })
      const res = await axiosBW.get(`/users/applicant-company-contacts/${companyId}`)
      if (res && res.data) {
        this.setState({ applicantCompanyContactsLoading: false, applicantCompanyContacts: res.data.payload })
      }
    } catch (error) {
      console.log(error)
    }
  }

  getCompanyInfo = async (companyId) => {
    try {
      this.setState({ applicantCompanyInfoLoading: true })
      const res = await axiosBW.get(`/companies/id/${companyId}`)
      if (res && res.data) {
        this.setState({ applicantCompanyInfoLoading: false, applicantCompanyInfo: res.data.payload })
      }
    } catch (error) {
      console.log(error)
    }
  }

  getapplicantCompanyCases = async (companyId, applicantCompanyId) => {
    try {
      this.setState({ applicantCasesInfoLoading: true })
      const res = await axiosBW.get(`/case/applicant-company-cases/${companyId}/${applicantCompanyId}`)
      if (res && res.data) {
        this.setState({ applicantCasesInfoLoading: false, applicantCasesInfo: res.data.payload })
      }
    } catch (error) {
      console.log(error)
    }
  }

  aditionalPaneHeader = () => {
    if (this.state.applicantCompanyInfo) {
      return (
        <div className="aditional-pane-header">
          <div className="aditional-pane-header-inline">
            <div className="aditional-pane-header-field">
              <span>RUT/RUC:</span>
              <Input
                type="text"
                name="rut"
                value={this.state.applicantCompanyInfo.taxId}
                // onChange={(e) => setNewSectionName(e.target.value)}
                disabled={true}
              />
            </div>
            <div className="aditional-pane-header-img">
              <img src={this.state.applicantCompanyInfo.imagePath} alt='Logo' />
            </div>

          </div>
          <div className="aditional-pane-header-inline">
            <div className="aditional-pane-header-field">
              <span>Razón Social:</span>
              <Input
                type="text"
                name="rut"
                value={this.state.applicantCompanyInfo.legalName}
                // onChange={(e) => setNewSectionName(e.target.value)}
                disabled={true}
              />
            </div>
            <div className="aditional-pane-header-field">
              <span>Nombre/Marca:</span>
              <Input
                type="text"
                name="rut"
                value={this.state.applicantCompanyInfo.fantasyName}
                // onChange={(e) => setNewSectionName(e.target.value)}
                disabled={true}
              />
            </div>
          </div>
          <div className="aditional-pane-header-field full-pane-header-field">
            <span>Dirección:</span>
            <Input
              type="text"
              name="rut"
              value={this.state.applicantCompanyInfo.addressLine}
              // onChange={(e) => setNewSectionName(e.target.value)}
              disabled={true}
            />
          </div>
          <div className="aditional-pane-header-field">
            <span>País:</span>
            <Input
              type="text"
              name="rut"
              value={this.state.applicantCompanyInfo.country}
              // onChange={(e) => setNewSectionName(e.target.value)}
              disabled={true}
            />
          </div>
        </div>
      )

    }
  }

  applicantCompanyCasesPane = () => {
    const processes = [{ slug: 'all-cases', title: 'Todos' }]
    this.state.applicantCasesInfo.forEach(c => {
      if (!processes.find(p => p.slug === c.processSlug)) {
        processes.push({ slug: c.processSlug, title: c.processName })
      }
    })
    let selected = 'all-cases'
    const changeSelect = (value) => {
      selected = value
      if (value === 'all-cases') {
        this.setState({ applicantCasesInfoFilter: this.state.applicantCasesInfo })
      } else {
        this.setState({ applicantCasesInfoFilter: this.state.applicantCasesInfo.filter(c => c.processSlug === selected) })
      }
    }
    if (!this.state.applicantCasesInfoFilter) {
      changeSelect(selected)
    }
    const columns = [
      {
        title: '#',
        dataIndex: 'caseNumber',
        key: 'caseNumber',
        width: '10%',

        render: (elem, obj) => <a href={`/#/servicios/caso/${obj.processId}/${obj.caseId}`} target="_blank" rel="noopener noreferrer">{elem}</a>,
      },
      {
        title: 'Proc.',
        dataIndex: 'processName',
        key: 'processName'
      },
      {
        title: 'Fecha',
        dataIndex: 'createDate',
        key: 'createDate',
        render: elem => momentTZ(elem).tz(this.props.timeZone).fromNow(),
      },
      {
        title: 'Datos adicionales',
        dataIndex: 'customField',
        key: 'customField',
        ellipsis: true
      },
      {
        title: 'Estado',
        dataIndex: 'status',
        key: 'status',
        render: elem => { return elem === 'open' ? 'Abierto' : elem === 'closed AOT' ? 'Cerrado AOT' : 'Cerrado' }
      }
    ]
    return (
      <div className="applicant-cases">
        {this.aditionalPaneHeader()}
        <div className="applicant-cases-title">
          <span className="label">Casos</span>
          <Select
            onChange={value => changeSelect(value)}
            // value={obj.defaultValue}
            defaultValue={selected}
          >
            {
              processes ? (
                processes.map((elem, index) => {
                  return (
                    <Select.Option key={`optSel-${index}`} value={elem.slug}>
                      {elem.title}
                    </Select.Option>
                  )
                })
              ) : ''
            }
          </Select>
        </div>
        <div className="applicant-cases-table">
          <AntdTable pagination={{ size: 'small', showSizeChanger: false }} columns={columns || []} dataSource={this.state.applicantCasesInfoFilter} locale={{ emptyText: 'Actualmente no existen datos en esta tabla', }} />
        </div>
      </div>
    )
  }

  contactUser = (user, i) => {
    return (
      <div className="applicant-contacts-user-card" key={i}>
        <div className="applicant-contacts-user-card-avatar">
          {user.profileImage ? (
            <Avatar size={50} src={user.profileImage} />
          ) : (
            <Avatar size={50}>{`${user.firstName.charAt(0).toUpperCase()}${user.lastName.charAt(0).toUpperCase()}`}</Avatar>
          )}
        </div>
        <div className="applicant-contacts-user-card-data">
          <div className="applicant-contacts-user-card-full-name">
            {`${user.firstName} ${user.lastName}`}
          </div>
          <div className="applicant-contacts-user-card-email">
            {user.email}
          </div>
          <div>
            <span className="applicant-contacts-user-card-job-title">{user.jobTitle || 'Empleado'} </span>

          </div>
        </div>
      </div>
    )
  }

  applicantCompanyContactsPane = () => {
    return (
      <div className="applicant-contacts">
        {this.aditionalPaneHeader()}
        <div className="applicant-contacts-title">
          <span className="label">Contactos</span>
        </div>
        <div className="applicant-contacts-user-container">
          {this.state.applicantCompanyContacts.map((user, i) => (
            this.contactUser(user, i)
          ))}
        </div>
      </div>
    )
  }

  tabsServices () {
    const defaultPanes = [
      {
        title: this.state.caseStatus.indexOf('overview') === -1 ? `${this.state.currentForm.roleAssignment ? this.state.currentForm.roleAssignment : 'Usuario'}: ${this.state.currentForm.title ? this.state.currentForm.title : 'Inicio'}` : 'Resumen',
        content: this.firstTab(),
        collapsed: false,
        disabled: false
      },
      {
        // title: (<Badge  count={this.state.newMessages}>Mensajes</Badge>),
        title: 'Mensajes ',
        content: this.state.messagingLoading ? this.loadingPane() : this.state.showMessages ? this.secondTab() : <div className="case_center h_error">Para comenzar una conversación, debe iniciar el caso</div>,
        collapsed: false,
        disabled: !this.state.showMessages,
        messaging: true,
        markAsRead: this.markAsRead,
        newMessages: this.state.newMessages
      },
      {
        title: 'Historial',
        // content: this.state.showMessages ? this.thirdTab() : <div className="h_error"> Para ver el historial, debe iniciar el caso</div>
        content: this.state.historicLoading ? this.loadingPane() : this.thirdTab(),
        collapsed: true,
        disabled: !this.state.showMessages
      }
    ]
    if (this.state.casePanelAccess) {
      defaultPanes.push(
        {
          title: 'Organización',
          content: this.state.applicantCasesInfoLoading || this.state.applicantCompanyInfoLoading ? this.loadingPane() : this.applicantCompanyCasesPane(),
          // content: <div>{this.aditionalPaneHeader()}</div>,
          collapsed: true,
          disabled: !this.state.showMessages
        }
      )
      defaultPanes.push(
        {
          title: 'Contactos',
          content: this.state.applicantCompanyContactsLoading || this.state.applicantCompanyInfoLoading ? this.loadingPane() : this.applicantCompanyContactsPane(),
          // content: <div>{this.aditionalPaneHeader()}</div>,
          collapsed: true,
          disabled: !this.state.showMessages
        }
      )
    }
    return defaultPanes
  }

  scrollToBottom = () => {
    this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  render () {
    const tabs = this.tabsServices()
    return (
      <>
        <Prompt
          when={this.state.caseStatus === 'form' && this.state.showPrompt}
          message='¡Atención! Aún no ha enviado el formulario con sus datos. Si abandona el formulario ahora, todos los datos ingresados hasta el momento se perderán y no se enviarán ¿Está seguro de abandonar el formulario?'
        />
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.state.processName ? `${this.state.processName}` : 'Blizwork'} {this.props.match.params.caseId !== 'inicio' && this.props.currentCaseNumber ? `#${this.props.currentCaseNumber}` : '- Inicio'}</title>
          <style>
            {`
            body {
            }
            .tab__title {
              color: ${this.state.formColors.primaryColor}
            }
            .tab__title--selected {
              border-bottom: 4px solid ${this.state.formColors.primaryColor};
            }
            .tab__content {
              background: ${this.state.formColors.fourthColor}
            }
            .begin_case__button {
              background: ${this.state.formColors.primaryColor}
            }
            .desktop_header__search_bar_button {
              background: ${this.state.formColors.primaryColor}
            }
            .desktop_sidebar__element--selected {
              border-right: 4px solid ${this.state.formColors.primaryColor}
            }
            .form_case__button {
              background: ${this.state.formColors.primaryColor}
            }
            .desktop_sidebar__link--selected  {
              filter: opacity(.5) drop-shadow(0 0 0 ${this.state.formColors.primaryColor});
            }
            .messaging__button {
              background: ${this.state.formColors.primaryColor}
            }
            .tabs_case .ant-tabs-tab-active {
              background-color: ${this.state.formColors.primaryColor};
            }
            `
            }
          </style>
        </Helmet>
        {/* <div className={`case_layout--${this.props.isMobile ? 'mobile' : 'desktop'}`}> */}
        <div>
          <CaseHeader
            history={this.props.history}
            className="case_header"
            key={`${this.state.timeIndicators} ${this.state.fechita}`}
            processTitle={this.state.processName}
            caseNumber={this.props.currentCaseNumber}
            timers={this.state.timeIndicators}
            wut={this.state.fechita}
            showTimer={this.state.showTimer}
            disconnectSockets={this.disconnectSockets}
            draft={!!(this.props.match.params.draft && this.props.match.params.draft === 'draft')}
            processSlug={this.state.processSlug}
            assignedUser={this.state.caseInfo.assignedTo}
            canUnassign={this.state.canUnassign}
            unassignUser={this.unassignUser}
          />
          <div className="case_cont">
            {!this.state.caseLoading ? tabs.map((pane, i) => (
              <HorizontalCollapse
                {...pane}
                collapsed={i > 0 && i < 5 ? this.state.casePanelCollapse[i] : pane.collapsed}
                title={pane.title}
                key={i}
                content={pane.content}
                disabled={this.props.match.params.draft && this.props.match.params.draft && (i === 1 || i === 2) ? true : pane.disabled}
                index={i}
                formColors={this.state.formColors}
                fullScr={this.state.fullScreen}
              />
            )) : this.loadingPane()}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentCaseNumber: state.process.currentCaseNumber,
    attachmentNumber: state.process.attachmentNumber,
    attachmentList: state.process.attachmentList,
    tableWidgetList: state.process.tableWidgetList,
    isMobile: state.navigation.isMobile,
    userId: state.user.userId,
    fullName: state.user.fullName,
    firstName: state.user.firstName,
    email: state.user.email,
    token: state.user.token,
    timeZone: state.user.timeZone
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentFormField: (fieldName, fieldValue) => dispatch(updateCurrentFormField(fieldName, fieldValue)),
    setCurrentCaseNumber: (caseNumber) => dispatch(setCurrentCaseNumber(caseNumber)),
    plusAttachmentNumber: () => dispatch(plusAttachmentNumber()),
    setAttachmentList: (newAttachment) => dispatch(setAttachmentList(newAttachment)),
    setCaseCurrentTab: (newTab) => dispatch(setCaseCurrentTab(newTab)),
    setGlobalLoader: (newStatus) => dispatch(setGlobalLoader(newStatus)),
    setTableWidgetList: (tableWidgetList) => dispatch(setTableWidgetList(tableWidgetList)),
    addRowToTableWidget: (tableSlug, columns) => dispatch(addRowToTableWidget(tableSlug, columns)),
    changeTableField: (fieldName, fieldValue, rowIndex, tableSlug, tableInfo) => dispatch(changeTableField(fieldName, fieldValue, rowIndex, tableSlug, tableInfo))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCase)