import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setGlobalLoader } from '../../../../../actions/actions'
import axiosBW from '../../../../../utils/Http'
import { toast } from 'react-toastify'
import { Modal, Tooltip, Space } from 'antd'
import { TableOutlined, FileOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const MasterDataBox = ({ table, showModalEditFunc, id, reloadFunc }) => {
  const dispatch = useDispatch()
  const companyId = useSelector(state => state.user.companyId)
  const userPermissions = useSelector(state => state.user.permissions)
  const canDelete = userPermissions.some(p => p === `masterdata-admin|${table.label}` || p === 'admin')

  const deleteTable = async () => {
    try {
      dispatch(setGlobalLoader(true))
      await axiosBW.post(`/master-data/table-delete/${companyId}`,
        {
          table: table.label
        })
      toast.success('La tabla se eliminó correctamente')
      await reloadFunc()
      dispatch(setGlobalLoader(false))
    } catch (error) {
      dispatch(setGlobalLoader(false))
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }

  return (
    <div className="table-box">
      <div className="table-box-content">
        <p className="table-title-text"><b>{table.label}</b></p>
        <div className="table-icon add-workflow-icon">
          <TableOutlined />
        </div>
      </div>
      <div className='table-box-footer'>
        <Space size={54} align={'center'}>
          <Tooltip title="Gestionar los datos de la tabla">
            {/* <Link to={`/masterdata/${table.label}`}> */}
            <EditOutlined className="box_icon_disable" />
            {/* </Link> */}
          </Tooltip>
          <Tooltip title="Listados">
            <Link to={`/masterdata-rpt/${table.label}`}>
              <FileOutlined className='box_icon_available' />
            </Link>
          </Tooltip>
          {
            canDelete ? (
              <Tooltip title="Eliminar tabla">
                <DeleteOutlined className="box_icon_available" onClick={() => {
                  Modal.confirm({
                    title: '¡¿Está seguro?!',
                    width: 500,
                    content: (
                      <>
                        <p>Tenga en cuenta que esta operación <b>no es reversible</b>.</p>
                        <p>&nbsp;</p>
                        <p>La tabla y <b>todos sus datos</b> se eliminarán definitivamente. Esto podría afectar procesos y extensiones.</p>
                      </>
                    ),
                    async onOk () { deleteTable() }
                  })
                }} />
              </Tooltip>
            ) : (<DeleteOutlined className="box_icon_disable" />)
          }
        </Space>
      </div>
    </div>
  )
}

export default MasterDataBox