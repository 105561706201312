/* eslint-disable react/display-name */
import React from 'react'
import { Modal, Switch, Tooltip } from 'antd';
import './AnalyticBox.scss'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link  } from 'react-router-dom'
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";



const AnalyticBox = ({analytic, companyId, processSlug, processTitle, sectionId, analyticId, switchActive, removeAnalytic, analyticIndex, id }) => {
  const sortable = useSortable({ id: analyticId });
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition
    } = sortable;
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      // display: "inline-block",
  
      // backgroundColor: "#ddd",
      // padding: ".5rem",
      // width: "100px",
      // margin: "0 1rem 1rem 0"
    };
  
  return (       
    <div className="analytic-box" 
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className="analytic-box-content">
        <p className="process-title-text"><b>{analytic.title}</b></p>
      </div>
      <div className={`analytic-box-footer${!analytic.active ? '__inactive' : ''}`}>
        <Tooltip title="Activar/Desactivar">
          <Switch checked={analytic.active} onChange={() => {
                    
            Modal.confirm({
              title: 'Confirmar',
              content: `¿Está seguro que desea ${analytic.active ? 'desactivar' : 'activar'} esta analítica? `,
              async onOk() {
                switchActive(companyId, processSlug, sectionId, analyticId, analytic.active ? false : true)
              }
            })
          } } />
        </Tooltip>
        <Tooltip title="Ir a diseño">
          <Link to={{
            pathname: '/analytic-designer', 
            state: { 
              companyId,
              processSlug,
              processTitle,
              sectionId,
              analyticId,
              analytic
            }
          }}>
          {/* <Link to={`/workflow/${process.processSlug}`}> */}
            <EditOutlined style={{fontSize: '22px', color: '#FFFFFF', paddingLeft: '11px'}} />
          </Link>
        </Tooltip>
        <Tooltip title="Eliminar">
          <DeleteOutlined style={{fontSize: '22px', color: '#FFFFFF', paddingLeft: '11px', cursor: 'pointer'}} onClick={() => {
                    
            Modal.confirm({
              title: 'Confirmar',
              content: `¿Está seguro que desea eliminar esta analítica? `,
              async onOk() {
                removeAnalytic(companyId, processSlug, sectionId, analyticId)
              }
            })
          } } />
        </Tooltip>
      </div>
    </div>
  )
}


export default AnalyticBox