import React from 'react'
import './FormTimer.scss'

const FormTimer = (props) => {
  return (
    <div className="form_timer__wrapper">
      <div className="form_timer">
        {props.timers.map((timer) => {
          if((timer.id === 2 || timer.id === 3) && !props.showTimer) {
            return null
          } else {
            return (
              <div key={timer.id} className="form_timer__element form_timer__element--begin" style={timer.conditionalStyle ? timer.conditionalStyle : null}>
                <p className="form_timer__element_sub">{timer.title}</p>
                <p className="form_timer__element_time"> {timer.value} {(timer.id === 2) && timer.value !== 'VENCIDO' ? 'hrs' : null} </p>
              </div>
            )
          }
          
        })}
      </div> 
    </div>
  )
}

export default FormTimer