
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Modal, Row, Col, Input, Button, Form, Tabs, Table, Select } from 'antd';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import './MyTeam.scss'
import { getMyTeamInfo } from '../../../actions/MyTeamActions'
import { setGlobalLoader } from '../../../actions/actions'
import UsersList from './components/UsersList/UsersList'
import RequestsList from './components/RequestsList/RequestsList'
// import axios from 'axios';
// import cf from '../../../config/APIConfig'
import axiosBW from '../../../utils/Http';
import { toast } from 'react-toastify';
// import { socket } from '../../../../../utils/Socket'
const { TabPane } = Tabs;
const { Option } = Select;


const MyTeam = (props) => {
  const dispatch = useDispatch();
  const companyId = useSelector(state => state.user.companyId)
  const [visible, setVisible] = useState(false)
  const [teamUserModalVisible, setTeamUserModalVisible] = useState(false)
  const [teamUserLoading, setTeamUserLoading] = useState(true)
  const [teamUserInfo, setTeamUserInfo] = useState(null)
  const [teamUserProfiles, setTeamUserProfiles] = useState(null)
  const [teamUserProfileList, setTeamUserProfileList] = useState(null)
  const [teamUserProcessList, setTeamUserProcessList] = useState(null)
  const [teamUserRoleList, setTeamUserRoleList] = useState(null)
  const [profileSelected, setProfileSelected] = useState('')
  const [processSelected, setProcessSelected] = useState('')
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    console.log('Received values of form: ', values);
    cancelModal()
    dispatch(setGlobalLoader(true))
    try {
      
      const resp = await axiosBW.post('/auth/invite-user/',
        {
          companyId: companyId,
          email: values.email
        }
      );
      dispatch(getMyTeamInfo())
      dispatch(setGlobalLoader(false))
      form.resetFields()
      console.log(resp.data);
      Modal.info({
        className: "invitation_modal_ok",
        icon: null,
        width: 586,
        footer: null,
        okText: 'Cerrar',
        title: (<div style={{alignContent: 'center', textAlign: 'center', fontSize: '30px'}}>Invitación enviada</div>),
        content: (
          <div style={{alignContent: 'center', textAlign: 'center', fontSize: '17px'}}>
            <div>
              La invitación al usuario fue enviada con éxito.
            </div>
            <div>
              El usuario deberá confirmar su email y completar sus datos.
            </div>
          </div>
        )
      })
    } catch (err) {
      form.resetFields()
      dispatch(setGlobalLoader(false))
      let errMsg = 'Hubo un error al guardar al enviar la invitación'
      if (err.response && err.response.data && err.response.data.message) {
        const respMsg = err.response.data.message
        if (respMsg === 'User already registered') {
          errMsg= 'El usuario ya existe en el sistema'
        }
      }
      Modal.error({
        title: 'Error',
        content: errMsg
      })
    }
  };
  dispatch(setGlobalLoader(false))
  const showModal = () => {
    setVisible(true)
  };
  const showTeamUserInfo = (userId) => {
    setTeamUserModalVisible(true)
    getTeamUserInfo(userId)
  };
  const cancelModal = () => {
    setVisible(false)
    setTeamUserModalVisible(false)
    setProcessSelected('')
    setProfileSelected('')
    setTeamUserInfo(null)
  }

  // Reemplazo de componentDidMount
  useEffect(() => {
    console.log('va el UseEffect');
    dispatch(getMyTeamInfo())
  }, []);

  // const dispatch = useDispatch();
  // //useSelector reemplaza el mapStateToProps
  // const users = useSelector(state => state.workflowDesigner.usersByRole);
  // const companyId = useSelector(state => state.workflowDesigner.workflow.companyId);
  
  // if (!users) {
  //   return 'No USERS'
  // }
  const ProfileList = () => {
    const data = []
    const columns = [
      {
        title: 'Perfil',
        dataIndex: 'profile',
        key: 'profile',
        render: text => <b>{text}</b>,
      },
      {
        title: 'Proceso',
        dataIndex: 'process',
        key: 'process',
      },
      {
        title: 'Eliminar',
        // key: 'active',
        width:'2%',
        render: elem  => {
          console.log(elem)
          return(
            <DeleteOutlined  style={{cursor: 'pointer'}} onClick={() =>{deleteUserProfile(teamUserInfo._id, elem.profileRaw)}}/>
          ) 
        }
      },
    ];
    const locale = {
      emptyText: (
        <span>
          <p>
            No hay perfiles asociados
          </p>
        </span>
      )
    };
    return (
      <div className="table-wrapper" >
        <Table pagination={{ pageSize: 4 }} locale={locale} columns={columns} dataSource={teamUserProfiles} />
      </div>
    )
  }
  const RoleList = () => {
    const data = []
    const columns = [
      {
        title: 'Proceso',
        dataIndex: 'process',
        key: 'process',
        render: text => <b>{text}</b>,
      },
      {
        title: 'Rol',
        dataIndex: 'role',
        key: 'role',
      }
    ];
    const locale = {
      emptyText: (
        <span>
          <p>
            No hay roles asociados
          </p>
        </span>
      )
    };
    return (
      <div className="table-wrapper" >
        <Table pagination={{ pageSize: 5 }} locale={locale} columns={columns} dataSource={teamUserRoleList} />
      </div>
    )
  }
  const getTeamUserInfo = async(userId) => {
    try {
      setTeamUserLoading(true)
      const resp = await axiosBW.get(`/users/team-user-info/${userId}`);
        console.log(resp)
        setTeamUserInfo(resp.data.payload.user)
        setTeamUserProfiles(resp.data.payload.userProfiles)
        setTeamUserProfileList(resp.data.payload.profiles)
        setTeamUserProcessList(resp.data.payload.processList)
        setTeamUserRoleList(resp.data.payload.userRoles)
        setTeamUserLoading(false)
        // dispatch(setGlobalLoader(false))
    } catch (err) {
      setTeamUserLoading(false)
      setTeamUserModalVisible(false)
      console.log(err) 
      if (err.response && err.response.data && err.response.data.message) {
        const respMsg = err.response.data.message
        Modal.error({
          title: 'Error',
          content: respMsg
        })
      }
      // setTimeout(() => {
      //   props.history.push('/')
      // }, 2000);
    }
  }
  const addUserProfile = async(userId) => {
    try {
      // setTeamUserLoading(true)
      console.log(teamUserProfiles)
      if (profileSelected === '') {
        toast.error('Seleccione un perfil por favor.')
        return
      }
      if (profileSelected !== 'company-manager' && processSelected === '') {
        toast.error('Seleccione un proceso por favor.')
        return
      }
      const profile = processSelected ? `${profileSelected}|${processSelected}`: profileSelected
      if (teamUserProfiles.find(p => p.profileRaw === profile)) {
        toast.error('El usuario ya posee este perfil.')
        return
      }
      const resp = await axiosBW.post(`/users/add-user-profile/`,
      {
        profileUserId: teamUserInfo._id, profile
      });
        console.log(resp)
        setTeamUserProfiles(resp.data.payload)
        setProcessSelected('')
        setProfileSelected('')
        
        // dispatch(setGlobalLoader(false))
    } catch (err) {
      // setTeamUserLoading(false)
      // setTeamUserModalVisible(false)
      console.log(err) 
      if (err.response && err.response.data && err.response.data.message) {
        const respMsg = err.response.data.message
        toast.error(respMsg)
      }
    }
  }
  const deleteUserProfile = async(profileUserId, profile) => {
    try {
      // setTeamUserLoading(true)
      console.log(profile)
      const resp = await axiosBW.post(`/users/delete-user-profile/`,
      {
        profileUserId, profile
      });
        console.log(resp)
        setTeamUserProfiles(resp.data.payload)
        setProcessSelected('')
        setProfileSelected('')
        
        // dispatch(setGlobalLoader(false))
    } catch (err) {
      // setTeamUserLoading(false)
      // setTeamUserModalVisible(false)
      console.log(err) 
      if (err.response && err.response.data && err.response.data.message) {
        const respMsg = err.response.data.message
        toast.error(respMsg)
      }
      // // setTimeout(() => {
      // //   props.history.push('/')
      // // }, 2000);
    }
  }
  const UserInfoModal = () => {
    return(
      <Modal
        className="user_modal"
        destroyOnClose={true}
        width={586}
        height={500}
        closable={true}
        centered={true}
        visible={teamUserModalVisible}
        title={(
          <>
          <div style={{alignContent: 'center', textAlign: 'center', fontSize: '30px', color: '#5C5C5C'}}>
            Usuario
          </div>
          <div style={{alignContent: 'center', textAlign: 'center', fontSize: '25px'}}>
            {teamUserInfo? `${teamUserInfo.email}` : ''}
          </div>
          </>
        )}
        // onOk={()=>{}}
        onCancel={cancelModal}
        // cancelText="Cancelar"
        // okText="Crear"
        footer={null}
      >
        {teamUserLoading || !teamUserInfo? (
          <div className="user_info_loading">
            <LoadingOutlined />
          </div>
        ):
        (
          <>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Información" key="1">
                <div className="user_modal_fields">
                  <label>Nombre</label>
                  <Input  disabled={true}  bordered={false}  value={teamUserInfo.firstName} />
                {/* </div>
                <div className="user_modal_field"> */}
                  <label>Apellido</label>
                  <Input  disabled={true}  bordered={false}  value={teamUserInfo.lastName} />
                {/* </div>
                <div className="user_modal_field"> */}
                  <label>Teléfono</label>
                  <Input  disabled={true}  bordered={false}  value={teamUserInfo.phone} />
                {/* </div>
                <div className="user_modal_field"> */}
                  <label>Cargo</label>
                  <Input  disabled={true}  bordered={false}  value={teamUserInfo.jobTitle} />
                </div>
              </TabPane>
              <TabPane tab="Perfiles" key="2">
                Agregar perfil
                <div className="add_profile">
                  <label>Perfil:</label>
                  <Select value={profileSelected} style={{width: '180px'}}  onChange={(value, option) => {
                    setProcessSelected('')
                    setProfileSelected(value)}}>
                  {teamUserProfileList.map(p => (
                    <Option value={p.profileId}>{p.name}</Option>
                  ))}
                  </Select>
                  <label>Proceso:</label>
                  <Select disabled={profileSelected === 'company-manager'} value={processSelected} style={{width: '180px'}} onChange={(value) => setProcessSelected(value)}>
                  {teamUserProcessList.map(p => (
                    <Option value={p.slug}>{p.title}</Option>
                  ))}
                  </Select>
                  <Button type="primary" onClick={()=> addUserProfile()}
                >
                  +
                </Button>
                  
                </div>
                <ProfileList/>
              </TabPane>
              <TabPane tab="Roles" key="3">
                
                <RoleList/>
              </TabPane>
            </Tabs>
            {/* <div className="user_modal_footer">
              <button  onClick={cancelModal}
              >
                Cancelar
              </button>
              <Button type="primary" htmlType="submit"
              >
                Enviar invitación
              </Button>
            </div>   */}
          </>
        )}
      </Modal>
    )
  }
  return (
    <div className="wrapper">
      
      <Tabs defaultActiveKey="1">
        <TabPane tab="Solicitudes" key="1">
          <Row>
            <Col span={24}>
              <button className="invite_button" onClick={ () => {
                showModal()
              }}
              >
                Invitar Usuario
              </button>
            </Col>
            
          </Row>
          <Row>
            <Col span={24}>
              <div className="table_title"> Solicitudes </div>
            </Col>
            <Col span={24}>
              <RequestsList/>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Mi equipo de trabajo" key="2">
          <Row>
            <Col span={24}>
              <UsersList showTeamUserInfo={showTeamUserInfo}/>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
      
      <div className="invitation_modal" >
        <Modal
          className="invitation_modal"
          destroyOnClose={true}
          width={586}
          height={497}
          closable={false}
          centered={true}
          visible={visible}
          title={(<div style={{alignContent: 'center', textAlign: 'center', fontSize: '30px'}}>Invitar a usuario</div>)}
          // onOk={()=>{}}
          onCancel={cancelModal}
          // cancelText="Cancelar"
          // okText="Crear"
          footer={null}
        >
          <div className="modal-form">
            <div className="invitation_modal_subtitle">
              A continuación escribe el mail del usuario que deseas integrar a tu equipo.
            </div>
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              preserve={false}
              scrollToFirstError
            >
              <Form.Item
                className="invitation_modal_field"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Email inválido',
                  },
                  {
                    required: true,
                    message: 'Email requerido',
                  },
                ]}
              >
                <Input placeholder="Ingrese el Email" />
              </Form.Item>
              <Form.Item
                className="invitation_modal_field"
                name="confirm"
                dependencies={['email']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Por favor confirma el Email',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('email') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject('El Email no coincide');
                    },
                  }),
                ]}
              >
                <Input placeholder="Confirmar el Email" />
              </Form.Item>
              <div className="invitation_modal_footer">
                <button  onClick={cancelModal}
                >
                  Cancelar
                </button>
                <Button type="primary" htmlType="submit"
                >
                  Enviar invitación
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
      {UserInfoModal()}
      {/* <UserInfoModal/> */}
    </div>
  )
}


export default MyTeam