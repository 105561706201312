import {
  GET_WORKFLOW_INFO_BEGIN,
  GET_WORKFLOW_INFO_SUCCESS,
  GET_WORKFLOW_INFO_FAILURE,
  CHANGE_ACTIVE_PROPERTIES,
  GET_USERS_BY_ROLE_BEGIN,
  GET_USERS_BY_ROLE_SUCCESS,
  GET_USERS_BY_ROLE_FAILURE,
  GET_VERSION_LIST_BEGIN,
  GET_VERSION_LIST_SUCCESS,
  GET_VERSION_LIST_FAILURE,
  GET_WORKFLOW_VERSION_BEGIN,
  GET_WORKFLOW_VERSION_SUCCESS,
  GET_WORKFLOW_VERSION_FAILURE,
  CREATE_DRAFT_BEGIN,
  CREATE_DRAFT_SUCCESS,
  CREATE_DRAFT_FAILURE,
  DISCARD_DRAFT_BEGIN,
  DISCARD_DRAFT_SUCCESS,
  DISCARD_DRAFT_FAILURE,
  SET_WORKFLOW,
  CHANGE_WORKFLOW_PROPERTIES,
  CHANGE_DRAFT_MODE,
  SET_USERS_CONNECTED,
  SET_USERS_EDITING,
  CHANGE_WORKFLOW_ACTION,
  CHANGE_WORKFLOW_ROLE,
  ADD_WORKFLOW_ROLE,
  DELETE_WORKFLOW_ROLE,
  MOVE_UP_WORKFLOW_ROLE,
  MOVE_DOWN_WORKFLOW_ROLE,
  SET_DRAGGING_STATUS,
  CHANGE_FORM_POSITION,
  RESET_WF_DATA,
  CREATE_NEW_ACTION_BEGIN,
  CREATE_NEW_ACTION_END,
  CREATE_NEW_ACTION_SOCKET,
  EDITING_ACTION_STATUS,
  EDITING_ACTION_BEGIN,
  EDITING_ACTION_SET_START_POSITION,
  EDITING_ACTION_END,
  EDITING_ACTION_SOCKET,
  DELETE_ACTION,
  GET_USERS_WITHOUT_ROLE_BEGIN,
  GET_USERS_WITHOUT_ROLE_SUCCESS,
  GET_USERS_WITHOUT_ROLE_FAILURE,
  CREATE_FORM,
  DELETE_FORM,
  PUBLISH_DRAFT_BEGIN,
  PUBLISH_DRAFT_SUCCESS,
  PUBLISH_DRAFT_FAILURE,
  SET_NEW_ACTION_SLUG,
  CHANGE_VERSION_TYPE,
  CREATE_NEW_ACTION_CANCEL,
  EDITING_ACTION_CANCEL
} from '../actions/WorkflowDesignerActions';



const initialState = {
  workflow: {},
  form: {},
  loading: false,
  error: null,
  status: null,
  activePropertie: 'workflow',
  auxPropertie: null,
  usersByRole: [],
  usersByRoleLoading: false,
  usersByRoleError: null,
  usersWithoutRole: [],
  usersWithoutRoleLoading: false,
  usersWithoutRoleError: null,
  versionList: [],
  versionListLoading: false,
  versionListError: null,
  draftError: null,
  draftMode: false,
  usersConnected: [],
  usersEditing: [],
  discardDraftError: null,
  publishDraftError: null,
  draggingStatus: false,
  createNewAction: false,
  createNewActionSlug: '',
  newAction: {
    startSlug: '',
    startPosition:''
  },
  editingActionStatus: false,
  editingActionOriginalActions: null,
  editingAction: {
    originalAction: {},
    newAction: {
      startSlug:'',
      hooks: [],
      label: '',
      formSlug: '',
      startLine: '',
      endLine: ''
    }
  },
  versionType: ''
};
const newFormTemplate = {
  _id:'',
  type: 'exec',
  slug: 'form',
  roleAssignment: 'customer',
  title: 'Form',
  description: ' Bienvenido',
  timeout: 0,
  step: 0,
  position: 0,
  actions: [],
  fields: [],
  actionOnTimeout: {
    timeout: 0,
    option: 'nothing'
  }
}


const wf = (state = initialState, action) => {
  switch(action.type) {
    case GET_WORKFLOW_INFO_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case GET_WORKFLOW_INFO_SUCCESS: {

      const wf = action.payload.workflow;

      return {
        ...state,
        loading: false,
        workflow: wf
      };
    }

    case GET_WORKFLOW_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        workflow: []
      };
    case CHANGE_ACTIVE_PROPERTIES:
      return {
        ...state,
        activePropertie: action.payload.activePropertie,
        auxPropertie: action.payload.aux,
      };
    case GET_USERS_BY_ROLE_BEGIN:
      return {
        ...state,
        usersByRoleLoading: true,
        error: null
      };

    case GET_USERS_BY_ROLE_SUCCESS:
      return {
        ...state,
        usersByRoleLoading: false,
        usersByRole: [...action.payload.users]
      };

    case GET_USERS_BY_ROLE_FAILURE:
      return {
        ...state,
        usersByRoleLoading: false,
        usersByRoleError: action.payload.error,
        usersByRole: []
      };
    case GET_VERSION_LIST_BEGIN:
      return {
        ...state,
        versionListLoading: true,
        versionListError: null
      };

    case GET_VERSION_LIST_SUCCESS:
      return {
        ...state,
        versionListLoading: false,
        versionList: [...action.payload.versionList]
      };

    case GET_VERSION_LIST_FAILURE:
      return {
        ...state,
        versionListLoading: false,
        versionListError: action.payload.error,
        versionList: []
      };
    case GET_WORKFLOW_VERSION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case GET_WORKFLOW_VERSION_SUCCESS: {

      const wf = action.payload.workflow;

      return {
        ...state,
        loading: false,
        workflow: wf
      };
    }

    case GET_WORKFLOW_VERSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        workflow: []
      };
    case CREATE_DRAFT_BEGIN:
      return {
        ...state,
        loading: true,
        draftError: null
      };

    case CREATE_DRAFT_SUCCESS: {

      const wf = action.payload.workflow;

      return {
        ...state,
        draftError: null,
        loading: false,
        workflow: wf
      };
    }

    case CREATE_DRAFT_FAILURE:
      return {
        ...state,
        loading: false,
        draftError: action.payload.error,
        // workflow: []
      };
    case SET_WORKFLOW:
      return {
        ...state,
        workflow: action.payload.wf
      };
    case CHANGE_WORKFLOW_PROPERTIES: {
      console.log(action.payload);
      const wf = {...state.workflow}
      for (const key in action.payload) {
        console.log(key);
        console.log(wf[key]);
        console.log(action.payload[key]);
        wf[key] = action.payload[key]
      }
      return {
        ...state,
        workflow: wf
      };
    }
    case CHANGE_DRAFT_MODE:
      return {
        ...state,
        draftMode: action.payload
      }
    case SET_USERS_CONNECTED:
      return {
        ...state,
        usersConnected: action.payload
      }
    case SET_USERS_EDITING:{
      console.log('SET_USERS_EDITING');
      console.log(action.payload);
      return {
        ...state,
        usersEditing: action.payload
      }
    }
    case DISCARD_DRAFT_BEGIN:
      return {
        ...state,
        loading: true,
        discardDraftError: null
      };

    case DISCARD_DRAFT_SUCCESS: {

      return {
        ...state,
        discardDraftError: null,
        loading: false
      };
    }

    case DISCARD_DRAFT_FAILURE:
      return {
        ...state,
        loading: false,
        discardDraftError: action.payload.error,
        // workflow: []
      };
    case CHANGE_WORKFLOW_ACTION: {
      const wf = {...state.workflow}
      const newAction = action.payload
      const formIndex = wf.forms.findIndex(f => f.slug === newAction.startSlug)
      const actionIndex = wf.forms[formIndex].actions.findIndex(a => a.formSlug === newAction.formSlug)
      
      for (const key in wf.forms[formIndex].actions[actionIndex] ) {
        wf.forms[formIndex].actions[actionIndex][key] = newAction[key]
      }
      return {
        ...state,
        workflow: wf,
        auxPropertie: newAction
      };
    }
    case CHANGE_WORKFLOW_ROLE: {
      console.log(action.payload);
      const wf = {...state.workflow}
      const data = action.payload
      const roleIndex = wf.roles.findIndex(r => r === data.oldRole)
      wf.roles[roleIndex] = data.newRole
      for (let i = 0; i < wf.forms.length; i++) {
        const form = wf.forms[i];
        if (form.roleAssignment === data.oldRole) {
          form.roleAssignment = data.newRole
        }
        
      }
      return {
        ...state,
        workflow: wf,
        auxPropertie: data.newRole
      };
    }
    case ADD_WORKFLOW_ROLE: {
      console.log(action.payload);
      const wf = {...state.workflow}
      const role = action.payload
      wf.roles.push(role)
      return {
        ...state,
        workflow: wf
      };
    }
    case DELETE_WORKFLOW_ROLE: {
      console.log(action.payload);
      const wf = {...state.workflow}
      const role = action.payload
      const roleIndex = wf.roles.findIndex(r => r === role)
      if (roleIndex !== -1) {
        wf.roles.splice(roleIndex,1)
      }
      return {
        ...state,
        workflow: wf
      };
    }
    case MOVE_UP_WORKFLOW_ROLE: {
      console.log(action.payload);
      const wf = {...state.workflow}
      const role = action.payload
      const roleIndex = wf.roles.findIndex(r => r === role)
      if (roleIndex !== -1) {
        wf.roles.splice(roleIndex - 1, 0, wf.roles.splice(roleIndex, 1)[0]);
      }
      return {
        ...state,
        workflow: wf
      };
    }
    case MOVE_DOWN_WORKFLOW_ROLE: {
      console.log(action.payload);
      const wf = {...state.workflow}
      const role = action.payload
      const roleIndex = wf.roles.findIndex(r => r === role)
      if (roleIndex !== -1) {
        wf.roles.splice(roleIndex + 1, 0, wf.roles.splice(roleIndex, 1)[0]);
      }
      return {
        ...state,
        workflow: wf
      };
    }
    case SET_DRAGGING_STATUS: {
      return {
        ...state,
        draggingStatus: action.payload
      };
    }
    case CHANGE_FORM_POSITION: {
      console.log(action.payload);
      const wf = {...state.workflow}
      const obj = action.payload
      for (let i = 0; i < wf.forms.length; i++) {
        const form = wf.forms[i];
        if (form.slug === obj.from.formSlug) {
          form.roleAssignment = obj.to.roleName
          form.position = obj.to.position
        }
      }
      return {
        ...state,
        workflow: wf
      };
    }
    case RESET_WF_DATA:
      return initialState
    case CREATE_NEW_ACTION_BEGIN: {
      console.log(action.payload);
      return {
        ...state,
        createNewAction: true,
        newAction: {
          startPosition: action.payload.position,
          startSlug:action.payload.slug
        }
      };
    }
    case SET_NEW_ACTION_SLUG: {
      console.log(action.payload);
      return {
        ...state,
        createNewActionSlug: action.payload
      };
    }
    case CREATE_NEW_ACTION_END: {
      console.log(action.payload);
      const wf = {...state.workflow}
      const startFormIndex = wf.forms.findIndex(f => f.slug === state.newAction.startSlug)
      wf.forms[startFormIndex].actions.push({
        _id: action.payload._id, 
        hooks: [],
        label: action.payload.name,
        formSlug: action.payload.slug,
        startLine: state.newAction.startPosition,
        endLine: action.payload.position
      })
      return {
        ...state,
        workflow: wf,
        createNewAction: false,
        newAction: {
          startPosition: '',
          startSlug: ''
        }
      };
    }
    case CREATE_NEW_ACTION_CANCEL: {
      return {
        ...state,
        createNewAction: false,
        newAction: {
          startPosition: '',
          startSlug: ''
        }
      };
    }
    case CREATE_NEW_ACTION_SOCKET: {
      console.log('CREATE_NEW_ACTION_SOCKET');
      console.log(action.payload);
      const wf = {...state.workflow}
      const startFormIndex = wf.forms.findIndex(f => f.slug === action.payload.startSlug)
      wf.forms[startFormIndex].actions.push({
        _id: action.payload._id,
        hooks: [],
        label: action.payload.label,
        formSlug: action.payload.formSlug,
        startLine: action.payload.startLine,
        endLine: action.payload.endLine
      })
      return {
        ...state,
        workflow: wf
      };
    }
    case EDITING_ACTION_STATUS: {
      console.log(action.payload);
      return {
        ...state,
        editingActionStatus: action.payload
      };
    }
    case EDITING_ACTION_BEGIN: {
      console.log(action.payload);
      const wf = {...state.workflow}
      const startFormIndex = wf.forms.findIndex(f => f.slug === action.payload.startSlug)
      const actionIndex = wf.forms[startFormIndex].actions.findIndex(a => a.formSlug === action.payload.formSlug && a.label === action.payload.label) 
      const editingActionOriginalActions = [...wf.forms[startFormIndex].actions]
      wf.forms[startFormIndex].actions.splice(actionIndex, 1);
      return {
        ...state,
        workflow: wf,
        editingActionStatus: true,
        editingActionOriginalActions,
        editingAction: { 
          originalAction: {...action.payload, startSlug: action.payload.startSlug },
          newAction: {...state.editingAction.newAction, startSlug: action.payload.startSlug}
        }
      };
    }
    case EDITING_ACTION_SET_START_POSITION: {
      console.log(action.payload);
      return {
        ...state,
        editingActionStatus: true,
        editingAction: {originalAction: state.editingAction.originalAction , newAction: { ...state.editingAction.newAction, startLine: action.payload }}
      };
    }
    case EDITING_ACTION_END: {
      console.log(action.payload);
      const wf = {...state.workflow}
      const startFormIndex = wf.forms.findIndex(f => f.slug === state.editingAction.newAction.startSlug)
      wf.forms[startFormIndex].actions.push({...state.editingAction.originalAction,
        formSlug: action.payload.formSlug,
        startLine: state.editingAction.newAction.startLine,
        endLine: action.payload.endLine
      })
      return {
        ...state,
        workflow: wf,
        editingActionStatus: false,
        editingActionOriginalActions: null,
        editingAction: initialState.editingAction
      };
    }
    case EDITING_ACTION_CANCEL: {
      
      
      const wf = {...state.workflow}
      const startFormIndex = wf.forms.findIndex(f => f.slug === state.editingAction.newAction.startSlug)
      wf.forms[startFormIndex].actions = state.editingActionOriginalActions
      return {
        ...state,
        workflow: wf,
        editingActionStatus: false,
        editingActionOriginalActions: null,
        editingAction: initialState.editingAction
      };
    }
    case EDITING_ACTION_SOCKET: {
      console.log(action.payload);
      const wf = {...state.workflow}
      const startFormIndex = wf.forms.findIndex(f => f.slug === action.payload.startSlug)
      const actionIndex = wf.forms[startFormIndex].actions.findIndex(a => a._id.toString() === action.payload._id.toString())
      wf.forms[startFormIndex].actions[actionIndex] = {
        ...wf.forms[startFormIndex].actions[actionIndex],
        label: action.payload.label,
        formSlug: action.payload.formSlug,
        startLine: action.payload.startLine,
        endLine: action.payload.endLine
      }
      return {
        ...state,
        workflow: wf
      };
    }
    case DELETE_ACTION: {
      console.log(action.payload);
      const wf = {...state.workflow}
      const startFormIndex = wf.forms.findIndex(f => f.slug === action.payload.startSlug)
      const actionIndex = wf.forms[startFormIndex].actions.findIndex(a => a._id.toString() === action.payload._id.toString()) 
      wf.forms[startFormIndex].actions.splice(actionIndex, 1);
      return {
        ...state,
        workflow: wf
      };
    }
    case GET_USERS_WITHOUT_ROLE_BEGIN:
      return {
        ...state,
        usersWithoutRoleLoading: true,
        error: null
      };

    case GET_USERS_WITHOUT_ROLE_SUCCESS:
      return {
        ...state,
        usersWithoutRoleLoading: false,
        usersWithoutRole: [...action.payload.users]
      };

    case GET_USERS_WITHOUT_ROLE_FAILURE:
      return {
        ...state,
        usersWithoutRoleLoading: false,
        usersWithoutRoleError: action.payload.error,
        usersWithoutRole: []
      };
    case CREATE_FORM: {
      const wf = {...state.workflow}
      let stop = false
      for (let i = 0; i < wf.roles.length; i++) {
        if (stop) {
          break;
        }
        const role = wf.roles[i];
        const forms = wf.forms.filter(f => f.roleAssignment === role)
        const maxPositionInRole = Math.max(...wf.forms.map(o => o.position || 0), 0);
        if (forms.length === 0) {
          const newForm = {
            ...JSON.parse(JSON.stringify(newFormTemplate)),
            _id:action.payload._id,
            title: action.payload.title,
            roleAssignment: role,
            slug: action.payload.slug,
            position: 0
          }
          wf.forms.push(newForm)
          stop = true
        } else {
          for (let j = 0; j < maxPositionInRole; j++) {
            let found
            if (j === 0) {
              found = forms.find(f => f.position === j || f.position === undefined)
            } else {
              found = forms.find(f => f.position === j)
            }
            if (!found) {
              const newForm = {
                ...JSON.parse(JSON.stringify(newFormTemplate)),
                _id:action.payload._id,
                title: action.payload.title,
                roleAssignment: role,
                slug: action.payload.slug,
                position: j
              }
              wf.forms.push(newForm)
              stop = true
              break;
            }
          }
        }
        

      }
      return {
        ...state,
        workflow: wf
      };
    }
    case DELETE_FORM: {
      console.log(action.payload);
      const wf = {...state.workflow}
      const formIndex = wf.forms.findIndex(f => f._id.toString() === action.payload._id.toString())
      wf.forms.splice(formIndex, 1);
      return {
        ...state,
        workflow: wf
      };
    }
    case PUBLISH_DRAFT_BEGIN:
      return {
        ...state,
        loading: true,
        publishDraftError: null
      };

    case PUBLISH_DRAFT_SUCCESS: {

      return {
        ...state,
        publishDraftError: null,
        loading: false
      };
    }

    case PUBLISH_DRAFT_FAILURE:
      return {
        ...state,
        loading: false,
        publishDraftError: action.payload.error,
        // workflow: []
      };
    case CHANGE_VERSION_TYPE:
      return {
        ...state,
        versionType: action.payload
      }
    default: {
      return {...state}
    }
  }
}

export default wf