import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import './DesktopHeader.scss'
import { changeSearchText } from '../../actions/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useHistory } from 'react-router-dom'
import { CaretDownOutlined } from '@ant-design/icons';
import { Row, Col, Avatar, Menu, Dropdown, Tooltip, AutoComplete } from 'antd';
import axiosBW from '../../utils/Http';

const companyLogo = (logoUrl, companyName) => {
  return (
    logoUrl ? (
      <img src={logoUrl} alt="brand logo" />
    ) : companyName ? (
      <Tooltip title={companyName}>
        <img className="default_img_company" src="/img/empresa-sin-logo.png" />
      </Tooltip>
      // <div>|  {companyName}</div>
    ) : ''
  )

}

const menu = (companyId, permissions) => (
  <Menu>
    <Menu.Item key="3">
      <Link to={`/`}>Mi Escritorio</Link>
    </Menu.Item>
    <Menu.Item key="0">
      <Link to={`/hall/${companyId}`}>Mi Organización</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to="/procesos">Mis Procesos</Link>
    </Menu.Item>
    <Menu.Item key="6">
      <Link to="/hooks">Mis Extensiones</Link>
    </Menu.Item>
    <Menu.Item key="6">
      <Link to="/masterdata">Mis Datos</Link>
    </Menu.Item>
    {
      permissions.filter(p => p === 'teamManagment' || p === 'admin').length > 0 ? (
        <Menu.Item key="1">
          <Link to={`/mi-equipo`}>Mi Equipo</Link>
        </Menu.Item>
      ) :''
    }
    <Menu.Item key="4">
      <Link to="/micuenta">Mi Cuenta</Link>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="5">
      <Link to="/cerrar-sesion">Cerrar sesión</Link>
    </Menu.Item>
  </Menu>
);

const DesktopHeader = (props) => {
  const history = useHistory();
  const [companies, setCompanies] = useState([])
  const [options, setOptions] = useState([])

  useEffect( () => {
    console.log('va el UseEffect');
    async function getRegistrationInfo() {
      try {
        const resp = await axiosBW.get(`/auth/get-company-names`);
          console.log(resp)
          const data = resp.data.payload.map(c =>({ label: c.legalName, value: c._id }))
          setCompanies(data)
          // dispatch(setGlobalLoader(false))
      } catch (err) {
        console.log(err) 
      }
    }
    // dispatch(setGlobalLoader(true))
    getRegistrationInfo()
    
    // dispatch(getMyTeamInfo())
  }, []);
  const onSearch = (searchText) => {
    setOptions(
      !searchText ? [] : companies.filter(c => c.label.toUpperCase().indexOf(searchText.toUpperCase()) !== -1),
    );
  };
  return <>
  <Row className ='desktop_header__wrapper'>
    <Col span={3}></Col>
    <Col span={3} style={{left: '-38px'}}>
      {props.type === 'full' ?
        <Link to="/micuenta" role="button">
          <div className="plan">
            <span>{`Plan ${props.companyPlan}`}</span>
          </div>
        </Link>  
      : null}
    </Col>
    <Col span={1}></Col>
    <Col span={11}>
      {props.type === 'full' ?
        <div className="desktop_header__search_bar">
          <div className="desktop_header__search_bar_wrapper">
            {/* <input type="text" className="desktop_header__search_bar_input" placeholder="Búsqueda" onChange={e => { props.changeSearchText(e.target.value)} } value={props.searchBarText} /> */}
            <AutoComplete
              className="desktop_header__search_bar_input"
              options={options}
              // filterOption={(inputValue, option) =>
              //   option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              // }
              onSelect={(value, option) => {
                history.push(`/hall/${value}`)
              }}
              placeholder="Búsqueda"
              // defaultOpen={false}
              onSearch={onSearch}
            />
            <FontAwesomeIcon className="h_top_icon" icon="search" />
            <button className="desktop_header__search_bar_button"> Buscar </button>
          </div>
        </div>  
      : null}
    </Col>
    <Col span={2}></Col>
    <Col span={1} className="company_logo">
      <div className="desktop_header__greeting_brand">
        {companyLogo(props.logoUrl, props.fantasyName || props.legalName)}
      </div>
    </Col>
    <Col span={2} className="profile">
      <Dropdown overlay={menu(props.companyId, props.permissions)} trigger={['click']} onClick={e => e.preventDefault()}>
        <div className ="">
          {props.profileImage ? (
            <Avatar className="avatar" size={59} src={props.profileImage}>
            </Avatar>
          ): (
            <Avatar className="avatar" size={59}>
              {props.nameInitials}
            </Avatar>
          )}
          
          <CaretDownOutlined />
        </div>
      </Dropdown>
      
    </Col>
  </Row>
  </>;
}

const mapStateToProps = state => ({
  searchBarText: state.navigation.searchBarText,
  logoUrl: state.user.logoUrl,
  fantasyName: state.user.fantasyName,
  legalName: state.user.legalName,
  permissions: state.user.permissions,
  companyPlan: state.user.companyPlan,
  nameInitials: state.user.nameInitials,
  profileImage: state.user.profileImage,
  companyId: state.user.companyId
})

const mapDispatchToProps = dispatch => ({
  changeSearchText: newText => dispatch(changeSearchText(newText))
})

export default connect(mapStateToProps, mapDispatchToProps)(DesktopHeader)