import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import axiosBW from '../../../../utils/Http';
import './HookBox.scss'

const HookBox = ({hookId, onClick}) =>   {
  const [loading, setLoading] = useState(false);
  const [hookInfo, setHookInfo] = useState({});

  useEffect(() => {
    console.log('va el hooks-log');
    (async () => {
      setLoading(true)
      // await getDashboard()
      await getHookInfo()
      setLoading(false)

    })();
    
  }, []);
  const getHookInfo = async() => {
    try {
      const res = await axiosBW.get(`hooks/hooks-info/${hookId}`)
      if(res && res.data) {

        if(res.data.status === 0) {
          setHookInfo(res.data.payload)
        }
      }
    } catch(ex) {
      console.log(ex);
    }
  }
  return (
    <div className="hooks-store-box">
        {!loading ? (
          <div className="hooks-store-box-content" onClick={() => onClick(hookInfo)}>
            <p className="hooks-store-box-title-text"><b>{hookInfo.name}</b></p>
            <div className="hooks-store-box-logo">
              <img src={hookInfo.icon} alt="avatar" />
            </div>
          </div>
        ): 'cargando'}
    </div>
  )
}

export default HookBox