import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import './OverviewCase.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import axios from 'axios';
// import cf from '../../../config/APIConfig'
import axiosBW from '../../../utils/Http';
import moment from 'moment'
// import Tabs from '../../../components/Library/Tabs/Tabs'
import ReactMarkdown from 'react-markdown'
import Table from '../../../components/Library/Table/Table';
import { setGlobalLoader } from '../../../actions/actions'
import { Table as AntdTable, Modal, Collapse, Tabs } from 'antd'
import { Helmet } from 'react-helmet';

const { TabPane } = Tabs;

const { Panel } = Collapse;

class OverviewCase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      overviewData: [],
      msgOverviewData: 'Cargando Resumen',
      charCounter: 0,
      attachedLabel: '',
      messagingAttachmentFilename: '',
      messagingUrlAttachment: '',
      messagingButtonDisabled: '',
      sendMessageStatus: '',
      messageList: [],
      processName: '',
      caseNumber: '',
      overviewForm: [],
      formsList: [],
      formsColumns: [],
      activeTab: '0'
    }
    this.messageRefAttachment = React.createRef()
    this.onChangeTab = this.onChangeTab.bind(this)
  }
  async componentDidMount() {
    
    if(!this.props.token) {
      this.props.history.push('/ingresar')
    }
    const query = new URLSearchParams(this.props.location.search)
    // get step form data
    if(!this.props.overview) {
      let resOverview
      try {
        resOverview = await axiosBW.get(`/case/overview/${this.props.match.params.caseId}`)
      } catch(ex) {
        console.log('error resOverview', ex)
        if(ex.response) {
          if(ex.response.status === 403) {
            Modal.error({
              title: 'Error',
              content: 'Usted no tiene permisos para ver este caso'
            })
            setTimeout(() => {
              this.props.history.push('/')
            }, 2000)
          }
        }
      }
      if(!resOverview || !resOverview.data) {
        return   
      }

      this.setState({
        overviewData: resOverview.data.payload.fieldValues && resOverview.data.payload.fieldValues.length > 0 ? resOverview.data.payload.fieldValues : [],
        processName: resOverview.data.payload.processName,
        caseNumber: resOverview.data.payload.caseNumber,
        overviewForm: resOverview.data.payload
      })
      
    } else {
      this.setState({
        overviewData: this.props.overview ? this.props.overview : []
      })
    }
    // messages-by-case API call
      let resMessagesByCase
      try {
        resMessagesByCase =  await axiosBW.get(`/messaging/messages-by-case/${this.props.match.params.caseId}`)
      } catch (ex) {
        console.log('error resMessagesByCase', ex)
      }
      if(!resMessagesByCase || !resMessagesByCase.data) {
        Modal.error({
          title: 'Error',
          content: `No fue posible obtener los mensajes del caso`,
        })
        return
      }
      this.setState({
        msgId: query.get('msgId'),
        messageList: resMessagesByCase.data.payload
      })
      // getFormsExecuted API call
      let resGetFormsExecuted
      try {
        resGetFormsExecuted = await axiosBW.get(`/case/getFormsExecuted/${this.props.match.params.caseId}`)
      } catch (ex) {
        console.log('error resGetFormsExecuted', ex)
      }
      if(!resGetFormsExecuted || !resGetFormsExecuted.data) {
        Modal.error({
          title: 'Error',
          content: `No fue posible obtener la lista de formularios`,
        })
        return
      }
      this.setState({
        formsList: resGetFormsExecuted.data.payload
      })
      this.props.setGlobalLoader(false)
  }
  async onChangeTab(tabId) {
    this.setState({
      activeTab: tabId
    })
  }

  tabsServices() {
    
    return [
      {
        title: `Resumen del Caso ${this.state.caseNumber}`,
        content: this.firstTab()
      },
      {
        title: 'Mensajería',
        content: this.secondTab()
      },
      {
        title: 'Historial del Caso',
        content: this.thirdTab()
      }
    ]
  }

  firstTab() {
    return (
      <div className="overview_case__wrapper">
        <header className="overview_case__header">
          <Link to="/" className="overview_case__back_arrow">
            <FontAwesomeIcon icon="arrow-left" />
          </Link>
          <div className="overview_case__title">
            <h1>
              {this.state.caseNumber ? `Resumen de Solicidud #${this.state.caseNumber}` : 'Resumen de Solicitud'}
            </h1>
          </div>
        </header>

        <section className="overview_case__bridge">
          <h2 className="overview_case__bridge_title"> Estado de la solicitud </h2>
          <p className="overview_case__bridge_desc">
            Su formulario ha sido procesado exitosamente. A continuación, se procede a mostrar la información resumida del formulario.
          </p>
        </section>

          {
                <div key={`overv`} className="overview_case__form">
                  <div className="overview_case__form_title">
                    Resumen
                  </div>
                  <div className="overview_case__form_content">
                    {
                      this.state.overviewData.map((e, i) => {

                        console.log('miOverviewxxxxxx', e)
                        let tableValue = []
                        if(e.type === 'table') {
                          const tableElem = JSON.parse(e.defaultValue).filter(te => te.slug === e.slug)
                          console.log('tableElem', tableElem)
                          tableValue = tableElem.length > 0 ? tableElem[0] : []
                        }
                        if(!e.hideInOverview) {
                          return (
                            <div key={`form-${e._id}`} className="overview_case__form_data">
                            {e.type !== 'markdown' && e.type !== 'hidden' ? (
                            <div className="overview_case__form_label">
                              {e.name}
                            </div>
                            ) : ''}
                            <div className="overview_case__form_value">
                              {
                                e.type === 'table' ? (
                                  <>
                                    <div> {<AntdTable pagination={false} columns={e.columns ? e.columns : []} dataSource={tableValue.data} locale={{emptyText: 'Actualmente no existen datos en esta tabla',}} /> } </div>
                                  </>
                                ) : null
                              }
                              {
                                e.type === 'markdown' ? (
                                  <ReactMarkdown escapeHtml={false} source={e.defaultValue} />
                                ): ('')
                              }
                              {
                                e.type === 'select' ? (
                                  <span>{e.option ? e.option.value : 'none'}</span>
                                ) : ('')
                              }
                              {
                                e.type === 'hidden' ? (
                                  <div></div>
                                ) : null
                              }
                              {
                                e.type !== 'markdown' && 
                                e.type !== 'select' && 
                                e.type !== 'table' && 
                                e.type !== 'hidden' ? (
                                  <>
                                  <span>{e.defaultValue}</span>  
                                  <span>{e.fileUrl && e.fileUrl.length > 0 ? <a href={e.fileUrl}> Ver archivo </a> : ''} </span>
                                  </>
                                ) : ('')
                              }
                              
                            </div>
                          </div>
                        )
                        } else {
                          return (<div />)
                        }
                        
                      })
                    }
                    {
                      this.state.overviewData.length === 0 ? (
                        <strong>El usuario aún no ha completado ningún formulario </strong>
                      ) : (null)
                    }
                    {
                      this.state.overviewData.filter(e => e.type === 'hidden').length > 0 ? (
                        <Collapse>
                          <Panel header="Campos Ocultos" key="1">
                            {
                              this.state.overviewData.filter(e => e.type === 'hidden').map((elem, index) => {
                                return (
                                  <div key={`form-${elem._id}`} className="overview_case__form_data">
                                    <div className="overview_case__form_label">
                                      {elem.name}
                                    </div>
                                    <div className="overview_case__form_value">
                                      {elem.defaultValue}
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </Panel>
                        </Collapse>
                      ) : null
                    }
                    
                  </div>
                </div>
            }
            
          <Link className="back_to" to="/">Volver al Escritorio</Link>
      </div>

    )
  }
  
  secondTab() {
    return (
      <div className="form_case__inside">
        <div className="form_case__wrapper form_case__wrapper--case form_case__wrapper--history">
          <header className="form_case__header">
            <div className="overview_case__back_arrow" onClick={(e) => this.props.history.push('/')}>
              <FontAwesomeIcon icon="arrow-left" />
            </div>
            <div className="form_case__title">
              <h1>
                {this.state.processName} #{this.state.caseNumber}
              </h1>
              <h1>
                Historial de Mensajes
              </h1>
            </div>

            <div className="messagebox__wrapper">
              <section className="messagebox">
              {
                this.state.messageList.map((elem, ind) => {
                  return (
                    <div key={`message-${ind}`} className={`messagebox__element ${this.props.userId === elem.userId ? 'messagebox__element--counterpart' : '' }`} >
                  <header className="messagebox__element_header">
                    <div className="messagebox__element_username">
                      {elem.firstName}
                    </div>
                    <div className="messagebox__element_date">
                      {moment(elem.messageDate).format('YYYY-MM-DD H:m')}
                    </div>
                  </header>
                  <div className="messagebox__element_content">
                    <p> {elem.message} </p>
                  </div>
                  <footer className="messagebox__element_footer">
                  {elem.fileUrl && elem.fileUrl.length > 0 ? (
                    <div className="messagebox__element_attachmentlist">
                      <div className="messagebox__element_attachment_wrapper">
                        <div className="messagebox__element_attachment_title">
                          Adjunto
                        </div>
                        <div className="messagebox__element_attachment">
                          <a href={elem.fileUrl}>{elem.filename} <FontAwesomeIcon icon="eye" /> </a>
                        </div>
                      </div>
                    </div>
                  ) : ''}
                  </footer>
                </div>
                  )
                })
              }
              </section>
            </div>
            {
              this.state.overviewForm && this.state.overviewForm.status !== 'closed' && this.state.overviewForm.status !== 'closed AOT' ? (
                <div className="messaging__wrapper">
                  <section className="messaging">
                    <div className="messaging__textarea_wrapper">
                      <div className="messaging__textarea_title">
                        Nuevo Mensaje
                      </div>
                      <textarea className="messaging__textarea" placeholder="Escriba su mensaje" onChange={e => this.onChangeTextarea(e)} value={this.state.textareaMessage}>
                      </textarea>
                      <div className="messaging__char_counter">
                        {this.state.charCounter}
                      </div>
                    </div>
                    <div className="messaging__attachment_wrapper">
                      <div className="messaging__attachment_title">
                        <p>Adjuntar Documento</p>
                      </div>
                      <div className="messaging__attachment_input_wrapper">
                        <input type="file" style={{display: 'none'}} name="message_attachment" ref={this.messageRefAttachment} onChange={e => this.attachmentMessageUpload(e.target.files[0])} />
                        <button className="messaging__attachment_button" onClick={e => this.openAttachment()}>
                          <p>{this.state.attachedLabel}</p>
                          <div className="messaging__attachment_input_icon">
                            <FontAwesomeIcon icon="paperclip" />
                          </div>
                        </button>
                        <div className="messaging__attachment_status">
                          {this.state.messagingAttachmentFilename}
                        </div>
                        <div className="messaging__attachment_image">
                          {this.state.messagingUrlAttachment.length > 0 ? (
                            <a className="messaging__attachment_link" href={this.state.messagingUrlAttachment}>Ver Archivo</a>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="messaging__button_wrapper">
                      <button onClick={e => this.sendMessage()} disabled={this.state.messagingButtonDisabled} className="messaging__button">Enviar Mensaje</button>
                      
                    </div>
                    {this.state.sendMessageStatus.length > 0 ? (
                      <div className="messaging__undermessage">
                        {this.state.sendMessageStatus}
                      </div>
                    ) : ''}
                  </section>
                </div>
              ) : (
                <div className="messaging__wrapper">
                <section className="messaging">
                  <h2>El caso está cerrado, no puede seguir interactuando</h2>
                </section>
                </div>
              )
            }
            
          </header>
        </div>
      </div>
    )
  }

  thirdTab() {
    const headStyle =  {
      fontSize: '13px',
      color: '#333333',
      background: 'white',
      fontWeight:'bold',
      borderBottom: '1px solid rgba(0,0,0,.2)'
    }
    return (
      <div className="form_case__inside">
        <div className="form_case__wrapper form_case__wrapper--case form_case__wrapper--history">
          <header className="form_case__header case_history">
            <div className="overview_case__back_arrow" onClick={() => this.props.history.push('/')}>
              <FontAwesomeIcon icon="arrow-left" />
            </div>
            <div className="form_case__title">
            <h1>
                  {this.state.processName} #{this.state.caseNumber}
                </h1>
                <h1>
                  Historial de Formularios
                </h1>
            </div>
          </header>
          <div className="case_history__table_wrapper">
            {
              this.state.formsList.length > 0 ? (
                <Table
                  data={this.state.formsList}
                  columns={ [{
                    key: 'fecha',
                    title: 'Fecha',
                    headStyle,
                    render: (r) => {
                      return (
                        <div className="case_list__datatable">
                          {r.createAudit.timeStamp ? moment(r.createAudit.timeStamp).format('YYYY-MM-DD hh:mm:ss') : 'N/A'}
                        </div>
                      )
                    }
                  },
                  {
                    key: 'Usuario',
                    title: 'Usuario',
                    headStyle,
                    render: (r) => {
                      return (
                        <div className="case_list__datatable">
                          {r.usuario.firstName} {r.usuario.lastName}
                        </div>
                      )
                    }
                  }, 
                  {
                    key: 'Actividad',
                    title: 'Actividad',
                    headStyle,
                    render: (r) => {
                      return (
                        <div className="case_list__datatable">
                          {r.formTitle}
                        </div>
                      )
                    }
                  }
                ]}
                />
              ) : (
                <div className="h_error">
                  Debe completar al menos un formulario para poder ver el historial.
                </div>
              )
            }
            
          </div>
        </div>
      </div>
    )
  }

  onChangeTextarea(e) {
    this.setState({
      textareaMessage: e.target.value
    })
  }

  sendMessage() {
    if(this.state.textareaMessage.length > 0) {
       axiosBW.post('/messaging/send', {
        caseId: this.props.match.params.caseId,
        message:  this.state.textareaMessage,
        filename:  this.state.messagingAttachmentFilenameTitle,
        fileUrl:  this.state.messagingUrlAttachment,
      })
    .then( res => {
      this.setState({
        textareaMessage: '',
        messagingUrlAttachment: '',
        messagingAttachmentFilename: '',
        messagingAttachmentFilenameTitle: '',
      })
       axiosBW.get(`/messaging/messages-by-case/${this.props.match.params.caseId}`)
        .then(response => {
          this.setState({
            messageList: response.data.payload
          })
        })
        .catch(error => {
          console.log('error', error)
        })
    })
    .catch(err => {
      console.log('error al enviar mensaje', err)
    })
    } else {
      this.setState({
        sendMessageStatus: 'Debe ingresar un mensaje'
      })
    }
  }


  openAttachment() {
    this.messageRefAttachment.current.click()
  }

  async attachmentMessageUpload(file) {
    this.setState({
      messagingButtonDisabled: true
    })
    await this.uploadFile(
      file, 
      'messaging'
    )
  }

  async uploadFile(file, type, previousStateToEdit) {
    if(type === 'messaging') {
      this.setState(previousStateToEdit)
      this.setState({
        messagingAttachmentFilename: `Cargando Archivo ${file.name}`,
        messagingAttachmentFilenameTitle: file.name
      })
    } 
    this.setState({
      isBlockedButton: true
    })
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      axiosBW.post('/attachment/upload-file', {
        module: 'form',
        filename: file.name,
        file: reader.result.split('base64')[1]
      })
      .then(res => {
        if(type === 'messaging') {
          this.setState({
            messagingAttachmentFilename: 'Archivo Subido Correctamente',
            messagingButtonDisabled: false,
            messagingUrlAttachment: res.data.payload.downloadUrl
          })
        }
      })
      .catch(err => {
        console.log('errosito', err)
        this.setState({
          messagingAttachmentFilename: 'Error al subir el archivo, por favor intentar nuevamente',
          messagingButtonDisabled: true,
          messagingUrlAttachment: ''
        })
      })
    }
    reader.onerror = function (error) {
      console.log(error)
    }
  }

  render() {
    const tabs = this.tabsServices();
    return (
      <>
        {/* <Tabs key={this.state.currentForm} tabs={this.tabsServices()} isMobile={this.props.isMobile} /> */}
        <Tabs
            onChange={this.onChangeTab}
            activeKey={this.state.activeTab}
            className={`tabs_overview ${this.props.isMobile ? 'tabs-mobile' : ''}`}
            // type="line"
            // pagination={false}
          >
            {tabs.map((pane, i) => (
              <TabPane tab={pane.title} key={i}>
                {pane.content}
              </TabPane>
            ))}
          </Tabs>
        <Helmet>
          <title> Resument del Caso - Blizwork</title>
        </Helmet>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentCaseNumber: state.process.currentCaseNumber,
    token: state.user.token,
    userId: state.user.userId
  }
}
const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState))
})

export default connect(mapStateToProps, mapDispatchToProps)(OverviewCase)