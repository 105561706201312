
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import './ActionProperties.scss'
import { Input, Modal, Checkbox, Select, Tooltip } from 'antd';
import { ExclamationCircleOutlined, PlusCircleOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { editingActionBegin, deleteAction } from '../../../../../actions/WorkflowDesignerActions'
import { socket } from '../../../../../utils/Socket'
import MyHooksModal from '../../../../../components/MyHooksModal/MyHooksModal';
import AddHookBox from '../../../../../components/AddHookBox/AddHookBox';
// import AddHookBox from '../../../../../components/AddHookBox/AddHookBox'
// import MyHooksModal from '../../../../../components/MyHooksModal/MyHooksModal'


const ActionProperties = ({ action, changeWorkflowAction, sendWorkflowAction, createLines, processSlug }) => {
  const dispatch = useDispatch();
  const [myHooksModalVisible, setMyHooksModalVisible] = useState(false);
  const [myHooksModalKey, setMyHooksModalKey] = useState(null);
  const draftMode = useSelector(state => state.workflowDesigner.draftMode)
  const usersEditing = useSelector(state => state.workflowDesigner.usersEditing)
  const usersConnected = useSelector(state => state.workflowDesigner.usersConnected)
  const editingActionStatus = useSelector(state => state.workflowDesigner.editingActionStatus)
  const email = useSelector(state => state.user.email)
  const companyId = useSelector(state => state.user.companyId)
  const token = useSelector(state => state.user.token)
  let editingDisable = true
  let disabledColor
  if (draftMode && usersConnected.lenght !== 0) {
    const userEditing = usersEditing.find(u => u.zone === 'wf-action' && u.aux.from === action.startSlug && u.aux.to === action.formSlug)
    if (userEditing) {
      editingDisable = userEditing.user === email ? false : true
      disabledColor = userEditing.user === email ? null : usersConnected.find(u => u.email === userEditing.user).color
    }
    if (editingActionStatus) {
      editingDisable = true
    }
  }

  // Reemplazo de componentDidMount, el segundo argumento, dice que se actualizará solo cuando cambie "role"
  // useEffect(() => {
  //   console.log('va el UseEffect');
  //   if (role !== 'customer') {
  //     dispatch(getUsersByRole(companyId, role))
  //   }
  // }, [role]);

  // const dispatch = useDispatch();
  // //useSelector reemplaza el mapStateToProps
  // const users = useSelector(state => state.workflowDesigner.usersByRole);
  // const companyId = useSelector(state => state.workflowDesigner.workflow.companyId);
  
  // if (!users) {
  //   return 'No USERS'
  // }
  const openMyHooksModal = () => {
    setMyHooksModalKey(Date.now())
    setMyHooksModalVisible(true)
  }
  const closeMyHooksModal = () => {
    setMyHooksModalVisible(false)
  }
  const addExtension = (hook) => {
    const hs = action.extensions ? [...action.extensions] : []
    const h = { ...hook }
    if (h.bodyParams) {
      for (let i = 0; i < h.bodyParams.length; i++) {
        const param = h.bodyParams[i];
        if (param.type === 'select') {
          const def = param.options.find(o => o.default)
          param.value = def ? def.value : param.value
        }
      }
    }
    hs.push({
      hookId: h._id,
      urlParams: h.urlParams || [],
      bodyParams: h.bodyParams || [],
      firstAdd: true
    })
    changeWorkflowAction({...action, extensions: hs})
    sendWorkflowAction({...action, extensions: hs})
    setMyHooksModalVisible(false)
  }
  const deleteExtension = (hookIndex) => {
      const hs = [...action.extensions]
      hs.splice(hookIndex, 1);
      changeWorkflowAction({...action, extensions: hs})
      sendWorkflowAction({...action, extensions: hs})
  }
  const editExtension = (urlParams, bodyParams, hookIndex) => {
      const hs = [...action.extensions]
      hs[hookIndex].bodyParams = bodyParams
      hs[hookIndex].urlParams = urlParams
      changeWorkflowAction({...action, extensions: hs})
      sendWorkflowAction({...action, extensions: hs})
  }
  return (
    <div className="properties">
      <div className="header-title">
        Propiedades de Acción
      </div>
      {draftMode && !editingDisable && (
        <>
          <div className="edit-position-button">      
            <button 
            onClick={ () => {
                dispatch(editingActionBegin(action))
                createLines()
              }}
            >
              Modificar posición
            </button>
            <button 
            onClick={ () => {
                Modal.confirm({
                  title: '¿Desea eliminar la acción?',
                  icon: <ExclamationCircleOutlined />,
                  onOk: () => {
                    socket.emit('client:wf-delete-action', {
                      companyId: companyId,
                      processSlug, 
                      token: token,
                      payload: action
                    })
                    dispatch(deleteAction(action))
                    createLines()
                  },
                  onCancel() {
                    console.log('Descartar cancelado');
                  },
                });
              }}
            >
              Eliminar
            </button>
          </div>
        </>
      )}
      <div className="input-role-container">
        <div className="label">
            Origen <Tooltip title="El formulario de origen de esta acción."><InfoCircleOutlined /></Tooltip> 
        </div>
        <Input
          placeholder="Intro"
          disabled={true}
          style={disabledColor ? { border: `2px solid ${disabledColor}` }: undefined}
          value={action.startSlug}
        />
      </div>
      <div className="input-role-container">
        <div className="label">
            Destino <Tooltip title="El formulario de destino de esta acción."><InfoCircleOutlined /></Tooltip> 
        </div>
        <Input
          placeholder="Fin"
          disabled={true}
          style={disabledColor ? { border: `2px solid ${disabledColor}` }: undefined}
          value={action.formSlug}
        />
      </div>      
      <div className="input-role-container">
        <div className="label">
            Etiqueta <Tooltip title="El botón se desplegará con este nombre."><InfoCircleOutlined /></Tooltip> 
        </div>
        <Input
          placeholder="Fin"
          disabled={editingDisable}
          style={disabledColor ? { border: `2px solid ${disabledColor}` }: undefined}
          value={action.label}
          onChange={(e) => changeWorkflowAction({...action, label: e.target.value})}
          onBlur={(e) => sendWorkflowAction({...action, label: e.target.value})}
        />
      </div>
      <div className="input-role-container">
        <div className="label">
            Color de fondo <Tooltip title="El botón se desplegará con este color de fondo (Estilo CSS. Ejemplos: #FF0000, red)."><InfoCircleOutlined /></Tooltip> 
        </div>
        <Input
          disabled={editingDisable}
          style={disabledColor ? { border: `2px solid ${disabledColor}` }: undefined}
          value={action.backgroundColor}
          onChange={(e) => changeWorkflowAction({...action, backgroundColor: e.target.value})}
          onBlur={(e) => sendWorkflowAction({...action, backgroundColor: e.target.value})}
        />
      </div>
      <div className="input-role-container">
        <div className="label">
            Color de texto <Tooltip title="El botón se desplegará con este color de texto (Estilo CSS. Ejemplos: #FF0000, red)."><InfoCircleOutlined /></Tooltip> 
        </div>
        <Input
          disabled={editingDisable}
          style={disabledColor ? { border: `2px solid ${disabledColor}` }: undefined}
          value={action.textColor}
          onChange={(e) => changeWorkflowAction({...action, textColor: e.target.value})}
          onBlur={(e) => sendWorkflowAction({...action, textColor: e.target.value})}
        />
      </div>
      <div className="input-role-container">
        <div className="label">
            Visible <Tooltip title="El botón solo se desplegará si la expresión ingresada retorna verdadero (true)."><InfoCircleOutlined /></Tooltip> 
        </div>
        <Input
          disabled={editingDisable}
          style={disabledColor ? { border: `2px solid ${disabledColor}` }: undefined}
          value={action.visible}
          onChange={(e) => changeWorkflowAction({...action, visible: e.target.value})}
          onBlur={(e) => sendWorkflowAction({...action, visible: e.target.value})}
        />
      </div>
      <div className="input-role-container">
        <div className="side_properties__input--label">
          Ancho <Tooltip title="La proporción del ancho del formulario que debe ocupar el botón."><InfoCircleOutlined /></Tooltip> 
        </div>
        <div className="side_properties__input--element">
          <Select 
            className="side_properties__input--select" 
            placeholder="Seleccione una opción"
            onChange={value => {
              changeWorkflowAction({...action, layout: value})
              sendWorkflowAction({...action, layout: value})
            }} 
            value={action.layout}
          >
            <Select.Option key={`opt-1`} value='half-width'>
              Mitad
            </Select.Option>
            <Select.Option key={`opt-2`} value='full-width'>
              Completo
            </Select.Option>
            
          </Select>
        </div>
        
      </div>
      <div className="input-role-container">
        <Checkbox
          disabled={editingDisable}
          checked={action.skipValidation}
          onChange={evt => {
            changeWorkflowAction({...action, skipValidation: evt.target.checked})
            sendWorkflowAction({...action, skipValidation: evt.target.checked})
          }} 
        />
        <label className="side_properties__input--label"> Saltarse validación del formulario <Tooltip title="Cuando el usuario presione el botón, el formulario no será validado"><InfoCircleOutlined /></Tooltip>  </label>
      </div>
      <div className="input-role-container">
        <Checkbox
          disabled={editingDisable}
          checked={action.confirm}
          onChange={evt => {
            changeWorkflowAction({...action, confirm: evt.target.checked})
            sendWorkflowAction({...action, confirm: evt.target.checked})
          }} 
        />
        <label className="side_properties__input--label"> Solicitar confirmación <Tooltip title="Cuando el usuario presione el botón, el usuario deberá confirmar para ejecutar la acción."><InfoCircleOutlined /></Tooltip>  </label>
      </div>
      <div className={`input-role-container${draftMode && !editingDisable ? '__hook' : ''}`}>
        <div className="label">
            Hooks
            {draftMode && !editingDisable &&
              <a className="add-hook-button"onClick={() => {
                const hs = [...action.hooks]
                hs.push('')
                changeWorkflowAction({...action, hooks: hs})
                sendWorkflowAction({...action, hooks: hs})
              }} >
                <PlusCircleOutlined />
              </a>
            }
            
        </div>
        {action.hooks ? action.hooks.map((hook, i)=> {
          return (
            <>
              <Input
                className="hook-input"
                key={i}
                disabled={editingDisable}
                value={hook}
                style={disabledColor ? { border: `2px solid ${disabledColor}` }: undefined}
                onChange={(e) => {
                  const hs = [...action.hooks]
                  hs[i] = e.target.value
                  changeWorkflowAction({...action, hooks: hs})
                }}
                onBlur={(e) => {
                  const hs = [...action.hooks]
                  hs[i] = e.target.value
                  sendWorkflowAction({...action, hooks: hs})
                }}
              />
              {draftMode && !editingDisable &&
                <a className="delete-hook-button"onClick={() => {
                  const hs = [...action.hooks]
                  hs.splice(i, 1);
                  changeWorkflowAction({...action, hooks: hs})
                  sendWorkflowAction({...action, hooks: hs})
                }} ><DeleteOutlined /></a>}
              
            </>
          )
        }) : 'No existen hooks para esta acción'}
        
      </div>
      <div className={`input-role-container${draftMode && !editingDisable ? '__hook' : ''}`}>
        <div className="label">
            Extensiones        
        </div>
        <div className="add-hook-container" style={{width: '264px'}}>
          {action.extensions ? action.extensions.map((hook, i)=> {
            return (
              <>
                <AddHookBox 
                  hook={hook}
                  hookId={hook.hookId}
                  hookIndex={i}
                  onDelete={deleteExtension}
                  onEdit={editExtension}
                  readOnly={!draftMode}
                />
                {/* <Input
                  className="hook-input"
                  key={i}
                  disabled={editingDisable}
                  value={hook}
                  style={disabledColor ? { border: `2px solid ${disabledColor}` }: undefined}
                  onChange={(e) => {
                    const hs = [...action.hooks]
                    hs[i] = e.target.value
                    changeWorkflowAction({...action, hooks: hs})
                  }}
                  onBlur={(e) => {
                    const hs = [...action.hooks]
                    hs[i] = e.target.value
                    sendWorkflowAction({...action, hooks: hs})
                  }}
                /> */}
                
                
              </>
            )
          }) : (
            <>
              
            </>
          )}
          {draftMode && !editingDisable ?
              (
                <div className="add-hook-box" style={{cursor: 'pointer'}} onClick={openMyHooksModal}>
                  <div className="add-hook-box-content">
                    <p className="add-hook-box-title-text"><b>agregar</b></p>
                    <div className="add-hook-box-logo add-hook-box-icon">
                      <PlusCircleOutlined />
                    </div>
                  </div>
                </div>
              ):''
                // <a className="delete-hook-button"onClick={() => {
                //   const hs = [...action.hooks]
                //   hs.splice(i, 1);
                //   changeWorkflowAction({...action, hooks: hs})
                //   sendWorkflowAction({...action, hooks: hs})
                // }} ><DeleteOutlined /></a>}
              }

        </div>
        
      </div>
        <MyHooksModal key={myHooksModalKey} show={myHooksModalVisible} onCancel={closeMyHooksModal} onAdd={addExtension}/>
    </div>
  )
}


export default ActionProperties