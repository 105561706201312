/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { Descriptions, Collapse, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone'
import './HooksLogDetail.scss'
const { Panel } = Collapse;

const HooksLogDetail = ({ hookLogDetail, back, environment }) => {
  const timeZone = useSelector(state => state.user.timeZone)

  const collapseStyle = environment === 'draft' ? {
    maxHeight: '429px',
    overflowY: 'scroll'
  } : {}
  return (
    <>
      <Row>
        <div style={{cursor: 'pointer', fontSize: '20px'}} onClick={() => back()}><FontAwesomeIcon icon="arrow-left" /> Volver</div>
      </Row>
      <Row> 
        <Collapse defaultActiveKey={['1']} style={{...collapseStyle, width: '100%'}} >
          <Panel header="Proceso" key="1">
            <Descriptions bordered>
              <Descriptions.Item label="Nro. Caso" span={3}>{hookLogDetail.caseInfo ? hookLogDetail.caseInfo.caseNumber : ''}</Descriptions.Item>
              <Descriptions.Item label="Versión" span={3}>{hookLogDetail.caseInfo ? hookLogDetail.caseInfo.processVersion : ''}</Descriptions.Item>
              <Descriptions.Item label="Formulario" span={3}>{hookLogDetail.caseInfo ? `${hookLogDetail.caseInfo.formTitle} (${hookLogDetail.caseInfo.formSlug})` : ''}</Descriptions.Item>
              <Descriptions.Item label="Acción" span={3}>{hookLogDetail.caseInfo ? `${hookLogDetail.caseInfo.actionLabel} (${hookLogDetail.caseInfo.actionEnd})` : ''}</Descriptions.Item>
            </Descriptions>
          </Panel>
          <Panel header="Web Hook" key="2">
            <Descriptions bordered>
              <Descriptions.Item label="Nombre" span={3}>{hookLogDetail.webHook.title}</Descriptions.Item>
              <Descriptions.Item label="URL" span={3}>{hookLogDetail.webHook.url}</Descriptions.Item>
            </Descriptions>
          </Panel>
          <Panel header="Solicitud" key="3">
            <Descriptions bordered>
              <Descriptions.Item label="Fecha-Hora" span={3}>{moment(hookLogDetail.invocation.execDate).tz(timeZone).format('YYYY-MM-DD HH:mm')}</Descriptions.Item>
              <Descriptions.Item label="Cabeceras HTTP" span={3}>{JSON.stringify(hookLogDetail.invocation.headers, null, 2)}</Descriptions.Item>
              <Descriptions.Item label="Data" span={3}>{JSON.stringify(hookLogDetail.invocation.data, null, 2)}</Descriptions.Item>
            </Descriptions>
          </Panel>
          <Panel header="Respuesta" key="4">
            <Descriptions bordered>
              <Descriptions.Item label="Fecha-Hora" span={3}>{moment(hookLogDetail.response.date).tz(timeZone).format('YYYY-MM-DD HH:mm')}</Descriptions.Item>
              <Descriptions.Item label="Estado HTTP" span={3}>{hookLogDetail.response.httpStatus}</Descriptions.Item>
              <Descriptions.Item label="Cabeceras HTTP" span={3}>{JSON.stringify(hookLogDetail.response.headers, null, 2)}</Descriptions.Item>
              <Descriptions.Item label="Body" span={3}>{JSON.stringify(hookLogDetail.response.data, null, 2)}</Descriptions.Item>
            </Descriptions>
          </Panel>
        </Collapse>
      </Row>
    </>
  )
}


export default HooksLogDetail