import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// import cf from '../../../config/APIConfig'
import axiosBW from '../../../utils/Http';
import ReactMarkdown from 'react-markdown'
import './InfoHall.scss'
import axios from 'axios';
import { Modal, Layout, Row, Col } from 'antd'
import { setGlobalLoader, setIsMobile } from '../../../actions/actions'
import { Helmet } from 'react-helmet'
import FeaturedServicesHall from './FeaturedServicesHall/FeaturedServicesHall'
const { Header } = Layout;

class InfoHall extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hallData: {
        logo: '',
        name: '',
        mainImage: '',
        sideImage: '',
        mainImageHeight: '',
        primaryColor: '',
        secondaryColor: '',
        textColor: '',
        enterpriseContactInfo: '',
        enterpriseAddress: '',
        textHeader: '',
        textContent: '',
        hook: '',
      },
      sourceHook: "",
      processList: []
    }
  }

  resize () {
    if (window.innerWidth < 768) {
      this.props.setIsMobile(true)
    } else {
      this.props.setIsMobile(false)
    }
  }

  async componentDidMount () {
    this.props.setGlobalLoader(true)
    // if(!this.props.token) {
    //   Modal.error({
    //     title: 'Error',
    //     content: `Debe Iniciar Sesión para Continuar`,
    //   })
    //   setTimeout(() => {
    //     this.props.history.push(`/ingresar?redirect_to=hall/${this.props.match.params.companyId}`)
    //   }, 2000)
    //   return
    // }
    if (window.innerWidth < 768) {
      this.props.setIsMobile(true)
    } else {
      this.props.setIsMobile(false)
    }
    window.addEventListener('resize', () => {
      this.resize()
    })
    let resHall
    try {
      resHall = await axiosBW.get(`/companies/hall/${this.props.match.params.companyId}`)
    } catch (ex) {
      console.log('error resAccountInfo', ex)
    }
    if (!resHall || !resHall.data) {
      Modal.error({
        title: 'Error',
        content: `Error al cargar la info del Hall`,
      })
      return
    }
    let pl = resHall.data.payload
    if (resHall.data.status === 0) {
      this.setState({
        hallData: {
          logo: pl.companyImagePath ? pl.companyImagePath : '',
          name: pl.fantasyName ? pl.fantasyName : 'N/A',
          mainImage: pl.portraitImgPath ? pl.portraitImgPath : '',
          sideImage: pl.circleImgPath ? pl.circleImgPath : '',
          mainImageHeight: pl.mainImageHeight ? pl.mainImageHeight : '232px',
          primaryColor: pl.portraitColor ? pl.portraitColor : '',
          secondaryColor: pl.circleColor ? pl.circleColor : '',
          textColor: pl.thirdColor ? pl.thirdColor : '',
          enterpriseContactInfo: pl.contactEmail ? pl.contactEmail : '',
          enterpriseAddress: pl.addressLine ? pl.addressLine : '',
          textHeader: pl.title ? pl.title : '',
          textContent: pl.text ? pl.text : '',
          hook: pl.hook ? pl.hook : '',
          hookTitle: pl.hookTitle ? pl.hookTitle : '',
          lightColor: pl.lightColor ? pl.lightColor : ''
        },
        processList: Array.isArray(pl.process) ? pl.process : [],

      })
      if (pl.hook && pl.hook.length > 0) {
        axios.get(`${pl.hook}/${this.props.token}`)
          .then(res => {
            console.log('res hook', res.data)
            this.setState({
              sourceHook: res.data.payload
            })
          })
          .catch(err => {
            console.log('error hoojk', err)
          })
      }
    }
    this.props.setGlobalLoader(false)
  }


  render () {
    return (
      <div className={this.props.isMobile ? 'info_hall__wrapper_mobile' : 'info_hall__wrapper'}>
        <section className="info_hall">
          <header className="info_hall__header">
            <div className="info_hall__logo">
              <img src={this.state.hallData.logo} alt={this.state.hallData.name} />
            </div>
            {this.props.match.params.companyId === this.props.companyId && !this.props.isMobile ? (
              <Link to="/procesos" role="button" style={{ paddingRight: '36px' }}>
                <div className="button_flujo">
                  <img src="/img/ico-flujos-de-trabajo.png"></img>
                  <span>{'Flujos de trabajo'}</span>
                </div>
              </Link>
            ) : ''}
          </header>
          <main className={this.props.isMobile ? 'info_hall__main_mobile' : 'info_hall__main'}>
            {!this.props.isMobile ? (
              <section className="info_hall__image"
                style={{
                  height: this.state.hallData.mainImageHeight,
                  backgroundColor: `${this.state.hallData.primaryColor}`,
                  backgroundImage: `url('${this.state.hallData.mainImage}')`,
                  backgroundSize: 'cover',
                }}
              >
              </section>
            ) : ''}
            <div className="services_title">
              Servicios disponibles
            </div>
            <Row className="info_hall__services_wrapper">
              <Col span={1}></Col>
              <Col span={24}>
                <FeaturedServicesHall defaultWidth='100%' type={this.props.isMobile ? "mobile" : "desktop"} featuredList={this.state.processList} statusMsg={this.state.msgFeaturedServices} />
              </Col>
              {/* <Col span={10}></Col>
              <Col span={5} style={{paddingTop: '24px'}}>
                 
              </Col> */}

            </Row>
            <section className="info_hall__content">
              <div className="info_hall__content_text">
                <header className="info_hall__content_title">
                  <h1 style={{ color: this.state.hallData.primaryColor }}>{this.state.hallData.textHeader}</h1>
                </header>
                <ReactMarkdown escapeHtml={false} source={this.state.hallData.textContent} style={{ color: this.state.hallData.textColor }} />
                {/* <p style={{color: this.state.hallData.textColor}}>{this.state.hallData.textContent}</p> */}
              </div>
              {
                this.state.hallData.hook && this.state.hallData.hook.length > 0 ? (
                  <div className="info_hall__content_hook">
                    <h1>{this.state.hallData.hookTitle}</h1>
                    <ReactMarkdown escapeHtml={false} source={this.state.sourceHook} />
                  </div>
                ) : (
                  <div className="info_hall__content_image">
                    <div className="info_hall__content_image_data" style={{ background: `${this.state.hallData.secondaryColor} url(${this.state.hallData.sideImage}) 100% 100%` }}>
                    </div>
                  </div>
                )
              }

            </section>
          </main>
        </section>
        <footer className="info_hall__footer" style={{ background: `${this.state.hallData.primaryColor}`, color: `${this.state.hallData.lightColor}` }}>
          <div className="info_hall__footer_info">
            {this.state.hallData.enterpriseContactInfo}
          </div>
          <div className="info_hall__footer_contact">
            {this.state.hallData.enterpriseAddress}
          </div>
        </footer>
        <Helmet>
          <title> {this.state.hallData.name} - Blizwork </title>
        </Helmet>
      </div>

    )
  }
}
const newcode = () => {
  return (
    <Layout className="info_hall__wrapper">
      <Layout className="info_hall">
        <Header className="info_hall__header">
          <div className="info_hall__logo">
            <img src={this.state.hallData.logo} alt={this.state.hallData.name} />
          </div>
        </Header>
      </Layout>
      <Layout>

      </Layout>
    </Layout>

  )
}

const mapStateToProps = (state) => ({
  companyId: state.user.companyId,
  token: state.user.token,
  isMobile: state.navigation.isMobile
})

const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState)),
  setIsMobile: (bool) => dispatch(setIsMobile(bool))
})

export default connect(mapStateToProps, mapDispatchToProps)(InfoHall)