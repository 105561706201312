/* eslint-disable react/display-name */
import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Modal, Table } from 'antd';
import './RequestsList.scss'
import { CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import { setGlobalLoader } from '../../../../../actions/actions'
import { getMyTeamInfo } from '../../../../../actions/MyTeamActions'
// import axios from 'axios';
// import cf from '../../../../../config/APIConfig'
import axiosBW from '../../../../../utils/Http';
import moment from 'moment-timezone'

const RequestsList = () => {
  const dispatch = useDispatch();
  const requestsList = useSelector(state => state.myTeam.requestsList)
  const timeZone = useSelector(state => state.user.timeZone)

  console.log(requestsList)
  const data = []
  const approveRequest = async (registrationId) => {
    console.log(registrationId)
    Modal.confirm({
      className: "invitation_modal_ok",
      icon: null,
      width: 586,
      footer: null,
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      title: (<div style={{alignContent: 'center', textAlign: 'center', fontSize: '30px'}}>Confirmación</div>),
      content: (
        <div style={{alignContent: 'center', textAlign: 'center', fontSize: '17px'}}>
          <div>
            ¿Está seguro de aceptar la solicitud?
          </div>
        </div>
      ),
      async onOk() {
        console.log('OK');
        dispatch(setGlobalLoader(true))
        try {
    
          const resp = await axiosBW.post('/auth/approve-request/',
            {
              registrationId
            }
          );
          dispatch(getMyTeamInfo())
          dispatch(setGlobalLoader(false))
          console.log(resp.data);
          Modal.info({
            className: "invitation_modal_ok",
            icon: null,
            width: 586,
            footer: null,
            okText: 'Cerrar',
            title: (<div style={{alignContent: 'center', textAlign: 'center', fontSize: '30px'}}>Solicitud Aprobada</div>),
            content: (
              <div style={{alignContent: 'center', textAlign: 'center', fontSize: '17px'}}>
                <div>
                  La solicitud fue aprobada con éxito
                </div>
              </div>
            )
          })
        } catch (err) {
          dispatch(setGlobalLoader(false))
          let errMsg = 'Hubo un error al aceptar la solicitud'
          // if (err.response && err.response.data && err.response.data.message) {
          //   const respMsg = err.response.data.message
          //   if (respMsg === 'User already registered') {
          //     errMsg= 'El usuario ya existe en el sistema'
          //   }
          // }
          Modal.error({
            title: 'Error',
            content: errMsg
          })
        }
      },
    })
  }
  const rejectRequest = async (registrationId) => {
    console.log(registrationId)
    Modal.confirm({
      className: "invitation_modal_ok",
      icon: null,
      width: 586,
      footer: null,
      okText: 'Eliminar',
      cancelText: 'Cancelar',
      title: (<div style={{alignContent: 'center', textAlign: 'center', fontSize: '30px'}}>Confirmación</div>),
      content: (
        <div style={{alignContent: 'center', textAlign: 'center', fontSize: '17px'}}>
          <div>
            ¿Está seguro de eliminar la solicitud?
          </div>
        </div>
      ),
      async onOk() {
        console.log('OK');
        dispatch(setGlobalLoader(true))
        try {
          
          const resp = await axiosBW.post('/auth/reject-request/',
            {
              registrationId
            }
          );
          dispatch(getMyTeamInfo())
          dispatch(setGlobalLoader(false))
          console.log(resp.data);
          Modal.info({
            className: "invitation_modal_ok",
            icon: null,
            width: 586,
            footer: null,
            okText: 'Cerrar',
            title: (<div style={{alignContent: 'center', textAlign: 'center', fontSize: '30px'}}>Solicitud Rechazada</div>),
            content: (
              <div style={{alignContent: 'center', textAlign: 'center', fontSize: '17px'}}>
                <div>
                  La solicitud fue rechazada con éxito.
                </div>
              </div>
            )
          })
        } catch (err) {
          dispatch(setGlobalLoader(false))
          let errMsg = 'Hubo un error al aceptar la solicitud'
          // if (err.response && err.response.data && err.response.data.message) {
          //   const respMsg = err.response.data.message
          //   if (respMsg === 'User already registered') {
          //     errMsg= 'El usuario ya existe en el sistema'
          //   }
          // }
          Modal.error({
            title: 'Error',
            content: errMsg
          })
        }
      },
    })
  }
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Fecha de solicitud',
      dataIndex: 'requestDate',
      key: 'requestDate',
      render: elem => moment(elem).tz(timeZone).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: 'Tipo de solicitud',
      dataIndex: 'type',
      key: 'type',
      render: elem => { return elem === 'invitation' ? 'Invitacion' : 'Solicitud'}
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: elem => { return elem === 'pending' ? 'Pendiente' : elem === 'approved' ? 'Aprobado' : 'Terminado'}
    },
    {
      title: 'Acciones',
      width:'2%',
      render: (value, record) => {
        if (record.type === 'request' && record.status === 'pending') {
          return (
            <div className="action-list">
              <div className="action-icon" onClick={() => approveRequest(record._id)}>
                <CheckCircleOutlined />
              </div>
              <div className="action-icon" onClick={() => rejectRequest(record._id)} style={{paddingLeft: '7px'}}>
                <CloseCircleOutlined />
              </div>
            </div>
          )
        }
        return (
          <div className="">
            N/A
          </div>
        )
      }
    },
  ];
  const locale = {
    emptyText: (
      <span>
        <p>
          No hay solicitudes pendientes
        </p>
      </span>
    )
  };
  return (
    <div className="table-wrapper" >
      <Table pagination={{ pageSize: 10 }} locale={locale} columns={columns} dataSource={requestsList} />
    </div>
  )
}


export default RequestsList