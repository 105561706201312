/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  // Switch,
  Route,
  useParams
} from "react-router-dom";
import axiosBW from '../../../utils/Http';
import { toast } from 'react-toastify';
// import Charts from '../../../TestPages/Charts';
// import AnalyticChart from '../AnalyticChart/AnalyticChart';
import { Table, Row, Col, Modal, Input, Collapse, Select, Tabs, InputNumber, Space, Switch, Button, Upload, Tooltip } from 'antd';
import Editor from "react-simple-code-editor";
import { PlusCircleOutlined, LoadingOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setGlobalLoader } from '../../../actions/actions'
import MyHooks from './components/MyHooks/MyHooks';
import HooksStore from './components/HooksStore/HooksStore';
// import HooksLogDetail from '../HooksLogDetail/HooksLogDetail'
import moment from 'moment'
import './Hooks.scss'

const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;

const Hooks = (props) => {
  const companyId = useSelector(state => state.user.companyId)
  console.log({props})
  // console.log({languages})
  const dispatch = useDispatch();
  dispatch(setGlobalLoader(false))
  // if(!location.state.companyId) history.push('/')
//   const usersList = useSelector(state => state.myTeam.usersList)
  const [analyticsList, setAnalyticsList] = useState([]);
  const [hooksList, setHooksList] = useState([]);
  const [hookModalVisible, setHookModalVisible] = useState(false);
  const [hookData, setHookData] = useState({
    _id: null,
    companyId,
    name: '',
    description: '',
    infoLink: '',
    url: '',
    timeout: 0,
    icon: 'https://stagefilestorage.file.core.windows.net/stagefiles/atenea/615bb22710160447dc2bb773_webhook.png?sv=2019-12-12&ss=bfqt&srt=o&sp=rx&se=2030-12-19T00:08:12Z&st=2020-12-10T16:08:12Z&spr=https&sig=SnGOf1S3tfMZkZ8LqhZlPqmJ%2Bu%2FNBpjhFli6lHaZ6io%3D',
    fee: {
      monthly: 0,
      oneTime: 0,
      perUsage: 0,
      gracePeriod: 0
    },
    active: true,
    shared: true
})
const [uploadingImage, setUploadingImage] = useState(false)

  const [oldAnalytics, setOldAnalytics] = useState(null);

  const [chartProperties, setChartProperties] = useState({});
  const [series, setSeries] = useState([]);
  const [type, setType] = useState('bar');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [showChart, setShowChart] = useState(false);
  // const [showModal, setModal] = useState(false)
  const [newSectionName, setNewSectionName] = useState('')
  const [hooksLogDetail, setHooksLogDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [detailPage, setDetailPage] = useState(false);
  
  let { processSlug, processId } = useParams();



  useEffect(() => {
    console.log('va el hooks-log');
    (async () => {
      // setLoading(true)
      // // await getDashboard()
      // await getHooksList()
      // setLoading(false)

    })();
    
  }, []);
  const getHooksList = async() => {
    try {
      const res = await axiosBW.get(`hooks/hooks-list/${companyId}/`)
      if(res && res.data) {

        if(res.data.status === 0) {
          setHooksList(res.data.payload)
        }
      }
    } catch(ex) {
      console.log(ex);
    }
  }
  const resetHookData = () => {
    setHookData({
      hookId: null,
      companyId,
      name: '',
      description: '',
      infoLink: '',
      url: '',
      timeout: 0,
      icon: 'https://stagefilestorage.file.core.windows.net/stagefiles/atenea/615bb22710160447dc2bb773_webhook.png?sv=2019-12-12&ss=bfqt&srt=o&sp=rx&se=2030-12-19T00:08:12Z&st=2020-12-10T16:08:12Z&spr=https&sig=SnGOf1S3tfMZkZ8LqhZlPqmJ%2Bu%2FNBpjhFli6lHaZ6io%3D',
      fee: {
        monthly: 0,
        oneTime: 0,
        perUsage: 0,
        gracePeriod: 0
      },
      active: true,
      shared: true
    })
  }
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onerror = reject;
      fr.onload = function() {
          resolve(fr.result);
      }
      fr.readAsDataURL(file);
    });
  }
  const beforeUpload = async(file) => {
    try {
      console.log(file)
      setUploadingImage(true)
      
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        // message.error('You can only upload JPG/PNG file!');
        throw new Error('Solo puede ser un archivo JPG/PNG!')
      }
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        // message.error('Image must smaller than 2MB!');
        throw new Error('La imagen debe ser menor a 1MB')
      }
      const base64File = await getBase64(file);
      console.log(base64File)
      
      const uploadedImage = await axiosBW.post('/attachment/upload-file', {
        module: 'hook-icon',
        filename: file.name,
        file: base64File.split('base64')[1]
      })
      setHookData({...hookData, icon: uploadedImage.data.payload.downloadUrl})
      setUploadingImage(false)
      // setUserImage(uploadedImage.data.payload.downloadUrl)
    } catch (error) {
      console.log(error)
      // onChange({imagePath: ''})
      setUploadingImage(false)
      toast.error(error.message)
    }
  }
  const createHook = async() => {
    try {
      if (!hookData.name || hookData.name === '') {
        throw new Error('Campo Nombre faltante')
      }
      if (!hookData.description || hookData.description === '') {
        throw new Error('Campo Descripción faltante')
      }
      if (!hookData.url || hookData.url === '') {
        throw new Error('Campo URL faltante')
      }
      await axiosBW.post('/hooks/create-hook', {
        ...hookData
      })
      setLoading(true)
      // await getDashboard()
      await getHooksList()
      cancelModal()
      setLoading(false)
      

    } catch (error) {
      toast.error(error.message)
    }
  }
  const updateHook = async() => {
    try {
      if (!hookData.name || hookData.name === '') {
        throw new Error('Campo Nombre faltante')
      }
      if (!hookData.description || hookData.description === '') {
        throw new Error('Campo Descripción faltante')
      }
      if (!hookData.url || hookData.url === '') {
        throw new Error('Campo URL faltante')
      }
      await axiosBW.put('/hooks/update-hook', {
        ...hookData
      })
      setLoading(true)
      // await getDashboard()
      await getHooksList()
      cancelModal()
      setLoading(false)
      

    } catch (error) {
      toast.error(error.message)
    }
  }
  const switchHook = async(hook) => {
    try {
      await axiosBW.put('/hooks/update-hook', {
        ...hook,
        active: !hook.active
      })
      setLoading(true)
      // await getDashboard()
      await getHooksList()
      cancelModal()
      setLoading(false)
      

    } catch (error) {
      toast.error(error.message)
    }
  }
  const deleteHook = async(hookId) => {
    try {
      await axiosBW.post('/hooks/delete-hook', {
        hookId,
        companyId
      })
      setLoading(true)
      // await getDashboard()
      await getHooksList()
      setLoading(false)
      

    } catch (error) {
      toast.error(error.message)
    }
  }

  const hookModal = () => {
    return(
      <Modal
        className="hook-modal"
        destroyOnClose={true}
        width={586}
        height={500}
        closable={true}
        centered={true}
        visible={hookModalVisible}
        title={(
          <>
          <div style={{alignContent: 'center', textAlign: 'center', fontSize: '30px', color: '#5C5C5C'}}>
            {hookData._id? 'Editar' : 'Nueva'} Extensión
          </div>
          </>
        )}
        // onOk={()=>{}}
        onCancel={cancelModal}
        // cancelText="Cancelar"
        // okText="Crear"
        footer={null}
      >
        {loading ? (
          <div className="user_info_loading">
            <LoadingOutlined />
          </div>
        ):
        (
          <>
            <div className="hook-modal-body">
              <div className="hook-modal-field">
                <label>Nombre:</label>
                <Input
                  type="text"
                  name="hookTitle"
                  value={hookData.name}
                  onChange={e => setHookData({...hookData, name: e.target.value})}
                />
              </div>
              <div className="hook-modal-field">
                <label>Descripción:</label>
                <Input
                  type="text"
                  name="description"
                  value={hookData.description}
                  onChange={e => setHookData({...hookData, description: e.target.value})}
                />
              </div>
              <div className="hook-modal-field">
                <label>Link documentación:</label>
                <Input
                  type="text"
                  name="linkInfo"
                  value={hookData.infoLink}
                  onChange={e => setHookData({...hookData, infoLink: e.target.value})}
                />
              </div>
              <div className="hook-modal-field">
                <label>URL:</label>
                <Input
                  type="text"
                  name="url"
                  value={hookData.url}
                  onChange={e => setHookData({...hookData, url: e.target.value})}
                />
              </div>
              <div className="hook-modal-field">
                <label>Tiempo de espera:</label>
                <Space direction="vertical">
                  <InputNumber addonAfter="$" defaultValue={30} onChange={e => setHookData({...hookData, timeout: e})} />
                  
                </Space>
              </div>
              <div className="hook-modal-field">
                <label>Ícono:</label>
                <Upload
                  // name="avatar"
                  listType="picture-card"
                  className="icon-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                >
                  {hookData.icon && hookData.icon.length > 0 ? <img src={hookData.icon} alt="avatar" style={{ width: '100%' }} /> : (
                    <div>
                      {uploadingImage ? <LoadingOutlined /> : <PlusOutlined />}
                      <div style={{ marginTop: 8 }}>Subir</div>
                    </div>
                  )}
                </Upload>
              </div>
              <div className="hook-modal-field">
                <label>Compartido:</label>
                <Switch 
                  checked={hookData.shared} 
                  onChange={e => setHookData({...hookData, shared: e})}
                />
              </div>
              {hookData.shared? (
                <>
                  <div className="hook-modal-field">
                    <label>Cargo mensual:</label>
                    <Space direction="vertical">
                      <InputNumber addonAfter="$" defaultValue={0} onChange={e => setHookData({...hookData, fee: {...hookData.fee, monthly: e}})} />
                    </Space>
                  </div>
                  <div className="hook-modal-field">
                    <label>Cargo único:</label>
                    <Space direction="vertical">
                      <InputNumber addonAfter="$" defaultValue={0} onChange={e => setHookData({...hookData, fee: {...hookData.fee, oneTime: e}})} />
                    </Space>
                  </div>
                  <div className="hook-modal-field">
                    <label>Cargo por uso:</label>
                    <Space direction="vertical">
                      <InputNumber addonAfter="$" defaultValue={0} onChange={e => setHookData({...hookData, fee: {...hookData.fee, perUsage: e}})} />
                    </Space>
                  </div>
                  <div className="hook-modal-field">
                    <label>Días de gracia:</label>
                    <Space direction="vertical">
                      <InputNumber addonAfter="$" defaultValue={0} onChange={e => setHookData({...hookData, fee: {...hookData.fee, gracePeriod: e}})} />
                    </Space>
                  </div>
                </>
              ): null}
            </div>
            <div className="hooks-modal-footer">
              <button  onClick={cancelModal}
              >
                Cancelar
              </button>
              <Button type="primary" htmlType="submit" onClick={!hookData._id ? createHook : updateHook}
              >
                {hookData._id? 'Editar': 'Crear'}
              </Button>
            </div>  
          </>
        )}
      </Modal>
    )
  }
  const showModal = () => {
    setHookModalVisible(true)
  };
  const openUpdateHook = (hook) => {
    setHookData({...hook})
    setHookModalVisible(true)
  };
  const cancelModal = () => {
    setHookModalVisible(false)
    resetHookData()
  };



 

  const isJson = (text) => {
    try {
      JSON.parse(text)
      return true
    } catch (error) {
      return false
    }
  }

  if (loading) {
      return (
        <div className="analytics-loading">
          <LoadingOutlined />
        </div>
      )
  }
  const callback = () => {

  }
 
  return (
    <div className="hooks-container">
      <Tabs destroyInactiveTabPane={true} defaultActiveKey="1">
        <TabPane tab="Extensiones" key="1">
          <MyHooks/>
        </TabPane>
        <TabPane tab="Tienda" key="2">
          <HooksStore/>
        </TabPane>
      </Tabs>
    </div>
  )
}


export default Hooks