import axiosBW from '../utils/Http';
import { setGlobalLoader } from './actions'
import { notificationValidationHandler } from '../utils/Helpers';
import { toast } from 'react-toastify';


export const addToCurrentForm = (widget, index, addAll) => ({
  type: 'ADD_TO_CURRENT_FORM',
  widget,
  index,
  addAll
})
export const replaceToCurrentForm = widget => ({
  type: 'REPLACE_TO_CURRENT_FORM',
  widget
})

export const setPropertiesVisible = element => ({
  type: 'SET_PROPERTIES_VISIBLE',
  element
})

export const formChangeOrder = (itemToObtain, positionToMove) => ({
  type: 'FORM_CHANGE_ORDER',
  itemToObtain,
  positionToMove
})
// formList
export const setSelectedForm = (formList) => ({
  type: 'SET_SELECTED_FORM',
  formList
})

export const changeFormMetadata = (name, value) => ({
  type: 'CHANGE_FORM_METADATA',
  name,
  value
})
export const setActionMetadata = (value) => ({
  type: 'SET_ACTION_METADATA',
  value
})


export const formChangeValue = (elem_id, value, identifier) => ({
  type: 'FORM_CHANGE_VALUE',
  elem_id,
  value,
  identifier
})

export const showFormData = () => ({
  type: 'SHOW_FORM_METADATA',
})

export const setComboboxKey = (value) => ({
  type: 'SET_COMBOBOX_KEY',
  value
})

export const setComboboxValue = (value) => ({
  type: 'SET_COMBOBOX_VALUE',
  value
})

export const addNewColumnKey = (elem_id) => ({
  type: 'ADD_NEW_COLUMN_KEY',
  elem_id
})

export const deleteWidget = (elem_id) => ({
  type: 'DELETE_WIDGET',
  elem_id
})

export const retrieveFormData = (widgets) => ({
  type: 'RETRIEVE_FORM_DATA',
  widgets
})

export const setColumnIdentifier = (value) => ({
  type: 'SET_COLUMN_IDENTIFIER',
  value
})
export const setColumnName = (value) => ({
  type: 'SET_COLUMN_NAME',
  value
})
export const setColumnType = (value) => ({
  type: 'SET_COLUMN_TYPE',
  value
})
export const setFormModified = (value) => ({
  type: 'SET_FORM_MODIFIED',
  value
})
export const setInitialFormData = (value) => ({
  type: 'SET_INITIAL_FORM_DATA',
  value
})
export const setInitialFormMetaData = (value) => ({
  type: 'SET_INITIAL_FORM_META_DATA',
  value
})

export const updateForm =  (companyId, processSlug, formSlug, formData, formMetadata, rawFormData, draft) => {
  console.log('formMetadata', formMetadata)
  console.log('formData', formData)
  let replaceForm = [...formData]
  let error = '0'
  replaceForm.forEach(f => {
    f.type = f._type
    if (!f.name || f.name.length <= 0 || f.slug === "") {
      error = '1'
      f.isMarked = true
    }
  })
  let actionOnTimeoutAction
  let actionOnTimeoutOption = formMetadata.filter(f => f.name === 'action')[0].value
  if (actionOnTimeoutOption !== 'nothing' && actionOnTimeoutOption !== 'close' ) {
    actionOnTimeoutOption = 'execute-action'
    actionOnTimeoutAction = formMetadata.filter(f => f.name === 'action')[0].value
  }
  /*formMetadataIn.forEach(f => {
    if (f.mandatory && (!f.value || f.value.length <= 0) {
      error = '1'
    }
  })*/
  
  
  return dispatch => {
    if(error !== '0') {
      notificationValidationHandler('Existen campos obligatorios sin llenar')
      dispatch(setGlobalLoader(false))      
      return 
    }
    replaceForm.forEach(f => {
      if (f._id && !isNaN(f._id)) {
        f._id = undefined
      }
    })
    dispatch(setGlobalLoader(true))
    axiosBW.put(`/process/update-form/${companyId}/${processSlug}/${formSlug}/`, {
      type: rawFormData.type ? rawFormData.type : '',
      roleAssignment: formMetadata.filter(f => f.name === 'roleAssignment')[0].value ? formMetadata.filter(f => f.name === 'roleAssignment')[0].value : '',
      userAssignment: formMetadata.find(f => f.name === 'userAssignment').value || '',
      title: formMetadata.filter(f => f.name === 'title')[0].value,
      description: formMetadata.filter(f => f.name === 'description')[0].value,
      actions: formMetadata.filter(f => f.name === 'hooks')[0].value,
      actionOnTimeout: { 
        timeout: formMetadata.filter(f => f.name === 'timeout')[0].value,
        option: actionOnTimeoutOption,
        action: actionOnTimeoutAction
      },
      fields: replaceForm,
      draft: !!draft
    })
    .then(res => {
      dispatch(setGlobalLoader(false))
      
      console.log('res', res)
      if(res.status === 200) {
        // Modal.success({
        //   title: 'Formulario Guardado',
        //   content: 'Formulario Guardado Exitosamente'
        // })
        toast.success('Formulario guardado exitosamente')
        dispatch(setInitialFormData(JSON.parse(JSON.stringify(replaceForm))))
        dispatch(setInitialFormMetaData(JSON.parse(JSON.stringify(formMetadata))))
      } else {
        // Modal.error({
        //   title: 'Error',
        //   content: 'Hubo un error al guardar el formulario'
        // })
        toast.error('Hubo un error al guardar el formulario')
      }
      //dispatch(updateFormWrapper(res.data.payload))
    })
    .catch(err => {
      dispatch(setGlobalLoader(false))

      console.log('error', err)
      toast.error('Hubo un error al guardar el formulario (Error en la llamada)')
    })
  }
}

export const updateFormWrapper = (response) => ({
  type: 'UPDATE_FORM',
  response
})

export const clearAllData = () => ({
  type: 'CLEAR_ALL_DATA'
})

export const setAllFormsFields = (value) => ({
  type: 'SET_ALL_FORMS_FIELDS',
  value
})
