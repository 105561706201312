import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import WidgetList from './components/WidgetList/WidgetList.view';
import SideProperties from './components/SideProperties/SideProperties.view';
import { Helmet } from 'react-helmet'
import './FormBuilder.scss'
import CenterWidget from './components/CenterWidget/CenterWidget.view';
import {
  formChangeOrder,
  addToCurrentForm,
  setGlobalLoader,
  setFormModified,
  showFormData,
  updateForm
} from '../../../actions/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom'
import {Row, Col } from 'antd';
import { Resizable } from "re-resizable";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import Sortable from './components/Sortable';

const FormBuilder = props => {
  const dispatch = useDispatch();
  const companyId = useSelector(state => state.user.companyId)
  const currentForm = useSelector(state => state.formBuilder.currentForm)
  const initialFormData = useSelector(state => state.formBuilder.initialFormData)
  const initialFormMetaData = useSelector(state => state.formBuilder.initialFormMetaData)
  const formMetadata = useSelector(state => state.formBuilder.formMetadata)
  const widgetList = useSelector(state => state.formBuilder.listOfWidgets)
  const formModified = useSelector(state => state.formBuilder.formModified)
  const [activeId, setActiveId] = useState(null);
  const [activeType, setActiveType] = useState(null);
  const [newItem, setNewItem] = useState(null)
  const [formWidgets, setFormWidgets] = useState([])
  const [formWidgetsItems, setFormWidgetsItems] = useState([])
  const sensors = useSensors(useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
      // delay: 250,
      // tolerance: 5,
    },
  }));
  
  const isDraft = props.isDraft
  let history = useHistory()
  if (JSON.stringify(initialFormData) === JSON.stringify(currentForm)
  && JSON.stringify(initialFormMetaData) === JSON.stringify(formMetadata)) {
    dispatch(setFormModified(false))
  } else {
    dispatch(setFormModified(true))
  }
  useEffect(() => {
    const setData = async() => {
      setFormWidgetsItems(currentForm.map((elem, ind) => `centerWidget-${elem._id}`))
      setFormWidgets(currentForm)
    }
    setData()
    
    
  }, [currentForm]);
  const handleDragEnd = (event) => {
    document.body.style.setProperty('cursor', '');
    const { active, over } = event;
    if (!over || !active) {
      setActiveType(null)
      setActiveId(null)
      setNewItem(null)
      setFormWidgetsItems(currentForm.map((elem, ind) => `centerWidget-${elem._id}`))
      setFormWidgets(currentForm)
      return;
    }
    const activeContainer = active.data.current?.sortable?.containerId || active.id;
    if (activeContainer === 'centerList') {
      if (newItem && active.id === `centerWidget-${newItem._id}`) {
        let itemToId = over.id.split('-')
        itemToId = itemToId.length > 2 ? `${itemToId[1]}-${itemToId[2]}` : itemToId[1]
        const toIndex = formWidgets.findIndex(f => f._id === itemToId)
        setFormWidgetsItems([])
        setFormWidgets([])
        dispatch(addToCurrentForm({...newItem, _id: Math.floor((Math.random() * 99999) + 1).toString()}, toIndex))
      } else {
        const itemFromId = active.id.split('-')[1]
        const itemToId = over.id.split('-')[1]
        const fromIndex = formWidgets.findIndex(f => f._id === itemFromId)
        const toIndex = formWidgets.findIndex(f => f._id === itemToId)
        if (fromIndex !== toIndex) {
          dispatch(formChangeOrder(fromIndex, toIndex))
        }
        
      }
      
    }
    setActiveType(null)
    setActiveId(null)
    setNewItem(null)
  }
  const handleDragStart = async(event) => {
    document.body.style.setProperty('cursor', 'grabbing');

    const { active } = event;
    if (!active?.id) {
      return;
    }
    const Id = active.id.split('-')
    const activeContainer = active.data.current?.sortable.containerId || active.id;
    setActiveType(Id[0])
    setActiveId(Id[1])
    if (activeContainer !== 'centerList') {
      console.log(Id[1])
      const newItem = widgetList.find((w) => w.widgetId.toString() === Id[1])
      const auxItem = {
            ico_url: newItem.ico_url,
            id: newItem._id,
            _id: `${Id[1]}-${Id[2]}`,
            title: newItem.title,
            _type: newItem.type,
            mandatory: newItem.mandatory,
            readOnly: newItem.readOnly,
            hideInOverview: newItem.hideInOverview,
            name: newItem.name,
            slug: newItem.slug,
            defaultValue: newItem.defaultValue,
            regEx: newItem.regEx,
            options: newItem.options ? newItem.options : [],
            tableOptions: {
              disableAddRowButton: false,
              disableDeleteRowButton: false
            },
            columns: newItem.columns ? newItem.columns : [],
            visible: newItem.visible ? newItem.visible : '',
            masterDataConfig: newItem.masterDataConfig
        }
      setNewItem(auxItem)
    }
  }
  const handleDragOver = async(event) => {
    const { active, over, draggingRect } = event;
    if (!over || !active) {
      return;
    }
    const { id } = active;
    const { id: overId } = over;
    const activeContainer = active.data.current?.sortable?.containerId || active.id;

    const overContainer = over.data.current?.sortable.containerId || over.id;
    if (activeContainer === 'centerList') {
      return;
    }
    
    if (overId === 'no-item' && newItem) {
      const thelist = [newItem]
      const widList = [`centerWidget-${newItem._id}`]
      setFormWidgets(thelist)
      setFormWidgetsItems(widList)
      return;
    }
    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }
    if (overId.indexOf('centerWidget-') === -1) {
      return;
    }
    if (overContainer !== 'centerList') {
      return;
    }
    
    
    
    try {
      let auxPrevList = [...formWidgets]
      
      let newIndex
      newIndex =over.data.current?.sortable.index || 0
      if (!auxPrevList.find(p => p._id === newItem._id)) {
        const thelist = [...auxPrevList.slice(0, newIndex), newItem, ...auxPrevList.slice(newIndex)]
        const widList = thelist.map((elem, ind) => `centerWidget-${elem._id}`)
        setFormWidgets(thelist)
        setFormWidgetsItems(widList)
      }
    } catch (error) {
      console.log(error)
      return;
    }

  }

  // const formWidgets = activeType && activeType === 'widgetList' ? auxWidgetList : currentForm

  // const formWidgetsItems = formWidgets.map((elem, ind) => `centerWidget-${elem._id}`)
  // console.log({formWidgetsItems})

  return (
    <DndContext
        autoScroll={true}
        sensors={sensors}
        onDragEnd={(e) => handleDragEnd(e)}
        onDragStart={(e) => handleDragStart(e)}
        onDragOver={handleDragOver}
      >
    <div>
      <header>
        <div className="form_builder__tit">
          
          <div className="form_builder__tit_name">
            <div className="form_builder__back_arrow" onClick={
              () => {
                history.goBack()
                  
              }
            }
            >
              <FontAwesomeIcon icon="arrow-left" />
            </div>
            <p onClick={() => dispatch(showFormData())}>
              <img src="/assets/form-builder/ico-title-editor.png" />
              {props.formData[0] ? `${props.formData[0].processName}` : 'Editor de formulario'}: <b>{props.formData[0] ? `${props.formData[0].title} (${props.formData[0].slug}) ${formModified ? '*': ''}` : ''}</b>
            </p>
          </div>
          <div className="form_builder__tit_role">
            <p>Rol: <b>{formMetadata.filter(f => f.name === 'roleAssignment')[0].value ? formMetadata.filter(f => f.name === 'roleAssignment')[0].value : ''}</b></p>
          </div>
        </div>
      </header>
      
        <Row style={{flexWrap: 'nowrap'}}>
        
          <Col flex="0 1 380px">
            <WidgetList 
            widgetList={widgetList} 
            formSlug={props.formData[0] ? props.formData[0].slug : ''}
            currentForm={currentForm}
            />
          </Col>
          <Col flex="auto" className="form_builder__content_wrapper" >
            {
              formWidgetsItems.length !== 0 ? (
                <div  className="form_builder__content" >
              <SortableContext  id={'centerList'}  items={formWidgetsItems} strategy={verticalListSortingStrategy}>
                {
                  formWidgetsItems.map((elem, ind) => {
                    const wid = formWidgets[ind]
                    return (
                      <Sortable key={ind} id={elem} itsNew={!!(newItem)}>
                        <CenterWidget
                          index={ind}
                          id={elem}
                          key={wid._id}
                          {...wid}
                          elem={wid}
                        />
                      </Sortable>
                    )
                  })
                }          
              </SortableContext>
                </div>

              ): (
                <SortableContext  id={'centerLiset'}  items={['no-item']} strategy={verticalListSortingStrategy}>
                  <div  className="form_builder__content" >
                  <Sortable key={1} id={'no-item'} itsNew={!!(newItem)}>
                    <p key="1"  className="form_builder__inside_empty"> Para comenzar a crear formularios, arrastre un elemento del panel izquierdo</p>
                  </Sortable>
                  </div>
                </SortableContext>
                )
            }
            <section className="form_builder__actions">
              <div style={{display: 'flex', justifyContent: 'center', flex: 4}}>
                <div key={`btn-return`} className="">
                  <a className="form_builder_back_down_button" 
                    onClick={
                      () => {
                        history.goBack()
                      }
                    }
                  ><FontAwesomeIcon icon="arrow-left" />Volver</a>
                </div>
                <button onClick={ () => {
                  dispatch(setGlobalLoader(true))
                  dispatch(updateForm(companyId, props.processSlug, props.formSlug, currentForm, formMetadata, props.rawFormData, isDraft))
                }}
                >
                  Guardar Formulario
                </button>
              </div>
            
            </section>
            
          </Col>
          
          {/* <Col flex="0 1 420px"> */}
          <Resizable 
            defaultSize={{
              width:420,
              // height:200,
            }} 
            minWidth={420}
            handleStyles={{
              left: {
                "display": "flex",
                "align-items": "center" 
              }
            }}
            handleComponent={{
              left: (<div className="resize-handler"><img src="/assets/form-builder/resize-icon.png"></img></div>)
            }}
            >
              {/* {SideProperties()} */}
            <SideProperties/>
          </Resizable>
          {/* </Col> */}
            <Helmet>
            <style>
              {`
                .desktop_layout__content {
                  padding: 0;
                  padding-top: 15vh;
                }
              `}
            </style>
          </Helmet>
        </Row>
    </div>
      <DragOverlay>
        {activeId && activeType ? newItem ? (
          <CenterWidget 
            style={{cursor: 'grabbing'}}
            index={currentForm.findIndex(f => f._id === newItem._id)} 
            id={`centerWidget-${newItem._id}`} 
            key={newItem._id} 
            {...newItem}
            elem={newItem}
          />
        ): null: null }
      </DragOverlay>
    </DndContext>
  )
}


export default FormBuilder;