
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment-timezone'
import { Table, Tabs, Col, Row } from 'antd';
import './TestDraft.scss'
import axiosBW from '../../../../../utils/Http';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HooksLog from '../../../../ChiefPages/FormList/components/HooksLog/HooksLog'

const { TabPane } = Tabs;


const TestDraft = ({ disconnectSockets, history }) => {
  const dispatch = useDispatch();
  const [cases, setCases] = useState([]);
  const workflow = useSelector(state => state.workflowDesigner.workflow)
  const companyId = useSelector(state => state.user.companyId)
  const timeZone = useSelector(state => state.user.timeZone)




  // Reemplazo de componentDidMount, el segundo argumento, dice que se actualizará solo cuando cambie "role"
  useEffect(() => {
    console.log('va el UseEffect');
    (async () => {
      try {
        const resCases = await axiosBW.get(`case/draft-cases/${workflow._id}`)
        if(resCases && resCases.data) {
  
          if(resCases.data.status === 0) {
            setCases(resCases.data.payload)
          }
        }
      } catch(ex) {
        console.log(ex);
      }
    })();
    
  }, []);

  // const dispatch = useDispatch();
  // //useSelector reemplaza el mapStateToProps
  // const users = useSelector(state => state.workflowDesigner.usersByRole);
  // const companyId = useSelector(state => state.workflowDesigner.workflow.companyId);
  
  // if (!users) {
  //   return 'No USERS'
  // }
  const columns = [
    {
      title: 'Nro.',
      dataIndex: 'caseNumber',
      key: 'caseNumber',
      render: text => <b>{text}</b>,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => new Date(b.caseNumber) - new Date(a.caseNumber),
      width: 50,
    },
    {
      title: 'Incio de caso',
      dataIndex: 'createAuditTimeStamp',
      key: 'createAuditTimeStamp',
      render: elem => moment(elem).tz(timeZone).format('YYYY-MM-DD HH:mm')
    },
    {
      title: 'Actividad actual',
      dataIndex: 'actualActivity',
      key: 'actualActivity',
    },
    {
      title: 'Usuario ejecución',
      dataIndex: 'userEmail',
      key: 'userEmail'
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: elem => elem === 'open' ? 'Abierto' : elem === 'error' ? 'Error' : 'Cerrado'
    },
    {
      title: 'Mensaje',
      dataIndex: 'errorMsg',
      key: 'errorMsg',
      ellipsis: true
    },
    {
      title: 'Ver',
      dataIndex: '',
      key: 'ver',
      width: 80,
      render: (elem, obj) => {
        if (obj.status !== 'error') {
          return(
            <div>
              <div className="home__icon--table" onClick={(e) => {
                e.preventDefault();
                disconnectSockets()
                goToCase(workflow._id, elem._id)
                } }> <FontAwesomeIcon icon="search" /> 
              </div>
            </div>
          )
        }
      }
    },
    // {
    //   title: 'Acciones',
    //   key: 'active',
    //   width:'2%',
    //   render: (value, record) => (
    //     <div className="switch">
    //       <Switch  checked={value} onChange={()=> {}} />
    //     </div>
    //   )
    // },
  ];
  const goToCase = (processId, caseId) => {
    history.push(`/servicios/caso/${processId}/${caseId}/draft`)
  }
  const locale = {
    emptyText: (
      <span>
        <p>
          No hay casos existentes
        </p>
      </span>
    )
  };
  return (
    <div className="draft-table-wrapper" >
      <Row justify="end">
        <button className="new-draft-case-button" onClick={ () => {
          history.push(`/servicios/caso/${workflow._id}/inicio/draft`)
        }}
        >
          Nuevo caso
        </button>
      </Row>
      <Col>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Casos" key="1">
          <Table pagination={{ pageSize: 6 }} locale={locale} columns={columns} dataSource={cases} />
        </TabPane>
        <TabPane tab="Traza" key="2">
          <HooksLog processSlug={workflow.slug} environment={'draft'}/>
        </TabPane>
      </Tabs>
      </Col>
    </div>
  )
}


export default TestDraft