import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { CloudServerOutlined, DashboardOutlined, CreditCardOutlined } from '@ant-design/icons';
import { Select, Modal, notification, Tabs, Progress } from 'antd';
import './MyAccount.scss'
import { countries } from './countries'
// import axios from 'axios';
// import cf from '../../../config/APIConfig'
import axiosBW from '../../../utils/Http';
import { setGlobalLoader } from '../../../actions/actions'
import FormBilling from './FormBilling';
import MyProfile from './components/MyProfile/MyProfile';
import { Helmet } from 'react-helmet';

const { TabPane } = Tabs;


class MyAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formBilling: {
        formNombre: {
          error: 0,
          errorMessage: '',
          value: '',
        },
        formApellidos: {
          error: 0,
          errorMessage: '',
          value: '',
        },
        formRut: {
          error: 0,
          errorMessage: '',
          value: '',
          validations: []
        },
        formTelefono: {
          error: 0,
          errorMessage: '',
          value: '',
        },
        formPais: {
          error: 0,
          errorMessage: '',
          value: '',
        },
        formCorreoElectronico: {
          error: 0,
          errorMessage: '',
          value: '',
        },
        formDireccion: {
          error: 0,
          errorMessage: '',
          value: '',
        },
        formRazonSocial: {
          error: 0,
          errorMessage: '',
          value: '',
        },
      },
      containsCC: false,
      creditCard: {
        last4digits: '',
        type: ''
      },
      planInfo: {
        name: '',
        limit: 0,
        price: 0,
        counters: {
          handledCases: 0,
          period: 202001,
          periodYear: 2020,
          periodMonth: 'Enero',
          usage: 0
        }
      },
      webpayUrl: '',
      webpayToken: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount () {
    if (this.props.userPermissions.some(p => p === `company-profile` || p === 'admin')) {
      await this.getCompanyInfo()
        .then(resp => {
          if (this.props.match.params.status) {
            notification.info({
              message: `Información`,
              description:
                'Se ha agregado la tarjeta correctamente.',
              placement: 'topRight'
            })
          }
          // rut validation
          this.state.formBilling.formRut.validations.push({
            name: 'rut',
            validate: (key, element) => {
              if (this.state.formBilling[key].value.length > 10) {
                this.setState({
                  [key]: { ...this.state.formBilling[key], error: 1, errorMessage: 'No puede tener más de 10 caracteres' }
                })
                return '1'
              } else {
                return '0'
              }
            }
          })
        })
        .catch(err => {
          console.log('error', err)
        })
    }
    this.props.setGlobalLoader(false)
  }
  async getCompanyInfo () {
    let resAccountInfo
    try {
      resAccountInfo = await axiosBW.get(`/companies/account-info/${this.props.companyId}`)
    } catch (ex) {
      console.log('error resAccountInfo', ex)
    }
    if (!resAccountInfo || !resAccountInfo.data) {
      Modal.error({
        title: 'Error',
        content: 'Error al Intentar Obtener datos de la cuenta. Intente nuevamente'
      })
      return
    }
    if (resAccountInfo.data.payload.creditCard) {
      this.setState({
        containsCC: true,
        creditCard: {
          last4digits: resAccountInfo.data.payload.creditCard.last4CardDigits,
          type: resAccountInfo.data.payload.creditCard.creditCardType,
        },
        planInfo: resAccountInfo.data.payload.plan ? resAccountInfo.data.payload.plan : this.state.planInfo
      })
    } else {
      const planInfo = resAccountInfo.data.payload.plan
      const today = new Date()
      const currentPeriod = today.getFullYear() * 100 + today.getMonth()
      if (currentPeriod > planInfo.counters.period) {
        // No activity in the current period.
        planInfo.counters.periodYear = today.getFullYear()
        planInfo.counters.periodMonthNum = today.getMonth()
        planInfo.counters.usage = 0
        planInfo.counters.handledCases = 0
      }
      const dateToGetMonthName = new Date(planInfo.counters.periodYear, planInfo.counters.periodMonthNum, 1)
      const periodMonth = dateToGetMonthName.toLocaleString('default', { month: 'long' })
      planInfo.counters.periodMonth = periodMonth
      this.setState({
        containsCC: false,
        creditCard: null,
        planInfo: resAccountInfo.data.payload.plan ? resAccountInfo.data.payload.plan : this.state.planInfo
      })
    }
  }

  changeForm (key, e) {
    this.setState({
      formBilling: { ...this.state.formBilling, [key]: { ...this.state.formBilling[key], value: e.target.value } }
    })
  }
  changeSelect (key, value) {
    this.setState({
      [key]: { ...this.state.formBilling[key], value: value }
    })
  }
  async handleSubmit (e) {
    e.preventDefault();
    let resInitDescription
    try {
      resInitDescription = await axiosBW.post('/payment/init-inscription/', {})
    } catch (ex) {
      console.log('error resInitDescription', ex)
    }
    if (!resInitDescription || !resInitDescription.data) {
      Modal.error({
        title: 'Error',
        content: 'Error al Comenzar la inscripción.'
      })
      return
    }
    this.setState({
      webpayUrl: resInitDescription.data.payload.urlWebpay, webpayToken: resInitDescription.data.payload.token
    })
    document.getElementById('f').submit()
  }

  async modifyBillingInfo () {
    const form = this.state.formBilling
    let resModifyBillingInfo
    try {
      resModifyBillingInfo = await axiosBW.put('/modifyBillingInfo', {
        firstName: form.formNombre.value,
        lastName: form.formApellidos.value,
        rut: form.formRut.value,
        telephone: form.formTelefono.value,
        country: form.formPais.value,
        email: form.formCorreoElectronico.value,
        address: form.formDireccion.value,
        legalName: form.formRazonSocial.value,
      })
    } catch (ex) {
      console.log('error resModifyBillingInfo', ex)
    }
    if (!resModifyBillingInfo || !resModifyBillingInfo.data) {
      Modal.error({
        title: 'Error',
        content: 'Error al modificar la información de facturación.'
      })
      return
    }
  }

  async removeCreditCard () {
    try {
      this.props.setGlobalLoader(true)
      const resRemoveCard = await axiosBW.post(`/payment/remove-card/${this.props.companyId}`, {
        last4CardDigits: this.state.creditCard.last4digits
      })
      await this.getCompanyInfo()
      this.props.setGlobalLoader(false)
      notification.info({
        message: `Información`,
        description:
          'Se ha eliminado la tarjeta de crédito correctamente.',
        placement: 'topRight'
      });
    } catch (ex) {
      this.props.setGlobalLoader(false)
      console.log('error resRemoveCard', ex)
      Modal.error({
        title: 'Error',
        content: 'Error al modificar la información de tarjeta.'
      })
    }

  }

  CompanyInfo = () => (
    <div className="myacc__wrapper">
      <main className="myacc">
        <div className="myacc__title">
          <h1> Mi Cuenta </h1>
        </div>
        <div className="myacc__separator">
          <FormBilling />
          <section className="myacc__plan_card">
            <div className="myacc__plan">
              <div className="myacc__subtitle">
                <CloudServerOutlined />
                <h2>
                  Mi Plan
                </h2>
              </div>
              <div className="myacc__plan_data_wrapper">
                <div className="myacc__plan_data">
                  <div className="myacc__pan_separator">
                    <div className="myacc__plan_type">
                      {this.state.planInfo.name}
                    </div>
                    <div className="myacc__plan_payment">
                      USD ${this.state.planInfo.price}/mes
                    </div>
                  </div>
                  <div className={`myacc__plan_upgrade`}>
                    <Link className={`${this.state.planInfo.name === 'Enterprise' ? 'myacc__plan_upgrade--disabled' : ''}`} to={`${this.state.planInfo.name !== 'Enterprise' ? '/upgradeplan/1' : '#'}`}>Actualizar</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='myacc__consumption'>
              <div className="myacc__subtitle">
                <DashboardOutlined />
                <h2>Utilización de mi plan</h2>
              </div>
              <center>
                {this.state.planInfo.counters.periodMonth} {this.state.planInfo.counters.periodYear}<br />
              </center>
              <Progress percent={this.state.planInfo.counters.usage} strokeWidth={20} status={this.state.planInfo.counters.usage >= 90 ? 'exception' : 'normal'} />
              <center>
                {this.state.planInfo.counters.handledCases} casos abiertos
                {this.state.planInfo.limit === 0 ? '. Casos ilimitados.' : ' de ' + this.state.planInfo.limit + ' casos disponibles por mes.'}
              </center>
            </div>
            <div className="myacc__card">
              <div className="myacc__subtitle">
                <CreditCardOutlined />
                <h2>
                  Información de Tarjeta de Crédito
                </h2>
              </div>
              <div className="myacc__card_wrapper">
                {
                  !this.state.containsCC ? (
                    <div className="myacc__card--nocard">
                      <div className="myacc__card__empt_text">
                        <p> Actualmente no registra tarjeta de crédito en el sistema.</p>
                      </div>
                      <div className="myacc__card_empt_separator">
                        <div className="myacc__card_empt_img">
                          <img src="/img/emptycard.png" alt="no credit card" />
                        </div>
                        <div className="myac__card_empt_btn">
                          <form onSubmit={this.handleSubmit} action={this.state.webpayUrl} id="f" name="f" method="post">
                            <input type="hidden" name="TBK_TOKEN" value={this.state.webpayToken} />
                            <button type="submit" className=""> Agregar tarjeta </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="myacc_card">
                      <div className="myacc__card_form">
                        <div className="myacc__card_element">
                          <div className="flow_form__label">
                            Número de la Tarjeta
                          </div>
                          <div className="flow_form__field cc_number">
                            <input
                              className={`flow_form__default flow_form__input_text`}
                              placeholder={`XXXX-XXXX-XXXX-${this.state.creditCard.last4digits}`}
                              disabled={true}
                              type="text"
                            />
                            <div className="cc_number__img">
                              <img src="/img/card_example.png" alt="ccname" />
                            </div>
                            <div className="set_right">
                              <button onClick={e => this.removeCreditCard()} className="billing_info__btn">
                                Eliminar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>

          </section>

        </div>
      </main>
    </div>
  )


  render () {
    return (
      <>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Mi Perfil" key="1">
            <MyProfile history={this.props.history} />
          </TabPane>
          {this.props.userPermissions.some(p => p === `company-profile` || p === 'admin') ? (
            <TabPane tab="Mi Empresa" key="2">
              {this.CompanyInfo()}
            </TabPane>

          ) : null}
        </Tabs>
        <Helmet>
          <title> Configurar mi Cuenta - Blizwork </title>
        </Helmet>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState))
})
const mstp = state => ({
  companyId: state.user.companyId,
  userPermissions: state.user.permissions
})

export default connect(mstp, mapDispatchToProps)(MyAccount)