/* eslint-disable react/display-name */
import React, { useEffect, useState, useReducer } from 'react'
import { useSelector } from "react-redux";
import { Statistic, Row, Col, Descriptions } from 'antd';
import axiosBW from '../../../../../utils/Http';
import { toast } from 'react-toastify';
import moment from 'moment'
import momentTZ from 'moment-timezone';
import './ProcessStatistics.scss'
import { LoadingOutlined } from '@ant-design/icons';

import 'moment/locale/es'  // without this line it didn't work
moment.locale('es')
momentTZ.defineLocale('es', moment.localeData()._config);
momentTZ.locale('es')

const ProcessStatistics = ({ processSlug }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [statistics, setStatistics] = useState(null);
  const companyId = useSelector(state => state.user.companyId)
  const timeZone = useSelector(state => state.user.timeZone)

  useEffect(() => {
    async function getStatistics() {
      try {
        const resp = await axiosBW.get(`/process/statistics/${companyId}/${processSlug}`);
          console.log(resp.data.payload)
          setStatistics(resp.data.payload)
      } catch (err) {
        console.log(err) 
        setError(true)
        if (err.response && err.response.data && err.response.data.message) {
          const respMsg = err.response.data.message
          toast.error(respMsg)
        }
      }
    }
    (async () => {
      setLoading(true)
      await getStatistics()
      setLoading(false)
    })();
    
    
  }, []);
  // useEffect( () => () => console.log("unmount"), [] );
  if (loading) {
    return (
      <div className="process-statistics-container">
        <LoadingOutlined className="loading"/>
      </div>
    )
  }
  if (error) {
    return (
      <div>
        No se han podido cargar los datos
      </div>
    )
  }
  return (
    <div className="process-statistics-container">
      
      <Row className="statistics-cases" gutter={16}>
        <Col span={8}>
          <Statistic title="Casos" value={statistics.totalCases} />
        </Col>
        <Col span={8}>
          <Statistic title="Abiertos" value={statistics.openCases}  />
        </Col>
        <Col span={8}>
          <Statistic title="Cerrados" value={statistics.closedCases}  />
        </Col>
      </Row>
      
      <Row  >
      
      <Col className="statistics-line" span={12}>
        <div className="statistics-label">
          Versión actual:
        </div>
        <div className="statistics-value">
          {statistics.version}
        </div>
      </Col>
      <Col className="statistics-line" span={12}>
        <div className="statistics-label">
          Borrador activo:
        </div>
        <div className="statistics-value">
          {statistics.draft ? 'Si' : 'No'}
        </div>
      </Col>
      
      <Col className="statistics-line" span={12}>
        <div className="statistics-label">
          Hooks:
        </div>
        <div className="statistics-value">
          {statistics.hooks}
        </div>
      </Col>
      <Col className="statistics-line" span={12}>
        <div className="statistics-label">
          Actividades:
        </div>
        <div className="statistics-value">
          {statistics.activities}
        </div>
      </Col>
      <Col className="statistics-line" span={12}>
        <div className="statistics-label">
          Roles:
        </div>
        <div className="statistics-value">
          {statistics.roles}
        </div>
      </Col>
      <Col className="statistics-line" span={12}>
        <div className="statistics-label">
          Usuarios:
        </div>
        <div className="statistics-value">
          {statistics.users}
        </div>
      </Col>
      <Col className="statistics-line" span={12}>
        <div className="statistics-label">
          Secciones:
        </div>
        <div className="statistics-value">
          {statistics.sections}
        </div>
      </Col>
      <Col className="statistics-line" span={12}>
        <div className="statistics-label">
          Analíticas:
        </div>
        <div className="statistics-value">
          {statistics.analytics}
        </div>
      </Col>
      <Col className="statistics-line" span={12}>
        <div className="statistics-label">
          Reportes:
        </div>
        <div className="statistics-value">
          {statistics.reports}
        </div>
      </Col>
      <Col  span={23}>
        <div className="statistics-label">
          Último caso abierto:
        </div>
        {statistics.lastOpenCase ? momentTZ(statistics.lastOpenCase).tz(timeZone).fromNow() : 'No hay registros'}
      </Col>
      <Col span={24}>
        <div className="statistics-label">
          Autor:
        </div>
        {statistics.author} ({momentTZ(statistics.authorDate).tz(timeZone).fromNow()})
      </Col>
      {/* <Descriptions
          title="Custom Size"
          size="small"
        >
          <Descriptions.Item label="Product">Cloud Database</Descriptions.Item>
          <Descriptions.Item label="Billing">Prepaid</Descriptions.Item>
        </Descriptions> */}
      </Row>
    </div>
  )
}


export default ProcessStatistics