import React from 'react' 
import { useDrag } from 'react-dnd';
import { ItemTypes } from '../../../utils/items';
import './TaskCard.scss'
const TaskCard = props => {
  // el hook useDrag le pasamos el tipo de item que va a traer y retornará extraProps y drag
  // el drag se atacha como ref a la caja que envuelve la card
  // en el item no solo pasamos el tipo, podemos pasar todas las props que queremos que viajen junto con el elemento
  const[{ isDragging }, drag] = useDrag({
    item: {
      type: ItemTypes.CARD,
      id: props._id
    },
    collect: monitor => ({
        isDragging: !!monitor.isDragging()
      })
    
  })
  return (
    <div className={`myBox ${isDragging ? 'myBox--faded': ''}`} ref={drag}>
      <div className="myBox__title">
        {props.title}
      </div>
      <div className="myBox__category">
        {props.category}
      </div>
      <div className="myBox__details">
        {props.detalis}
      </div>
    </div>
  )
}

export default TaskCard