import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import './FormFieldsList.scss'
import { ItemTypes } from '../../../../../utils/items'
import { PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {addToCurrentForm, replaceToCurrentForm } from '../../../../../actions/actions' 
import { Modal, Button } from 'antd'


const FormFieldsList = props => {
  const dispatch = useDispatch();
  let [ conflictModalShow, setConflictModalShow ] = useState(false)
  let [ oneConflictModalShow, setOneConflictModalShow ] = useState(false)
  let [ conflictItem, setConflictItem ] = useState(null)

  const addItem = (elem, addAll) => {
    dispatch(addToCurrentForm({
      type: ItemTypes.CARD,
      ico_url: elem.ico_url,
      id: elem._id,
      _id: Math.floor((Math.random() * 99999) + 1),
      title: elem.title,
      _type: elem.type,
      mandatory: elem.mandatory,
      readOnly: elem.readOnly,
      hideInOverview: elem.hideInOverview,
      name: elem.name,
      slug: elem.slug,
      defaultValue: elem.defaultValue,
      options: elem.options ? elem.options : [],
      columns: elem.columns ? elem.columns : [],
      visible: elem.visible ? elem.visible : '',
      masterDataConfig: elem.masterDataConfig
    }, props.currentForm.length, addAll))
  }
  const replaceItemForm = (elem) => {
    dispatch(replaceToCurrentForm({
      type: ItemTypes.CARD,
      ico_url: elem.ico_url,
      id: elem._id,
      _id: Math.floor((Math.random() * 99999) + 1),
      title: elem.title,
      _type: elem.type,
      mandatory: elem.mandatory,
      readOnly: elem.readOnly,
      hideInOverview: elem.hideInOverview,
      name: elem.name,
      slug: elem.slug,
      defaultValue: elem.defaultValue,
      options: elem.options ? elem.options : [],
      columns: elem.columns ? elem.columns : [],
      visible: elem.visible ? elem.visible : '',
      masterDataConfig: elem.masterDataConfig
    }))
  }

  const prevAddItem = (field) => {
    const found = props.currentForm.find( current => current.slug === field.slug)
    if (!found) {
      addItem(field)
    } else {
      setConflictItem(field)
      setOneConflictModalShow(true)
    }
  }
  const replaceItem = () => {
    // const found = props.currentForm.find( current => current.slug === conflictItem.slug)
    // props.deleteWidget(found.elem_id)
    replaceItemForm(conflictItem)
    setOneConflictModalShow(false)
  }
  const addAllItems = (fields) => {
    let conflict = false
    for (let i = 0; i < fields.length; i++) {
      const found = props.currentForm.find( current => current.slug === fields[i].slug)
      if (found) {
        conflict = true
        break;
      }
    }
    if (conflict) {
      setConflictModalShow(true)
    } else {
      for (let i = 0; i < fields.length; i++) {
        console.log(fields[i].slug)
        addItem(fields[i], true)
      }
    }
  }
  const replaceAll = () => {
    for (let i = 0; i < props.fields.length; i++) {
      const found = props.currentForm.find( current => current.slug === props.fields[i].slug)
      if (found) {
        replaceItemForm(props.fields[i])
      } else {
        addItem(props.fields[i])
      }
    }
    setConflictModalShow(false)
  }
  const replaceWithoutConflict = () => {
    for (let i = 0; i < props.fields.length; i++) {
      const found = props.currentForm.find( current => current.slug === props.fields[i].slug)
      if (!found) {
        addItem(props.fields[i])
      } 
    }
    setConflictModalShow(false)
  }
  return (
    <div className="form_widget__wrapper">
      <div className="form_list_header">
        <div className="form_list_title">
          {props.title}
        </div>
        <p className="form_list_title__actions" onClick={() => {addAllItems(props.fields)}}> Agregar todos < PlusCircleOutlined /> </p>
      </div>
      
      {
        props.fields.map((elem, ind) => {
          return (
            <div key={ind} className={`form_list_widget`} onClick={() => {prevAddItem(elem)}} >
              <img className="form_list_widget__icon" src={elem.ico_url} alt={elem.name}/>
              <div className="form_widget__title">
                 
                {elem.name} ({elem.slug})
                
                
              </div>
              <p className="form_widget__actions"><PlusOutlined /></p>
            </div>          
          )
          
        })
      }
      <Modal
        width={500}
        className="conflict-modal"
        closable={false}
        centered={true}
        visible={conflictModalShow}
        title={(<div style={{alignContent: 'center', textAlign: 'center'}}>Conflictos al agregar</div>)}
        onCancel={() => setConflictModalShow(false)}
        footer={(
          <>
            <Button  onClick={() => setConflictModalShow(false)}>
              Cancelar
            </Button>
            <Button  onClick={() => replaceAll()}>
              Reemplazar todos
            </Button>
            <Button onClick={() => replaceWithoutConflict()}>
              Agregar solo sin conflictos
            </Button>
          </>
        )}
      >
        <div className="modal-form">
          El formulario actual ya posee elementos con el mismo identificador único, que los elementos que desea agregar.
          Por favor elija una acción a realizar.
        </div>
      </Modal>
      <Modal
        width={500}
        className="conflict-modal"
        closable={false}
        centered={true}
        visible={oneConflictModalShow}
        title={(<div style={{alignContent: 'center', textAlign: 'center'}}>Conflicto al agregar</div>)}
        onCancel={() => {
          setOneConflictModalShow(false)
          setConflictItem(null)
        }}
        footer={(
          <>
            <Button  onClick={() => {
              setOneConflictModalShow(false)
              setConflictItem(null)
            }}>
              Cancelar
            </Button>
            <Button  onClick={() => replaceItem()}>
              Reemplazar
            </Button>
          </>
        )}
      >
        <div className="modal-form">
          El formulario actual ya posee un elemento con el mismo identificador único que el que desea agregar. Por favor elija una acción a realizar.
        </div>
      </Modal>
    </div>
  )
}
export default FormFieldsList