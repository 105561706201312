import React from 'react'
// Router Configs
import { Switch } from 'react-router-dom'
import RouteWithLayout from './RouteWithLayout'
// Layouts
// import LoginLayout from '../../layouts/LoginLayout'
import GuestLayout from '../../layouts/GuestLayout'
import MainLayout from '../../layouts/MainLayout'
import MainFullLayout from '../../layouts/MainFullLayout'
import FullScreenLayout from '../../layouts/FullScreenLayout'
// Pages
import Register from '../../pages/GuestPages/Register/Register'
import Invitation from '../../pages/GuestPages/Invitation/Invitation'
import CountryTaxId from '../../pages/GuestPages/CountryTaxId/CountryTaxId'
import Home from '../../pages/UserPages/Home/Home'
import Login from '../../pages/GuestPages/Login/Login'
import ForgotPassword from '../../pages/GuestPages/ForgotPassword/ForgotPassword'
import ConfirmEmail from '../../pages/GuestPages/ConfirmEmail/ConfirmEmail'
import ValidateMail from '../../pages/GuestPages/ValidateMail/ValidateMail'
import NewCase from '../../pages/UserPages/NewCase/NewCase'
import Hooks from '../../pages/UserPages/Hooks/Hooks'

import NotFound from '../../pages/GeneralPages/NotFound/NotFound'
import OverviewCase from '../../pages/UserPages/OverviewCase/OverviewCase'
import FinishCase from '../../pages/UserPages/FinishCase/FinishCase'
import Logout from '../../pages/UserPages/Logout/Logout'
import Charts from '../../pages/TestPages/Charts'
import ProcessList from '../../pages/UserPages/ProcessList/ProcessList'
import MasterDataList from '../../pages/UserPages/MasterDataList/MasterDataList'
import MasterDataTable from '../../pages/UserPages/MasterDataTable/MasterDataTable'
import MasterDataReport from '../../pages/UserPages/MasterDataReport/MasterDataReport'
import ChangePassword from '../../pages/GuestPages/ChangePassword/ChangePassword'
import InfoHall from '../../pages/HallPages/InfoHall/InfoHall'
import OnlySidebar from '../../layouts/OnlySidebar'
import MyAccount from '../../pages/ChiefPages/MyAccount/MyAccount'
import UpgradePlan from '../../pages/ChiefPages/UpgradePlan/UpgradePlan'
import Metrics from '../../pages/UserPages/Metrics/Metrics'
import Reports from '../../pages/UserPages/Metrics/Reports/Reports'
import TestAsync from '../../pages/TestPages/TestAsync'
import FormList from '../../pages/ChiefPages/FormList'
import WorkflowDesigner from '../../pages/ChiefPages/WorkflowDesigner/WorkflowDesigner'
import FormBuilder from '../../pages/ChiefPages/FormBuilder'
import newDnd from '../../pages/TestPages/newDnd/newDnd'
import FormBuilderContainer from '../../pages/ChiefPages/FormBuilder/FormBuilder.container'
import AnalyticsDesigner from '../../pages/ChiefPages/AnalyticsDesigner/AnalyticsDesigner'
import ReportsDesigner from '../../pages/ChiefPages/ReportsDesigner/ReportsDesigner'
import MyTeam from '../../pages/ChiefPages/MyTeam/MyTeam'

const RouteList = () => {
  return (
    <Switch>
      <RouteWithLayout layout={GuestLayout} exact path="/registrar" component={Register} />
      <RouteWithLayout layout={GuestLayout} exact path="/ingresar" component={Login} />
      <RouteWithLayout layout={GuestLayout} exact path="/recuperar_password" component={ForgotPassword} />
      <RouteWithLayout layout={GuestLayout} exact path="/confirmar_cuenta" component={ConfirmEmail} />
      <RouteWithLayout layout={GuestLayout} exact path="/validar_cuenta/:token" component={ValidateMail} />
      <RouteWithLayout layout={GuestLayout} exact path="/cambiar_password/:token" component={ChangePassword} />
      <RouteWithLayout layout={GuestLayout} exact path="/invitacion/:invitationId" component={Invitation} />
      <RouteWithLayout layout={GuestLayout} exact path="/ultimo_paso/" component={CountryTaxId} />
      <RouteWithLayout layout={GuestLayout} exact path="/ultimo_pasos/" component={CountryTaxId} />

      {/* <RouteWithLayout key={Math.random()*10} layout={MainLayout} exact path="/servicios/caso/:processId/:caseId/:draft?" component={NewCase} /> */}
      <RouteWithLayout key={Math.random() * 10} layout={FullScreenLayout} exact path="/servicios/caso/:processId/:caseId/:draft?" component={NewCase} />
      <RouteWithLayout layout={MainLayout} exact path="/servicios/resumen/:processId/:caseId" component={OverviewCase} />
      <RouteWithLayout layout={MainLayout} exact path="/servicios/cerrar/:caseId" component={FinishCase} />

      <RouteWithLayout layout={GuestLayout} exact path="/cerrar-sesion" component={Logout} />
      <RouteWithLayout layout={MainLayout} exact path="/" component={Home} />
      <RouteWithLayout layout={MainLayout} exact path="/to_attend/:isToAttend" component={Home} />

      <RouteWithLayout layout={MainLayout} exact path="/charts/:processId" component={Charts} />

      <RouteWithLayout layout={OnlySidebar} exact path="/hall/:companyId" component={InfoHall} />

      <RouteWithLayout layout={MainLayout} exact path="/procesos" component={ProcessList} />

      <RouteWithLayout layout={MainLayout} exact path="/hooks" component={Hooks} />

      <RouteWithLayout layout={MainLayout} exact path="/masterdata" component={MasterDataList} />
      <RouteWithLayout layout={MainLayout} exact path="/masterdata-rpt/:table" component={MasterDataReport} />
      <RouteWithLayout layout={FullScreenLayout} exact path="/masterdata/:table" component={MasterDataTable} />

      <RouteWithLayout layout={MainLayout} exact path="/micuenta" component={MyAccount} />
      <RouteWithLayout layout={MainLayout} exact path="/upgradeplan/:stage" component={UpgradePlan} />
      <RouteWithLayout layout={MainLayout} exact path="/upgradeplan/:stage/:tag/:period" component={UpgradePlan} />
      <RouteWithLayout layout={MainLayout} exact path="/micuenta/:status?" component={MyAccount} />
      <RouteWithLayout layout={MainLayout} exact path="/mi-equipo" component={MyTeam} />

      <RouteWithLayout layout={MainLayout} exact path="/metricas" component={Metrics} />
      <RouteWithLayout layout={MainLayout} exact path="/metricas/:processId/:processSlug/:goto" component={Metrics} />

      <RouteWithLayout layout={MainFullLayout} exact path="/show_metricas/:processSlug/show/:reportId" component={Reports} />

      <RouteWithLayout layout={MainLayout} exact path="/draggero" component={newDnd} />
      <RouteWithLayout layout={MainLayout} exact path="/form_builder" component={FormBuilderContainer} />

      <RouteWithLayout layout={MainLayout} exact path="/test_async" component={TestAsync} />

      <RouteWithLayout layout={MainLayout} exact path="/form-list/:processId/:processSlug" component={FormList} />
      <RouteWithLayout layout={FullScreenLayout} exact path="/workflow/:processSlug/:draft?" component={WorkflowDesigner} />

      {/* <RouteWithLayout layout={MainLayout} exact path="/form-builder/:companyId/:processSlug/:formSlug" component={FormBuilder} /> */}
      <RouteWithLayout layout={FullScreenLayout} exact path="/form-builder/:companyId/:processSlug/:formSlug/:draft?" component={FormBuilder} />
      <RouteWithLayout layout={FullScreenLayout} exact path="/analytic-designer/:flag?" component={AnalyticsDesigner} />
      <RouteWithLayout layout={FullScreenLayout} exact path="/report-designer/:flag?" component={ReportsDesigner} />

      <RouteWithLayout layout={GuestLayout} component={NotFound} />

    </Switch>
  )
}

export default RouteList
