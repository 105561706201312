import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setGlobalLoader } from '../../../actions/actions'

class ConfirmEmail extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.setGlobalLoader(false)
  }
  

  goToIndex(e) {
    e.preventDefault()
    this.props.history.push('/')
  }

  confirmEmailComponent() {
    return (
      <div className="guest__wrapper">
        <section className="register h_white_block">
          <header className="guest__title">
            <h1>¡Muchas Gracias por Registrarse!</h1>
          </header>
          <div className="guest__subtitle--nobg">
            <p>Por motivos de seguridad y verificar que su cuenta corresponde a la empresa ingresada, debe realizarse una aprobación manual. Una vez realizada, será notificado a través del correo electrónico ingresado en el formulario para activar su cuenta.</p>
          </div>
          <div className="guest__check_icon">
            <FontAwesomeIcon className="guest__check_icon--inside" icon={["far", "check-circle"]} />
          </div>
          <div className="guest__form_element button_padding">
              <button 
                className="guest__button"
                onClick={(e) => this.goToIndex(e)}
                id="register"
                ref={this.refLoginButton}
              >
                <span>Volver al Inicio</span>
              </button>
            </div>
        </section>
      </div>
    )
  }

  render() {
    if(this.props.isMobile) {
      return this.confirmEmailComponent()
    } else  {
        return (
          <>
          <div className="guest__header--desktop">
            <img src="/img/logo-mini.png" alt="blizwork logo" />
          </div>
          <div className="guest__wrapper--desktop">
            <div className="guest__side_image">

            </div>
            <div className="guest__content">
              {this.confirmEmailComponent()} 
            </div>
          </div>
          </>
        )
    }
  }
}

const mapStateToProps = (state) => ({
  isMobile: state.navigation.isMobile
})

const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail)