import React, { useState, useEffect } from 'react'
import './Tabs.scss'


/*eslint no-unused-vars: [0, { "argsIgnorePattern": "tabHeight" }]*/
const Tabs = props => {
  let active = !props.activeTab ? 0 : props.activeTab
  let [ tabHeight, setTabHeight ] = useState('300px')
  let [ currentTab, setCurrentTab ] = useState(active)
  const tabListWrapper = props.tabs ? props.tabs : []
  let tabList = []
  let count = 0
  tabListWrapper.forEach(element => {
    tabList = tabList.concat({...element, id: count})
    count +=1
  })
  useEffect(()=> {
    let height = 0
    if(document.querySelectorAll('.tabs__head')[0] && document.querySelectorAll('.tab__content')[currentTab]) {
      height = document.querySelectorAll('.tabs__head')[0].clientHeight + document.querySelectorAll('.tab__content')[currentTab].clientHeight
    } else {
      height = 0
    }
    setTabHeight(`${height}px`)
  }, [currentTab])
  return (
    <div className={`tabs__wrapper`}>
      <section className="tabs">
      <div className="tabs__head" style={props.headStyle ? props.headStyle : undefined}>
        {tabList.map((tab, i) =>  {
          return (
            <div className={`tab__title${tab.id === currentTab ? ' tab__title--selected' : ''}`} onClick={() => {
              setCurrentTab(tab.id)
              if(props.setter) {
                props.setter(tab.id)
              }
              if(props.onClickTab) {
                return props.onClickTab
              }
            }
              }
             key={i}>
              {tab.title}
            </div>
          )
        })}
      </div>
      <div className={`tabs__content`}>
      {tabList.map((tab, i) =>  {
          return (
            <div className={`tab__content${tab.id === currentTab ? ' tab__viewer' : ' tab__hider'}`} key={i}>
              {tab.content}
            </div>
          )
        })}
      </div>
      </section>
    </div>
  )
}

export default Tabs