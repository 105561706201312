import React, { Component } from 'react'
import { DeleteOutlined } from '@ant-design/icons';
import { Table as AntdTable } from 'antd';
import { connect } from 'react-redux'
import { removeRowToTableWidget } from '../../../../actions/actions';
import './TableWidget.scss'

class TableWidget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [],
      dataSource: []
    }
  }

  componentDidMount() {
    const removeCol = {
      title: (<DeleteOutlined />),
      width: '7%',
      render: (t, r, i) => {
        return <div> <button className="table_widget__delete" onClick={e => this.props.removeRowToTableWidget(this.props.slug, i)}> <DeleteOutlined /> </button> </div>
      }
    }

    const formattedCols = this.props.columns
    if (!this.props.hideDeleteButton && !this.props.disableTableFields) {
      formattedCols.push(removeCol)
    }
    this.setState({
      columns: this.props.disableTableFields ? this.props.columns : formattedCols,
      dataSource: this.props.dataSource
    })
  }

  render() {
    return (
      <AntdTable tableLayout="fixed" pagination={false} dataSource={this.props.dataSource} columns={this.state.columns} locale={{emptyText: 'Actualmente no existen datos en esta tabla',}} />
    )
  }
}

const mapStateToProps = state => {
  return {

  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeRowToTableWidget: (tableSlug, rowIndex) => dispatch(removeRowToTableWidget(tableSlug, rowIndex))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableWidget)