import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import ZingChart from 'zingchart-react'
import axios from 'axios'
// import cf from '../../config/APIConfig'
import axiosBW from '../../utils/Http';
import './Charts.scss'
import { Link } from 'react-router-dom'
import { setGlobalLoader } from '../../actions/actions'
import { Helmet } from 'react-helmet';
import { Modal, Row, Col, Divider } from 'antd';
import { LineChartOutlined } from '@ant-design/icons';


class Charts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      metricsList: [],
      testFormat: {},
      processList: {},
      testMetricsList: {},
      allSeries: {}
    }
    this.formRefs = {}
  }

  async componentDidMount() {
    try {
      const path = this.props.processId
      let resDashboardDef
      try {
        resDashboardDef = await axiosBW.get(`/dashboard/definition/${path}`)
      } catch (ex) {
        console.log('Error resDashboardDef', ex)
      }
      if(!resDashboardDef || !resDashboardDef.data) {
        Modal.error({
          title: 'Error',
          content: `Error al cargar la info del Hall`,
        })
        return
      }
        if(resDashboardDef.data.payload) {
          this.setState({
            processList: resDashboardDef.data.payload
          }, async () => {
            if (this.state.processList.analytics && this.state.processList.analytics.length > 0) {
              let newMetricsList = {...this.state.processList}
              const allSeries = {}
              for (let i = 0; i < newMetricsList.analytics.length; i++) {
                const elem = newMetricsList.analytics[i]
                this.formRefs[elem._id] = createRef()
                allSeries[elem._id] = {separators: [], series: []}
              //   this.setState({
              //     allSeries: {...this.state.allSeries, [elem._id]: {separators: [], series: []}}
              //   }, () => {
              //     console.log('allserieeee!', this.state.allSeries)
              // })
                elem.parameters.series = []
              }
              this.setState({
                processList: newMetricsList,
                allSeries: allSeries
                }, () => {
                  console.log('minuevo!', this.state.processList)
              })
              for (let i = 0; i < this.state.processList.analytics.length; i++) {
                const element = this.state.processList.analytics[i];
                if (element.type !== 'separator') {
                  this.getChartDatasource(element.dataSource,element.type,i)
                }
                
              }
            }
          })
        }

    } catch (error) {
      console.log('error', error)
    }
    
    
  }

  async getChartDatasource(url, type, index) {
    // const newSeries = []
    const elem = this.state.processList
    const respuesta = await axios.get(url, {
      timeout: 10*60*1000,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      }
    })
    if(type === 'pie') {
      let fakeDatasource = respuesta.data.payload
      let newSeries = elem.analytics[index].parameters ? elem.analytics[index].parameters.series : []
      fakeDatasource.data.forEach(l => {
        newSeries.push({
          "values": l.values.map(v=> parseFloat(v)),
          "text": l.key
        })
      })
      elem.analytics[index].parameters.series = newSeries
    } else if (type === 'bar' || type === 'hbar') {
      let fakeDatasource = respuesta.data.payload
      let newSeries = elem.analytics[index].parameters ? elem.analytics[index].parameters.series : []
      fakeDatasource.data.forEach(l => {
        newSeries.push({
          "values": l.values.map(v=> parseFloat(v)),
          "text": l.key
        })
      })
      elem.analytics[index].parameters.series = newSeries
      
      if(fakeDatasource.separator && fakeDatasource.separator.length > 0) {
        elem.analytics[index].parameters.scaleX = {
          labels: fakeDatasource.separator
        }
      } 
    } else if (type === 'line') {
      let fakeDatasource = respuesta.data.payload
      let newSeries = elem.analytics[index].parameters ? elem.analytics[index].parameters.series : []
      fakeDatasource.data.forEach(l => {
        newSeries.push({
          "text": l.key,
          "values": l.values.map(v=> parseFloat(v))
        })
      })
      elem.analytics[index].parameters.series = newSeries
      
      if(fakeDatasource.separator && fakeDatasource.separator.length > 0) {
        elem.analytics[index].parameters.scaleX.labels = fakeDatasource.separator
      } 
    } else if (type === 'simple') {
      let newSeries = elem.analytics[index].parameters ? elem.analytics[index].parameters.series : []
      let fakeDatasource = respuesta.data.payload
      fakeDatasource.data.forEach(l => {
        newSeries.push({
          "values": l.values
        })
      })
      elem.analytics[index].parameters.series = newSeries
    } else if(type === 'gauge') {
      let fakeDatasource = respuesta.data.payload
      let newSeries = elem.analytics[index].parameters ? elem.analytics[index].parameters.series : []
      fakeDatasource.data.forEach(l => {
        newSeries.push({
          "values": [l.values],
          "text": l.key
        })
      })
      elem.analytics[index].parameters.series = newSeries
    }
    if (this.formRefs[elem.analytics[index]._id].current !== null) {
      
      this.formRefs[elem.analytics[index]._id].current.modify({data: elem.analytics[index].parameters})
    }
    // [elem.analytics[index]._id].current.addPlot({
    //   data: elem.analytics[index].parameters.series
    // })
    this.setState({
      processList: elem
    })
  }

  returnWidget(flow) {
    switch(flow.analytics[0].parameters.type) {
      case 'pie': {
        return flow.analytics[0].parameters
      }
      default: {
        break
      }
    }
  } 
  

  render() {
      
    return (
      <>
        <div className="metrics_wrapper">
          <section className="metrics">
            <div className="metrics__title">
              <h1>Panel de Métricas {this.props.metricsName ? ` para ${this.props.metricsName} ` : ``}</h1>
            </div>
            {!this.state.processList || !this.state.processList.analytics || this.state.processList.analytics.length <= 0 ? (
              <div className="h_error">
                No existen métricas creadas para este proceso
              </div>
            ) : ''}
            <div  >
              <Row className="chart_wrapper" type="flex" justify="space-around" gutter={[0, 24]}>
                {
                  this.state.processList && this.state.processList.analytics? this.state.processList.analytics.map((e, i) => {
                    // console.log('AAAAAL',this.state.allSeries[e._id]);
                    if (e.type === 'separator') {
                      return (
                        <Divider style={{borderColor: 'black'}}>{e.name}</Divider>
                      )
                    }
                    if (e.type !== "simple") {
                      if (e.parameters.legend) {
                        e.parameters.legend.header = {
                          text: 'Legend'
                        }
                        e.parameters.legend['alpha'] = 0.5
                        e.parameters.legend.minimize = true
                        e.parameters.legend.draggable = true 
                        e.parameters.legend['drag-handler'] = 'icon'
                        e.parameters.legend.align = 'right'
                        e.parameters.legend.layout = undefined
                        e.parameters.legend.overflow = 'scroll'
                        e.parameters.legend['max-items'] = 5
                      }
                      e.parameters.title['font-size'] = 14
                      e.parameters.title.align = 'left'
                      e.parameters.subtitle.align = 'left'
                      e.parameters.noData = {
                        text: 'Cargando información...',
                        fontSize: 18
                      }
                    }
                    
                    return (
                      <>
                        <Col key={`charter-col-${i}`} flex="400px" style="">
                        {
                          e.type !== "simple" ?
                            (
                              <div key={`charter-zing-${i}`} className="chart_elem" style={{width: '100%', height: '300px', border: '3px solid white'}}>
                                <ZingChart ref={this.formRefs[e._id]} width="100%" height="100%" data={e.parameters} series={e.parameters.series}   />
                              </div>
                            )
                            :
                            (
                              <div key={`charter-simple-${i}`} className="simple_analytic" style={{width: "100%"}}>
                                <div className="simple_analytic_image">
                                  {
                                    // <img src={e.parameters.imgPath} />
                                    <LineChartOutlined />
                                  }
                                </div>
                                <div className="simple_analytic_data">
                                  <div className="simple_analytic_number">
                                    {e.parameters.series[0] ? e.parameters.series[0].values[0]: '0'}
                                  </div>
                                  <div className="simple_analytic_text">
                                    {e.parameters.title}
                                  </div>
                                </div>
                              </div>
                            )
                        }
                        </Col>
                      </>
                    )
                  }) : ''
                }
              </Row>
            </div>
          </section>
        </div>
        <div className="h_go_back"><Link to="/procesos">Volver a Procesos</Link></div>
        <Helmet>
          <title> Gráficas - Blizwork</title>
        </Helmet>
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState))
})

export default connect(null, mapDispatchToProps)(Charts)