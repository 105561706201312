import {
  GET_MY_TEAM_INFO_BEGIN,
  GET_MY_TEAM_INFO_SUCCESS,
  GET_MY_TEAM_INFO_FAILURE
} from '../actions/MyTeamActions';



const initialState = {
  loading: false,
  error: null,
  usersList: [],
  requestsList: []
};



const myTeam = (state = initialState, action) => {
  switch(action.type) {
    case GET_MY_TEAM_INFO_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case GET_MY_TEAM_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        usersList: action.payload.usersList,
        requestsList: action.payload.requestsList
      };
    }

    case GET_MY_TEAM_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        usersList: [],
        requestsList: []
      };
    default: {
      return {...state}
    }
  }
}

export default myTeam