import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import { connect } from 'react-redux'
import './Modal.scss'
import { CloseOutlined } from '@ant-design/icons';
import { Collapsed, DissapearFlex } from '../../_globals/animations'

class Modal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.refModal = React.createRef()
  }

  componentDidMount() {}
  
  render() {
    const title = this.props.title ? this.props.title : ''
    return createPortal(
      <>
        <DissapearFlex
          className="w_ft-modal"
          pose={this.props.isVisible ? 'visible' : 'hidden'}
          ref={this.refModal}
          onMouseDown={e => {
            if(e.target === this.refModal.current) {
              this.props.onCancel(e)
            }
          }}
        >
          <Collapsed
            pose={this.props.isVisible ? 'visible' : 'hidden'}
            className="ft-modal"
          >
            <div className="ft-modal-2">
              <div className="ft-modal__title">
                <h2> {title} </h2>
                <div
                  className="ft-modal__cross"
                  onClick={
                    e => {
                      this.props.onCancel(e)
                    }
                  }
                >
                  <CloseOutlined />
                </div>
              </div>
              <div className="ft-modal__content">{this.props.children}</div>
            </div>
          </Collapsed>
        </DissapearFlex>
      </>,
      document.getElementById('firstLineContent')
    );
  }
}

export default connect()(Modal)
