import { library } from '@fortawesome/fontawesome-svg-core'
import { faLinkedin, faLinkedinIn, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faEye, faEyeSlash, faCheckCircle, faSearch, faArrowLeft, faPaperclip, faPlusCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle as faCheckCircleRegular, faTimesCircle as faTimesCircleRegular } from '@fortawesome/free-regular-svg-icons'

export const iconLibrary = () => {
  return library.add(
    faCheckSquare, 
    faCoffee,
    faEye,
    faEyeSlash,
    faLinkedin,
    faLinkedinIn,
    faGoogle,
    faCheckCircle,
    faCheckCircleRegular,
    faTimesCircleRegular,
    faSearch,
    faArrowLeft,
    faPaperclip,
    faPlusCircle,
    faExclamationTriangle,
  )
}