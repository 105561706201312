import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RightCircleOutlined, DownCircleOutlined, ShrinkOutlined, ArrowsAltOutlined } from '@ant-design/icons';
import { Badge } from 'antd'
import { socket } from '../../../../utils/Socket'
import axiosBW from '../../../../utils/Http';

import './HorizontalCollapse.scss'

const HorizontalCollapse = ({title, content, collapsed, disabled, messaging, markAsRead, newMessages, index, formColors, fullScr }) => {

  const [collapsedStatus, setCollapsedStatus] = useState(collapsed || false);
  const [fullScreen, setFullScreenStatus] = useState(fullScr)

  const borderStyle = index === 0 && formColors.primaryColor !== '' ? { borderTop: `3px solid ${formColors.primaryColor}` }: null
  const borderCollapsedStyle = index === 0 && formColors.primaryColor !== '' ? { borderTop: `2px solid ${formColors.primaryColor}` }: null

  const setCollapse = () => {
    if (index > 0 && index < 5) {
      updateCollapseStatus(!collapsedStatus)
    }
    setCollapsedStatus(!collapsedStatus)
    if (messaging && !collapsedStatus && socket) {
      markAsRead()
    }
  }

  const updateCollapseStatus = async (status) => {
    try {
      await axiosBW.post('/users/change-case-panel-collapse/', {
        collapse: status,
        index
      })
    } catch (error) {
      console.log(error)
    }
  }

  const setFullScreen = () => {
    setFullScreenStatus(!fullScreen)
  }


  useEffect (()=> {
    if (messaging && !disabled && !collapsedStatus && socket) {
      markAsRead()
    }
  }, [newMessages])

  if (disabled) {
    return (
      <div className="hori-coll-container_collapsed_disabled">
        <div className="hori-coll_header_collapsed">
          <div className="hori-coll_header_icon_collapsed_disabled" >
            <DownCircleOutlined />
          </div>
          <div className="hori-coll_header_title_collapsed">
            {title}
          </div>
        </div>
      </div>
    )
  }

  if (collapsedStatus) {
    return (
      <div style={borderCollapsedStyle} className={`hori-coll-container_collapsed ${index === 0 ? 'form-pane': ''}`}>
        <div className={`hori-coll_header_collapsed ${index === 0 ? 'form-pane': ''}`}>
          <div className="hori-coll_header_icon_collapsed" onClick={() => setCollapse()}>
            <DownCircleOutlined />
          </div>
          {messaging ? (
            <Badge  count={newMessages}>
              <div className="hori-coll_header_title_collapsed">
                {title}
              </div>
            </Badge>
          ): (
            <div className="hori-coll_header_title_collapsed">
                {title}
              </div>
          ) }
        </div>
      </div>
    )
  }

  return (
      <div className={`${index === 0 ? (fullScreen ? 'hori-coll-container-fullscr' : 'hori-coll-container-main') : 'hori-coll-container'}`}>
        {/* <div className={`hori-coll_header ${index === 0 ? 'form-pane': ''}`} style={index === 0 && formColors.primaryColor !== '' ? { borderTop: `3px solid ${formColors.primaryColor} !important` }: {}}> */}
        <div style={borderStyle} className={`hori-coll_header ${index === 0 ? 'form-pane': ''}`} >
          <div className="hori-coll_header_icon" onClick={() => setCollapse()}>
            <RightCircleOutlined />
          </div>
          <div className="hori-coll_header_title">
            {title}
          </div>
          {
            index == 0 ? 
              <div style={{marginLeft: 'auto', marginRight: '0', cursor: 'pointer'}} onClick={() => setFullScreen()}>
                {fullScreen ? <ShrinkOutlined />: <ArrowsAltOutlined />}
              </div>
            : ''
          }
        </div>
        <div className="hori-coll_content">
          {content}
        </div>
      </div>
  )
}

export default HorizontalCollapse
