/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  // Switch,
  Route,
  useParams
} from "react-router-dom";
import axiosBW from '../../../../../utils/Http';
import { toast } from 'react-toastify';
// import Charts from '../../../TestPages/Charts';
// import AnalyticChart from '../AnalyticChart/AnalyticChart';
import { Table, Row, Col, Modal, Input, Collapse, Select, Tabs, InputNumber, Space, Switch, Button, Upload, Tooltip } from 'antd';
import Editor from "react-simple-code-editor";
import { PlusCircleOutlined, LoadingOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setGlobalLoader } from '../../../../../actions/actions'
// import HooksLogDetail from '../HooksLogDetail/HooksLogDetail'
import moment from 'moment'
import './HooksStore.scss'


const HooksStore = (props) => {

  console.log({props})
  // console.log({languages})
  const dispatch = useDispatch();
  dispatch(setGlobalLoader(false))



  useEffect(() => {
    console.log('va el hooks-log');
    (async () => {
    })();
    
  }, []);

  return (
    <div className="hooks-store-container">
        Pronto...
    </div>
  )
}


export default HooksStore