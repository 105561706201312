import React, { useState } from 'react' 
import { Link } from 'react-router-dom'
import { Modal, Switch, Tabs } from 'antd';
import HooksLog from './components/HooksLog/HooksLog'
import AnalyticsList from './components/AnalyticsList/AnalyticsList'
import CustomListField from './components/CustomListField/CustomListField'
import ReportList from './components/ReportsList/ReportList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FormList.scss'

const { TabPane } = Tabs;

const FormList = (props) => {
  console.log(props.userPermissions)
  const [showModal, setModal] = useState(false)
  const canEdit = props.userPermissions.some(p => p === `form-edit|${props.processSlug}` || p === 'admin')
  const canAditionalData = props.userPermissions.some(p => p === `process-aditional-data|${props.processSlug}` || p === 'admin')
  const canAnalytics = props.userPermissions.some(p => p === `process-analytics-edit|${props.processSlug}` || p === 'admin')
  const canTraceAudit = props.userPermissions.some(p => p === `process-trace-audit|${props.processSlug}` || p === 'admin')
  const canVersions = props.userPermissions.some(p => p === `process-versions|${props.processSlug}` || p === 'admin')
  const changeForm = (e, id) => {
    e.preventDefault()
    props.selectForm(id)
  }
  const processSlug = props.processSlug
  return (
    <>
      <div className="form_list__wrapper">
        <section className="form_list">
          <div className="process_list">
            <header className="process_list__title">
              <div className="process_list__left">
                {/* <div className="process_list__icon">
                  <img src="/img/ico-title-flujo.png" alt="ico" />
                </div> */}
                <div className="form_list__back_arrow" onClick={(e) => {
                    props.goBack()
                  }}>
                  <FontAwesomeIcon icon="arrow-left" />
                </div>
                <div className="process_list__title_text">
                  <h1>{props.processTitle} ({props.processSlug}) | v.{props.processVersion} ({props.existDraft ? 'con borrador pendiente': 'sin borrador'})</h1>
                </div>
              </div>
              {canVersions ? (
                <div className="process_list__right">
                  Diseñador de flujos (Beta) 
                  <Link to={`/workflow/${processSlug}`}>
                    <Switch checked={false} />
                  </Link>
                </div>
              ) : ''}
            </header>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Formularios" key="1">
                <main className="process_list__main">
                  {props.formList.length <= 0 ? (
                          <div className="h_error">
                            No existen formularios creados para este proceso
                          </div>
                  ) : (<div className="process_list__table_wrapper">
                    <table className="process_list__table">
                      <thead>
                        <tr>
                          <td>Título</td>
                          <td>Rol</td>
                          <td>Slug</td>
                          <td>Tiempo Definido</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        
                        {props.formList.map((e, i) => {
                          
                          return (
                            <tr key={`table_type_${i}`}>
                              <td>{e.title}</td>
                              <td>{e.roleAssignment}</td>
                              <td>{e.slug}</td>
                              <td>{e.actionOnTimeout ? e.actionOnTimeout.timeout : '0'} hrs</td>
                              <td>
                                <div className="process_list__actions">
                                  {canEdit ? (
                                    <div className="table_element table_element--icon">
                                      <Link onClick={(evt) => changeForm(evt, e._id)} to={`/form-builder/${e._id}`}>
                                        <img src="/img/ico-edit.png" alt="Dashboards" />
                                      </Link>
                                    </div>
                                  ): ''}
                                  
                                  {/* <div className="table_element table_element--icon h_blocked">
                                    <img src="/img/ico-delete.png" alt="Delete" />
                                  </div> */}
                                </div>
                              </td>
                            </tr>
                          )
                          
                        })}
                      </tbody>
                    </table>
                  </div>
                  )}
                </main>
              </TabPane>
              {canAditionalData ? (
                <TabPane tab="Datos Adicionales" key="2">
                  <CustomListField processSlug={processSlug} environment={'production'}/>
                </TabPane>
              ) : ''}
              {canAnalytics ? (
                <TabPane tab="Analíticas" key="3">
                  <AnalyticsList processSlug={processSlug} processTitle={props.processTitle} history={props.history} />
                </TabPane>
              ) : ''}
              <TabPane tab="Reportes" key="4">
                <ReportList processSlug={processSlug} processTitle={props.processTitle} history={props.history} />
              </TabPane>
              {canTraceAudit ? (
                <TabPane tab="Traza" key="5">
                  <HooksLog processSlug={processSlug} environment={'production'}/>
                </TabPane>
              ) : ''}
            </Tabs>
          </div>

        </section>
      </div>
      <Modal
        title="Crear nuevo formulario"
        visible={props.showModalNew}
        onOk={e => {
          props.createNewForm(props.currentForm)
        }}
        onCancel={e => {props.setModalNew(false)}}
      >
        <div className="modal__new_form">
          <div className="modal__new_form_title">
            <h2> Nombre de formulario (slug) </h2>
          </div>
          <input value={props.newFormSlug} onChange={e => props.changeFormSlug(e.target.value)} />
          <div className="modall__new_form_message">
            {!props.newFormSlug || props.newFormSlug.length <= 0 ? (
              <p> Campo obligatorio </p>
            ) : '' }
          </div>
        </div>
      </Modal>
    </>
  )
}

export default FormList