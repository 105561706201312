import axios from 'axios'
import cf from '../config/APIConfig'

export default axios.create({
  baseURL: cf.BASE_URL.url,
  timeout: 30000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  },
})

