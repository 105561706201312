import React from 'react'
import { connect } from 'react-redux'
import { setGlobalLoader } from '../../../actions/actions'

const NotFound = (props) => {
  props.setGlobalLoader(false)
  return (
    <div> 
      <h1>UPS! Al parecer a la ruta que intentas acceder no existe</h1>
      {props.location.state ? (
        <div className="not_fount__message"> 
           {props.location.state.message}
        </div>
      ) : ''}
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState))
})

export default connect(null, mapDispatchToProps)(NotFound)