import React from 'react'
import { notification, Select, DatePicker } from 'antd'
import ReactMarkdown from 'react-markdown'

const Option = Select.Option

// handle input changes
export const inputChange = (state, key, value) => {
  state.setState({
    [key]: value
  })
}

// handle notifications
export const notificationHandler = (
  title,
  msg,
  type
) => {
  notification[type]({
    message: title,
    description: msg,
  })
}

// handle notifications for validations
export const notificationValidationHandler = (
  msg,
) => {
  notification.open({
    description: msg,
    style: {
      backgroundColor: '#FAE0E1',
      borderColor: '#F8D1D5',
      color: '#842B2E',
      borderTopRightRadius: '7px',
      borderTopLeftRadius: '7px',
      borderBottomRightRadius: '7px',
      borderBottomLeftRadius: '7px',
      width: '320px',
      height: '46px',
      lineHeight: 0,
    },
  })
}

// if a array that we need to show elements at table, contains children arrays, create an object for every children in specific children
//export const cleanArray = (array, stringChildren) => {

//}


export const getFieldByObj = (obj, functionUpdate) => {
  switch(obj.type) {
    case 'text': {
      return (
      <div className="flow_form__wrapper">
        <div className="flow_form__label">
          {obj.name}
        </div>
        <div className="flow_form__field">
          <input 
          id={obj._id} 
          className="flow_form__default flow_form__input_text"
          type="text" 
          name={obj.name} 
          onChange={e => {console.log(e.target.value)}} 
          value={obj.defaultValue}
          disabled={obj.readOnly}
        />
        </div>
      </div>
      )
    }
    case 'select': {
      return (
        <div className="flow_form__wrapper">
          <div className="flow_form__label">
            {obj.name}
          </div>
          <div className="flow_form__field">
            <Select 
              id={obj._id} 
              className="flow_form flow_form__select"
              name={obj.name}
              onChange={(value) => console.log(value)}
              disabled={obj.readOnly}
            >
              {
                obj.options ? (
                  obj.options.map((elem, index) => {
                    return (
                      <Option key={`optSel-${index}`} value={elem.key}>
                        {elem.value}
                      </Option>
                    )
                  })
                ) : ''
              }
            </Select>
          </div>
        </div>
      )
    }
    case 'datepicker': {
      return (
        <div className="flow_form__wrapper">
          <div className="flow_form__label">
            {obj.name}
          </div>
          <div className="flow_form__field">
            <DatePicker
              allowClear={true}
              className="flow_form flow_form__datepicker"
              defaultValue={null}
              format={'YYYY-MM-DD'}
              name={obj.name}
              onChange={date => console.log(date)}
              disabled={obj.readOnly}
              placeholder=""
            />
          </div>
        </div>
      )
    }
    case 'textarea': {
      return (
        <div className="flow_form__wrapper">
          <div className="flow_form__label">
            {obj.name}
          </div>
          <div className="flow_form__field">
            <textarea 
              className="flow_form__default flow_form__textarea" 
              name={obj.name}
              disabled={obj.readOnly}
              onChange={e => {functionUpdate(obj.name, e.target.value)}} 
            >
            
            </textarea>
          </div>
        </div>
      )
    }
    case 'markdown': {
      return (
        <ReactMarkdown className="flow_form__markdown" source={obj.defaultValue} />
      )
    }
    case 'attachment': {
      return (
        <div className="attachment_field">
          <input type="file" value={obj.defaultValue} />
          <button>Examinar...</button>
          <label>{obj.name}</label>
        </div>

      )
    }
    default: {
      return (
        <div className="no_flow_element">No ha seleccionado un elemento de formulario válido</div>
      )
    }
      
  }
}
// validate chilean rut
export const validaRut = rut => {
  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rut)) return false;
  let tmp = rut.split("-");
  let digv = tmp[1].toLowerCase()
  let rutWithoutDv = tmp[0];

  const dv = rutWithoutDv => {
    let sum = 0;
    let reversedRut = rutWithoutDv.split('').reverse();

    for (let i = 0, j = 2; i < reversedRut.length; i++, j < 7 ? j++ : j = 2) {
      sum += reversedRut[i] * j;
    }

    let res = 11 - (sum % 11)
    if (res === 11) return '0';
    if (res === 10) return 'k';
    return String(res);
  }

  return dv(rutWithoutDv) === digv
  
}

/**
 * Returns the current local thounsands separator.
 */
export const getThounsandsSeparator = () => {
  const testNumber = 10000.1
  return Intl.NumberFormat()
    .formatToParts(testNumber)
    .find(part => part.type === 'group')
    .value
}

export const getDecimalSeparator = () => {
  const thounsandsSeparator = getThounsandsSeparator()
  if (thounsandsSeparator === ',') {
    return '.'
  } else {
    return ','
  }
}

/**
 * Returns a string with the thounsands separators removed.
 */
export const removeThounsandsSeparator = (someValue) => {
  if (typeof someValue === 'string') {
    return someValue.split(getThounsandsSeparator()).join('')
  }
  return someValue
}

/**
 * Formats a number according to the current locale.
 */
export const formatNumber = (someValue, decimals=0) => {
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;
  // console.log(`userLocale: ${userLocale}`)
  // console.log(`someValue: ${someValue}`)
  let cleanString = removeThounsandsSeparator(someValue)
  // console.log(`cleanString: ${cleanString}`)
  // console.log(`Decimal separator: ${getDecimalSeparator()}`)
  return Intl.NumberFormat(userLocale, {minimumFractionDigits: decimals}).format(cleanString)
}

/**
 * Clears number formatting from table widget
 */
export const tableClearNumberFormat = (tableWidget, componentProps) => {
  const tableValues = componentProps.tableWidgetList.find(d => d.slug === tableWidget.slug)
  tableValues.data.forEach(row => {
    for (const key in row) {
      if (Object.prototype.hasOwnProperty.call(row, key) && key !== 'key') {
        const value = row[key]
        if (typeof value === 'string') {
          const column = tableWidget.columns.find(c => c.slug === key)
          if (column && column.numberFormat) {
            const newValue = Number(removeThounsandsSeparator(value))
            row[key] = newValue
          }
        }
      }
    }
  })     
}