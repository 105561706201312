import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import { inputChange } from '../../../utils/Helpers'

import './ForgotPassword.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import validations from '../Login_old/Login.Validations'
// import Axios from 'axios';
// import cf from '../../../config/APIConfig'
import axiosBW from '../../../utils/Http'
import { setGlobalLoader } from '../../../actions/actions'
import { Helmet } from 'react-helmet';


class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formEmail: '',
      formEmailError: {
        title: '',
        description: '',
        icon: '',
      },
      resetPasswordMessage: {
        icon: '',
        description: ``,
      },
      currStep: '1'
    }

    // form refs
  }
  componentDidMount() {
    this.props.setGlobalLoader(false)
  }
  
  goToIndex(e) {
    e.preventDefault()
    this.props.history.push('/')
  }

  doForgotPassword(e) {
    e.preventDefault()
    console.log('wut')
    let value = 'wrong'
    if(validations.validateEmail(this.state.formEmail).error !== 0) {
      value = 'wrong'
      this.setState({
        formEmailError: {
          title: 'Correo Inválido',
          description: 'El formato de correo electrónico ingresado es incorrecto',
          icon: <FontAwesomeIcon icon="exclamation-triangle" />
        }
      })
    } else {
      value = 'ok'
      this.setState({
        formEmailError: {
          title: '',
          description: '',
          icon: ''
        }
      })
    }

    if(value !== 'ok') {
      return true
    } else {
      axiosBW.post('/auth/reset-password-generate', {
          email: this.state.formEmail
      })
      .then(res => {
        if(res.data.payload && res.data.payload.status) {
          this.setState({
            currStep: '2',
            resetPasswordMessage: {
              icon: <FontAwesomeIcon className="guest__check_icon--inside" icon={["far", "check-circle"]} />,
              description: `Ha sido enviado un mensaje al correo ${this.state.formEmail} con la información necesaria para Restablecer su contraseña.`,
            } 
            
          })
        } else {
          this.setState({
            currStep: '2',
            resetPasswordMessage: {
              icon: <FontAwesomeIcon className="guest__check_icon--inside" icon="exclamation-triangle" />,
              description: `Hubo un error al intentar restablecer la contraseña. Intente nuevamente`,
            } 
          })
        }
        
      })
      .catch(err => {
        console.log('error', err)
        // console.log('error', err.response.data.message)
        if(err.response && err.response.data && err.response.data.message && err.response.data.message === 'No user') {
          this.setState({
            currStep: '2',
            resetPasswordMessage: {
              icon: <FontAwesomeIcon className="guest__check_icon--inside" icon="exclamation-triangle" />,
              description: `Hubo un error al intentar restablecer la contraseña. El correo ingresado no posee una cuenta o aún no ha sido habilitada`,
            } 
          })
        } else {
          this.setState({
            currStep: '2',
            resetPasswordMessage: {
              icon: <FontAwesomeIcon className="guest__check_icon--inside" icon="exclamation-triangle" />,
              description: `Hubo un error al intentar restablecer la contraseña. Intente nuevamente`,
            } 
          })
        }
        
      })
      
    }
  }

  ForgotPasswordComponent() {
    return (
      <div className="guest__wrapper">
        <section className="forgot">
          <header className="guest__title">
            <h1> Restablecer Contraseña </h1>
          </header>
          <form className="guest__form" noValidate>
            <div className="guest__form_element">
              <label className="guest__form_label"> Ingrese Su Correo Electrónico </label>
              <input 
                className="guest__form_input" 
                onChange={(e) => inputChange(this, 'formEmail', e.target.value)} 
                ref={this.refEmail}
                type="email" 
                value={this.state.formEmail} 
              />
              {this.state.formEmailError.title.length > 0 ? (
                <>
                  <div className={`guest__message_form`}>
                    <h2>{this.state.formEmailError.icon} {this.state.formEmailError.title}</h2>
                    <p>{this.state.formEmailError.description}</p>
                  </div>
                </>
              ): ''}
            </div>
            <div className="guest__form_element">
              <button 
                className="guest__button button_padding"
                onClick={(e) => this.doForgotPassword(e, this.state.formEmail)}
                ref={this.refLoginButton}
              >
                <span>Restablecer mi Contraseña</span>
              </button>
            </div>
          </form>
        
        </section>
        <Helmet>
          <title>Olvidé mi Contraseña - Blizwork</title>
        </Helmet>
      </div>
    )
  }

  SuccessMessageComponent() {
    return (
      <section className="register h_white_block">
          <header className="guest__title">
            <h1>Restablecer Contraseña</h1>
          </header>
          <div className="guest__subtitle--nobg">
            <p>{this.state.resetPasswordMessage.description}</p>
          </div>
          <div className="guest__check_icon">
            {this.state.resetPasswordMessage.icon}
          </div>
          <div className="guest__form_element button_padding">
              <button 
                className="guest__button"
                onClick={(e) => this.goToIndex(e)}
                id="register"
                ref={this.refLoginButton}
              >
                <span>Volver al Inicio</span>
              </button>
            </div>
            <Helmet>
              <title>Olvidé mi Contraseña - Blizwork</title>
            </Helmet>
        </section>
    )
  }
  
  render() {
      console.log('currStep', this.state.currStep)
      if(this.props.isMobile) {
        if(this.state.currStep === '1') {
          return this.ForgotPasswordComponent()
        } else {
          return this.SuccessMessageComponent()
        }
      } else {
          return (
            <>
            <div className="guest__header--desktop">
              <img src="/img/logo-mini.png" alt="blizwork logo" />
            </div>
            <div className="guest__wrapper--desktop">
              <div className="guest__side_image">
  
              </div>
              <div className="guest__content h_center_flex">
                {this.state.currStep === '1' ? (
                  this.ForgotPasswordComponent()
                ) : (
                  this.SuccessMessageComponent()
                )}
              </div>
            </div>
            </>
          )
      }
  }
}

const mapStateToProps = (state) => ({
  isMobile: state.navigation.isMobile
})

const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)