import React from 'react'
import './CaseHeader.scss'
import FormTimer from '../FormTimer/FormTimer'
import { Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CaseHeader = (props) => {
  return (
    <div className="case_center form_header__wrapper">
      <header className="form_header">
        <div >
          <div className="form_header__title">
            <div className="form_case__back_arrow" onClick={(e) => {
              props.disconnectSockets()
              props.history.push(props.draft ? `/workflow/${props.processSlug}/draft` :'/')
              }}>
              <FontAwesomeIcon icon="arrow-left" />
            </div>
            <div className="case_header__text">
              {props.draft ? '(Borrador):' : ''} {props.processTitle ? props.processTitle : '' } {props.caseNumber ? `#${props.caseNumber}` : '' }
              {props.assignedUser && props.assignedUser !== '' ? (
                <div className="case_header__text_assigned">
                  {`Usuario asignado: ${props.assignedUser}` }
                  {props.canUnassign? (
                    <button className= "case_header__text_unassign" onClick={() => {
                      Modal.confirm({
                        title: 'Confirmación',
                        content: `¿Está seguro de quitar la asignación de este caso?`,
                        onOk() {
                          props.unassignUser()
                        }
                      })
                    }}> Quitar asign.</button>
                  ) : null } 
                </div>

              ): null}
            </div>
          </div>
        </div>
        <div className="form_header__timedata">
          {
            <FormTimer key={props.wut} wut={props.wut} timers={props.timers ? props.timers : []} showTimer={props.showTimer}/>
          }
        </div>
      </header>
    </div>
  )
}

export default CaseHeader