/* eslint-disable react/display-name */
import React, { useState } from 'react'
import { useSelector } from "react-redux";
import { Table, Row, Col, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './UsersList.scss'

const UsersList = (props) => {
  const usersList = useSelector(state => state.myTeam.usersList)
  const [usersListFiltered, setUsersListFiltered] = useState(null)
  const [usersListFilteredText, setUsersListFilteredText] = useState('')

  const searchTable = (value) => {
    setUsersListFilteredText(value)
    const baseData = usersList
    let filterTable
    if (value !== '') {
      filterTable = baseData.filter(o =>
        Object.keys(o).some((k) =>{
          if (k !== 'createAuditUserId' && k !== '_id') {
            const evaluateValue = o[k]
            return String(evaluateValue)
            .toLowerCase()
            .includes(value.toLowerCase())
          } 
        })
      );
    } else {
      filterTable = null
    }
    setUsersListFiltered(filterTable)


  };

  console.log(usersList)
  const data = []
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'firstName',
      key: 'firstName',
      render: text => <b>{text}</b>,
    },
    {
      title: 'Apellido',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width:'50%',
    },
    {
      title: 'Ver usuario',
      // key: 'active',
      width:'2%',
      render: elem  => (
        <SearchOutlined  style={{cursor: 'pointer'}} onClick={() => props.showTeamUserInfo(elem._id)}/>
      )
    },
  ];
  const locale = {
    emptyText: (
      <span>
        <p>
          No hay solicitudes pendientes
        </p>
      </span>
    )
  };
  return (
    <>
      <Row>
        <Col className="user_list_title" span={24}>
          <div className="table_title"> Mi equipo de trabajo </div>
          {/* <Input className="search-home-input" placeholder='Filtrar...' size={'large'} bordered={false} prefix={<SearchOutlined />} value={this.props.requestedServicesOpenedByUserFilterText} onChange={ e => this.searchTable(e.target.value, 'requestedServicesOpenedByUser')}></Input> */}
          <Input className="search-home-input" placeholder='Filtrar...' size={'large'} bordered={false} prefix={<SearchOutlined />} value={usersListFilteredText} onChange={ e => searchTable(e.target.value)}></Input>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="table-wrapper" >
            <Table pagination={{ pageSize: 10 }} locale={locale} columns={columns} dataSource={usersListFiltered || usersList} />
          </div>
        </Col>
      </Row>
    </>
  )
}


export default UsersList