import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DeleteOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import './CenterWidget.scss'
import {
  setPropertiesVisible,
  setGlobalLoader, 
  showFormData,
  deleteWidget
} from '../../../../../actions/actions';


// export default class Card extends React.Component<CardProps> {
const CenterWidget =(props) => {
  const dispatch = useDispatch();
  const visiblePropertiesList = useSelector(state => state.formBuilder.visiblePropertiesList)
 
  return (
    <div
      ref={!props.dragOverlay? null : null} 
      id={props.id}  
      className={`center_widget__wrapper ${visiblePropertiesList[1] && props.elem.elem_id === visiblePropertiesList[1].elem_id ? 'h_highlighted' : ''}`} 
    >
      <div 
        className={`center_widget__title`}
        onClick={ async(e) => {
          dispatch(setGlobalLoader(true))
          dispatch(showFormData())
          await new Promise(r => setTimeout(r, 250))
          dispatch(setPropertiesVisible(props.elem))
          dispatch(setGlobalLoader(false))
        }
      }
      >
        <img className="center_widget__icon" src={props.ico_url} alt={props.title}/> {props.name} ({props.slug}) <small>- {props.title}</small>
      </div>
      <div className="center_widget__actions" 
        onClick={() => {
          Modal.confirm({
            title: 'Confirmar Eliminación',
            content: `¿Está seguro que desea eliminar el widget de ${props.title}? `,
            onOk() {
              dispatch(deleteWidget(props.elem_id))
            }
          })
        }}
      >
        <p> <DeleteOutlined /> </p>
      </div>
  </div>
  );

}
export default CenterWidget;
