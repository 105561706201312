function lengthEmail(email) {
  if(!email || email.length < 1) {
    return {
      error: 1,
      message: 'Campo de Correo Electrónico no puede quedar vacío',
      ref: 'refEmail',
      errorField: 'formEmailError'
    }
  }
  return { error: 0 }
}
  
function lengthPassword(password) {
  if(!password || password.length < 6) {
    return {
      error: 1,
      message: 'Largo de password debe ser mínimo de 6 caracteres',
      ref: 'refPassword',
      errorField: 'formPasswordError'
    }
  }
  return { error: 0 }
}

function validateEmail(email) {
  const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  if(!re.test(String(email).toLowerCase())) {
    return {
      error: 2,
      message: 'Correo electrónico inválido',
      ref: 'refEmail',
      errorField: 'formEmailError'
    }
  }
  return { error: 0 }
}

export default {
  lengthEmail,
  lengthPassword,
  validateEmail
}