function lengthFullName(fullName) {
  if(!fullName || fullName.length < 1) {
    return {
      error: 1,
      message: 'Campos de Nombre no pueden quedar vacíos',
      ref: 'refFullName',
      errorField: 'formFullNameError'
    }
  }
  return { error: 0 }
}

function lengthEmail(email) {
  if(!email || email.length < 1) {
    return {
      error: 1,
      message: 'Campo de Correo Electrónico no puede quedar vacío',
      ref: 'refEmail',
      errorField: 'formEmailError'      

    }
  }
  return { error: 0 }
}
  
function lengthPassword(password) {
  if(!password || password.length < 6) {
    return {
      error: 1,
      message: 'Largo de password debe ser mínimo de 6 caracteres',
      ref: 'refPassword',
      errorField: 'formPasswordError' 
    }
  }
  return { error: 0 }
}
  function lengthPasswordRepeated(password) {
    if(!password || password.length < 6) {
      return {
        error: 1,
        message: 'Largo de password debe ser mínimo de 6 caracteres',
        ref: 'refPasswordRepeated',
        errorField: 'formPasswordRepeatedError'      
      }
    }
    return { error: 0 }
  }

  function lengthEnterprise(enterprise) {
    if(!enterprise || enterprise.length < 1) {
      return {
        error: 1,
        message: 'Campo de Empresa no puede quedar vacío',
        ref: 'refEnterprise',
        errorField: 'formEnterpriseError'      
  
      }
    }
    return { error: 0 }
  }

  function matchPasswords(firstPassword, secondPassword) {
    if(firstPassword !== secondPassword) {
      return {
        error: 3,
        message: 'Las passwords no coinciden',
        ref: 'refPasswordRepeated',
        errorField: 'formPasswordRepeatedError'
      }
    }
    return { error: 0}
  }

  function validateEmail(email) {
    const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  
    if(!re.test(String(email).toLowerCase())) {
      return {
        error: 2,
        message: 'Correo electrónico inválido',
        ref: 'refEmail',
        errorField: 'formEmailError'
      }
    }
    return { error: 0 }
  }

export default {
  lengthFullName,
  lengthEmail,
  lengthPassword,
  lengthPasswordRepeated,
  lengthEnterprise,
  matchPasswords,
  validateEmail
}
