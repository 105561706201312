import React, { Component } from 'react'
import { connect } from 'react-redux'
import { disconnectSocket, socket } from '../../../utils/Socket'
import { resetUserData } from '../../../actions/userActions'
import axiosBW from '../../../utils/Http';


class Logout extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  async componentDidMount() {
    if (socket) {
      disconnectSocket()
    }
    localStorage.clear()
    this.props.resetUserData()
    await axiosBW.post('/auth/logout', {})
    this.props.history.push('/ingresar')
  }
    
  render() {
    return (<div>Logged out successfully</div>)
  }
}
const mapDispatchToProps = dispatch => ({
  resetUserData: () => dispatch(resetUserData()),
  
})

export default connect(null, mapDispatchToProps)(Logout)