export const initialState = {
  selectedForm: {}
}

const formList = (state = initialState, action) => {
  switch(action.type) {
    case 'SET_SELECTED_FORM': {
      return {
        ...state,
        selectedForm: action.formList
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default formList