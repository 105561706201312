
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import './RoleProperties.scss'
import { Input } from 'antd';
import { getUsersByRole, getUsersWithoutRole} from '../../../../../actions/WorkflowDesignerActions'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
// import axios from 'axios'
// import cf from '../../../../../config/APIConfig'
import axiosBW from '../../../../../utils/Http';

const UserCard = ({processSlug, user, add, role, refreshUsers}) => {
  const addRoleUser = async () => {
    await axiosBW.post(`/process/add-role-user/`,{
      roleUserId: user._id,
      role,
      processSlug
    })
  }
  const deleteRoleUser = async () => {
    await axiosBW.post(`/process/delete-role-user/`,{
      roleUserId: user._id,
      role,
      processSlug
    })
  }
  return (
    <div className="user-card">
      <div>
        <div className="user-card-title">
          {`${user.firstName} ${user.lastName}`}
        </div>
        <div className="user-card-subtitle">
          {user.email}
        </div>
      </div>
      {add? 
        <a className="action-role"onClick={async () => {
          await addRoleUser()
          refreshUsers()
          // addRoleUser()
          
        }} ><PlusCircleOutlined /></a> :
        <a className="action-role"onClick={async () => {
          await deleteRoleUser()
          refreshUsers()
        }} ><DeleteOutlined /></a>
      }
    </div>
  )
}

const RoleProperties = ({ workflow, role, changeWorkflowRole, sendWorkflowRole }) => {
  const [originalRole, setoriginalRole] = useState(role);
  const draftMode = useSelector(state => state.workflowDesigner.draftMode)
  const usersEditing = useSelector(state => state.workflowDesigner.usersEditing)
  const usersConnected = useSelector(state => state.workflowDesigner.usersConnected)
  const email = useSelector(state => state.user.email)
  let editingDisable = true
  let disabledColor
  if (draftMode && usersConnected.lenght !== 0) {
    const userEditing = usersEditing.find(u => u.zone === 'wf-role')
    if (userEditing) {
      editingDisable = userEditing.user === email ? false : true
      disabledColor = userEditing.user === email ? null : usersConnected.find(u => u.email === userEditing.user).color
    }
    if (role === 'customer') {
      editingDisable = true
    }
  }

  // Reemplazo de componentDidMount, el segundo argumento, dice que se actualizará solo cuando cambie "role"
  useEffect(() => {
    if (role !== 'customer') {
      dispatch(getUsersByRole(companyId, role, workflow.slug))
      dispatch(getUsersWithoutRole(companyId, role, workflow.slug))
    }
  }, [role]);
  const refreshUsers = () => {
    dispatch(getUsersByRole(companyId, role, workflow.slug))
    dispatch(getUsersWithoutRole(companyId, role, workflow.slug))
  }
  const dispatch = useDispatch();
  //useSelector reemplaza el mapStateToProps
  const usersByRole = useSelector(state => state.workflowDesigner.usersByRole);
  const usersWithoutRole = useSelector(state => state.workflowDesigner.usersWithoutRole);
  const companyId = useSelector(state => state.workflowDesigner.workflow.companyId);
  // console.log({usersByRole});
  
  if (!usersByRole) {
    return 'No USERS'
  }
  return (
    <div className="properties">
      <div className="header-title">
        Propiedades de Rol
      </div>
      <div className="input-role-container">
        <div className="label">
          ROL
        </div>
        <Input
          placeholder="Servicio Técnico"
          disabled={editingDisable}
          style={disabledColor ? { border: `2px solid ${disabledColor}` }: undefined}
          value={role}
          onChange={(e) => changeWorkflowRole({oldRole: role, newRole: e.target.value})}
          onBlur={(e) => {
            sendWorkflowRole({oldRole: originalRole, newRole: e.target.value})
            setoriginalRole(e.target.value)
          }}
        />
      </div>
      {role !== 'customer' 
        ? (
          <>
            <div className="input-role-container">
              <div className="label">
                Usuarios de la organización
              </div>
              {usersWithoutRole && role !== 'customer' ? usersWithoutRole.map((user, i) =>  {
                return (
                  <UserCard processSlug={workflow.slug} key={i} user={user} add={true} role={role} refreshUsers={refreshUsers} style={{paddingBottom: '10px'}}/>
                )
              }): ''} 
            </div>
            <div className="input-role-container">
              <div className="label">
                Usuarios activos en este rol
              </div>
              {usersByRole && role !== 'customer' ? usersByRole.map((user, i) =>  {
                return (
                  <UserCard processSlug={workflow.slug} key={i} user={user} add={false} role={role} refreshUsers={refreshUsers} style={{paddingBottom: '10px'}}/>
                )
              }): ''} 
            </div>
          </>
        )
        : ''
      }
      
    </div>
  )
}


export default RoleProperties