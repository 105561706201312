/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import axiosBW from '../../../../../utils/Http';
import { Table, Input, Modal, Switch } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone'
import { toast } from 'react-toastify';
import './CustomListField.scss'

const HooksLog = ({ processSlug, environment }) => {
//   const usersList = useSelector(state => state.myTeam.usersList)
  const [hooksLog, setHooksLog] = useState([]);
  const [hooksLogDetail, setHooksLogDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [detailPage, setDetailPage] = useState(false);

  const [active, setactive] = useState(false);
  const [fieldDefinition, setFieldDefinition] = useState('');
  const [caseNumberTest, setCaseNumberTest] = useState('0');
  const [testResult, setTestResult] = useState('');
  const companyId = useSelector(state => state.user.companyId)
  const timeZone = useSelector(state => state.user.timeZone)
  const pageSize = environment === 'draft' ? 4 : 10


  useEffect(() => {
    console.log('va el hooks-log');
    (async () => {
      try {
        setLoading(true)
        const resCustomListField = await axiosBW.get(`process/get-custom-list-field/${companyId}/${processSlug}/`)
        setLoading(false)
        if(resCustomListField && resCustomListField.data) {
  
          if(resCustomListField.data.status === 0) {
            const customListField = resCustomListField.data.payload
            setFieldDefinition(customListField.fieldDefinition)
            setactive(customListField.active)

          }
        }
      } catch(ex) {
        console.log(ex);
      }
    })();
    
  }, []);

  const saveChanges = async() => {
    try {
      setLoading(true)
      const resCustomListField = await axiosBW.post(`process/modify-custom-list-field/`, {
        companyId,
        processSlug,
        fieldDefinition,
        active
      })
      setLoading(false)
      if(resCustomListField && resCustomListField.data) {

        if(resCustomListField.data.status === 0) {
          toast.success('Cambios guardados correctamente')
          const customListField = resCustomListField.data.payload
          setFieldDefinition(customListField.fieldDefinition)
          setactive(customListField.active)

        }
      }
    } catch(ex) {
      setLoading(false)
      console.log(ex);
      if (ex.response && ex.response.data) {
        toast.error(ex.response.data.message)
      } else {
        toast.error('Ha ocurrido un error')
      }
    }
  }
  const testCustomListField = async() => {
    if (isNaN(caseNumberTest)) {
      toast.error('Ha ingresado un número no valido')
      return;
    }
    try {
      setLoading(true)
      const resTest = await axiosBW.post(`process/test-custom-list-field/`, {
        companyId,
        processSlug,
        fieldDefinition,
        caseNumber: caseNumberTest
      })
      setLoading(false)
      if(resTest && resTest.data) {
        if(resTest.data.status === 0) {
          setTestResult(resTest.data.payload)
          toast.success('Evaluado correctamente')
        }
      }
    } catch(ex) {
      setLoading(false)
      setTestResult('')
      console.log(ex);
      if (ex.response && ex.response.data) {
        toast.error(ex.response.data.message)
      } else {
        toast.error('Ha ocurrido un error')
      }
    }
  }

  if (loading) {
      return 'Cargando'
  }
 
  return (
    <div className="" >
      <div className="custom-list-item">
        <div className="custom-list-label">
          Habilitado:
        </div>
        <div className="custom-list-content">
          <Switch checked={active} onChange={() => {
                          
            // Modal.confirm({
            //   title: 'Confirmar',
            //   content: `¿Está seguro que desea ${active ? 'desactivar' : 'activar'} dato? `,
            //   async onOk() {
            //     setactive(!active)
            //   }
            // })
            setactive(!active)
          } } />
        </div>
      </div>
      <div className="custom-list-item">
        <div className="custom-list-label">
          Definición del campo:
        </div>
        <div className="custom-list-content">
          <Input
            className="input-definition"
            type="text"
            name="fieldDefinition"
            value={fieldDefinition}
            onChange={(e) => setFieldDefinition(e.target.value)}
          />
        </div>
      </div>
      <div className="custom-list-item">
        <button className="custom-list-save-button" onClick={ () => {
          saveChanges()
        }}
        >
          Guardar cambios
        </button>
      </div>
      <div className="custom-list-title">
        Pruebas
      </div>
      <div className="custom-list-item">
        <div className="custom-list-test-label">
          Nro. de caso:
        </div>
          <Input
            className="input-case-number"
            type="text"
            name="caseNumberTest"
            value={caseNumberTest}
            onChange={(e) => setCaseNumberTest(e.target.value)}
          />

          <button className="custom-list-test-button" onClick={ () => {
            testCustomListField()
          }}
          >
            Probar
          </button>

      </div>
      <div className="custom-list-item">
        <div className="custom-list-test-label">
          Resultado:
        </div>
          <Input
            className="input-case-test-result"
            type="text"
            name="testResult"
            value={testResult}
            // onChange={(e) => testResult(e.target.value)}
            disabled= {true}
          />

      </div>
    </div>
    
  )
}


export default HooksLog