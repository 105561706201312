import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Modal, Row, Col, Collapse, Tabs, Table, Badge, Input, Button, Form, Image,Checkbox, AutoComplete, Radio, Avatar, Upload, Select } from 'antd';
import { CameraOutlined, LoadingOutlined } from '@ant-design/icons';
import './MyProfile.scss'
// import axios from 'axios'
// import cf from '../../../config/APIConfig'
import axiosBW from '../../../../../utils/Http';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setGlobalLoader } from '../../../../../actions/actions'
import { setUserData } from '../../../../../actions/userActions'
import { Helmet } from "react-helmet";
import ErrorList from 'antd/lib/form/ErrorList';
import timeZoneList from '../../../../../utils/timezones'

const { Option } = Select;

const MyProfile = (props) => {
  const dispatch = useDispatch();
  console.log({props})
  const isMobile = useSelector(state => state.navigation.isMobile)
//   const [visible, setVisible] = useState(false)
  const [profileInfo, setProfileInfo] = useState(null)
  const [file, setFile] = useState(null)
  const [userImage, setUserImage] = useState(null)
  const [uploadingImage, setUploadingImage] = useState(false)
//   const [step, setStep] = useState(1)
//   const [stepMsg, setStepMsg] = useState('')
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    console.log('Received values of form: ', values);
    dispatch(setGlobalLoader(true))
    try {
      await axiosBW.put(`/users/my-profile-info`,
        {
          firstName: values.firstName,
          lastName: values.lastName,
          jobTitle: values.jobTitle || profileInfo.jobTitle,
          phone: values.phone || profileInfo.jobTitle,
          profileImage: userImage || profileInfo.profileImage,
          timeZone: values.timeZone || profileInfo.timeZone
        }
      );
      const userInfo = await axiosBW.get('/auth/user-info')
      const user = userInfo.data.payload.user
      const token = userInfo.data.payload.token
      dispatch(setUserData(user, token))
      const resp = await axiosBW.get(`/users/my-profile-info`);
      setProfileInfo(resp.data.payload)
      dispatch(setGlobalLoader(false))
      
    } catch (err) {
      localStorage.clear()
      if (err.response && err.response.data && err.response.data.message) {
        const respMsg = err.response.data.message
        Modal.error({
          title: 'Error',
          content: respMsg
        })
      }
    }
  };
  

  // Reemplazo de componentDidMount
  useEffect( () => {
    console.log('va el UseEffect');
    async function getMyProfileInfo() {
      try {
        const resp = await axiosBW.get(`/users/my-profile-info`);
          console.log(resp)
          setProfileInfo(resp.data.payload)
          dispatch(setGlobalLoader(false))
      } catch (err) {
        console.log(err) 
        if (err.response && err.response.data && err.response.data.message) {
          const respMsg = err.response.data.message
          Modal.error({
            title: 'Error',
            content: respMsg
          })
        }
        setTimeout(() => {
          props.history.push('/')
        }, 2000);
      }
    }
    dispatch(setGlobalLoader(true))
    getMyProfileInfo()
    
    // dispatch(getMyTeamInfo())
  }, []);
  
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onerror = reject;
      fr.onload = function() {
          resolve(fr.result);
      }
      fr.readAsDataURL(file);
  });
  }

  const beforeUpload = async(file) => {
    try {
      console.log(file)
      setUploadingImage(true)
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        // message.error('You can only upload JPG/PNG file!');
        throw new Error('Solo puede ser un archivo JPG/PNG!')
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        // message.error('Image must smaller than 2MB!');
        throw new Error('La imagen debe ser menor a 2MB')
      }
      const base64File = await getBase64(file);
      console.log(base64File)
      
      const uploadedImage = await axiosBW.post('/attachment/upload-file', {
        module: 'profile-avatar',
        filename: file.name,
        file: base64File.split('base64')[1]
      })
      setUploadingImage(false)
      setUserImage(uploadedImage.data.payload.downloadUrl)
    } catch (error) {
      console.log(error)
      setUploadingImage(false)
      Modal.error({
        title: 'Error',
        content: error.message
      })
    }
  }
  const changePassword = async () => {
    Modal.confirm({
      title: 'Confirmar cambio de contraseña',
      content: `¿Está seguro que desea cambiar su contraseña?\nSe enviará un correo con los pasos a seguir`,
      async onOk() {
        await axiosBW.post('/auth/reset-password-generate', {
          email: profileInfo.email
        })
        Modal.success({
          title: 'Solicitud generada correctamente',
          content: 'Ha sido enviado un mensaje a su  correo con la información necesaria para cambiar su contraseña.'
        })
      }
    })
  }

  if (!profileInfo) {
    return ('CARGANDO')
  } else {
    console.log(profileInfo)
    return (
      <div className="my-profile-container">
        <div className="my-profile-info">
          <div className="my-profile-user-card">
            <div className="my-profile-user-card-avatar">
              { uploadingImage ? (
                <Avatar size={96}><LoadingOutlined /></Avatar>
              ) : userImage || profileInfo.profileImage ? (
                <Avatar size={96} src={userImage || profileInfo.profileImage } />
              ): (
                <Avatar size={96}>{`${profileInfo.firstName.charAt(0).toUpperCase()}${profileInfo.lastName.charAt(0).toUpperCase()}`}</Avatar>
              )}
              <div className="my-profile-user-card-upload">
                <Upload
                  beforeUpload={beforeUpload}
                  showUploadList={false}
                >
                  <CameraOutlined />
                </Upload>
              </div>
            </div>
            <div className="my-profile-user-card-data">
              <div className="my-profile-user-card-full-name">
                {`${profileInfo.firstName} ${profileInfo.lastName}`}
              </div>
              <div className="my-profile-user-card-email">
                {profileInfo.email}
              </div>
              <div>
                <span className="my-profile-user-card-job-title">{profileInfo.jobTitle || 'Empleado'} en </span><span className="my-profile-user-card-company">{profileInfo.companyName}</span>

              </div>
            </div>
          </div>
          <div className="my-profile-form">
            <Form
              form={form}
              size="middle"
              layout="vertical"
              name="register"
              onFinish={onFinish}
              preserve={false}
              initialValues={{
                "firstName": profileInfo.firstName,
                "lastName": profileInfo.lastName,
                "phone": profileInfo.phone || '',
                "jobTitle": profileInfo.jobTitle || '',
                "timeZone": profileInfo.timeZone || 'America/Santiago',
              }}
              scrollToFirstError
            >
              <Form.Item
                label="Nombre"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'Nombre requerido',
                  },
                ]}
              >
                <Input  placeholder="Nombre" />
              </Form.Item>
              <Form.Item
                label="Apellidos"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Apellidos requeridos',
                  },
                ]}
              >
                <Input  placeholder="Apellidos" />
              </Form.Item>
              <Form.Item
                label="Teléfono"
                name="phone"
              >
                <Input  placeholder="Teléfono" />
              </Form.Item>
              <Form.Item
                label="Cargo"
                name="jobTitle"
              >
                <Input  placeholder="Cargo" />
              </Form.Item>
              <Form.Item
                label="Huso Horario"
                name="timeZone"
              >
                <Select
                  showSearch
                  placeholder="Select a person"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {timeZoneList.map((tz, i) => (<Option key={i} value={tz}>{tz}</Option>))}
                </Select>
              </Form.Item>
              <input type="submit" id="submit-form" className="hidden" style={{opacity: '0', position:'absolute'}} />
            </Form>
          </div>
        </div>
        <div className="user-info-stats">
          <div className="user-info-stats-title">
            Actividad
          </div>
          <div className="user-info-stats-subtitle">
            Te mostramos en números, tu participación en la plataforma.
          </div>
          <div className="user-info-stats-list-container">
            <div className="user-info-stats-list-item">
                <span>Nº de casos que he iniciado</span><span>{profileInfo.startedCases || 0}</span>
            </div>
            <div className="user-info-stats-list-item">
                <span>Nº de casos en los que he participado</span><span>{profileInfo.participatedCases || 0}</span>
            </div>
            <div className="user-info-stats-list-item">
                <span>Nº de casos solicitados</span><span>{profileInfo.outBoxCases || 0}</span>
            </div>
            <div className="user-info-stats-list-item">
                <span>Nº de casos por atender</span><span>{profileInfo.inBoxCases || 0}</span>
            </div>
          </div>
        </div>
        <div className="user-info-buttons">
          <label for="submit-form" tabIndex="0" onClick={e => {}} className="billing_info__btn">
            Guardar cambios
          </label>
          <label onClick={e => {props.history.push('/servicios/caso/5e04ed0ba9511c00245bb774/inicio')}} className="billing_info__btn">
            Mesa de ayuda
          </label>
          <label onClick={e => changePassword()} className="billing_info__btn">
            Cambiar contraseña
          </label>
        </div>
      </div>
    )

  }
}
export default MyProfile