import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import './ReportList.scss'
import { setGlobalLoader } from '../../../../actions/actions'
import { FileTextOutlined } from '@ant-design/icons';
class ReportList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reportList: [],
    }
  }

  componentDidMount() {
    this.setState({
      reportsList: [
        {
          title: 'Usuarios Registrados',
          icon: '',
          reportId: 'asdasd'
        },
        {
          title: 'Facturación',
          icon: '',
          reportId: 'zxczx'
        },
      ],
      reportList: this.props.reportList ? this.props.reportList : [],
    })
    this.props.setGlobalLoader(false)
  }
  

  render() {
    return (
      <div className="report_list__wrapper">
        {
          this.state.reportList.map((e, i) => {
            return (
              <div className="grid_block" key={`rep-${i}`}>
                <div className="grid_block__title">
                  {e.name}
                </div>
                <div className="grid_block__icon">
                  <FileTextOutlined />
                </div>
                <div className="grid_block__button_wrapper">
                  <Link to={`/show_metricas/${e.processSlug}/show/${e._id}`} className="grid_block__button">
                    Ver Reporte
                  </Link>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState))
})

export default connect(null, mapDispatchToProps)(ReportList)