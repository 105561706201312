
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment-timezone'
import './WorkflowProperties.scss'
import {  Collapse, Input, Upload } from 'antd';
import { toast } from 'react-toastify';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import axiosBW from '../../../../../utils/Http';
import { setGlobalLoader } from '../../../../../actions/actions'
const { Panel } = Collapse;

const WorkflowProperties = ({ workflow, changeWorkflowProperties, sendWorkflowProperties, loadWorkFlowInfo}) => {
  const dispatch = useDispatch();
  const [uploadingImage, setUploadingImage] = useState(false)
  const draftMode = useSelector(state => state.workflowDesigner.draftMode)
  const usersEditing = useSelector(state => state.workflowDesigner.usersEditing)
  const usersConnected = useSelector(state => state.workflowDesigner.usersConnected)
  const email = useSelector(state => state.user.email)
  const timeZone = useSelector(state => state.user.timeZone)

  let editingDisable = true
  let disabledColor
  if (draftMode && usersConnected.lenght !== 0) {
    const userEditing = usersEditing.find(u => u.zone === 'wf-properties')
    if (userEditing) {
      editingDisable = userEditing.user === email ? false : true
      disabledColor = userEditing.user === email ? null : usersConnected.find(u => u.email === userEditing.user) ? usersConnected.find(u => u.email === userEditing.user).color : null
    }
  }
  
  
  if (!workflow) {
    return ''
  }
  const onChange = (data) => {
    changeWorkflowProperties(data)
  }
  const onBlur = (data) => {
    sendWorkflowProperties(data)
  }
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onerror = reject;
      fr.onload = function() {
          resolve(fr.result);
      }
      fr.readAsDataURL(file);
    });
  }
  const beforeUpload = async(file) => {
    try {
      console.log(file)
      setUploadingImage(true)
      if (draftMode) {
        onChange({imagePath: ''})
      } else {
        dispatch(setGlobalLoader(true))
      }
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        // message.error('You can only upload JPG/PNG file!');
        throw new Error('Solo puede ser un archivo JPG/PNG!')
      }
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        // message.error('Image must smaller than 2MB!');
        throw new Error('La imagen debe ser menor a 1MB')
      }
      const base64File = await getBase64(file);
      console.log(base64File)
      
      const uploadedImage = await axiosBW.post('/attachment/upload-file', {
        module: 'profile-avatar',
        filename: file.name,
        file: base64File.split('base64')[1]
      })
      if(!draftMode) {
        await axiosBW.post('/process/change-icon', {
          imagePath: uploadedImage.data.payload.downloadUrl,
          processSlug: workflow.slug,
          companyId: workflow.companyId
        })
        await loadWorkFlowInfo()
        dispatch(setGlobalLoader(false))
      } else {
        onChange({imagePath: uploadedImage.data.payload.downloadUrl})
        onBlur({imagePath: uploadedImage.data.payload.downloadUrl})
      }
      setUploadingImage(false)
      // setUserImage(uploadedImage.data.payload.downloadUrl)
    } catch (error) {
      console.log(error)
      // onChange({imagePath: ''})
      setUploadingImage(false)
      if(!draftMode) dispatch(setGlobalLoader(false))
      toast.error(error.message)
    }
  }
  return (
    <div className="properties">
      <Collapse
        accordion
        bordered={false}
        defaultActiveKey={['1']}
        expandIconPosition={'right'}
      >
        <Panel header="Propiedades del flujo" className="" key="1" >
          <div className="input-container">
            <div className="label">
              Identificador Único (solo caracteres, números y guiones)
            </div>
            <Input
              placeholder="SERV-TEC"
              disabled={true}
              style={disabledColor ? { border: `2px solid ${disabledColor}` }: undefined}
              value={workflow.slug}
            />
          </div>
          <div className="input-container">
            <div className="label">
              Nombre
            </div>
            <Input
              placeholder="Servicio Técnico"
              disabled={editingDisable}
              style={disabledColor ? { border: `2px solid ${disabledColor}` }: undefined}
              value={workflow.title}
              onChange={(e) => onChange({title: e.target.value})}
              onBlur={(e) => onBlur({title: e.target.value})}
            />
          </div>
          <div className="input-container">
            <div className="label">
              Descripción
            </div>
            <Input.TextArea
              className="textarea"
              placeholder="SERV-TEC"
              disabled={editingDisable}
              style={disabledColor ? { border: `2px solid ${disabledColor}` }: undefined}
              value={workflow.description}
              onChange={(e) => onChange({description: e.target.value})}
              onBlur={(e) => onBlur({description: e.target.value})}
            />
          </div>
          <div className="input-container">
            <div className="label">
              Ícono (Tipo: JPG/PNG, Resolución hasta 120x120, Tamaño hasta 1MB)
            </div>
            <Upload
              // name="avatar"
              listType="picture-card"
              className="icon-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
            >
              {workflow.imagePath && workflow.imagePath.length > 0 ? <img src={workflow.imagePath} alt="avatar" style={{ width: '100%' }} /> : (
                <div>
                  {uploadingImage ? <LoadingOutlined /> : <PlusOutlined />}
                  <div style={{ marginTop: 8 }}>Subir</div>
                </div>
              )}
            </Upload>
            {/* <Input
              placeholder="SERV-TEC"
              disabled={editingDisable}
              style={disabledColor ? { border: `2px solid ${disabledColor}` }: undefined}
              value={workflow.imagePath}
              onChange={(e) => onChange({icon: e.target.value})}
              onBlur={(e) => onBlur({icon: e.target.value})}
            /> */}
          </div>
        </Panel>
        <Panel header="Control de cambios" className="" key="2" >
          <div className="input-container">
            <div className="label">
              Fecha de creación
            </div>
            <Input
              disabled={true}
              value={workflow.createAudit ? moment(workflow.createAudit.timeStamp).tz(timeZone).format('DD-MM-YYYY HH:mm:ss') : ''}
            />
          </div>
          <div className="input-container">
            <div className="label">
              Usuario de creación
            </div>
            <Input
              disabled={true}
              value={workflow.createAudit ? workflow.createAudit.userId : ''}
            />
          </div>
          <div className="input-container">
            <div className="label">
              Fecha de modificación
            </div>
            <Input
              disabled={true}
              value={workflow.modifyAudit ? moment(workflow.modifyAudit.timeStamp).tz(timeZone).format('DD-MM-YYYY HH:mm:ss') : ''}
            />
          </div>
          <div className="input-container">
            <div className="label">
              Usuario de modificación
            </div>
            <Input
              disabled={true}
              value={workflow.modifyAudit ? workflow.modifyAudit.userId : ''}
            />
          </div>
          <div className="input-container">
            <div className="label">
              Versión
            </div>
            <Input
              disabled={true}
              value={workflow.modifyAudit ? workflow.version : ''}
            />
          </div>
        </Panel>
      </Collapse>
    </div>
  )
}


export default WorkflowProperties