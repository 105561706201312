import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Tabs from '../../../components/Library/Tabs/Tabs'
import Analytics from './Analytics/Analytics';
import Charts from '../../TestPages/Charts';
import ReportList from './ReportList/ReportList';
import './Metrics.scss'
import { setGlobalLoader } from '../../../actions/actions'
import Axios from 'axios'
import cf from '../../../config/APIConfig'
import { Modal } from 'antd';
import { Helmet } from 'react-helmet';


class Metrics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: 0,
      reportList: []
    }
  }

  async componentDidMount() {
    this.setState({
      selectedTab: this.props.match.params.goto ? parseInt(this.props.match.params.goto) : 0
    })
    if (this.props.match.params.goto === '0') {
      this.props.setGlobalLoader(true)
      let resReport
      try {
        resReport = await Axios.get(`reports/${this.props.match.params.processSlug}/${this.props.userId}` , {
          baseURL: cf.BASE_URL.analytics,
          timeout: 30000,
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${this.props.token}`
          }
        })
      } catch (ex) {
        console.log('error reports', ex)
      }
      if(!resReport || !resReport.data) {
        Modal.error({
          title: 'Error',
          content: 'Error al cargar data de reportes'
        })
        return
      }
      if(resReport.data.payload) {
        this.setState({
          reportList: resReport.data.payload
        })
      }
      console.log('resReport', resReport.data.payload)
      this.setState({
        selectedTab: this.props.match.params.goto ? parseInt(this.props.match.params.goto) : 0
      })
      this.props.setGlobalLoader(false)
    
    }

  }

  tabGraphics() {
    return (
      <div>
        <Analytics processId={this.props.match.params.processId}/>
      </div>
    )
  }

  tabReports() {
    return (
      <div>
        <ReportList key={this.state.reportList} reportList={this.state.reportList} processId={this.props.match.params.processId} />
      </div>
    )
  }

  tabsServices() {
    return [
      {
        title: `Reportes`,
        content: this.tabReports()
      },
      {
        title: 'Gráficos',
        content: this.tabGraphics()
      }
    ]
  }

  render() {
    return (
      <div className="metrics__wrapper">
        {this.state.selectedTab === 0?
        (
          this.tabReports()
        ): this.state.selectedTab === 1 ?
        (
          this.tabGraphics()
        ): ''}
        {/* <Tabs key={this.state.selectedTab} activeTab={this.state.selectedTab} tabs={this.tabsServices()} />  */}
        <Helmet>
          <title> Métricas - Blizwork</title>
        </Helmet>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setGlobalLoader: (newStatus) => dispatch(setGlobalLoader(newStatus))
  }
}
const mapStateToProps = (state) => ({
  userId: state.user.userId,
  token: state.user.token
})

export default connect(mapStateToProps, mapDispatchToProps)(Metrics)