import React, { Component } from 'react'
import { connect } from 'react-redux'
import './ValidateMail.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axiosBW from '../../../utils/Http'
import { setGlobalLoader } from '../../../actions/actions'
import { setUserData } from '../../../actions/userActions'

class ValidateMail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formEmail: '',
      validateMessage: {
        icon: '',
        description: ``,
      },
      currStep: '1',
      currentToken: '',
    }
  }

  componentDidMount() {
    this.setState({
      currentToken: this.props.match.params.token,
    }, () =>  {
      this.doValidateMail()
    })
    this.props.setGlobalLoader(false)
  }

  goToIndex(e) {
    e.preventDefault()
    this.props.history.push('/')
  }

  doValidateMail() {
      axiosBW.post(
        '/auth/user-confirmation',
        {
          registrationId: this.state.currentToken,
        }
      )
      .then(res => {
        if(res.data.payload) {
          const data = res.data.payload
          console.log(data)
          this.props.setUserData(data.user, data.token)
          this.setState({
            validateMessage: {
              icon: <FontAwesomeIcon className="guest__check_icon--inside" icon={["far", "check-circle"]} />,
              description: <div><p>Su cuenta ha sido activada existosamente. En unos segundos será redigirido a la aplicación.</p> <p>Si no es redirigido, haga click en el botón</p> </div>
            }
           })
           console.log(data.user)
           setTimeout(() => {
            if (data.user.haveToCompleteData) {
              this.props.history.push(`/ultimo_paso`)
            } else if (data.user.firstRedirect) {
              this.props.history.push(`/${data.user.firstRedirect}`)
            } else {
              this.props.history.push('/')
            }
          }, 3000);
        }
        
      })
      .catch(err => {
        console.log('Error', err)
         if(err.response.data.message && err.response.data.message === 'Invalid Token') {
           this.setState({
            validateMessage: {
              icon: <FontAwesomeIcon icon="exclamation-triangle" />,
              description: err.response.data.message
            }
           })
         } else {
          this.setState({
            validateMessage: {
              icon: <FontAwesomeIcon icon="exclamation-triangle" />,
              description: 'Ha ocurrido un error al intentar validar la activación de su cuenta. Intente nuevamente ingresar desde el enlace enviado a su correo electrónico o contacte a soporte técnico (support@blizwork.com)'
            }
           })
         }
      })
  }

  SuccessMessageComponent() {
    return (
      <section className="register h_white_block">
        <header className="guest__title">
          <h1>Activación de Cuenta</h1>
        </header>
        <div className="guest__subtitle--nobg">
          <p>{this.state.validateMessage.description}</p>
        </div>
        <div className="guest__check_icon">
          {this.state.validateMessage.icon}
        </div>
        <div className="guest__form_element button_padding">
          <button
            className="guest__button"
            onClick={e => this.goToIndex(e)}
            id="register"
            ref={this.refLoginButton}
          >
            <span>Ir a la aplicación</span>
          </button>
        </div>
      </section>
    )
  }

  render() {
    console.log('currStep', this.state.currStep)
    if (this.props.isMobile) {
      return this.SuccessMessageComponent()
    } else {
      return (
        <>
          <div className="guest__header--desktop">
            <img src="/img/logo-mini.png" alt="blizwork logo" />
          </div>
          <div className="guest__wrapper--desktop">
            <div className="guest__side_image"></div>
            <div className="guest__content h_center_flex">
              {this.SuccessMessageComponent()}
            </div>
          </div>
        </>
      )
    }
  }
}

const mapStateToProps = state => ({
  isMobile: state.navigation.isMobile,
})

const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState)),
  setUserData: (user, token) => dispatch(setUserData(user, token))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidateMail)
