import React, { Component } from 'react'
import moment from 'moment'
import momentTZ from 'moment-timezone';
import { connect } from 'react-redux'
import ResizeableTitle from '../../../components/Library/ResizableTitle/ResizableTitle'
import './Home.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FeaturedProvidersScroll from './FeaturedProvidersScroll/FeaturedProvidersScroll';
import axiosBW from '../../../utils/Http';
import FeaturedServicesScroll from './FeaturedServicesScroll/FeaturedServicesScroll';
import { DownCircleOutlined, SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { Modal, Row, Col, Collapse, Tabs, Table, Badge, Input, Tooltip } from 'antd';
import { setGlobalLoader, setHomeSelectedTab } from '../../../actions/actions';
import { changeFilter } from '../../../actions/HomeActions';
import { Helmet } from 'react-helmet';
import { socket } from '../../../utils/Socket'
import 'moment/locale/es'  // without this line it didn't work
const { Panel } = Collapse;
const { TabPane } = Tabs;
moment.locale('es')
momentTZ.defineLocale('es', moment.localeData()._config);
momentTZ.locale('es')



export class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      requestedServicesOpened: [],
      requestedServicesOpenedLoading: false,
      requestedServicesOpenedByUser: [],
      requestedServicesOpenedByUserLoading: false,
      requestedServicesClosed: [],
      requestedServicesClosedLoading: false,
      requestedServicesOpenedFilter: null,
      requestedServicesOpenedByUserFilter: null,
      requestedServicesClosedFilter: null,
      requestedServicesCols: [],
      toAttendServicesOpened: [],
      toAttendServicesOpenedLoading: false,
      toAttendServicesAssignedOpened: [],
      toAttendServicesAssignedOpenedLoading: false,
      toAttendServicesClosed: [],
      toAttendServicesClosedLoading: false,
      toAttendServicesOpenedFilter: null,
      toAttendServicesAssignedOpenedFilter: null,
      toAttendServicesClosedFilter: null,
      toAttendServicesCols: [],
      featuredServices: [],
      favoriteProviders: [],
      // validation messages
      msgFeaturedServices: 'Cargado Servicios Destacados',
      msgFavoriteProviders: 'Cargando Proveedores Favoritos',
      msgRequestedServicesOpened: 'Cargando Casos Abiertos',
      msgRequestedServicesOpenedByUser: 'Cargando Casos Abiertos',
      msgRequestedServicesClosed: 'Cargando Casos Cerrados',
      msgToAttendServicesOpened: 'Cargando Casos Abiertos',
      msgToAttendServicesAssignedOpened: 'Cargando Casos Asignados',
      msgToAttendServicesClosed: 'Cargando Casos Cerrados',
      companyId: '',
      homeSelectedTab: 0,
      featuredCollapse: true,
      closedCasesCollapseActive: false,
      tableColumnsDesktop: [
        {
          title: 'EMPRESA',
          dataIndex: 'companyName',
          key: 'companyName',
          // width: 260,
          width: '18%%',
          ellipsis: true,
          render: elem => (
            <b>{elem}</b>
          )
        },
        {
          title: 'PROCESO',
          dataIndex: 'processName',
          key: 'processName',
          width: '15%',
          ellipsis: true,
        },
        {
          title: 'CASO',
          dataIndex: 'caseNumber',
          key: 'caseNumber',
          width: 76,
          sorter: (a, b) => a.caseNumber - b.caseNumber,
          render: elem => (`#${elem}`),
          align: 'right'
          // width: '8%',
        },
        {
          title: 'FECHA CREACIÓN',
          dataIndex: 'createAuditTimeStamp',
          key: 'createAuditTimeStamp',
          width: 120,
          render: elem =>(
            <Tooltip title={momentTZ(elem).tz(this.props.userInfo.timeZone).format('YYYY-MM-DD HH:mm')}>
              {momentTZ(elem).tz(this.props.userInfo.timeZone).fromNow()}
            </Tooltip>
          ),
          defaultSortOrder: 'ascend',
          sorter: (a, b) => new Date(b.createAuditTimeStamp) - new Date(a.createAuditTimeStamp),
        },
        {
          title: 'CREADO POR',
          dataIndex: 'userEmail',
          key: 'userEmail',
          ellipsis: true,
        },
        {
          title: 'ACTIVIDAD',
          dataIndex: 'currentActivity',
          key: 'currentActivity',
          ellipsis: true,
          render: (currentActivity, elem) => (
            <Tooltip title={`Úlima Actividad: ${elem.lastActivity} ${momentTZ(elem.lastActivityDate).tz(this.props.userInfo.timeZone).format('YYYY-MM-DD HH:mm')}`}>
              {currentActivity || 'N/A'}
            </Tooltip>
            
          )
        },
        {
          title: 'DATOS ADICIONALES',
          dataIndex: 'customField',
          key: 'customField',
          ellipsis: true,
        },
        {
          title: 'DETALLE',
          dataIndex: '',
          key: 'detalle',
          width: 75,
          align: 'center',
          render: elem => (
            <Badge  dot={elem.notReadMsg}><div><div className="home__icon--table" onClick={(e) => {
              this.disconnectSockets()
              this.props.history.push(`/servicios/caso/${elem.processId}/${elem._id}`)
            } }> <FontAwesomeIcon icon="search" /> </div></div></Badge>
          )
        },
      ]
      
    }
    this.onChangeTabHome = this.onChangeTabHome.bind(this)
  }

  async componentDidMount() {
    console.log('USER INFOOOOOOO', this.props.userInfo)
    this.setState({
      homeSelectedTab: this.props.homeSelectedTab,
      featuredCollapse: this.props.userInfo.featuredCollapse,
    })

    this.props.setGlobalLoader(true)
    let resServices 
    try {
      resServices = await axiosBW.get('/featured/services')
    } catch (ex) {
      console.log('error resServices', ex)
    }
    if(!resServices || !resServices.data) {
      this.setState({ msgFeaturedServices: 'No ha sido posible cargar los Servicios Destacados. Error en la petición'})
      return
    }
    this.setState({
      featuredServices: resServices.data.payload,
      msgFeaturedServices: ''
    })
    
    // featured Providers API Call
    let resProviders
    try {
      resProviders = await axiosBW.get('/featured/providers')
    } catch (ex) {
      console.log('error Featured Providers', ex)
    }
    if(!resProviders || !resProviders.data) {
      this.setState({ msgFeaturedServices: 'No ha sido posible cargar los Proveedores Favoritos '})
      this.props.setGlobalLoader(false)
      return
    }
    if(resProviders.data.status === 0) {
      this.setState({
        favoriteProviders: resProviders.data.payload,
        msgFavoriteProviders: ''
      })
    } else {
      this.setState({ msgFavoriteProviders: 'No ha sido posible cargar los Proveedores Favoritos '})
    }
    this.props.setGlobalLoader(false)
    if(this.props.homeSelectedTab.toString() === '0') {
      await this.loadRequestedData()
    }
    
    if(this.props.homeSelectedTab.toString() === '1') {
      await this.loadToAttendData()
    }
    
    
    // SOCKETS LISTENERS
    socket.on('server:home-new-messages',this.onHomeNewMessages)
  }
  onHomeNewMessages = (data) => {
    const newRequestedServicesOpened = this.state.requestedServicesOpened.map(c => 
      c._id === data.caseId
        ? { ...c, notReadMsg: true}
        : c
    )
    const newToAttendServicesOpened  = this.state.toAttendServicesOpened.map(c => 
      c._id === data.caseId
        ? { ...c, notReadMsg: true}
        : c
    )
    this.setState({
      requestedServicesOpened: newRequestedServicesOpened,
      toAttendServicesOpened: newToAttendServicesOpened
    })
  }
  disconnectSockets = () => {
    socket.off('server:home-new-messages', this.onHomeNewMessages)
  }
  componentDidUpdate(){
    window.onbeforeunload = () => {
      this.disconnectSockets()
    }
  }
  searchTable = (value, table) => {
    const baseData = this.state[table]
    let filterTable
    if (value !== '') {
      if (value.charAt(0) === '#') {
        console.log('es #')
        filterTable = baseData.filter(o =>
          Object.keys(o).some((k) => {
            if (k === 'caseNumber') {
              const evaluateValue = k === 'createAuditTimeStamp' ? momentTZ(o[k]).tz(this.props.userInfo.timeZone).format('YYYY-MM-DD HH:mm') : o[k]
              return String(evaluateValue)
              .toLowerCase()
              .includes(value.slice(1).toLowerCase())
            } 
          })
        );
      } else {
        filterTable = baseData.filter(o =>
          Object.keys(o).some((k) => {
            if (k !== 'createAuditUserId' && k !== 'processId' && k !== '_id') {
              const evaluateValue = k === 'createAuditTimeStamp' ? momentTZ(o[k]).tz(this.props.userInfo.timeZone).format('YYYY-MM-DD HH:mm') : o[k]
              return String(evaluateValue)
              .toLowerCase()
              .includes(value.toLowerCase())
            } 
          })
        );
      }
    } else {
      filterTable = null
    }

    // this.setState({ [`${table}Filter`]: filterTable });
    this.props.changeFilter(`${table}Filter`, filterTable, value )
  };
  // async syncUserData() {
  //   axiosBW.get('/auth/user-info')
  //   .then(res => {
  //     // handle code errors and show in message bar
  //     this.setState({
  //       messageForm: {
  //         title: '',
  //         description: '',
  //         icon: ''
  //       }
  //     })
  //     if(res.data.message.length === 0) {
  //       localStorage.setItem('company', res.data.payload.companyId)
  //       localStorage.setItem('userObject', JSON.stringify(res.data.payload))
  //       localStorage.setItem('name', res.data.payload.firstName + ' ' +res.data.payload.lastName)
  //       localStorage.setItem('user_id', res.data.payload._id)
  //       localStorage.setItem('logo_url', res.data.payload.companyImage)
  //       localStorage.setItem('company_name', res.data.payload.companyName)
  //       localStorage.setItem('initials', res.data.payload.firstName.charAt(0).toUpperCase()+res.data.payload.lastName.charAt(0).toUpperCase())
  //       localStorage.setItem('plan_name', res.data.payload.companyPlan)
  //       localStorage.setItem('permissions', JSON.stringify(res.data.payload.permissions) || '[]')
  //     }
  //   })
  // }
  async onChangeTabHome(tabId) {
    console.log('change TAB');
    // this.props.setGlobalLoader(true)
    this.props.setHomeSelectedTab(tabId)
    if(tabId.toString() === '0') {
      await this.loadRequestedData()
    } else if(tabId.toString() === '1') {
      await this.loadToAttendData()
    }
    // this.props.setGlobalLoader(false)
  }

  async loadRequestedData() {
    // lazy load for requested and toAttend
    // let resOpenRequestsByUser
    this.setState({ requestedServicesOpenedByUserLoading: true })
    this.setState({ requestedServicesOpenedLoading: true })
    // try {
    //   resOpenRequestsByUser = await axiosBW.get(`/case/open-requests-by-user/${this.props.companyId}`)
    //   if(!resOpenRequestsByUser || !resOpenRequestsByUser.data) {
    //     this.setState({ msgRequestedServicesOpenedByUser: 'No ha sido posible cargar los Servicios Abiertos' })
    //     // this.props.setGlobalLoader(false)
    //     return
    //   }
    //   this.setState({ requestedServicesOpenedByUserLoading: false })
    //   if(resOpenRequestsByUser.data.status === 0) {
    //     this.setState({
    //       requestedServicesOpenedByUser: resOpenRequestsByUser.data.payload
    //     })
    //   } else {
    //     this.setState({ msgRequestedServicesOpenedByUser: 'No ha sido posible cargar los Servicios Abiertos'})
    //   }
    // } catch (ex) {
    //   console.log('error case/open-requests-by-user', ex)
    //   this.setState({ msgRequestedServicesOpenedByUser: 'No ha sido posible cargar los Servicios Abiertos', requestedServicesOpenedByUserLoading: false})
    // }
    
    // lazy load for requested and toAttend
    let resOpenRequests
    try {
      
      resOpenRequests = await axiosBW.get(`/case/open-requests/${this.props.companyId}`)
    } catch (ex) {
      console.log('error case/open-requests', ex)
    }
    this.setState({ requestedServicesOpenedLoading: false })
    this.setState({ requestedServicesOpenedByUserLoading: false })
    if(!resOpenRequests || !resOpenRequests.data) {
      this.setState({ msgRequestedServicesOpened: 'No ha sido posible cargar los Servicios Abiertos' })
      this.setState({ msgRequestedServicesOpenedByUser: 'No ha sido posible cargar los Servicios Abiertos' })
      // this.props.setGlobalLoader(false)
      return
    }
    if(resOpenRequests.data.status === 0) {
      this.setState({
        requestedServicesOpened: resOpenRequests.data.payload.open,
        requestedServicesOpenedByUser: resOpenRequests.data.payload.openByUser
      })
    } else {
      this.setState({ msgRequestedServicesOpened: 'No ha sido posible cargar los Servicios Abiertos'})
      this.setState({ msgRequestedServicesOpenedByUser: 'No ha sido posible cargar los Servicios Abiertos' })
    }
  }
  async loadClosedRequests() {
    // closed-requests API Call
    let resClosedRequests
    try {
      this.setState({ requestedServicesClosedLoading: true })
      // this.props.setGlobalLoader(true)
      resClosedRequests = await axiosBW.get(`/case/closed-requests/${this.props.companyId}`)
      this.setState({ requestedServicesClosedLoading: false })

      // this.props.setGlobalLoader(false)
      if(!resClosedRequests || !resClosedRequests.data) {
        this.setState({ msgRequestedServicesClosed: 'No ha sido posible cargar los Servicios Cerrados' })
        return
      }
      if(resClosedRequests.data.status === 0) {
        this.setState({
          requestedServicesClosed: resClosedRequests.data.payload
        })
      } else {
        this.setState({ msgRequestedServicesClosed: 'No ha sido posible cargar los Servicios Cerrados'})
      }
    } catch (ex) {
      this.setState({ requestedServicesClosedLoading: false })
      // this.props.setGlobalLoader(false)
      console.log('error case/closed-requests')
      this.setState({ msgRequestedServicesClosed: 'No ha sido posible cargar los Servicios Cerrados'})
    }
  }
  async loadToAttendData() {
    //get toAttendAssigned services
    //let resOpenToAttendAssigned
    this.setState({ toAttendServicesAssignedOpenedLoading: true })
    this.setState({ toAttendServicesOpenedLoading: true })
    // try {
    //   resOpenToAttendAssigned = await axiosBW.get(`/case/open-assigned/${this.props.companyId}`)
    //   this.setState({ toAttendServicesAssignedOpenedLoading: false })
    //   if(!resOpenToAttendAssigned || !resOpenToAttendAssigned.data) {
    //     this.setState({ msgToAttendServicesAssignedOpened: 'No ha sido posible cargar los Servicios Abiertos' })
    //     // this.props.setGlobalLoader(false)
    //   }
    //   if(resOpenToAttendAssigned.data.status === 0) {
    //     this.setState({
    //       toAttendServicesAssignedOpened: resOpenToAttendAssigned.data.payload
    //     })
    //   } else {
    //     this.setState({ msgToAttendServicesAssignedOpened: 'No ha sido posible cargar los Servicios Abiertos'})
    //   }
    // } catch (ex) {
    //   this.setState({ toAttendServicesAssignedOpenedLoading: false })
    //   console.log('case/open-assigned')
    //   this.setState({ msgToAttendServicesAssignedOpened: 'No ha sido posible cargar los Servicios Abiertos'})
    // }
    //get toAttend services
    let resOpenToAttend
    try {
      
      resOpenToAttend = await axiosBW.get(`/case/open-to-attend/${this.props.companyId}`)
    } catch (ex) {
      console.log('case/open-to-attend')
    }
    this.setState({ toAttendServicesOpenedLoading: false })
    this.setState({ toAttendServicesAssignedOpenedLoading: false })
    if(!resOpenToAttend && !resOpenToAttend.data) {
      this.setState({ msgToAttendServicesOpened: 'No ha sido posible cargar los Servicios Abiertos' })
      this.setState({ msgToAttendServicesAssignedOpened: 'No ha sido posible cargar los Servicios Abiertos' })
      // this.props.setGlobalLoader(false)
    }
    if(resOpenToAttend.data.status === 0) {
      this.setState({
        toAttendServicesOpened: resOpenToAttend.data.payload.open,
        toAttendServicesAssignedOpened: resOpenToAttend.data.payload.openAssigned
      })
    } else {
      this.setState({ msgToAttendServicesOpened: 'No ha sido posible cargar los Servicios Abiertos'})
      this.setState({ msgToAttendServicesAssignedOpened: 'No ha sido posible cargar los Servicios Abiertos'})
    }
  }
  async loadClosedToAttend() {
     // closedToAttend API Call
     let resClosedToAttend
     try {
      // this.props.setGlobalLoader(true)
      this.setState({ toAttendServicesClosedLoading: true })
       resClosedToAttend = await axiosBW.get(`/case/closed-to-attend/${this.props.companyId}`,{
        timeout: 10*60*1000
       })
       this.setState({ toAttendServicesClosedLoading: false })
      //  this.props.setGlobalLoader(false)
       if(!resClosedToAttend || !resClosedToAttend.data) {
        this.setState({ msgToAttendServicesClosed: 'No ha sido posible cargar los Servicios Cerrados' })
        return
      }
  
      if(resClosedToAttend.data.status === 0) {
        this.setState({
          toAttendServicesClosed: resClosedToAttend.data.payload
        })
      } else {
        this.setState({ msgToAttendServicesClosed: 'No ha sido posible cargar los Servicios Cerrados'})
      }
     } catch (ex) {
      this.setState({ toAttendServicesClosedLoading: false })
       console.log('Error closed-to-attend', ex)
       this.setState({ msgToAttendServicesClosed: 'No ha sido posible cargar los Servicios Cerrados'})
     }
     
  }

  async setCurrentCaseStatus(caseId, processId) {
    this.props.setGlobalLoader(true)
    await axiosBW.get(`/process/next-exec/${caseId}`)
      .then((res) => {
        if(!res.data.payload.roleApproved) {
          this.props.history.push({
            pathname: `/servicios/resumen/${processId}/${caseId}`,
            state: { overview: res.data.payload.overview }
          })
        } else {
          localStorage.setItem('caseId', caseId)
          this.props.history.push({
            pathname: `/servicios/caso/${processId}/${caseId}/${res.data.payload.nextForm.slug}`,
            state: { caseId: caseId }
          })
        }
      })
      .catch((err) => {
        let message = ''
        let title = ''
        let vm = this
        if(err.response.data.message === 'Plan Limit Exceeded') {
          title = 'Plan excedido'
          message = 'Ha alcanzado el máximo de su plan. Por favor contactar a soporte para extender el plan.'
        } else if (err.response.status === 403) {
          title = 'Error'
          message = 'Hubo un error al ingresar. Es probable que usted no tenga acceso a crear o visualizar este caso '
        } else {
          title = 'Error'
          message = 'Hubo un error al intentar obtener el caso. por favor intente más tarde'
        }
        Modal.error({
          title: title,
          content: message,
          onOk() {
            vm.props.history.push({
              pathname: `/`
            })
          }
        })
        console.log('error', err.response)
      })
      this.props.setGlobalLoader(false)
  }
  tableComponents() {
    return {
      header: {
        cell: ResizeableTitle,
      }
    }
  }
  tableColumnsDesktop() {
    const columns = this.state.tableColumnsDesktop.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));
    // console.log(columns);
    return columns
  }
  // handleResize = index => (e, { size }) => {
  //   this.setState(({ columns }) => {
  //     const nextColumns = [...columns];
  //     nextColumns[index] = {
  //       ...nextColumns[index],
  //       width: size.width,
  //     };
  //     return { columns: nextColumns };
  //   });
  // };
  handleResize (index) {
    let _this=this;
    return function(e, { size }) {
      _this.setState(({ tableColumnsDesktop }) => {
        const nextColumns = [...tableColumnsDesktop];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return { tableColumnsDesktop: nextColumns };
      });
    }
    
  }
  tableColumnsMobile() {
    return [
      {
        title: 'PROCESO',
        dataIndex: 'processName',
        key: 'processName',
      },
      {
        title: 'Nº DE CASO',
        dataIndex: 'caseNumber',
        key: 'caseNumber',
      },
      {
        title: 'DETALLE',
        dataIndex: '',
        key: 'detalle',
        render: elem => (
          <div><div className="home__icon--table" onClick={(e) => {
            this.disconnectSockets()
            this.props.history.push(`/servicios/caso/${elem.processId}/${elem._id}`)
          }}
          > <FontAwesomeIcon icon="search" /> </div></div>
        )
      },
    ]
  }

  tableRequested() {
    return (
      this.state.requestedServicesOpenedLoading ? ( 
        <div className="home-table-loading"><LoadingOutlined /></div>
      ) : this.state.requestedServicesOpened.length > 0 && Array.isArray(this.state.requestedServicesOpened) ? (
        <Table components={this.tableComponents()}  pagination={{ position: ['bottomCenter'],showSizeChanger: false }} rowKey={obj => obj._id} dataSource={this.props.requestedServicesOpenedFilter || this.state.requestedServicesOpened} columns={this.props.isMobile ? this.tableColumnsMobile() : this.tableColumnsDesktop()  } />
      ) : (
        <div className="no_data">No existen datos actualmente</div>
      )
    )
  }
  tableRequestedByUser() {
    return (
      this.state.requestedServicesOpenedByUserLoading ? ( 
        <div className="home-table-loading"><LoadingOutlined /></div>
      ) : this.state.requestedServicesOpenedByUser.length > 0 && Array.isArray(this.state.requestedServicesOpenedByUser) ? (
        <Table components={this.tableComponents()}  pagination={{ position: ['bottomCenter'],showSizeChanger: false }} rowKey={obj => obj._id} dataSource={this.props.requestedServicesOpenedByUserFilter || this.state.requestedServicesOpenedByUser} columns={this.props.isMobile ? this.tableColumnsMobile() : this.tableColumnsDesktop()  } />
      ) : (
        <div className="no_data">No existen datos actualmente</div>
      )
    )
  }
  tableRequestedClosed() {
    return (
      this.state.requestedServicesClosedLoading ? ( 
        <div className="home-table-loading"><LoadingOutlined /></div>
      ) : this.state.requestedServicesClosed.length > 0 && Array.isArray(this.state.requestedServicesClosed) ? (
        <Table components={this.tableComponents()} pagination={{ position: ['bottomCenter'],showSizeChanger: false }} rowKey={obj => obj._id} dataSource={this.props.requestedServicesClosedFilter || this.state.requestedServicesClosed} columns={this.props.isMobile ? this.tableColumnsMobile() : this.tableColumnsDesktop() } />
      ) : (
        <div className="no_data">No existen datos actualmente</div>
      )
  )
  }

  tableToAttend() {
    return (
      this.state.toAttendServicesOpenedLoading ? ( 
        <div className="home-table-loading"><LoadingOutlined /></div>
      ) : this.state.toAttendServicesOpened.length > 0 ? (
        <Table components={this.tableComponents()} pagination={{ position: ['bottomCenter'],showSizeChanger: false }} rowKey={obj => obj._id} dataSource={this.props.toAttendServicesOpenedFilter || this.state.toAttendServicesOpened} columns={this.props.isMobile ? this.tableColumnsMobile() : this.tableColumnsDesktop() } />
      ) : (
        <div className="no_data">No existen datos actualmente</div>
      )
    )
  }
  tableToAttendAssigned() {
    return (
      this.state.toAttendServicesAssignedOpenedLoading ? ( 
        <div className="home-table-loading"><LoadingOutlined /></div>
      ) : this.state.toAttendServicesAssignedOpened.length > 0 ? (
        <Table components={this.tableComponents()} pagination={{ position: ['bottomCenter'],showSizeChanger: false }} rowKey={obj => obj._id} dataSource={this.props.toAttendServicesAssignedOpenedFilter || this.state.toAttendServicesAssignedOpened} columns={this.props.isMobile ? this.tableColumnsMobile() : this.tableColumnsDesktop() } />
      ) : (
        <div className="no_data">No existen datos actualmente</div>
      )
    )
  }
  tableToAttendClosed() {
    return (
      this.state.toAttendServicesClosedLoading ? ( 
        <div className="home-table-loading"><LoadingOutlined /></div>
      ) : this.state.toAttendServicesClosed.length > 0 ? (
          <Table components={this.tableComponents()} pagination={{ position: ['bottomCenter'],showSizeChanger: false }} rowKey={obj => obj._id} dataSource={this.props.toAttendServicesClosedFilter || this.state.toAttendServicesClosed } columns={this.props.isMobile ? this.tableColumnsMobile() : this.tableColumnsDesktop() } />
      ) : (
        <div className="no_data">No existen datos actualmente</div>
      )
    )
  }

  tabsServices() {
    return [
      {
        title: 'Bandeja de salida',
        content: (
          <div className={this.props.isMobile ? "tabs_cases_mobile": "tabs_cases"}>
            <div className="requested">
              <div className="home__subtitle">SERVICIOS ABIERTOS SOLICITADOS POR MI<Input className="search-home-input" placeholder='Filtrar...' size={'large'} bordered={false} prefix={<SearchOutlined />} value={this.props.requestedServicesOpenedByUserFilterText} onChange={ e => this.searchTable(e.target.value, 'requestedServicesOpenedByUser')}></Input></div>
              {this.tableRequestedByUser()}
            </div>
            <div className="requested">
              <div className="home__subtitle">ABIERTOS <Input className="search-home-input" placeholder='Filtrar...' size={'large'} bordered={false} prefix={<SearchOutlined />} value={this.props.requestedServicesOpenedFilterText} onChange={ e => this.searchTable(e.target.value, 'requestedServicesOpened')}></Input></div>
              {this.tableRequested()}
            </div>
            <Collapse
              bordered={false}
              className="closed_cases_collapse"
              expandIcon={({ isActive }) => <DownCircleOutlined rotate={isActive ? 0 : -90} />}
              expandIconPosition={'left'}
              onChange={(e) => this.handleClosedCasesCollapse(e)}
              collapsible="header"
            >
              <Panel  header="CERRADOS" className="customPanelStyle"  key="1" >
                <div className="requested--closed">
                  <div className="home__subtitle"> <Input className="search-home-input" placeholder='Filtrar...' size={'large'} bordered={false} prefix={<SearchOutlined />} value={this.props.requestedServicesClosedFilterText} onChange={ e => this.searchTable(e.target.value, 'requestedServicesClosed')}></Input></div>
                  {this.tableRequestedClosed()}
                </div>
                
              </Panel>

            </Collapse>
            
          </div>
        ) 
      },
      {
        title: 'Bandeja de entrada',
        content: (
          <div className={this.props.isMobile ? "tabs_cases_mobile": "tabs_cases"}>
            <div className="attend">
              <div className="home__subtitle">MIS CASOS <Input className="search-home-input" placeholder='Filtrar...' size={'large'} bordered={false} prefix={<SearchOutlined />} value={this.props.toAttendServicesAssignedOpenedFilterText} onChange={ e => this.searchTable(e.target.value, 'toAttendServicesAssignedOpened')}></Input></div>
              {this.tableToAttendAssigned()}
            </div>
            <div className="attend">
              <div className="home__subtitle">ABIERTOS <Input className="search-home-input" placeholder='Filtrar...' size={'large'} bordered={false} prefix={<SearchOutlined />} value={this.props.toAttendServicesOpenedFilterText} onChange={ e => this.searchTable(e.target.value, 'toAttendServicesOpened')}></Input></div>
              {this.tableToAttend()}
            </div>
            <Collapse
              bordered={false}
              className="closed_cases_collapse"
              expandIcon={({ isActive }) => <DownCircleOutlined rotate={isActive ? 0 : -90} />}
              expandIconPosition={'left'}
              onChange={(e) => this.handleClosedCasesCollapse(e)}
              collapsible="header"
            >
              <Panel header="CERRADOS" className="customPanelStyle" key="1" >
                <div className="requested--closed">
                  <div className="home__subtitle"><Input className="search-home-input" placeholder='Filtrar...' size={'large'} bordered={false} prefix={<SearchOutlined />} value={this.props.toAttendServicesClosedFilterText} onChange={ e => this.searchTable(e.target.value, 'toAttendServicesClosed')}></Input></div>
                  {this.tableToAttendClosed()}
                </div>
                
              </Panel>

            </Collapse>
          </div>
        ) 
      },
    ]
  }
  collapseHeader () {
    return (
      <Row>
        <Col span={13} className="home__services_blocks_featured">
          <div className="home__title--desktop"> Servicios destacados </div>
        </Col>
        <Col span={2}></Col>
        <Col span={9} className="home__services_blocks_favorites">
          <div className="home__title--desktop"> Proveedores destacados </div>
        </Col>
      </Row>
      
    )
  }
  collapseHeaderCollapse () {
    return (
      <Row>
        <Col span={18}></Col>
        <Col span={6} className="home__services_blocks_favorites">
          <div className="home__title--desktop__collapsed"> Mostrar Servicios y Proveedores </div>
        </Col>
      </Row>
      
    )
  }
  handleCollapse (e, first) {
    this.setState({
      featuredCollapse: e.length > 0 ? false : true
    })
    try {
      axiosBW.post(`/users/change-featuredl-collapse/`,{
        featuredCollapse: e.length > 0 ? false : true
      })
    } catch (ex) {
      // console.log('case/open-to-attend')
    }
    // console.log(this.state.featuredCollapse);
  }
 async handleClosedCasesCollapse(e) {
    if (e.length > 0) {
      if (this.props.homeSelectedTab.toString() === '0') {
        await this.loadClosedRequests()
      } else {
        await this.loadClosedToAttend()
      }
    }
    this.setState({
      closedCasesCollapseActive: e.length > 0 ? true : false
    })
  }

  render() {
    const tabs = this.tabsServices()
    return (
      <div className={this.props.isMobile ? "home_layout_mobile": "home_layout"}>
        {this.props.isMobile ? (<div style={{paddingTop: '10px'}} />) : null}
        {this.props.isMobile ? null : (
          <Row className="home__services_blocks--desktop">
            <Collapse
              bordered={false}
              className="home_collapse"
              // defaultActiveKey={['1']}
              activeKey={!this.state.featuredCollapse ? ['1']: []}
              expandIcon={({ isActive }) => <DownCircleOutlined rotate={isActive ? 90 : 0} />}
              expandIconPosition={'right'}
              onChange={(e) => this.handleCollapse(e)}
              // {...this.state.featuredCollapseProps}
            >
              <Panel header={this.state.featuredCollapse ?  this.collapseHeaderCollapse() : this.collapseHeader()} className="customPanelStyle" key="1" >
                <Row>
                  <Col span={13} className="home__services_blocks_featured">
                    {/* <div className="home__title--desktop"> Servicios destacados </div> */}
                    <FeaturedServicesScroll defaultWidth='100%' type="desktop" featuredList={this.state.featuredServices} statusMsg={this.state.msgFeaturedServices} />
                  </Col>
                  <Col span={2}></Col>
                  <Col span={9} className="home__services_blocks_favorites">
                    {/* <div className="home__title--desktop"> Proveedores destacados </div> */}
                    <FeaturedProvidersScroll type="desktop" favoriteList={this.state.favoriteProviders} statusMsg={this.state.msgFavoriteProviders} />
                  </Col>
                </Row>
                
              </Panel>

            </Collapse>
            
          </Row>
        )}
        <Row>
          <Tabs
            onChange={this.onChangeTabHome}
            activeKey={this.props.homeSelectedTab.toString()}
            className={ `tabs_home ${this.props.isMobile ? 'tabs-mobile' : ''}`}
            // type="line"
            // pagination={false}
          >
            {tabs.map((pane, i) => (
              <TabPane tab={pane.title} key={i}>
                {pane.content}
              </TabPane>
            ))}
          </Tabs>
          <Helmet>
            <title> {this.props.fullName || 'Invitado'} - Blizwork</title>
          </Helmet>
        </Row>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    isMobile: state.navigation.isMobile,
    homeSelectedTab: state.navigation.homeSelectedTab,
    requestedServicesOpenedFilter: state.home.requestedServicesOpenedFilter,
    requestedServicesOpenedByUserFilter: state.home.requestedServicesOpenedByUserFilter,
    requestedServicesClosedFilter: state.home.requestedServicesClosedFilter,
    toAttendServicesOpenedFilter: state.home.toAttendServicesOpenedFilter,
    toAttendServicesAssignedOpenedFilter: state.home.toAttendServicesAssignedOpenedFilter,
    toAttendServicesClosedFilter: state.home.toAttendServicesClosedFilter,
    requestedServicesOpenedFilterText: state.home.requestedServicesOpenedFilterText,
    requestedServicesOpenedByUserFilterText: state.home.requestedServicesOpenedByUserFilterText,
    requestedServicesClosedFilterText: state.home.requestedServicesClosedFilterText,
    toAttendServicesOpenedFilterText: state.home.toAttendServicesOpenedFilterText,
    toAttendServicesAssignedOpenedFilterText: state.home.toAttendServicesAssignedOpenedFilterText,
    toAttendServicesClosedFilterText: state.home.toAttendServicesClosedFilterText,
    companyId: state.user.companyId,
    fullName: state.user.fullName,
    userInfo: state.user
  }
}

const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState)),
  setHomeSelectedTab: (selectedTab) => dispatch(setHomeSelectedTab(selectedTab)),
  changeFilter: (filter, value, text) => dispatch(changeFilter(filter, value, text))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
