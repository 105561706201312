import React from 'react'
import { connect } from 'react-redux'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { Link } from 'react-router-dom'

import 'pure-react-carousel/dist/react-carousel.es.css';
import './FeaturedServicesHall.scss'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { setGlobalLoader } from '../../../../actions/actions'

const FeaturedServicesHall = props => {
  return (
    <div style={{ width: props.defaultWidth, margin: '0 auto' }}>

      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={props.featuredList.length}
        visibleSlides={props.isMobile ? 2 : 5}
      >
        <Slider style={{ height: props.isMobile ? '140px' : '120px', marginLeft: props.isMobile ? '-20px' : '20px', marginRight: props.isMobile ? '20px' : '30px' }}>
          {
            props.featuredList.length > 0 ? (
              props.featuredList.map((elem, index) => {
                return (
                  <>
                    <Slide key={`slidex${index}`} index={index}>
                      <Link to={`/servicios/caso/${elem._id}/inicio`} key={`featured_${index}`} className={`featured_services_hall__element ${props.type === 'desktop' ? 'featured_services_hall__element--desktop' : ''}`}>
                        <div className={`featured_services__header`}>{elem.companyName}</div>
                        <div className={`featured_services__image`}>
                          {
                            !elem.imagePath || elem.imagePath.includes('/path/to/image') ? (
                              <div className="no_image">No Image</div>
                            ) : (
                              <img src={elem.imagePath} alt={elem.title} />
                            )
                          }
                        </div>
                        <div className={`featured_services__description`}>{elem.title}</div>
                      </Link>

                    </Slide>


                  </>


                )
              })
            ) : (
              <div className={`featured_services__no_list`}>
                <p>{props.statusMsg}</p>
              </div>
            )
          }

        </Slider>
        <ButtonBack style={{ left: `${props.type !== 'desktop' ? '-15px' : '-25px'}` }} ><LeftOutlined /></ButtonBack>
        <ButtonNext><RightOutlined /></ButtonNext>
      </CarouselProvider>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  setGlobalLoader: (newState) => dispatch(setGlobalLoader(newState))
})

const mapStateToProps = state => ({
  isMobile: state.navigation.isMobile
})

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedServicesHall)