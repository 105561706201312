import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Modal, Row, Col, Collapse, Tabs, Table, Badge, Input, Button, Form, Image,Checkbox } from 'antd';
import './Invitation.scss'
// import axios from 'axios'
// import cf from '../../../config/APIConfig'
import axiosBW from '../../../utils/Http';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setGlobalLoader } from '../../../actions/actions'
import { setUserData } from '../../../actions/userActions'
import { Helmet } from "react-helmet";
import ErrorList from 'antd/lib/form/ErrorList';

const Invitation = (props) => {
  const dispatch = useDispatch();
  console.log({props})
  const isMobile = useSelector(state => state.navigation.isMobile)
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    console.log('Received values of form: ', values);
    dispatch(setGlobalLoader(true))
    try {
      const resp = await axiosBW.post(`/auth/finish-invitation/`,
        {
          invitationId: props.match.params.invitationId,
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName
        }
      );
      console.log(resp.data)
      const data = resp.data.payload
      dispatch(setUserData(data.user, data.token))
      Modal.info({
        className: "invitation_modal_success",
        icon: null,
        width: 586,
        footer: null,
        okText: 'OK',
        title: (<div style={{alignContent: 'center', textAlign: 'center', fontSize: '30px'}}>¡Bienvenid@ a Blizwork!</div>),
        content: (<div style={{alignContent: 'center', textAlign: 'center', fontSize: '17px'}}>
            <div>
              Serás redirigido en un momento
            </div>
          </div>)
      })
      setTimeout(() => {
        if (data.user.haveToCompleteData) {
          this.props.history.push(`/ultimo_paso`)
        } else if (data.user.firstRedirect) {
          this.props.history.push(`/${data.user.firstRedirect}`)
        } else {
          this.props.history.push('/')
        }
      }, 2000);
    } catch (err) {
      localStorage.clear()
      if (err.response && err.response.data && err.response.data.message) {
        const respMsg = err.response.data.message
        Modal.error({
          title: 'Error',
          content: respMsg
        })
      }
      setTimeout(() => {
        props.history.push('/')
      }, 2000);
    }
  };
  
  const showModal = () => {
    setVisible(true)
  };
  const cancelModal = () => {
    setVisible(false)
    form.resetFields()
  }

  // Reemplazo de componentDidMount
  useEffect( () => {
    console.log('va el UseEffect');
    async function getInvitationInfo() {
      try {
        const resp = await axiosBW.get(`/auth/invitation-info/${props.match.params.invitationId}`);
          console.log(resp)
          const data = resp.data.payload
          form.setFieldsValue({
            email: data.email,
            confirm: data.email,
            companyName: data.companyName
          });
          dispatch(setGlobalLoader(false))
      } catch (err) {
        console.log(err) 
        if (err.response && err.response.data && err.response.data.message) {
          const respMsg = err.response.data.message
          Modal.error({
            title: 'Error',
            content: respMsg
          })
        }
        localStorage.clear()
        setTimeout(() => {
          props.history.push('/')
        }, 2000);
      }
    }
    dispatch(setGlobalLoader(true))
    getInvitationInfo()
    
    // dispatch(getMyTeamInfo())
  }, []);

  // const dispatch = useDispatch();
  // //useSelector reemplaza el mapStateToProps
  // const users = useSelector(state => state.workflowDesigner.usersByRole);
  // const companyId = useSelector(state => state.workflowDesigner.workflow.companyId);
  
  // if (!users) {
  //   return 'No USERS'
  // }
  return (
    <div className="invitation_back">
      <div className="invitation_wrapper">
        <Row>
          <Col flex="0 1 auto">
            {
              !isMobile ? (
                <Image
                  preview={false}
                  src='\img\atenea-a-un-click.png'
                />
              ) : ''
            }
            
            </Col>
          <Col flex="auto">
            <div className="invitation_form_wrapper">
              <div className="invitation_form_title">
                ¿Completemos tu perfil?
              </div>
              <div className="invitation_form_subtitle">
                Completa tus datos a continuación y podrás comenzar a usar BlizWork
              </div>
                <Form
                form={form}
                size="large"
                name="register"
                onFinish={onFinish}
                preserve={false}
                scrollToFirstError
              >
                <div className={!isMobile ? 'invitation_form_inline' : ''}>
                  <Form.Item
                    className="invitation_form_field"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: 'Nombre requerido',
                      },
                    ]}
                  >
                    <Input  placeholder="Nombre" />
                  </Form.Item>
                  <Form.Item
                    className="invitation_form_field"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: 'Apellido requerido',
                      }
                    ]}
                  >
                    <Input size="large" placeholder="Apellido" />
                  </Form.Item>
                </div>
                <div className={!isMobile ? 'invitation_form_inline' : ''}>
                  <Form.Item
                    className="invitation_form_field"
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'Email inválido',
                      },
                      {
                        required: true,
                        message: 'Email requerido',
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Ingrese el Email" disabled={true} />
                  </Form.Item>
                  <Form.Item
                    className="invitation_form_field"
                    name="confirm"
                    dependencies={['email']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Por favor confirma el Email',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('email') === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject('El Email no coincide');
                        },
                      }),
                    ]}
                  >
                    <Input size="large" placeholder="Confirmar el Email" disabled={true} />
                  </Form.Item>
                </div>
                <div className={!isMobile ? 'invitation_form_inline' : ''}>
                  <Form.Item
                    className="invitation_form_field"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Email requerido',
                      },
                    ]}
                  >
                    <Input.Password placeholder="Contraseña" />
                  </Form.Item>
                  <Form.Item
                    className="invitation_form_field"
                    name="confirmPassword"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Por favor confirma la contraseña',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject('Las contraseñas no coinciden');
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Confirmar la contraseña" />
                  </Form.Item>
                </div>
                <Form.Item
                  className="invitation_form_field"
                  name="companyName"
                  style={{ width: '100% ' }}
                  rules={[
                    {
                      required: true,
                      message: 'Empresa requerida',
                    },
                  ]}
                >
                  <Input  disabled={true} placeholder="Empresa" />
                </Form.Item>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject('Debes aceptar los términos y condiciones'),
                    },
                  ]}
                >
                  <Checkbox>
                    Acepto los <a href="">términos y condiciones</a>
                  </Checkbox>
                </Form.Item>
                <Button type="primary" htmlType="submit"
                >
                  ¡Listo!
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}


export default Invitation