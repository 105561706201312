import { combineReducers } from 'redux'
import navigation from './navigation'
import home from './home'
import process from './process'
import workflowDesigner from './workflowDesigner'
import myTeam from './myTeam'
import user from './user'
import formBuilder from '../pages/ChiefPages/FormBuilder/FormBuilder.reducer'
import formList from '../pages/ChiefPages/FormList/FormList.reducer'
// root reducer
export default combineReducers({
  navigation,
  process,
  formBuilder,
  formList,
  workflowDesigner,
  myTeam,
  user,
  home
})
